/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from 'react-bootstrap';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, Dialog, DialogActions, DialogContent, DialogContentText, Slide, Typography, AppBar, Toolbar } from '@material-ui/core';
import useStyles from '../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import PageviewIcon from '@material-ui/icons/Pageview';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'react-moment';
import mControl from './KasMasuk.controller'
import moment from 'moment-timezone'
import numberWithCommas from '../../Helper/numberWithCommas'
import { FaChevronLeft } from 'react-icons/fa';


moment.tz("Asia/Jakarta")
const dateNow = moment();
const dateSkrg = moment(dateNow).tz("Asia/Jakarta").set({
    date: 1,
    hour: 0,
    minute: 0,
    second: 0
});
const bulanDepan = moment(dateNow).add(1, 'months');
const dateAkhir = moment(bulanDepan).tz("Asia/Jakarta").set({
    date: 1,
    hour: 23,
    minute: 59,
    second: 59
}).subtract(1, 'days');
const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: "noinvoice", label: "No Transaksi" },
    { id: "tanggal", label: "Tanggal Transaksi" },
    { id: "sessionId", label: "Session ID" },
    { id: "total", label: "Total" },


];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell>Action</TableCell>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const KasKeluarTransaksi = (props) => {
    const kodeoutlet = props.kodeoutlet;
    const defaultPage = 0;
    const defaultrowsPerPage = 5;
    const clientID = props.clientID
    const token = props.token
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [dense, setDense] = React.useState(true);
    const [isRefresh, setIsRefresh] = useState(true)
    const [rows, setRows] = useState([])
    const [openRiwayat, setOpenRiwayat] = useState(true)
    const [openTgl, setOpenTgl] = useState(false)
    const [tglDari, setTglDari] = useState(dateSkrg)
    const [tglSampai, setTglSampai] = useState(dateAkhir)
    const [scroll, setScroll] = useState('paper');
    const [open, setOpen] = useState(false);
    const [notrans, setNotrans] = useState()
    const [transDet, setTransDet] = useState([])
    let totalDepan = 0, totalDetail = 0
    useEffect(() => {
        var render = true
        if (isRefresh) {
            setRows([])
            mControl.loadDataTransaksi(kodeoutlet, clientID, token, tglDari, tglSampai).then(resp => {
                if (resp.length === 0) {
                    alert("Tidak ada transaksi")
                    handleOpenTgl()
                    return
                }
                if (render) setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh]);
    const handleView = (kodetrans) => {
        setNotrans(kodetrans)
        setOpen(true)
        setTransDet([])
        mControl.loadDataTransaksiDetail(kodeoutlet, clientID, token, kodetrans).then(resp => {
            setTransDet(resp);
        }).catch(err => {
            console.error(err);
        })
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const loadingRow = [0, 1, 2, 3, 4];
    const TglDariHandler = (val) => {
        setTglDari(moment(val).format("D MMM YYYY"))
    }
    const TglSampaiHandler = (val) => {
        setTglSampai(moment(val).format("D MMM YYYY"))
    }
    const handleOpenRiwayat = () => {
        setOpenTgl(false)
        setOpenRiwayat(true);
        setIsRefresh(true);
    }
    const handleOpenTgl = () => {
        setOpenRiwayat(false);
        setOpenTgl(true)
        setPage(defaultPage)
        setRowsPerPage(defaultrowsPerPage)
    }
    function handleCloseMoidalDetPem() {
        setOpen(false);
    }
    return (
        <React.Fragment>
            {openTgl && (
                <Container>
                    <Row>
                        <Col xs={12} lg={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDatePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Dari"
                                    format="DD MMM YYYY"
                                    value={tglDari}
                                    onChange={TglDariHandler}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} lg={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDatePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Sampai"
                                    format="DD MMM YYYY"
                                    value={tglSampai}
                                    onChange={TglSampaiHandler}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={12} className="text-center"><br />
                            <Button variant="contained" color="primary" onClick={handleOpenRiwayat}>Lihat Laporan</Button>
                        </Col>
                    </Row>
                </Container>
            )}
            {openRiwayat && (
                <div >
                    <Paper className={classes.paper}>
                        <div className="btnTable">
                            <span>
                                <b>
                                    <Moment format="D MMM YYYY">{tglDari}</Moment>
                                    &nbsp;-&nbsp;
                                            <Moment format="D MMM YYYY">{tglSampai}</Moment>
                                </b>
                            </span>
                            <span className="ubahTanggal" onClick={handleOpenTgl}> (Ubah Pencarian)</span>
                            {rows.map(row => {
                                totalDepan += row.total
                            })}
                            <h6>Total  {numberWithCommas(totalDepan)} dari {rows.length} transaksi</h6>
                        </div>
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {rows.length == 0 && loadingRow.map(el => (
                                        <TableRow
                                            key={el.toString()}
                                            style={{ height: 50 }}
                                        >
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell >
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                        </TableRow>
                                    ))

                                    }

                                    {stableSort(rows, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row._id}
                                                    className={(row.statusid === 8 || row.statusid === 9) && ("bgRowCancel") || ("")}
                                                >
                                                    <TableCell>
                                                        <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleView.bind(this, row.noinvoice)}><PageviewIcon /></Button>
                                                    </TableCell>
                                                    <TableCell >
                                                        {row.noinvoice}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Moment format="D-MMM-YYYY">{moment(row.tanggal).tz("Asia/Jakarta").format("DD-MMM-YYYY")}</Moment>
                                                    </TableCell>
                                                    <TableCell >
                                                        {row.sessionId}
                                                    </TableCell>
                                                    <TableCell>
                                                        {numberWithCommas(row.total)}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={7} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "previous page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "next page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                        label="Dense padding"
                    />
                </div>
            )}
            <Dialog
                open={open}
                onClose={handleCloseMoidalDetPem}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar>
                    <Toolbar>
                        <FaChevronLeft color="inherit" onClick={handleCloseMoidalDetPem} className="tangan" />
                        <Typography variant="h6">
                            Detail Laporan Kas Keluar</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers={scroll === 'paper'} className="DialogFont bodyDialog">
                    <DialogContentText className="colorWhite">
                        <h6 className="text-center">No Transaksi : {notrans}</h6>
                        <Table>
                            <TableHead>
                                <TableRow className="bgTabel">
                                    <TableCell className="colorPutih" >Kode Biaya</TableCell>
                                    <TableCell className="colorPutih">Nama Biaya</TableCell>
                                    <TableCell className="colorPutih">Keterangan</TableCell>
                                    <TableCell className="colorPutih text-right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transDet.map((el, i) => {
                                    totalDetail += el.amount
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{el.kodebiaya} </TableCell>
                                            <TableCell>{el.namabiaya} </TableCell>
                                            <TableCell>{el.keterangan} </TableCell>
                                            <TableCell className="text-right">{numberWithCommas(el.amount)} </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow >
                                    <TableCell colSpan="3"><b>Total</b></TableCell>
                                    <TableCell className="colorWhite text-right"><b>{numberWithCommas(totalDetail)}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    );
}
export default KasKeluarTransaksi;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});