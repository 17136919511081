import React, { useState, useEffect } from 'react'
import mControl from './Diskon.Controler'
import { Row, Col } from 'react-bootstrap';
import { Toolbar, Typography, DialogContent, DialogContentText, Button, TextField, Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft } from 'react-icons/fa';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import mKategori from './../Kategori/Kategori.controller'
import mSubkategori from './../SubKategori/SubKategori.controller'

import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const tglBesok = moment().add(1, 'days')

const Modal = (props) => {
    const kodeoutlet = props.kodeoutlet
    const clientID = props.clientID
    const token = props.token
    const type = props.type

    const [listBarangFilter, setListBarangFilter] = useState([])
    const [listKategori, setListKategori] = useState([])
    const [listSubKategori, setListSubKategori] = useState([])

    const [form, setForm] = useState({
        kategoriAndSubCategory: "",
        tglmulai: tglBesok,
        tglselesai: tglBesok,
        harga1: 0,
        harga2: 0,
        harga3: 0,
        harga4: 0,
        harga5: 0,
    });

    useEffect(() => {
        var render = true;
        if (type === "Kategori") {
            mKategori.loadData(kodeoutlet, clientID, token).then(resp => {
                if (render) {
                    setListKategori(resp);
                }
            })
        } else {
            mSubkategori.loadData(kodeoutlet, clientID, token).then(resp => {
                if (render) {
                    setListSubKategori(resp);
                }
            })
        }
        return () => {
            render = false;
        };
    }, [kodeoutlet, clientID, token, type])


    const handleClose = () => {
        props.setOpen(false)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        if (key === "kategoriAndSubCategory") {
            if (type === "Kategori") {
                let Data = props.listBarang.filter(el => el.kodekategori === value);
                setListBarangFilter(Data);
            } else {
                let Data = props.listBarang.filter(el => el.kodesubkategori === value);
                setListBarangFilter(Data);
            }
        }
        if ((key === 'harga1' || key === 'harga2' || key === 'harga3' || key === 'harga4' || key === 'harga5') && value > 100) {
            return alert('Persentase Tidak Boleh Melebihi 100%')
        }
        setForm(newForm);
    }
    const handleSimpan = () => {
        if (moment(form.tglmulai) < moment(form.tglselesai)) {
            return listBarangFilter?.forEach((el, i) => {
                const harga1 = (el.hargasatuan > 0 && form.harga1 > 0) ? (el.hargasatuan - (el.hargasatuan * form.harga1 / 100)) : 0;
                const harga2 = (el.hargagojek > 0 && form.harga2 > 0) ? (el.hargagojek - (el.hargagojek * form.harga2 / 100)) : 0;
                const harga3 = (el.hargagrab > 0 && form.harga3 > 0) ? (el.hargagrab - (el.hargagrab * form.harga3 / 100)) : 0;
                const harga4 = (el.hargalain > 0 && form.harga4 > 0) ? (el.hargalain - (el.hargalain * form.harga4 / 100)) : 0;
                const harga5 = (el.hargalain2 > 0 && form.harga5 > 0) ? (el.hargalain2 - (el.hargalain2 * form.harga5 / 100)) : 0;
                mControl
                    .saveDiskon(kodeoutlet, clientID, token, "", el.kodebarang, el.namabarang, form.tglmulai, form.tglselesai, harga1, harga2, harga3, harga4, harga5)
                    .then(resp => {
                        if (i + 1 === listBarangFilter.length) {
                            alert("Data Berhasil Disimpan")
                            handleClose()
                            props.setIsRefresh(true)
                            props.setIsRefreshBerjalanMendatang(true)
                        }
                    }).catch(err => {
                        if (err.length > 0) {
                            alert(err[0].message)
                        } else if (typeof err) {
                            alert(err.message)
                        } else {
                            console.log(err);
                        }
                    })
            })

        } else {
            alert("Tanggal mulai harus dibawah tanggal selesai")
        }
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <FaChevronLeft color="inherit" onClick={handleClose} className="tangan" />
                    <Typography variant="h6">
                        Add Diskon - {type}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="DialogFont bodyDialog">
                <DialogContentText className="colorWhite">
                    <Row>
                        <Col xs={12} lg={12} style={{ marginTop: 10 }}>

                            <FormControl fullWidth={true}>

                                <InputLabel>{type === "Kategori" ? "Kategori" : "Sub Kategori"} </InputLabel>
                                <Select
                                    name="kategoriAndSubCategory"
                                    id="kategoriAndSubCategory"
                                    required
                                    value={form.kategoriAndSubCategory}
                                    onChange={handleChange}
                                >
                                    {type === "Kategori" ? (
                                        listKategori.map(el => (
                                            <MenuItem key={el.kodekategori} value={el.kodekategori}>{el.namakategori}</MenuItem>
                                        ))

                                    ) : (
                                        listSubKategori.map(el => (
                                            <MenuItem key={el.kodesubkategori} value={el.kodesubkategori}>{el.namasubkategori}</MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} lg={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDateTimePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Mulai"
                                    format="DD-MM-YYYY HH:mm"
                                    value={form.tglmulai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    onChange={(e) => setForm({ ...form, tglmulai: e })}
                                    ampm={false}
                                    minDate={tglBesok}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDateTimePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Sampai"
                                    format="DD-MM-YYYY HH:mm"
                                    value={form.tglselesai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{ color: '#000 !important' }}
                                    onChange={(e) => setForm({ ...form, tglselesai: e })}
                                    ampm={false}
                                    minDate={tglBesok}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga1"
                                name="harga1"
                                label="Harga Satuan Persen(%)"
                                fullWidth
                                type="number"
                                value={form.harga1}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga2"
                                name="harga2"
                                label="Harga Gojek  Persen(%)"
                                fullWidth
                                type="number"
                                value={form.harga2}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga3"
                                name="harga3"
                                label="Harga Grab  Persen(%)"
                                fullWidth
                                type="number"
                                value={form.harga3}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga4"
                                name="harga4"
                                label="Harga Lainya  Persen(%)"
                                fullWidth
                                type="number"
                                value={form.harga4}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga5"
                                name="harga5"
                                label="Harga Lainya 2  Persen(%)"
                                fullWidth
                                type="number"
                                value={form.harga5}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 20 }}>
                        <Col xs={12} className="text-center">
                            <Button variant="contained" color="primary" fullWidth onClick={handleSimpan}>Simpan</Button>
                        </Col>
                    </Row>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;