import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import { Line, } from 'react-chartjs-2';
import mLaporan from '../Laporan.controler'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { BoxInformasi, Item1, Item2 } from './SalesSummaryBox'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const dateNow = moment();
const SalesSummary = (props) => {
    const classes = useStyles();
    const [isRefresh, setIsRefresh] = useState(true)
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const { clientID, token } = useContext(AuthContext);
    const kodeoulet = props.kodeOutlet
    const [listPenjualanPerjam, setListPenjualanPerjam] = useState([])
    let totalPengunjung = 0, totalTrans = 0, totalPenjualan = 0



    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.laporanPerJam(clientID, token, kodeoulet, tglDari, tglSampai).then(resp => {
                setListPenjualanPerjam(resp.penjualanPerJam)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])



    const lihatLaporan = () => {
        setIsRefresh(true)
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} md={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Dari"
                            format="DD-MM-YYYY HH:mm"
                            value={tglDari}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            onChange={handleChangeTglDari}
                            ampm={false}
                            disableFuture
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Sampai"
                            format="DD-MM-YYYY HH:mm"
                            value={tglSampai}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ color: '#000 !important' }}
                            onChange={handleChangeTglSampai}
                            ampm={false}
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={2} className="text-center"><br />
                    <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                </Col>
            </Row>
            <Row className="marginGrafik">
                <Col xs={12}>
                    <ReactHTMLTableToExcel
                        className="buttonExportExcel"
                        table="laporanPerJam"
                        filename={`Laporan Per Jam ${moment(tglDari).format("DD-MM-YYYY HH mm")} sampai ${moment(tglSampai).format("DD-MM-YYYY HH mm")}`}
                        sheet="Sheet1"
                        buttonText={namaExport()} />
                    <Table id="laporanPerJam" striped bordered hover responsive>
                        <thead>
                            <tr className="colorWhite bgHeaderTabel tabelCenter">
                                <th>Jam</th>
                                <th>Total Tamu</th>
                                <th>Total Transaksi</th>
                                <th>Total Penjualan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listPenjualanPerjam.map((data, index) => {
                                totalPengunjung += data.pengunjung
                                totalTrans += data.totalTrans
                                totalPenjualan += data.totalJual
                                return (
                                    <tr className="colorWhite" key={index}>
                                        <td className="text-center">
                                            {data.jam}
                                        </td>
                                        <td className="text-center">
                                            {data.pengunjung}
                                        </td>
                                        <td className="text-center">
                                            {data.totalTrans}
                                        </td>
                                        <td className="text-right">
                                            {numberWithCommas(data.totalJual)}
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr className="colorWhite" >
                                <th className="text-center">Total</th>
                                <th className="text-center">
                                    {totalPengunjung}
                                </th>
                                <th className="text-center">
                                    {totalTrans}
                                </th>
                                <th className="text-right">
                                    {numberWithCommas(totalPenjualan)}
                                </th>
                            </tr>
                        </tbody>
                    </Table>

                </Col>
            </Row>
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default SalesSummary;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));