import React, { useState, useContext, useEffect } from 'react'
import AuthContext from '../../context/auth-context'
import { FaChevronLeft } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Table, TableRow, TableCell, TableBody, TableHead, Tabs, Tab, Dialog, DialogContent, DialogContentText, Slide, Typography, AppBar, Toolbar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import InfoIcon from '@material-ui/icons/Info';
import mRiwayat from './RiwayatTrans.controller'
import CurrencyFormat from 'react-currency-format';
import moment from 'moment-timezone'
import numberWithCommas from '../../Helper/numberWithCommas'



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    tabel: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));
const RiwayatTransaksiDetail = (props) => {
    const kodetrans = props.match.params.kodetrans;
    const kodeoutlet = props.match.params.kodeoutlet;
    const { clientID, token } = useContext(AuthContext)
    const page = props.match.params.page;
    const rowsPerPage = props.match.params.rowsPerPage;
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const [isRefresh, setIsRefresh] = useState(true)
    const [dataInformasi, setDataInformasi] = useState({})
    const [listOrderan, setListOrderan] = useState([])
    const [listBayar, setListBayar] = useState([])
    const [detOrder, setDetOrder] = useState([])
    const [detBayar, setDetBayar] = useState([])
    const [detail, setDetail] = useState({})
    const [jumlahDataBayar, setJumlahDataBayar] = useState(0)
    const [noOrder, setNoOrder] = useState()
    const [totalListOrderanDb, setTotalListOrderanDb] = useState(null)
    const [subTotalDb, setSubTotalDb] = useState(null)
    const [tipeOutlet, setTipeOutlet] = useState()
    var totalListOrderan = 0;
    var bayarSubtotal = 0;
    var bayarDiskon = 0;
    var bayarServiceTax = 0;
    var bayarTotal = 0;
    var detTotOrder = 0
    var detTotBayar = 0;
    useEffect(() => {
        var render = true
        if (!tipeOutlet) {
            mRiwayat.getOutlet(kodeoutlet, clientID, token).then(resp => {
                setTipeOutlet(resp.tipeOutlet);
                loadData(resp.tipeOutlet)
            }).catch(err => {
                console.error(err);
            })
        } else {
            loadData(tipeOutlet)
        }
        return () => {
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kodeoutlet, clientID, token, isRefresh]);

    const loadData = (tipeOutlet) => {
        if (tipeOutlet === "NORMAL") {
            mRiwayat.loadDetail(kodeoutlet, clientID, token, kodetrans).then(resp => {
                setDataInformasi(resp)
                setListOrderan(resp.detail)
                if (resp.payment !== null) setListBayar(resp.payment)
                setNoOrder(resp._id.substring(27))
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        } else if (tipeOutlet === "SIMPLE") {
            mRiwayat.loadDetailRetail(kodeoutlet, clientID, token, kodetrans).then(resp => {
                setDataInformasi(resp)
                setListOrderan(resp.detail)
                if (resp.payment !== null) setListBayar(resp.payment)
                setNoOrder(resp._id.substring(27))
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
    }
    const goBack = () => {
        if (props.match.params.rpt === "rpt") {
            window.close();
        } else {
            props.history.push(`/manajemenoutlet/${kodeoutlet}/transhistory/${page}/${rowsPerPage}`)
        }
    }
    const classes = useStyles();
    const [value, setValue] = useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }
    function handleChangeIndex(index) {
        setValue(index);
    }
    const handleModalDetPembayaran = (scrollType, index, total, subTotal) => () => {
        setTotalListOrderanDb(total)
        setSubTotalDb(subTotal)
        setOpen(true);
        setScroll(scrollType);
        setDetail(listBayar[index])
        setDetOrder(listBayar[index].item)
        setDetBayar(listBayar[index].payment);
        setJumlahDataBayar(listBayar[index].payment.length - 1)
    };

    function handleCloseMoidalDetPem() {
        setOpen(false);
    }
    const renderDetail = () => {
        if (tipeOutlet === "NORMAL") {
            return (
                <Col xs={12}>
                    <div className={classes.root}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Informasi" className="font12" />
                            <Tab label="List orderan" className="font12" />
                            <Tab label="Pembayaran" className="font12" />
                        </Tabs>
                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <div value={value} index={0} className={classes.tabel}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                No Order
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.noinvoice === null ? noOrder :
                                                    dataInformasi.noinvoice}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Tanggal
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {moment(dataInformasi.tanggal).format("DD MMM YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                No Meja
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.nomejaint}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Nama Tamu
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.namacustomer}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Order Oleh
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.userin}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Order Jam
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {moment(dataInformasi.jamin).format("DD MMM YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Aksi Terakhir Oleh
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.userupt}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Aksi Terakhir Pada
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {moment(dataInformasi.jamupt).format("DD MMM YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                tanggal Lunas
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {moment(dataInformasi.lastJamBayar).format("DD MMM YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Status
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.statusid === 20 && ("Lunas")}
                                                {dataInformasi.statusid === 8 && ("Batal")}
                                                {dataInformasi.statusid === 9 && ("Retur")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Alasan
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.voidReason}
                                            </TableCell>
                                        </TableRow>
                                        {dataInformasi.statusid === 9 && (
                                            <React.Fragment>
                                                <TableRow >
                                                    <TableCell className="colorWhite">No Retur</TableCell>
                                                    <TableCell className="colorWhite">
                                                        {dataInformasi.noretur}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell className="colorWhite">Session Retur</TableCell>
                                                    <TableCell className="colorWhite">
                                                        {dataInformasi.sessionRetur}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell className="colorWhite">User Retur</TableCell>
                                                    <TableCell className="colorWhite">
                                                        {dataInformasi.userRetur}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell className="colorWhite">Jam Retur</TableCell>
                                                    <TableCell className="colorWhite">
                                                        {moment(dataInformasi.jamretur).format("DD MMM YYYY HH:mm:ss")}
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <div value={value} index={1}>
                                <h6>List Orderan</h6>
                                <div className={classes.tabel}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow className="bgTabel">
                                                <TableCell className="colorBlack" style={{ minWidth: ' 200px' }}>Item</TableCell>
                                                <TableCell className="colorBlack text-center" style={{ minWidth: ' 30px' }}>Qty</TableCell>
                                                <TableCell className="colorBlack text-right" style={{ minWidth: ' 100px' }}>Harga</TableCell>
                                                <TableCell className="colorBlack text-right" style={{ minWidth: ' 100px' }}>Disc</TableCell>
                                                <TableCell className="colorBlack text-right" style={{ minWidth: ' 130px' }}>Jumlah</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listOrderan.map((data, idx) => {
                                                totalListOrderan += data.jumlah;
                                                return (
                                                    <TableRow key={idx}>
                                                        <TableCell className="colorWhite">
                                                            {data.namabarang}
                                                        </TableCell>
                                                        <TableCell className="colorWhite text-center">
                                                            {data.qty}
                                                        </TableCell>
                                                        <TableCell className="colorWhite text-right">
                                                            {numberWithCommas(data.harga)}
                                                        </TableCell>
                                                        <TableCell className="colorWhite text-right">
                                                            {data.discountAmount > 0 ? <span>{data.discountPercent}% = {numberWithCommas(data.discountAmount)}</span> : 0}
                                                        </TableCell>
                                                        <TableCell className="colorWhite text-right">
                                                            {numberWithCommas(data.jumlah)}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="colorWhite" colSpan="4">Total </TableCell>
                                                <TableCell className="colorWhite text-right" style={{ minWidth: ' 130px' }}>
                                                    <CurrencyFormat value={parseFloat(Math.round(totalListOrderan * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            </div>
                            <div value={value} index={2} className={classes.tabel}>
                                <h6>List Pembayaran</h6>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow className="bgTabel">
                                            <TableCell className="colorBlack" style={{ minWidth: '30px' }}>Action</TableCell>
                                            <TableCell className="colorBlack" style={{ minWidth: '100px' }}>No invoice</TableCell>
                                            {tipeOutlet === "NORMAL" && (
                                                <TableCell className="colorBlack" style={{ minWidth: '100px' }}>ID Session</TableCell>
                                            )}
                                            <TableCell className="colorBlack text-right" style={{ minWidth: '100px' }}>Sub Total</TableCell>
                                            <TableCell className="colorBlack text-right" style={{ minWidth: '30px' }}>Diskon</TableCell>
                                            <TableCell className="colorBlack text-right" style={{ minWidth: '100px' }}>Service & Tax</TableCell>
                                            <TableCell className="colorBlack text-right" style={{ minWidth: '130px' }}>Total Payment</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listBayar.map((data, idx) => {
                                            var serviceTax = data.serviceAmount + data.taxAmount
                                            var subTotal = data.total + data.discountAmount - serviceTax;
                                            const subTotalFix = data.subtotal || subTotal
                                            bayarSubtotal += subTotal;
                                            bayarDiskon += data.discountAmount;
                                            bayarServiceTax += serviceTax;
                                            bayarTotal += data.total;
                                            return (
                                                <TableRow key={idx.toString()}>
                                                    <TableCell className="colorWhite">
                                                        <InfoIcon className="tangan" onClick={handleModalDetPembayaran('paper', idx, data.total, subTotalFix)} />
                                                    </TableCell>
                                                    <TableCell className="colorWhite">
                                                        {data.noinvoice}
                                                    </TableCell>
                                                    {tipeOutlet === "NORMAL" && (
                                                        <TableCell className="colorWhite">
                                                            {data.sessionId}
                                                        </TableCell>
                                                    )}
                                                    <TableCell className="colorWhite text-right">
                                                        <CurrencyFormat value={parseFloat(Math.round(subTotalFix * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                    </TableCell>
                                                    <TableCell className="colorWhite text-right">
                                                        <CurrencyFormat value={parseFloat(Math.round(data.discountAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                    </TableCell>
                                                    <TableCell className="colorWhite text-right">
                                                        <CurrencyFormat value={parseFloat(Math.round(serviceTax * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                    </TableCell>
                                                    <TableCell className="colorWhite text-right">
                                                        <CurrencyFormat value={parseFloat(Math.round(data.total * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="colorWhite" colSpan={tipeOutlet === "NORMAL" ? 3 : 2}>
                                                Total
                                        </TableCell>
                                            <TableCell className="colorWhite text-right">
                                                <CurrencyFormat value={parseFloat(Math.round(bayarSubtotal * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                            <TableCell className="colorWhite text-right">
                                                <CurrencyFormat value={parseFloat(Math.round(bayarDiskon * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                            <TableCell className="colorWhite text-right">
                                                <CurrencyFormat value={parseFloat(Math.round(bayarServiceTax * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                            <TableCell className="colorWhite text-right">
                                                <CurrencyFormat value={parseFloat(Math.round(bayarTotal * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </div>
                        </SwipeableViews>
                    </div>
                </Col>
            )
        } else if (tipeOutlet === "SIMPLE") {
            return (
                <Col xs={12}>
                    <div className={classes.root}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Informasi" className="font12" />
                            <Tab label="List orderan" className="font12" />
                            <Tab label="Pembayaran" className="font12" />
                        </Tabs>
                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <div value={value} index={0} className={classes.tabel}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                No Order
                                            </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.noinvoice === null ? noOrder :
                                                    dataInformasi.noinvoice}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Tanggal
                                            </TableCell>
                                            <TableCell className="colorWhite">
                                                {moment(dataInformasi.tanggal).format("DD MMM YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Order Jam
                                            </TableCell>
                                            <TableCell className="colorWhite">
                                                {moment(dataInformasi.jamin).format("DD MMM YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Aksi Terakhir Oleh
                                            </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.userupt}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Aksi Terakhir Pada
                                            </TableCell>
                                            <TableCell className="colorWhite">
                                                {moment(dataInformasi.jamupt).format("DD MMM YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                tanggal Lunas
                                            </TableCell>
                                            <TableCell className="colorWhite">
                                                {moment(dataInformasi.lastJamBayar).format("DD MMM YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Status
                                            </TableCell>
                                            <TableCell className="colorWhite">
                                                {dataInformasi.statusid === 20 && ("Lunas")}
                                                {dataInformasi.statusid === 8 && ("Batal")}
                                                {dataInformasi.statusid === 9 && ("Retur")}
                                            </TableCell>
                                        </TableRow>
                                        {dataInformasi.statusid === 9 && (
                                            <React.Fragment>
                                                <TableRow >
                                                    <TableCell className="colorWhite">No Retur</TableCell>
                                                    <TableCell className="colorWhite">
                                                        {dataInformasi.noretur}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell className="colorWhite">Session Retur</TableCell>
                                                    <TableCell className="colorWhite">
                                                        {dataInformasi.sessionRetur}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell className="colorWhite">User Retur</TableCell>
                                                    <TableCell className="colorWhite">
                                                        {dataInformasi.userRetur}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell className="colorWhite">Jam Retur</TableCell>
                                                    <TableCell className="colorWhite">
                                                        {moment(dataInformasi.jamretur).format("DD MMM YYYY HH:mm:ss")}
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <div value={value} index={1}>
                                <h6>List Orderan</h6>
                                <div className={classes.tabel}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow className="bgTabel">
                                                <TableCell className="colorBlack" style={{ minWidth: ' 200px' }}>Item</TableCell>
                                                <TableCell className="colorBlack text-center" style={{ minWidth: ' 30px' }}>Qty</TableCell>
                                                <TableCell className="colorBlack text-right" style={{ minWidth: ' 100px' }}>Harga</TableCell>
                                                <TableCell className="colorBlack text-right" style={{ minWidth: ' 130px' }}>Jumlah</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listOrderan.map((data, idx) => {
                                                totalListOrderan += data.jumlah;
                                                return (
                                                    <TableRow key={idx}>
                                                        <TableCell className="colorWhite">
                                                            {data.namabarang}
                                                        </TableCell>
                                                        <TableCell className="colorWhite text-center">
                                                            {data.qty}
                                                        </TableCell>
                                                        <TableCell className="colorWhite text-right">
                                                            {numberWithCommas(data.harga)}
                                                        </TableCell>
                                                        <TableCell className="colorWhite text-right">
                                                            {numberWithCommas(data.jumlah)}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="colorWhite" colSpan="3">Total </TableCell>
                                                <TableCell className="colorWhite text-right" style={{ minWidth: ' 130px' }}>
                                                    <CurrencyFormat value={parseFloat(Math.round(totalListOrderan * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            </div>
                            <div value={value} index={2} className={classes.tabel}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="colorWhite">
                                                Sub Total
                                            </TableCell>
                                            <TableCell className="colorWhite text-right">
                                                <b>{numberWithCommas(dataInformasi.subtotal)}</b>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="colorWhite">
                                                Diskon
                                            </TableCell>
                                            <TableCell className="colorWhite text-right">
                                                <b>{numberWithCommas(dataInformasi.diskonAmt)}</b>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="colorWhite">
                                                Tax
                                            </TableCell>
                                            <TableCell className="colorWhite text-right">
                                                <b>{numberWithCommas(dataInformasi.taxAmt)}</b>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="colorWhite">
                                                Total Orderan
                                            </TableCell>
                                            <TableCell className="colorWhite  text-right">
                                                <b>{numberWithCommas(dataInformasi.total)}</b>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="colorWhite">
                                                Total Pembayaran
                                            </TableCell>
                                            <TableCell className="colorWhite  text-right">
                                                <b>{numberWithCommas(dataInformasi.totalBayar)}</b>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="colorWhite">
                                                Kembalian
                                            </TableCell>
                                            <TableCell className="colorWhite text-right">
                                                <b>{numberWithCommas(dataInformasi.kembalian)}</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </SwipeableViews>
                    </div>
                </Col>
            )
        }
    }
    return (
        <Container>
            <Col xs={12}>
                <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" /> Orderan Detail</h4>
            </Col>
            {renderDetail()}

            <Dialog
                open={open}
                onClose={handleCloseMoidalDetPem}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar>
                    <Toolbar>
                        {/* <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                            <CloseIcon />
                        </IconButton> */}
                        <FaChevronLeft color="inherit" onClick={handleCloseMoidalDetPem} className="tangan" />
                        <Typography variant="h6">
                            Detail Pembayaran Transaksi</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers={scroll === 'paper'} className="DialogFont bodyDialog">
                    <DialogContentText className="colorWhite">
                        <h6>List Orderan</h6>
                        <div className={`bgContent-orderan ${classes.tabel}`}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow className="bgTabel">
                                        <TableCell className="colorBlack" style={{ width: '35%' }}>Item</TableCell>
                                        <TableCell className="colorBlack" style={{ width: ' 5%' }}>Qty</TableCell>
                                        <TableCell className="colorBlack"
                                            align="right" style={{ width: ' 20%' }}>Harga</TableCell>
                                        <TableCell className="colorBlack text-right" style={{ width: ' 20%' }}>Disc</TableCell>
                                        <TableCell className="colorBlack"
                                            align="right" style={{ width: ' 20%' }}>Jumlah</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detOrder.map((data, idx) => {
                                        detTotOrder += data.jumlah;
                                        return (
                                            <TableRow key={idx.toString()}>
                                                <TableCell className="colorWhite">
                                                    {data.namabarang}
                                                </TableCell>
                                                <TableCell className="colorWhite">
                                                    {data.qty}
                                                </TableCell>
                                                <TableCell className="colorWhite text-right">
                                                    {numberWithCommas(data.harga)}
                                                </TableCell>
                                                <TableCell className="colorWhite text-right">
                                                    {data.discountAmount > 0 ? <span>{data.discountPercent}% = {numberWithCommas(data.discountAmount)}</span> : 0}
                                                </TableCell>
                                                <TableCell className="colorWhite text-right">
                                                    {numberWithCommas(data.jumlah)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="colorWhite" colSpan="4">Sub Total </TableCell>
                                        <TableCell align="right" className="colorWhite">
                                            <CurrencyFormat value={parseFloat(Math.round(subTotalDb * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </div>
                        <div className={classes.tabel}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow >
                                        <TableCell className="colorWhite" style={{ width: '50%' }}>
                                            Discount
                                        </TableCell>
                                        <TableCell className="colorWhite" style={{ width: '10%' }}>
                                            {detail.discountPercent}%
                                        </TableCell>
                                        <TableCell align="right" className="colorWhite" style={{ width: '40%' }}>
                                            <CurrencyFormat value={parseFloat(Math.round(detail.discountAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="colorWhite">
                                            Service
                                        </TableCell>
                                        <TableCell className="colorWhite">
                                            {detail.servicePercent}%
                                        </TableCell>
                                        <TableCell align="right" className="colorWhite">
                                            <CurrencyFormat value={parseFloat(Math.round(detail.serviceAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="colorWhite">
                                            tax
                                        </TableCell>
                                        <TableCell className="colorWhite">
                                            {detail.taxPercent}%
                                        </TableCell>
                                        <TableCell align="right" className="colorWhite">
                                            <CurrencyFormat value={parseFloat(Math.round(detail.taxAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="colorWhite" colSpan="2">
                                            <b>Total</b>
                                        </TableCell>
                                        <TableCell align="right" className="colorWhite">
                                            <b>  <CurrencyFormat value={parseFloat(Math.round(totalListOrderanDb * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} /> </b>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                        <h6>List Pembayaran</h6>
                        <div className={`bgContent-orderan ${classes.tabel}`}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow className="bgTabel">
                                        <TableCell className="colorBlack" style={{ width: '30%' }}>Payment</TableCell>
                                        <TableCell className="colorBlack" style={{ width: '50%' }} align="center">Remark</TableCell>
                                        <TableCell className="colorBlack" style={{ width: '20%' }} align="right">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detBayar.map((data, idx) => {
                                        var kembalian = 0;
                                        if (idx === jumlahDataBayar) kembalian = detail.kembalian;
                                        detTotBayar += data.amount + kembalian;
                                        return (
                                            <TableRow key={idx}>
                                                <TableCell className="colorWhite">
                                                    {data.namapayment}
                                                </TableCell>
                                                <TableCell className="colorWhite" align="right">
                                                    {data.remark}
                                                </TableCell>
                                                <TableCell align="right" className="colorWhite">
                                                    <CurrencyFormat value={parseFloat(Math.round((data.amount + kembalian) * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableHead>
                                    <TableRow >
                                        <TableCell className="colorWhite" colSpan="2">Total</TableCell>
                                        <TableCell className="colorWhite" align="right">
                                            <CurrencyFormat value={parseFloat(Math.round(detTotBayar * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </div>
                        <div className={classes.tabel}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow >
                                        <TableCell className="colorWhite" style={{ width: '80%' }}>
                                            Kembalian
                                        </TableCell>
                                        <TableCell align="right" className="colorWhite" style={{ width: '20%' }}>
                                            <CurrencyFormat value={parseFloat(Math.round(detail.kembalian * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </Container>
    )
}

export default RiwayatTransaksiDetail;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});