import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import { Bar } from 'react-chartjs-2';
import mLaporan from '../Laporan.controler'
import CurrencyFormat from 'react-currency-format';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { stableSort, getSorting } from '../../../../Helper/Sorting'
import PropTypes from "prop-types";
import { TableSortLabel } from '@material-ui/core';



const Barang = (props) => {
    const [isRefresh, setIsRefresh] = useState(true)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const [kodeoulet, setKodeoulet] = useState(props.kodeOutlet)
    const [idOutlet, setIdOutlet] = useState(props.idOutlet)
    const [listLaporan, setListLaporan] = useState([])
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("qty");
    const [dataGrafik, setDataGrafik] = useState({
        labels: [],
        datasets: [
            {
                label: 'Laporan Penjualan Barang by Qty',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgb(230, 123, 33)',
                borderColor: 'rgb(255, 117, 0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#000',
                pointBackgroundColor: '#000',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255, 117, 0)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: []
            }
        ]
    })

    const [dataGrafikbyJumlah, setDataGrafikbyJumlah] = useState({
        labels: [],
        datasets: [
            {
                label: 'Laporan Penjualan Barang by Jumlah',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgb(230, 123, 33)',
                borderColor: 'rgb(255, 117, 0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#000',
                pointBackgroundColor: '#000',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255, 117, 0)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: []
            }
        ]
    })
    var totalPenjualan = 0
    var totalQty = 0
    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.laporanBarang(clientID, token, userLogin, kodeoulet, idOutlet, moment(tglDari).tz("Asia/Jakarta"), moment(tglSampai).tz("Asia/Jakarta")).then(resp => {
                const newDataGrafik = {
                    ...dataGrafik,
                    labels: resp.namaBarangLimit,
                    datasets: [
                        {
                            ...dataGrafik.datasets[0],
                            data: resp.qtyJualLimit
                        }
                    ]
                }
                setDataGrafik(newDataGrafik)
                const newDataGrafikByJumlah = {
                    ...dataGrafikbyJumlah,
                    labels: resp.namaBarangLimitByJumlah,
                    datasets: [
                        {
                            ...dataGrafikbyJumlah.datasets[0],
                            data: resp.JumlahJualLimit
                        }
                    ]
                }
                setDataGrafikbyJumlah(newDataGrafikByJumlah)
                setListLaporan(resp.listLaporanBarang)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])


    const lihatLaporan = () => {
        setListLaporan([])
        setIsRefresh(true)
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    const headRows = [
        {
            id: "namabarang", label: "Barang"
        },
        { id: "qty", label: "Qty" },
        { id: "jumlah", label: "Jumlah" },

    ];
    const EnhancedTableHead = (props) => {
        const {
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (

            <thead>
                <tr className="colorWhite bgHeaderTabel tabelCenter">
                    {headRows.map(row => (
                        <th key={row.id} className={row.label === "Jumlah" ? ("text-right") : ("")}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} lg={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Dari"
                            format="DD-MM-YYYY HH:mm"
                            value={tglDari}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            onChange={handleChangeTglDari}
                            ampm={false}
                            disableFuture
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Sampai"
                            format="DD-MM-YYYY HH:mm"
                            value={tglSampai}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ color: '#000 !important' }}
                            onChange={handleChangeTglSampai}
                            ampm={false}
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={2} className="text-center"><br />
                    <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="marginGrafik">
                    <Paper className={`${useStyles.paper} grafikMax`}>
                        <Bar data={dataGrafik} />
                    </Paper>
                </Col>
                <Col xs={12} md={6} className="marginGrafik">
                    <Paper className={`${useStyles.paper} grafikMax`}>
                        <Bar data={dataGrafikbyJumlah} />
                    </Paper>
                </Col>
                <Col xs={12} className="marginGrafik">
                    <ReactHTMLTableToExcel
                        className="buttonExportExcel"
                        table="laporanBarang"
                        filename={`Laporan barang ${moment(tglDari).format("DD-MM-YYYY HH mm")} sampai ${moment(tglSampai).format("DD-MM-YYYY HH mm")}`}
                        sheet="Sheet1"
                        buttonText={namaExport()} />
                    <Table id="laporanBarang" striped bordered hover responsive="lg">
                        <EnhancedTableHead
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                        />
                        <tbody>
                            {stableSort(listLaporan, getSorting(order, orderBy))
                                .map((data, index) => {
                                    totalPenjualan += data.jumlah
                                    totalQty += data.qty
                                    return (
                                        <tr className="colorWhite" key={index}>
                                            <td>{data.namabarang}</td>
                                            <td className="text-center">{data.qty}</td>
                                            <td className="text-right">
                                                <CurrencyFormat value={parseFloat(Math.round(data.jumlah * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            <tr className="colorWhite">
                                <th className="text-center">Total</th>
                                <th className="text-center">
                                    <CurrencyFormat value={parseFloat(Math.round(totalQty * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </th>
                                <th className="text-right">
                                    <CurrencyFormat value={parseFloat(Math.round(totalPenjualan * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default Barang;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));