import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import mLaporan from '../Laporan.controler'
import moment from 'moment-timezone'
import AuthContext from '../../../../context/auth-context'
import { makeStyles } from '@material-ui/core/styles';
import { FaChevronLeft } from 'react-icons/fa';
import { Button, TableRow, TableCell, TableBody, TableHead,  } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import CurrencyFormat from 'react-currency-format';

const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PaymentDetail = (props) => {
    const classes = useStyles();
    const dataDetail = props.dataDetail
    const [isRefresh, setIsRefresh] = useState(true)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [listPayment, setlistPayment] = useState([])
    const [listPaymentDet, setListPaymentDet] = useState([])
    const [paymentDet, setPaymentDet] = useState({})
    const [isDetail, setIsDetail] = useState(false)
    const [detOrder, setDetOrder] = useState([])
    const [detBayar, setDetBayar] = useState([])
    const [jumlahDataBayar, setJumlahDataBayar] = useState(0)
    var totalPayment = 0;
    var detTotBayar = 0;

   
    useEffect(() => {
        var render = true
        if (isRefresh) {
            setlistPayment(props.listTrans)
            setListPaymentDet(props.paymentDet)
            setIsRefresh(false)
        }
        return () => {
            render = false
        };
    }, [isRefresh])
    const handleDetail = (noinvoice) => {
        const a = listPaymentDet.filter(el => {
            return noinvoice === el.noinvoice
        })
        setIsDetail(true)
        setPaymentDet(a[0])   
        setDetOrder(a[0].item)
        setDetBayar(a[0].payment);
        setJumlahDataBayar(a[0].payment.length - 1)
        props.setIsDetHeader(false)
    }
    const handleCloseDetail = () => {
        setIsDetail(false)
        props.setIsDetHeader(true)
    }

    return (
        <React.Fragment>
            {!isDetail && (
                <Row >
                    <Col xs={12} className={`${classes.contentBody} bodySessionDetail`}>
                        <Table id="laporanPayment" striped bordered hover responsive="lg">
                            <thead>
                                <tr className="colorWhite bgHeaderTabel tabelCenter">
                                    <th>Detail Invoice</th>
                                    <th>No Invoice</th>
                                    <th>Tanggal</th>
                                    <th>Payment</th>
                                    <th className="text-right">Jumlah</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listPayment.map((data, index) => {
                                    totalPayment += data.jumlah
                                    return (
                                        <tr className="colorWhite" key={index}>
                                            <td className="text-center">
                                                <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleDetail.bind(this, data.noinvoice)}><PageviewIcon /></Button>
                                            </td>
                                            <td >{data.noinvoice}</td>
                                            <td>
                                                {moment(data.tanggal).format("DD-MMM-YYYY HH:mm")}
                                            </td>
                                            <td className="text-center">{data.namapayment}</td>
                                            <td className="text-right">
                                                {numberWithCommas(data.jumlah)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr className="colorWhite">
                                    <th className="text-center" colSpan="4">Total</th>
                                    <th className="text-center">
                                        {numberWithCommas(totalPayment)}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {isDetail && (
                <React.Fragment>
                    <Row>
                        <Col xs={12}>
                            <div className="fixHeaderModal">
                                <h3 style={{ paddingBottom: 10 }} className="border-bottom btnBack"><FaChevronLeft onClick={handleCloseDetail} className="btnBack" />Tipe Transaksi Detail </h3>
                                <h5 className="colorBlack">No Invoice : {paymentDet.noinvoice}</h5>
                            </div>
                        </Col>
                        <Col xs={12} className={`${classes.contentBody} bodySessionDetail`}>
                            <h6 className="colorBlack">List Orderan</h6>
                            <div className={`bgContent-orderan ${classes.tabel}`}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow className="bgTabel">
                                            <TableCell className="colorBlack" style={{ width: '50%' }}>Item</TableCell>
                                            <TableCell className="colorBlack" style={{ width: ' 10%' }}>Qty</TableCell>
                                            <TableCell className="colorBlack"
                                                align="right" style={{ width: ' 20%' }}>Harga</TableCell>
                                            <TableCell className="colorBlack"
                                                align="right" style={{ width: ' 20%' }}>Jumlah</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {detOrder.map((data, idx) => {
                                            return (
                                                <TableRow key={idx.toString()}>
                                                    <TableCell className="colorWhite">
                                                        {data.namabarang}
                                                    </TableCell>
                                                    <TableCell className="colorWhite">
                                                        {data.qty}
                                                    </TableCell>
                                                    <TableCell align="right" className="colorWhite">
                                                        <CurrencyFormat value={parseFloat(Math.round(data.harga * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                    </TableCell>
                                                    <TableCell align="right" className="colorWhite">
                                                        <CurrencyFormat value={parseFloat(Math.round(data.jumlah * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="colorWhite" colSpan="3">Sub Total</TableCell>
                                            <TableCell align="right" className="colorWhite">
                                                {numberWithCommas(paymentDet.total + paymentDet.discountAmount - (paymentDet.serviceAmount + paymentDet.taxAmount))}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </div>
                            <div className={classes.tabel}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow >
                                            <TableCell className="colorWhite" style={{ width: '50%' }}>
                                                Discount
                                        </TableCell>
                                            <TableCell className="colorWhite" style={{ width: '10%' }}>
                                                {paymentDet.discountPercent}%
                                        </TableCell>
                                            <TableCell align="right" className="colorWhite" style={{ width: '40%' }}>
                                                <CurrencyFormat value={parseFloat(Math.round(paymentDet.discountAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                Service
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {paymentDet.servicePercent}%
                                        </TableCell>
                                            <TableCell align="right" className="colorWhite">
                                                <CurrencyFormat value={parseFloat(Math.round(paymentDet.serviceAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite">
                                                tax
                                        </TableCell>
                                            <TableCell className="colorWhite">
                                                {paymentDet.taxPercent}%
                                        </TableCell>
                                            <TableCell align="right" className="colorWhite">
                                                <CurrencyFormat value={parseFloat(Math.round(paymentDet.taxAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell className="colorWhite" colSpan="2">
                                                <b>Total</b>
                                            </TableCell>
                                            <TableCell align="right" className="colorWhite">
                                                <b>   {numberWithCommas(paymentDet.total)} </b>
                                               
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                            <h6 className="colorBlack">List Pembayaran</h6>
                            <div className={`bgContent-orderan ${classes.tabel}`}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow className="bgTabel">
                                            <TableCell className="colorBlack" style={{ width: '30%' }}>Payment</TableCell>
                                            <TableCell className="colorBlack" style={{ width: '50%' }} align="center">Remark</TableCell>
                                            <TableCell className="colorBlack" style={{ width: '20%' }} align="right">Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {detBayar.map((data, idx) => {
                                            var kembalian = 0;
                                            if (idx === jumlahDataBayar) kembalian = paymentDet.kembalian;
                                            detTotBayar += data.amount + kembalian;
                                            return (
                                                <TableRow key={idx}>
                                                    <TableCell className="colorWhite">
                                                        {data.namapayment}
                                                    </TableCell>
                                                    <TableCell className="colorWhite" align="right">
                                                        {data.remark}
                                                    </TableCell>
                                                    <TableCell align="right" className="colorWhite">
                                                        <CurrencyFormat value={parseFloat(Math.round((data.amount + kembalian) * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell className="colorWhite" colSpan="2">Total</TableCell>
                                            <TableCell className="colorWhite" align="right">
                                                <CurrencyFormat value={parseFloat(Math.round(detTotBayar * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </div>
                            <div className={classes.tabel}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow >
                                            <TableCell className="colorWhite" style={{ width: '80%' }}>
                                                Kembalian
                                        </TableCell>
                                            <TableCell align="right" className="colorWhite" style={{ width: '20%' }}>
                                                <CurrencyFormat value={parseFloat(Math.round(paymentDet.kembalian * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </Col>
                    </Row>

                </React.Fragment>
            )}
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default PaymentDetail;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));