/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import "./MyPopOver.css";
import "bootstrap/dist/css/bootstrap.min.css";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));


const MyPopover = (props) => {
  const [anchor, setAnchor] = useState(null);
  const handleClick = (ev) => {
    setAnchor(ev.currentTarget);
  };
  const classes = useStyles();
  const open = Boolean(anchor);

  return (
    <>
      <button
        className="info"
        data-toggle="popover"
        data-placement="right"
        ria-describedby="simple-popover"
        color="primary"
        onClick={handleClick}
      >
        ?
      </button>
      <Popover
        id="simple-popover"
        open={open}
        anchorEl={anchor}
        onClose={setAnchor.bind(null, null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div className="justify">
            {props.description}
          </div>
        </Typography>
      </Popover>
    </>
  );
};

export default MyPopover;

