import React, { useState, useContext, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import KasMasukForm from './KasMasukForm'
import AuthContext from '../../context/auth-context'
import mControl from './KasMasuk.controller'
import { isArray } from 'util'
import validate from 'validate.js'
import DialogInfo from '../../Dialog/DialogInfo'

const ListKasMasukAdd = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const { clientID, token } = useContext(AuthContext)
    const [state, setState] = useState({ kodebiaya: '', namabiaya: '' })
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isRefresh, setIsRefresh] = useState(true)
    const [kodeOutletString, setKodeOutletString] = useState()
    useEffect(() => {
        var render = true
        if (isRefresh) {
            mControl.getOutlet(kodeoutlet, clientID, token).then(resp => {
                setKodeOutletString(resp.kodeoutlet)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh]);
    const validateForm = () => {
        const { namabiaya } = state;
        const promKategori = new Promise((resolve, reject) => {
            const constraint = {
                namabiaya: {
                    length: {
                        minimum: 3,
                        message: "Nama Biaya minimal terdiri dari 3 huruf"
                    },
                }
            }
            const validator = validate({ namabiaya }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promKategori]);
    }
    const handleSimpan = () => {
        setIsLoading(true)
        validateForm().then(() => {
            mControl.simpan(kodeOutletString,state.namabiaya, clientID, token).then(resp => {
                setIsLoading(false)
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Kas masuk berhasil di simpan<p/>`)
                    setDialogUrl(`/manajemenoutlet/${kodeoutlet}/listkasmasuk/1/5`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Server tidak dapat menjalankan perintah Anda saat ini. Silakan coba sesaat lagi<p/>`)
                }
            }).catch(err => {
                setIsLoading(false)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setIsLoading(false)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "<br/>";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }
    const handleChange = (key, value) => {
        const newState = Object.assign({}, state);
        newState[key] = value;
        setState(newState);
    }

    return (
        <Container>
            <KasMasukForm type="add" history={props.history} kodeoutlet={kodeoutlet} handleChange={handleChange} kodebiaya={state.kodebiaya} namabiaya={state.namabiaya} />
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12} className="text-center">
                    <Button variant="contained" color="primary" onClick={handleSimpan}  disabled={isLoading}>Simpan</Button>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    )
}

export default ListKasMasukAdd;