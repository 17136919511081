import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Doughnut,Pie, Line, Scatter, Bar , Radar, Polar, Bubble} from 'react-chartjs-2';
import mKLaporan from '../Laporan.controler'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import CurrencyFormat from 'react-currency-format';

const numberWithCommas = (x) => {
    if (!x) x = 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Kategori = (props) => {
    const classes = useStyles();
    const [isRefresh, setIsRefresh] = useState(true)
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const [jumlahHari, setJumlahHari] = useState()
    const [dataGrafik, setDataGrafik] = useState({
        labels: ['Makanan', 'Minuman','Bahan Baku', 'Heng Heng'],
        datasets: [
            {
                label: 'Laporan Penjualan Kategori',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgb(230, 123, 33)',
                borderColor: 'rgb(255, 117, 0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#000',
                pointBackgroundColor: '#000',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255, 117, 0)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [4500000, 4000000, 4300000, 3500000]
            }
        ]
    })


    useEffect(() => {
        var render = true
        if (isRefresh) {
            sumDate()
            setIsRefresh(false)
        }
        return () => {
            render = false
        };
    }, [isRefresh])


    const sumDate = () => {
        var totTanggal = []
        for (var d = new Date(tglDari); d <= new Date(tglSampai); d.setDate(d.getDate() + 1)) {
            totTanggal.push(d)
        }
        setJumlahHari(totTanggal.length)
    }
    const lihatLaporan = () => {
        sumDate()
        setIsRefresh(true)
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} lg={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Dari"
                            format="DD-MM-YYYY HH:mm"
                            value={tglDari}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            onChange={handleChangeTglDari}
                            ampm={false}
                            disableFuture
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Sampai"
                            format="DD-MM-YYYY HH:mm"
                            value={tglSampai}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ color: '#000 !important' }}
                            onChange={handleChangeTglSampai}
                            ampm={false}
                            disableFuture
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={2} className="text-center"><br />
                    <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="marginGrafik">
                    <Paper className={`${useStyles.paper} grafikMax`}>
                        <Bar data={dataGrafik} />
                    </Paper>
                </Col>
                <Col xs={12}  className="marginGrafik">
                    <Table striped bordered hover responsive="lg">
                        <thead>
                            <tr className="colorWhite bgHeaderTabel tabelCenter">
                                <th>Kategori</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="colorWhite">
                                <td>Minuman</td>
                                <td className="text-center">
                                    <CurrencyFormat value="4500000" displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </td>
                            </tr>
                            <tr className="colorWhite">
                                <td>Makanan</td>
                                <td className="text-center">
                                    <CurrencyFormat value="10000000" displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </td>
                            </tr>
                            <tr className="colorWhite">
                                <td>Bahan Baku</td>
                                <td className="text-center">
                                    <CurrencyFormat value="20000" displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </td>
                            </tr>
                            <tr className="colorWhite">
                                <td>Heng Heng</td>
                                <td className="text-center">
                                    <CurrencyFormat value="50000" displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </td>
                            </tr>
                            <tr className="colorWhite">
                                <th className="text-center">Total</th>
                                <th className="text-center">
                                    <CurrencyFormat value="14500000" displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default Kategori;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));