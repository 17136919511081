import GQL from '../../GQL';
const kategori = {
    loadData: (idOutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listKategoriApp(idOutlet:${idOutlet}){
                        kodekategori
                        namakategori
                        isHide
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listKategoriApp) return(resolve(resp.data.listKategoriApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    search: (idOutlet,search,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listKategoriApp(idOutlet:${idOutlet},search:"${search}"){
                        kodekategori
                        namakategori
                        isHide
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listKategoriApp) return(resolve(resp.data.listKategoriApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDetail: (idOutlet,kodekategori,clientID,token)=> {
        return new Promise((resolve,reject)=> {
            const query =`
                query{
                    kategoriApp(idOutlet:${idOutlet},kodekategori:"${kodekategori}"){
                        kodekategori
                        namakategori
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.kategoriApp) return (resolve(resp.data.kategoriApp))
            }).catch(err => reject(err))
        })
    },
    simpanKategori:(idOutlet, namakategori, clientID,token)=>{
         return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanKategoriApp(idOutlet:${idOutlet},input:{namakategori:"${namakategori}"})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanKategoriApp){
                    return(resolve(resp.data.simpanKategoriApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    updateKategori:(idOutlet,kodekategori,namakategori,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanKategoriApp(idOutlet:${idOutlet},input:{namakategori:"${namakategori}",kodekategori:"${kodekategori}"})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanKategoriApp != undefined){
                    return(resolve(resp.data.simpanKategoriApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    deleteKategori:(idOutlet,kodekategori,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    hapusKategoriApp(idOutlet:${idOutlet},kodekategori:"${kodekategori}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.hapusKategoriApp != undefined ){
                    return (resolve(resp.data.hapusKategoriApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    importKategori:(idOutlet,listKategori,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(listKategori.length > 0){
                let input = '[';
                for(var i=0,j=listKategori.length;i<j;i++){
                    if(i>0) input += `,`;
                    var _kodekategori = listKategori[i].kodekategori ? `,kodekategori:"${listKategori[i].kodekategori}"` : '';
                    input += `{namakategori:"${listKategori[i].namakategori}"${_kodekategori}}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiKategoriApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.simpanMultiKategoriApp != undefined ){
                        return (resolve(resp.data.simpanMultiKategoriApp));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    deleteMultiKategori:(idOutlet,kodekategori,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(kodekategori.length > 0){
               // console.log(kodekategori)
                let input = '[';
                for(var i=0,j=kodekategori.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodekategori[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        hapusMultiKategoriApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.hapusMultiKategoriApp != undefined ){
                        return (resolve(resp.data.hapusMultiKategoriApp));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    getData: (idOutlet,clientID,token, kodekategori) =>{
        return new Promise((resolve,reject)=>{
            let input = '[';
                for(var i=0,j=kodekategori.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodekategori[i]}"`;
                };
                input += ']';
            const query = `
                query {
                    getMultiKategori(idOutlet:${idOutlet}, kodekategori: ${input}){
                        kodekategori
                        namakategori
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.getMultiKategori) return(resolve(resp.data.getMultiKategori));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    handleActive:(idOutlet,kodekategori,clientID,token, hide)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    handleActiveKategori(idOutlet:${idOutlet},kodekategori:"${kodekategori}",hide:${hide})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.handleActiveKategori != undefined ){
                    return (resolve(resp.data.handleActiveKategori));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default kategori;