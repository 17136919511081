/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import BarangForm from './BarangForm'
import mBarang from './Barang.controller'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util';
import validate from 'validate.js';
import DialogInfo from '../../Dialog/DialogInfo'


const BarangAdd = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const { clientID, token } = useContext(AuthContext)
    const [listKategori, setListKategori] = useState([])
    const [listSubKategori, setListSubKategori] = useState([])
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [listTopping, setListTopping] = useState([])
    const [state, setState] = useState({
        kodebarang: '',
        namabarang: '',
        kategori: {
            kodekategori: '',
            namakategori: ''
        },
        subkategori: {
            kodesubkategori: '',
            namasubkategori: ''
        },
        hargasatuan: 0,
        hargagojek: 0,
        hargagrab: 0,
        hargalain: 0,
        hargalain2: 0,
        satuan: '',
        isStock: false,
        favorite: false,
        useVarian: false,
        printer: 'none',
        bgColor: null,
        colorText: null,
        topping: ''
    })
    useEffect(() => {
        var render = true;
        mBarang.loadCombobox(kodeoutlet, clientID, token).then(resp => {
            if (render) {
                setListKategori(resp.listKategoriApp);
                setListSubKategori(resp.listSubKategoriApp);
                setListTopping(resp.listToppingGroup || [])
            }
        })
        return () => {
            render = false;
        };
    }, [kodeoutlet, clientID, token])
    const handleChange = (key, value) => {
        var newState = { ...state }
        if (key == 'kategori') {
            newState.kategori.kodekategori = value;
        } else if (key == 'subkategori') {
            newState.subkategori.kodesubkategori = value;
        } else {

            newState[key] = value;
        }
        setState(newState);
    }

    const validateForm = () => {
        const { namabarang, kategori, subkategori, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock, printer } = state;
        const kategoriFix = kategori.kodekategori
        const subKategoriFix = subkategori.kodesubkategori
        const promBarang = new Promise((resolve, reject) => {
            const constraint = {
                namabarang: {
                    length: {
                        minimum: 3,
                        message: "Nama Barang minimal terdiri dari 3 huruf"
                    },
                },
                kategoriFix: {
                    length: {
                        minimum: 1,
                        message: "Kategori Wajib isi"
                    },
                },
                subKategoriFix: {
                    length: {
                        minimum: 1,
                        message: "Sub Kategori Wajib Isi"
                    }
                },
                satuan: {
                    length: {
                        minimum: 1,
                        message: "Satuan Wajib Isi"
                    }
                },
                hargasatuan: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga satuan yang sesuai"
                    }
                },
                hargagojek: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga gojek yang sesuai"
                    }
                },
                hargagrab: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga grab yang sesuai"
                    }
                },
                hargalain: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga lain yang sesuai"
                    }
                }
            }
            const validator = validate({ namabarang, kategoriFix, subKategoriFix, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock, printer }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promBarang]);
    }


    const handleSimpan = () => {
        setIsLoading(true)
        validateForm().then(() => {
            const { namabarang, kategori, subkategori, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, satuan, isStock, printer, bgColor, colorText, favorite, useVarian, topping } = state;
            mBarang.simpanBarang(kodeoutlet, namabarang, kategori.kodekategori, subkategori.kodesubkategori, satuan, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, isStock, printer, clientID, token, bgColor, colorText, favorite, useVarian, topping).then((resp) => {
                setIsLoading(false)
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Barang berhasil di simpan<p/>`)
                    if (useVarian) {
                        setDialogUrl(`/manajemenoutlet/${kodeoutlet}/barang-detail/${resp}/1/5`)
                    } else {
                        setDialogUrl(`/manajemenoutlet/${kodeoutlet}/barang/1/5`)
                    }
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Data Gagal Di simpan<p/>`)
                }
            }).catch(err => {
                setIsLoading(false)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setIsLoading(false)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }
    return (
        <Container>
            <BarangForm
                history={props.history}
                kodeoutlet={kodeoutlet}
                kodebarang={state.kodebarang}
                listKategori={listKategori}
                listSubKategori={listSubKategori}
                namabarang={state.namabarang}
                kategori={state.kategori}
                subkategori={state.subkategori}
                satuan={state.satuan}
                hargasatuan={state.hargasatuan}
                hargagojek={state.hargagojek}
                hargagrab={state.hargagrab}
                hargalain={state.hargalain}
                hargalain2={state.hargalain2}
                isStock={state.isStock}
                printer={state.printer}
                dBgColor={state.bgColor}
                dColor={state.colorText}
                favorite={state.favorite}
                useVarian={state.useVarian}
                handleChange={handleChange}
                topping={state.topping}
                listTopping={listTopping}
            />
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12} className="text-center">
                    <Button variant="contained" color="primary" onClick={handleSimpan} disabled={isLoading}>Simpan</Button>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    )
}

export default BarangAdd;