/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import useStyles from '../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'react-moment';
import moment from 'moment'
import CurrencyFormat from 'react-currency-format';
import PageviewIcon from '@material-ui/icons/Pageview';
import mHistoryPasti from './HistorySettlementPasstiCtrl'
const dateNow = moment();
const dateSkrg = moment(dateNow).tz("Asia/Jakarta").set({
    date: 1,
    hour: 0,
    minute: 0,
    second: 0
});
const bulanDepan = moment(dateNow).add(1, 'months');
const dateAkhir = moment(bulanDepan).tz("Asia/Jakarta").set({
    date: 1,
    hour: 23,
    minute: 59,
    second: 59
}).subtract(1, 'days');
const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    {
        id: "noSettlement", numeric: false, disablePadding: true, label: "No Settlement"
    },
    { id: "tglSettlement", numeric: true, disablePadding: false, label: "tgl Settlement" },
    { id: "MIDReader", numeric: true, disablePadding: false, label: "ID Passti" },


];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
        rowCount,
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell>Detail</TableCell>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const HistorySettlementPassti = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = parseInt(props.match.params.page, 10) - 1;
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const { clientID, token } = useContext(AuthContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [dense, setDense] = React.useState(false);
    const [isRefresh, setIsRefresh] = useState(true)
    const [rows, setRows] = useState([])
    const [openRiwayat, setOpenRiwayat] = useState(true)
    const [openTgl, setOpenTgl] = useState(false)
    const [tglDari, setTglDari] = useState(dateSkrg)
    const [tglSampai, setTglSampai] = useState(dateAkhir)

    useEffect(() => {
        var render = true
        if (isRefresh) {
            mHistoryPasti.loadData(kodeoutlet, clientID, token, tglDari, tglSampai).then(resp => {
                if (render) setRows(resp)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh])
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}`)
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const loadingRow = [0, 1, 2, 3, 4];
    const TglDariHandler = (val) => {
        setTglDari(moment(val).format("D MMM YYYY"))
    }
    const TglSampaiHandler = (val) => {
        setTglSampai(moment(val).format("D MMM YYYY"))
    }
    const handleOpenRiwayat = () => {
        setOpenTgl(false)
        setOpenRiwayat(true);
        setIsRefresh(true);
    }
    const handleOpenTgl = () => {
        setOpenRiwayat(false);
        setOpenTgl(true)
        setPage(defaultPage)
        setRowsPerPage(defaultrowsPerPage)
    }
    const handleView = (kodetrans) => {
        alert("Masih proses Pengerjaan")
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />History Settlement Passti</h3>
                    {openTgl && (
                        <Row>
                            <Col xs={12} lg={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                    <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        id="tanggal"
                                        label="Tanggal Dari"
                                        format="DD MMM YYYY"
                                        value={tglDari}
                                        onChange={TglDariHandler}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                            <Col xs={12} lg={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                    <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        id="tanggal"
                                        label="Tanggal Sampai"
                                        format="DD MMM YYYY"
                                        value={tglSampai}
                                        onChange={TglSampaiHandler}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                            <Col xs={12} md={12} className="text-center"><br />
                                <Button variant="contained" color="primary" onClick={handleOpenRiwayat}>Lihat Settlement</Button>
                            </Col>
                        </Row>
                    )}
                    {openRiwayat && (
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <div className="btnTable">
                                    <span>
                                        <b>
                                            <Moment format="D MMM YYYY">{tglDari}</Moment>
                                            &nbsp;-&nbsp;
                                            <Moment format="D MMM YYYY">{tglSampai}</Moment>
                                        </b>
                                    </span>
                                    <span className="ubahTanggal" onClick={handleOpenTgl}> (Ubah Tanggal)</span>
                                    <div className={classes.tableWrapper}>
                                    </div>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {rows.length == 0 && loadingRow.map(el => (
                                                <TableRow
                                                    key={el.toString()}
                                                    style={{ height: 50 }}
                                                >
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell >
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                </TableRow>
                                            ))

                                            }
                                            {stableSort(rows, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const total = row.statusid === 20 ? row.payment.map(el => el.total).reduce((a, b) => a + b, 0) : 0;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={index}
                                                            className={(row.statusid === 8 || row.statusid === 9) && ("bgRowCancel")}
                                                        >
                                                            <TableCell>
                                                                <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleView.bind(this, row._id)}><PageviewIcon /></Button>
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.nosettlement}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.tanggal}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.MIDReader}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={7} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page"
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
export default HistorySettlementPassti;