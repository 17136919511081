/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TextField, Button } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import PublishIcon from '@material-ui/icons/Publish';
import { ExcelRenderer } from 'react-excel-renderer';
import mControl from './Paket.controller'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util';
import validate from 'validate.js'
import DialogInfo from '../../Dialog/DialogInfo'
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from 'react-loading-skeleton';
import mBarang from '../Barang/Barang.controller'

const PaketImport = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = props.defaultPage * 1 || 1;
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const { clientID, token } = useContext(AuthContext)
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [listBarang, setListBarang] = useState([])
    const [isReload, setIsReload] = useState(true)
    useEffect(() => {
        mBarang.loadData(kodeoutlet, clientID, token).then(resp => {
            setListBarang(resp);
        }).catch(err => {
            console.log(err);
        }).finally(() => setIsReload(false))
        return () => {

        }
    }, [kodeoutlet, clientID, token, isReload])
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/paket/${defaultPage}/${defaultrowsPerPage}`)
    }
    const importHandler = (val) => {
        var fileObj = val.target.files[0];
        ExcelRenderer(fileObj, (err, resp) => {
            if (resp) {
                const listPaket = [];
                //VALIDASI Header
                const [kodepkt, namapaket, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, n_kodebarang, n_namabarang, n_qty] = resp.rows[0];

                if (kodepkt.toUpperCase() !== 'KODE PAKET' || namapaket.toUpperCase() !== 'NAMA PAKET' || satuan.toUpperCase() !== 'SATUAN' || hargasatuan.toUpperCase() !== 'HARGA SATUAN' || hargagojek.toUpperCase() !== 'HARGA GOJEK' || hargagrab.toUpperCase() !== 'HARGA GRAB' || hargalain.toUpperCase() !== 'HARGA LAIN' || isStock.toUpperCase() !== 'MANAJEMEN STOK' || n_kodebarang.toUpperCase() !== 'KODE BARANG' || n_namabarang.toUpperCase() !== 'NAMA BARANG' || n_qty.toUpperCase() !== 'QTY') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Format data di excel tidak sesuai<p/>`)
                    return
                }
                // const [kodepaket_det, namapaket_det, satuan_det, hargasatuan_det, hargagojek_det, hargagrab_det, hargalain_det, isStock_det] = resp.rows[1];
                // if (kodepaket_det.toString().length <= 0 && namapaket_det.toString().length <= 0 && satuan_det.toString().length <= 0 && hargasatuan_det.toString().length <= 0 && hargagojek_det.toString().length <= 0 && hargagrab_det.toString().length <= 0 && hargalain_det.toString().length <= 0 && isStock_det.toString().length) {
                //     setOpenConfirmInfo(true)
                //     setDialogtext(`<p>Periksa kembali data paket anda<p/>`)
                //     return
                // }
                for (var i = 1, j = resp.rows.length; i < j; i++) {
                    if (resp.rows[i].length > 0) {
                        const [kodepkt, namapaket, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, kodebrg, namabarang, qty] = resp.rows[i];
                        //VALIDASI Data
                        var kodebarang = kodebrg.toString();
                        var kodepaket = kodepkt.toString();
                        const cekBarang = listBarang.filter(el => el.kodebarang === kodebarang)
                        if (cekBarang.length <= 0) {
                            setOpenConfirmInfo(true)
                            setDialogtext(`<p>Kode barang ${kodebarang} yang anda masuki belum terdaftar di data barang<p/>`)
                            return
                        }
                        const constraint = {
                            kodepaket: {
                                length: {
                                    minimum: 1,
                                    message: "Kode Paket wajib isi dan minimal terdiri dari 3 huruf atau angka"
                                },
                            },
                            satuan: {
                                length: {
                                    minimum: 1,
                                    message: "Satuan Wajib Isi"
                                }
                            },
                            hargasatuan: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga satuan yang sesuai"
                                }
                            },
                            hargagojek: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga gojek yang sesuai"
                                }
                            },
                            hargagrab: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga grab yang sesuai"
                                }
                            },
                            hargalain: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga lain yang sesuai"
                                }
                            },
                            kodebarang: {
                                length: {
                                    minimum: 1,
                                    message: "Kode Paket wajib isi dan minimal terdiri dari 3 huruf atau angka"
                                },
                            },
                            namabarang: {
                                length: {
                                    minimum: 3,
                                    message: "Nama Paket minimal terdiri dari 3 huruf"
                                },
                            },
                            qty: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi qty yang sesuai"
                                }
                            }
                        }
                        const validator = validate({ kodepaket, namapaket, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, kodebarang, namabarang, qty }, constraint, { fullMessages: false });
                        if (validator !== undefined) {
                            const keys = Object.keys(validator);
                            var errorMessage = "";
                            for (const key of keys) {
                                errorMessage += validator[key] + "\n";
                            }
                            setOpenConfirmInfo(true)
                            setDialogtext(`<p>${errorMessage}<p/>`)
                            return
                        }
                        if (namapaket) {
                            if ((kodebarang || '').toUpperCase() !== 'KODE PAKET' || namapaket.toUpperCase() !== 'NAMA PAKET') {
                                listPaket.push({ kodepaket, namapaket, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, kodebarang, namabarang, qty })
                            }
                        }
                    }
                }
                setIsLoading(true);
                mControl.simpanMultiPaket(kodeoutlet, clientID, token, listPaket).then(resp => {
                    setIsLoading(false);
                    if (resp) {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Data Berhasil di Import<p/>`)
                        setDialogUrl(`/manajemenoutlet/${kodeoutlet}/paket/1/5`)
                    } else {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Data Gagal di import<p/>`)
                    }
                }).catch(err => {
                    setIsLoading(false);
                    if (isArray(err) && err.length > 0) {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Gagal Simpan :  ${err[0].message}<p/>`)
                    } else if (typeof err == 'error') {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>${err.message}<p/>`)
                    } else {
                        console.log(err);
                    }
                })
            }
            else {
                console.log(err);
            }
        });
    }

    const loadingRow = [0, 1, 2];
    return (
        <Container>
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12}>
                    <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Import Paket</h4>
                </Col>
                <Col xs={12} style={{ marginTop: 20 }}>
                    <TextField
                        id="importFile"
                        label="Gambar"
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={importHandler.bind(this)}
                    />
                    {!isReload && (
                        <label htmlFor="importFile">
                            <Button variant="contained" color="primary" disabled component="span"><PublishIcon /> Pilih file</Button>
                            {isLoading && (
                                <span><CircularProgress /> Mohon tunggu sedang import data...</span>
                            )}
                        </label>
                    )}
                    <p>
                        File harus dalam format XLSX (namafile.xlsx).<br />
                        Contoh format sebagai berikut :
                    </p>
                    <ul>
                        <li>Untuk contoh format, Anda bisa menggunakan <a href="/Import/ImportPaket.xlsx" download>Template ini</a></li>
                        <li>Setiap kode paket tidak boleh sama</li>
                        <li>Kode paket tidak boleh sama dan yang berawal dari angka 0(NOL) ditulis di excel dengan format tanda petik 1 didepan kode. Contoh : '0001</li>
                    </ul>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr className="tabelWhite">
                                <th>Kode Paket</th>
                                <th>Nama Paket</th>
                                <th>Satuan</th>
                                <th>Harga Satuan</th>
                                <th>Harga Gojek</th>
                                <th>Harga Grab</th>
                                <th>Harga Lain</th>
                                <th>Manajemen Stok</th>
                                <th>Kode Barang</th>
                                    <th>Nama Barang</th>
                                    <th>qty</th>
                            </tr>
                        </thead>
                        {isReload && loadingRow.map(el => (
                            <tbody>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                                <td> <Skeleton /></td>
                            </tbody>
                        ))}
                        {!isReload && (
                            <tbody>
                                <tr className="tabelWhite">
                                    <td>0001</td>
                                    <td>Paket hemat</td>
                                    <td>Porsi</td>
                                    <td>5000</td>
                                    <td>7000</td>
                                    <td>8000</td>
                                    <td>0</td>
                                    <td>TRUE</td>
                                    <td>7003</td>
                                    <td>AYAM BAKAR</td>
                                    <td>1</td>
                                </tr>
                                <tr className="tabelWhite">
                                    <td>0001</td>
                                    <td>Paket hemat</td>
                                    <td>Porsi</td>
                                    <td>5000</td>
                                    <td>7000</td>
                                    <td>8000</td>
                                    <td>0</td>
                                    <td>TRUE</td>
                                    <td>Ait200404093537</td>
                                    <td>AYAM KREMES</td>
                                    <td>1</td>
                                </tr>
                                <tr className="tabelWhite">
                                    <td>0001</td>
                                    <td>Paket hemat</td>
                                    <td>Porsi</td>
                                    <td>5000</td>
                                    <td>7000</td>
                                    <td>8000</td>
                                    <td>0</td>
                                    <td>TRUE</td>
                                    <td>Aya200403101239</td>
                                    <td>AYAM PENYET</td>
                                    <td>1</td>
                                </tr>
                                <tr className="tabelWhite">
                                    <td>0002</td>
                                    <td>Paket Sarapan</td>
                                    <td>Porsi</td>
                                    <td>6000</td>
                                    <td>8000</td>
                                    <td>9000</td>
                                    <td>0</td>
                                    <td>TRUE</td>
                                    <td>9901</td>
                                    <td>NASI KUCING</td>
                                    <td>1</td>
                                </tr>
                                <tr className="tabelWhite">
                                    <td>0002</td>
                                    <td>Paket Sarapan</td>
                                    <td>Porsi</td>
                                    <td>6000</td>
                                    <td>8000</td>
                                    <td>9000</td>
                                    <td>0</td>
                                    <td>TRUE</td>
                                    <td>9902</td>
                                    <td>NASI AYAM</td>
                                    <td>1</td>
                                </tr>
                                <tr className="tabelWhite">
                                    <td>0002</td>
                                    <td>Paket Sarapan</td>
                                    <td>Porsi</td>
                                    <td>6000</td>
                                    <td>8000</td>
                                    <td>9000</td>
                                    <td>0</td>
                                    <td>TRUE</td>
                                    <td>1010</td>
                                    <td>NASI KEBULI</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    )
}

export default PaketImport;