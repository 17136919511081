/* eslint-disable no-redeclare */
import GQL from '../../GQL';
const barang = {
    loadData: (idOutlet, clientID, token) => {
        console.log("load")
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listBarangApp2(idOutlet:${idOutlet}){
                        kodebarang
                        namabarang
                        kodekategori
                        kodesubkategori
                        hargasatuan
                        useVarian
                        isHide
                        isGambar
                        kategori{
                            kodekategori
                            namakategori
                        }
                        subkategori{
                            kodesubkategori
                            namasubkategori
                        }
                        hargasatuan
                        hargagojek
                        hargagrab
                        hargalain
                        hargalain2
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                //   console.log(resp.data.listBarangApp, "resp.data.listBarangApp")
                const listBarang = []
                resp.data.listBarangApp2.map(el => {
                    const kodebarang = el.kodebarang
                    const namabarang = el.namabarang
                    const hargasatuan = el.hargasatuan

                    const kodekategori = el.kodekategori
                    const kodesubkategori = el.kodesubkategori

                    const useVarian = el.useVarian
                    const isHide = el.isHide || false
                    if (el.kategori) {
                        var kategori = el.kategori.namakategori
                    } else {
                        var kategori = `Kode Kategori ${el.kodekategori} belum terdaftar`
                    }
                    if (el.subkategori) {
                        var subkategori = el.subkategori.namasubkategori
                    } else {
                        var subkategori = `Kode Sub Kategori ${el.kodesubkategori} belum terdaftar`
                    }
                    listBarang.push({
                        kodebarang,
                        namabarang,
                        kodekategori,
                        kategori,
                        kodesubkategori,
                        subkategori,
                        isHide,
                        hargasatuan,
                        useVarian,
                        isGambar: el.isGambar
                    })
                })
                //   console.log(listBarang, "listBarang")
                if (resp.data && listBarang) return (resolve(listBarang));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadExport: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listBarangApp(idOutlet:${idOutlet}){
                        kodebarang
                        namabarang
                        kodekategori
                        kodesubkategori
                        satuan
                        hargasatuan
                        hargagojek
                        hargagrab
                        hargalain
                        hargalain2
                        isStock
                        printer
                        isHide
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listBarangApp) return (resolve(resp.data.listBarangApp));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    search: (idOutlet, search, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listBarangApp(idOutlet:${idOutlet},search:"${search}"){
                        kodebarang
                        namabarang
                        kodekategori
                        kodesubkategori
                        hargasatuan
                        useVarian
                        isHide
                        isGambar
                        kategori{
                            kodekategori
                            namakategori
                        }
                        subkategori{
                            kodesubkategori
                            namasubkategori
                        }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                const listBarang = []
                resp.data.listBarangApp.map(el => {
                    const kodebarang = el.kodebarang
                    const namabarang = el.namabarang
                    const isHide = el.isHide || false
                    if (el.kategori) {
                        var kategori = el.kategori.namakategori
                    } else {
                        var kategori = `Kode Kategori ${el.kodekategori} belum terdaftar`
                    }
                    if (el.subkategori) {
                        var subkategori = el.subkategori.namasubkategori
                    } else {
                        var subkategori = `Kode Sub Kategori ${el.kodesubkategori} belum terdaftar`
                    }
                    listBarang.push({
                        kodebarang,
                        namabarang,
                        kategori,
                        subkategori,
                        isHide,
                        isGambar: el.isGambar
                    })
                })
                if (resp.data && listBarang) return (resolve(listBarang));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadDetail: (idOutlet, kodebarang, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    barangApp(idOutlet:${idOutlet},kodebarang:"${kodebarang}"){
                        kodebarang
                        namabarang
                        kategori{
                            kodekategori
                            namakategori
                        }
                        subkategori{
                            kodesubkategori
                            namasubkategori
                        }
                        satuan
                        hargasatuan
                        hargagojek
                        hargagrab
                        hargalain
                        hargalain2
                        isStock
                        gambar
                        printer
                        bgColor
                        colorText
                        useVarian
                        favorite
                        topping
                        variant{
                            kodebarang
                            namabarang 
                            hargasatuan
                            hargagojek
                            hargagrab
                            hargalain
                            hargalain2
                        }
                    }
                    listKategoriApp(idOutlet:${idOutlet}){
                        kodekategori
                        namakategori
                    }
                    listSubKategoriApp(idOutlet:${idOutlet}){
                        kodesubkategori
                        namasubkategori
                    }
                    listToppingGroup(idOutlet:${idOutlet}){
                        kodegroup
                        namagroup
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data) return (resolve(resp.data))
            }).catch(err => reject(err))
        })
    },
    loadCombobox: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    listKategoriApp(idOutlet:${idOutlet}){
                        kodekategori
                        namakategori
                    }
                    listSubKategoriApp(idOutlet:${idOutlet}){
                        kodesubkategori
                        namasubkategori
                    }
                    listToppingGroup(idOutlet:${idOutlet}){
                        kodegroup
                        namagroup
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data) return (resolve(resp.data))
            }).catch(err => reject(err))
        });
    },
    simpanBarang: (idOutlet, namabarang, kategori, subkategori, satuan, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, isStock, printer, clientID, token, bgColor, colorText, favorite, useVarian, topping) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanBarangApp(idOutlet:${idOutlet},input:{namabarang:"${namabarang}",kategori:"${kategori}",subkategori:"${subkategori}",satuan:"${satuan}",hargasatuan:${hargasatuan},hargagojek:${hargagojek}, hargagrab:${hargagrab},hargalain:${hargalain},hargalain2:${hargalain2},isStock:${isStock},printer:"${printer}",bgColor:"${bgColor}",colorText:"${colorText}",favorite:${favorite},useVarian:${useVarian}, topping:"${topping}"})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanBarangApp) {
                    return (resolve(resp.data.simpanBarangApp));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    updateBarang: (idOutlet, kodebarang, namabarang, kategori, subkategori, satuan, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, isStock, printer, clientID, token, bgColor, colorText, favorite, useVarian, topping) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanBarangApp(idOutlet:${idOutlet},input:{kodebarang:"${kodebarang}",namabarang:"${namabarang}",kategori:"${kategori}",subkategori:"${subkategori}",satuan:"${satuan}",hargasatuan:${hargasatuan},hargagojek:${hargagojek}, hargagrab:${hargagrab},hargalain:${hargalain},hargalain2:${hargalain2},isStock:${isStock},printer:"${printer}",bgColor:"${bgColor}",colorText:"${colorText}",favorite:${favorite},useVarian:${useVarian}, topping:"${topping}"})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanBarangApp !== undefined) {
                    return (resolve(resp.data.simpanBarangApp));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    deleteBarang: (idOutlet, kodebarang, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusBarangApp(idOutlet:${idOutlet},kodebarang:"${kodebarang}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusBarangApp !== undefined) {
                    return (resolve(resp.data.hapusBarangApp));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    importBarang: (idOutlet, listBarang, clientID, token) => {
        return new Promise((resolve, reject) => {
            if (listBarang.length > 0) {
                let input = '[';
                for (var i = 0, j = listBarang.length; i < j; i++) {
                    const { kodebarang, namabarang, kategori, subkategori, satuan, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, isStock, printer } = listBarang[i];
                    if (i > 0) input += `,`;
                    var _kodebarang = kodebarang ? `,kodebarang:"${kodebarang}"` : '';
                    input += `{namabarang:"${namabarang}",kategori:"${kategori}",subkategori:"${subkategori}",satuan:"${satuan}",hargasatuan:${hargasatuan},hargagojek:${hargagojek},hargagrab:${hargagrab},hargalain:${hargalain},hargalain2:${hargalain2},isStock:${isStock},printer:"${printer}"${_kodebarang}}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiBarangApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.simpanMultiBarangApp !== undefined) {
                        return (resolve(resp.data.simpanMultiBarangApp));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                })
            } else {
                return (reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    hapusMultiBarangApp: (idOutlet, kodebarang, clientID, token) => {
        return new Promise((resolve, reject) => {
            if (kodebarang.length > 0) {
                let input = '[';
                for (var i = 0, j = kodebarang.length; i < j; i++) {
                    if (i > 0) input += `,`;
                    input += `"${kodebarang[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        hapusMultiBarangApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.hapusMultiBarangApp !== undefined) {
                        return (resolve(resp.data.hapusMultiBarangApp));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                })
            } else {
                return (reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    getData: (idOutlet, clientID, token, kodebarang) => {
        return new Promise((resolve, reject) => {
            let input = '[';
            for (var i = 0, j = kodebarang.length; i < j; i++) {
                if (i > 0) input += `,`;
                input += `"${kodebarang[i]}"`;
            };
            input += ']';
            const query = `
                query {
                    getMultiBarang(idOutlet:${idOutlet}, kodebarang: ${input}){
                        kodebarang
                        namabarang
                        kategori{
                            kodekategori
                            namakategori
                        }
                        subkategori{
                            kodesubkategori
                            namasubkategori
                        }
                        satuan
                        hargasatuan
                        hargagojek
                        hargagrab
                        hargalain
                        hargalain2
                        isStock
                        gambar
                        printer
                    }
                    listKategoriApp(idOutlet:${idOutlet}){
                        kodekategori
                        namakategori
                    }
                    listSubKategoriApp(idOutlet:${idOutlet}){
                        kodesubkategori
                        namasubkategori
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.getMultiBarang) return (resolve(resp.data));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    saveVariantBarang: (idOutlet, clientID, token, kodebarangori, kodebarang, namabarang, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2 = 0) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanBarangVariant(idOutlet:${idOutlet}, kodebarang:"${kodebarangori}", input:[
                        {kodebarang:"${kodebarang}", namabarang:"${namabarang}", hargasatuan: ${hargasatuan},hargagojek: ${hargagojek},hargagrab: ${hargagrab},hargalain: ${hargalain},hargalain2: ${hargalain2}}
                    ])
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanBarangVariant !== undefined) {
                    return (resolve(resp.data.simpanBarangVariant));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    hapusVariantBarang: (idOutlet, clientID, token, kodebarangori, kodebarang) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusBarangVariant(idOutlet:${idOutlet}, kodebarang:"${kodebarangori}", kodebarangvariant:"${kodebarang}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusBarangVariant !== undefined) {
                    return (resolve(resp.data.hapusBarangVariant));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    handleActive: (idOutlet, kodebarang, clientID, token, hide) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    handleActiveBarang(idOutlet:${idOutlet},kodebarang:"${kodebarang}",hide:${hide})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.handleActiveBarang !== undefined) {
                    return (resolve(resp.data.handleActiveBarang));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    loadGambar: (idOutlet, clientID, token, kodebarang) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    barangApp(idOutlet:${idOutlet},kodebarang:"${kodebarang}"){
                        gambar
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.barangApp.gambar) return (resolve(resp.data.barangApp.gambar))
            }).catch(err => reject(err))
        })
    },
    simpanGambarBarang: (idOutlet, clientID, token, kodebarang, gambar) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanGambarBarang(idOutlet:${idOutlet},kodebarang:"${kodebarang}", gambar:"${gambar}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.simpanGambarBarang) return (resolve(resp.data.simpanGambarBarang))
            }).catch(err => reject(err))
        })
    },
    resetGambarBarang: (idOutlet, clientID, token, kodebarang) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    resetGambarBarang(idOutlet:${idOutlet},kodebarang:"${kodebarang}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.resetGambarBarang) return (resolve(resp.data.resetGambarBarang))
            }).catch(err => reject(err))
        })
    },
}

export default barang;