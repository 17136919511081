import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import { Line, } from 'react-chartjs-2';
import mLaporan from '../Laporan.controler'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { BoxInformasi, Item1, Item2 } from './SalesSummaryBox'

const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const dateNow = moment();
const SalesSummary = (props) => {
    const classes = useStyles();
    const [isRefresh, setIsRefresh] = useState(true)
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [kodeoulet, setKodeoulet] = useState(props.kodeOutlet)
    const [idOutlet, setIdOutlet] = useState(props.idOutlet)
    const [jumlahHari, setJumlahHari] = useState()
    const [isBulanan, setIsBulanan] = useState(0)
    const [listPenjualanPerjam, setListPenjualanPerjam] = useState([])
    const [salesJam, setsalesJam] = useState({
        labels: [],
        datasets: [
            {
                label: 'Laporan Penjualan Per Jam',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgb(230, 123, 33)',
                borderColor: 'rgb(255, 117, 0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#000',
                pointBackgroundColor: '#000',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255, 117, 0)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: []
            }
        ]
    })
    const [salesMingguan, setsalesMingguan] = useState({
        labels: [],
        datasets: [
            {
                label: 'Laporan Penjualan Mingguan',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgb(230, 123, 33)',
                borderColor: 'rgb(255, 117, 0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#000',
                pointBackgroundColor: '#000',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255, 117, 0)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: []
            }
        ]
    })
    const [salesBulanan, setsalesBulanan] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
        datasets: [
            {
                label: 'Laporan Penjualan Bulanan',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgb(230, 123, 33)',
                borderColor: 'rgb(255, 117, 0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#000',
                pointBackgroundColor: '#000',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255, 117, 0)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: []
            }
        ]
    })
    const [statistikSukses, setStatistikSukses] = useState({
        hitung: 0, jumlah: 0
    })
    const [statistikGagal, setStatistikGagal] = useState({
        hitung: 0, jumlah: 0
    })
    const [pembayaran, setPembayaran] = useState({
        tunai: 0, nontunai: 0
    })


    useEffect(() => {
        var render = true
        if (isRefresh) {
            sumDate()
            mLaporan.loadRingakasanPenjualan(clientID, token, userLogin, kodeoulet, idOutlet, tglDari, tglSampai).then(resp => {
                setStatistikSukses(resp.statistikSukses)
                setStatistikGagal(resp.statistikGagal)
                setPembayaran(resp.pembayaran)
                const newGrafikharian = {
                    ...salesMingguan,
                    labels: resp.hariurutArray,
                    datasets: [
                        {
                            ...salesMingguan.datasets[0],
                            data: resp.penjualanHarian
                        }
                    ]
                }
                const newGrafikBulanan = {
                    ...salesBulanan,
                    labels: resp.penjualanBulanan,
                    datasets: [
                        {
                            ...salesBulanan.datasets[0],
                            data: resp.penjualanBulananTotal
                        }
                    ]
                }
                const newGrafikPerJam = {
                    ...salesJam,
                    labels: resp.laporanPerJam10,
                    datasets: [
                        {
                            ...salesJam.datasets[0],
                            data: resp.laporanPerJam10TotTrans
                        }
                    ]
                }
                setIsBulanan(resp.penjualanBulanan.length)
                setsalesBulanan(newGrafikBulanan)
                setsalesMingguan(newGrafikharian)
                setsalesJam(newGrafikPerJam)
                setListPenjualanPerjam(resp.penjualanPerJam)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])


    const sumDate = () => {
        var totTanggal = []
        for (var d = new Date(tglDari); d <= new Date(tglSampai); d.setDate(d.getDate() + 1)) {
            totTanggal.push(d)
        }
        setJumlahHari(totTanggal.length)
    }
    const lihatLaporan = () => {
        sumDate()
        setIsRefresh(true)
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} lg={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Dari"
                            format="DD-MM-YYYY HH:mm"
                            value={tglDari}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            onChange={handleChangeTglDari}
                            ampm={false}
                            disableFuture
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Sampai"
                            format="DD-MM-YYYY HH:mm"
                            value={tglSampai}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ color: '#000 !important' }}
                            onChange={handleChangeTglSampai}
                            ampm={false}
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={2} className="text-center"><br />
                    <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                </Col>
            </Row>
            <Row className="marginGrafik">
                <BoxInformasi bg="bg-totPenerimaan" bgHari="" nama="Total Penerimaan" jumlah={numberWithCommas(statistikSukses.jumlah + statistikGagal.jumlah)} value={(
                    <Item1 judul="Total Penjualan" total={numberWithCommas(statistikSukses.jumlah)} />
                )} value2={(
                    <Item2 judul="Total Return" total={numberWithCommas(statistikGagal.jumlah)} />
                )} />
                <BoxInformasi bg="bg-todTrans" nama="Total Transaksi" jumlah={`${statistikSukses.hitung + statistikGagal.hitung} Transaksi`} value={(
                    <Item1 judul="Transaksi Penjualan" total={statistikSukses.hitung} />
                )} value2={(
                    <Item2 judul="Transaksi Return" total={statistikGagal.hitung} />
                )} />
                <BoxInformasi bg="bg-pembayaran" nama="Pembayaran" Pembayaran tunai={numberWithCommas(pembayaran.tunai)} nonTunai={numberWithCommas(pembayaran.nontunai)} />
            </Row>
            <Row>


                {jumlahHari > 1 && (
                    <React.Fragment>
                        {isBulanan > 1 && (
                            <Col xs={12} className="marginGrafik">
                                <Paper className={`${useStyles.paper} grafikMax`}>
                                    <Line data={salesBulanan} />
                                </Paper>
                            </Col>
                        )}
                        <Col xs={12} className="marginGrafik">
                            <Paper className={`${useStyles.paper} grafikMax`}>
                                <Line data={salesMingguan} />
                            </Paper>
                        </Col>
                    </React.Fragment>
                )}
                <Col xs={12} className="marginGrafik">
                    <Paper className={`${useStyles.paper} grafikMax`}>
                        <Line data={salesJam} />
                    </Paper>
                </Col>
                <Col xs={12} className="marginGrafik">
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr className="colorWhite bgHeaderTabel tabelCenter">
                                <th>Jam</th>
                                <th>Total Transaksi</th>
                                <th>Total Penjualan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listPenjualanPerjam.map((data, index) => {
                                return (
                                    <tr className="colorWhite" key={index}>
                                        <td>
                                            {data.jam}
                                        </td>
                                        <td className="text-center">
                                            {data.totalTrans}
                                        </td>
                                        <td className="text-right">
                                            {numberWithCommas(data.totalJual)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                </Col>
            </Row>
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default SalesSummary;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));