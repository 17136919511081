import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import mLaporan from '../Laporan.controler'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { stableSort, getSorting } from '../../../../Helper/Sorting'
import PropTypes from "prop-types";
import { TableSortLabel } from '@material-ui/core';
import numberWithCommas from '../../../../Helper/numberWithCommas'

const Salesman = (props) => {
    const classes = useStyles();
    const lapBarang = props.lisLaporanSub
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("namasubkategori");
    const [isRefresh, setIsRefresh] = useState(true)
    const [listLaporan, setListLaporan] = useState([])
    const { clientID, token, userLogin } = useContext(AuthContext);

    var totJumlah = 0;
    useEffect(() => {
        var render = true
        const listLaporanCreate = []
        lapBarang.reduce((a, b) => {
            if (!a[b.kodesubkategori]) {
                a[b.kodesubkategori] = {
                    kodesubkategori: b.kodesubkategori,
                    total: 0,

                }
                listLaporanCreate.push(a[b.kodesubkategori]);
            }
            a[b.kodesubkategori].total += (b.qty * b.harga) + ((b.qty * b.harga) * (b.tax + b.service) / 100) - b.discountAmount
            return a;
        }, {});
        if (isRefresh) {
            mLaporan.listSubKategori(props.idOutlet, clientID, token).then(resp => {
                const listLaporanCreateFinal = []
                listLaporanCreate.map(el => {
                    resp.find(data => {
                        if (data.kodesubkategori === el.kodesubkategori) {
                            listLaporanCreateFinal.push({
                                kodesubkategori: el.kodesubkategori,
                                namasubkategori: data.namasubkategori,
                                total: el.total,
                            })
                        }
                    })
                })
                setListLaporan(listLaporanCreateFinal)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])



    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    const headRows = [
        { id: "namasubkategori", label: "Nama Sub Kategori" },
        { id: "total", label: "Jumlah" },

    ];
    const EnhancedTableHead = (props) => {
        const {
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (

            <thead>
                <tr className="colorWhite bgHeaderTabel tabelCenter">
                    {headRows.map(row => (
                        <th key={row.id} >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }


    return (
        <React.Fragment>
            <Row>
                <Col xs={12} className="marginGrafik">
                    <ReactHTMLTableToExcel
                        className="buttonExportExcel"
                        table="laporanSalesmanPerSub"
                        filename={`Laporan Salesman Per Sub Kategori ${props.namawaiter} ${moment(props.tglDari).format("DD-MM-YYYY")} sampai ${moment(props.tglSampai).format("DD-MM-YYYY")}`}
                        sheet="Sheet1"
                        buttonText={namaExport()} />
                    <Table id="laporanSalesmanPerSub" striped bordered hover responsive="lg">
                        <EnhancedTableHead
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                        />
                        <tbody className={`${classes.contentBody}`}>
                            {stableSort(listLaporan, getSorting(order, orderBy))
                                .map((data, index) => {
                                    totJumlah += data.total
                                    return (
                                        <tr className="colorWhite" key={index}>
                                            <td>{data.namasubkategori}</td>
                                            <td className="text-right">
                                                {numberWithCommas(data.total)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            <tr className="colorWhite">
                                <th className="text-center">Total</th>
                                <th className="text-right">
                                    {numberWithCommas(totJumlah)}
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default Salesman;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));