import React, { useContext, useState, useEffect } from "react";
import {
    Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, Table, TableRow, TableCell, TableHead, TableBody, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import { Row } from "react-bootstrap";
import mBarang from './Barang.controller'
import AuthContext from '../../context/auth-context'
import moment from 'moment'
import { isArray } from 'util'
import DeleteIcon from '@material-ui/icons/Delete';
import validate from 'validate.js';
import ModalFilterColumn from './Modal.FilterColumn'


var dateNow = moment();
const DialogEditBarang = (props) => {
    const classes = useStyles();
    const kodebarang = props.item
    const kodeoutlet = props.kodeoutlet
    const { clientID, token } = useContext(AuthContext)
    const [addRow, setAddRow] = useState(dateNow.format("YYMMDDHHmmss") * 1 + 0)
    const [listBarang, setListBarang] = useState([])
    const [listKategori, setListKategori] = useState([])
    const [listSubKategori, setListSubKategori] = useState([])
    const [isShow, setIsShow] = useState({
        namabarang: true,
        kategori: true,
        subkategori: true,
        satuan: true,
        hargasatuan: true,
        hargagojek: true,
        hargagrab: true,
        hargalain: true,
        hargalain2: true,
        manajemenstok: true,
        manajemenprinter: true
    })
    const [filterColumn, setFilterColumn] = useState(false)

    const handleClose = () => {
        props.setOpen(false);
    }

    useEffect(() => {
        if (kodebarang == null) {
            setAddRow(addRow + 1)
            setListBarang([
                {
                    kodebarang: addRow,
                    namabarang: '',
                    kategori: {
                        kodekategori: '',
                        namakategori: '',
                    },
                    subkategori: {
                        kodesubkategori: '',
                        namasubkategori: '',
                    },
                    satuan: '',
                    hargasatuan: 0,
                    hargagojek: 0,
                    hargagrab: 0,
                    hargalain: 0,
                    hargalain2: 0,
                    isStock: false,
                    printer: 'none',
                    isInsert: true,
                }
            ])
        } else {
            setFilterColumn(true)
            var render = true
            mBarang.getData(kodeoutlet, clientID, token, kodebarang).then(resp => {
                if (render) {
                    setListBarang(resp.getMultiBarang);
                    setListKategori(resp.listKategoriApp)
                    setListSubKategori(resp.listSubKategoriApp)
                }
            }).catch(err => {
                console.error(err);
            })
            return () => {
                render = false
            };
        }

    }, [kodeoutlet, clientID, token]);
    useEffect(() => {
        mBarang.loadCombobox(kodeoutlet, clientID, token).then(resp => {
            setListKategori(resp.listKategoriApp)
            setListSubKategori(resp.listSubKategoriApp)
        }).catch(err => {
            console.error(err);
        })
        return () => {

        }
    }, [kodeoutlet, clientID, token])

    const handleaddRow = () => {
        setAddRow(addRow + 1)
        setListBarang([
            ...listBarang,
            {
                kodebarang: addRow,
                namabarang: '',
                kategori: {
                    kodekategori: '',
                    namakategori: '',
                },
                subkategori: {
                    kodesubkategori: '',
                    namasubkategori: '',
                },
                satuan: '',
                hargasatuan: 0,
                hargagojek: 0,
                hargagrab: 0,
                hargalain: 0,
                hargalain2: 0,
                isStock: false,
                printer: 'none',
                isInsert: true,
            }
        ])
    }
    const handleChange = (ev, kodebarang) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        let b = listBarang.map(el => {
            if (el.kodebarang == kodebarang) {
                if (key == "namabarang") {
                    return {
                        ...el,
                        namabarang: value
                    }
                } else if (key == "kodebarang") {
                    return {
                        ...el,
                        kodebarang: value,
                    }
                } else if (key === "kategori") {
                    return {
                        ...el,
                        kategori: {
                            kodekategori: value
                        }
                    }
                } else if (key == "subkategori") {
                    return {
                        ...el,
                        subkategori: {
                            kodesubkategori: value
                        }
                    }
                } else if (key == "satuan") {
                    return {
                        ...el,
                        satuan: value
                    }
                } else if (key == "hargasatuan") {
                    return {
                        ...el,
                        hargasatuan: value
                    }
                } else if (key == "hargagojek") {
                    return {
                        ...el,
                        hargagojek: value
                    }
                } else if (key == "hargagrab") {
                    return {
                        ...el,
                        hargagrab: value
                    }
                } else if (key == "hargalain") {
                    return {
                        ...el,
                        hargalain: value
                    }
                } else if (key == 'hargalain2') {
                    return {
                        ...el,
                        hargalain2: value
                    }
                } else if (key == "isStock") {
                    return {
                        ...el,
                        isStock: ev.target.checked
                    }
                } else if (key == "manajemenprinter") {
                    return {
                        ...el,
                        printer: value
                    }
                }
            } else return el
        })
        setListBarang(b)
    }
    const deleteRow = (data) => {
        let a = listBarang.filter(el => {
            return el.kodebarang !== data.kodebarang
        })
        setListBarang(a)
    }
    const SimpanBarang = () => {
        const validasiError = []
        listBarang.map(el => {
            const { namabarang, kategori, subkategori, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, satuan, isStock, printer } = el;
            const kategoriFix = kategori.kodekategori
            const subKategoriFix = subkategori.kodesubkategori
            const constraint = {
                namabarang: {
                    length: {
                        minimum: 3,
                        message: "Nama Barang minimal terdiri dari 3 huruf"
                    },
                },
                kategoriFix: {
                    length: {
                        minimum: 1,
                        message: "Kategori Wajib isi"
                    },
                },
                subKategoriFix: {
                    length: {
                        minimum: 1,
                        message: "Sub Kategori Wajib Isi"
                    }
                },
                satuan: {
                    length: {
                        minimum: 1,
                        message: "Satuan Wajib Isi"
                    }
                },
                hargasatuan: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga satuan yang sesuai"
                    }
                },
                hargagojek: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga gojek yang sesuai"
                    }
                },
                hargagrab: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga grab yang sesuai"
                    }
                },
                hargalain: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga lain yang sesuai"
                    }
                },
                hargalain2: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga lain yang sesuai"
                    }
                }
            }
            const validator = validate({ namabarang, kategoriFix, subKategoriFix, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, satuan, isStock, printer }, constraint, { fullMessages: false });
            if (validator) {
                const err = [validator][0]
                const keys = Object.keys(err);
                var errorMessage = "";
                for (const key of keys) {
                    errorMessage += err[key] + "\n";
                }
                validasiError.push(errorMessage)
                return
            }
        })
        if (validasiError.length > 0) return alert(validasiError[0])
        const dataSimpan = listBarang.map(el => {
            return {
                ...el,
                kategori: el.kategori.kodekategori,
                subkategori: el.subkategori.kodesubkategori,
            }
        })
        mBarang.importBarang(kodeoutlet, dataSimpan, clientID, token).then(resp => {
            if (resp) {
                alert("Data Berhasil Di Simpan")
                props.setOpen(false);
                props.setIsRefresh(true);
                props.setSelected([])
            } else {
                alert("Data Gagal Di Simpan")
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                alert(`Gagal Simpan :  ${err[0].message}`)
            } else if (typeof err == 'error') {
                alert(err.message)
            } else {
                console.log(err);
            }
        })
    }

    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Edit Barang</Typography>
                    <Button color="inherit" onClick={SimpanBarang}>
                        save</Button>
                </Toolbar>
            </AppBar>
            <div className="contentManual DialogFont bodyDialog">
                <Row className={classes.root}>
                    {kodebarang && (
                        <h6 className="tangan link" onClick={() => setFilterColumn(true)}>Filter Column</h6>
                    )}
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className="colorWhite" style={{ width: ' 10px' }}></TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 220px' }}>Kode Barang</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.namabarang ? "table-cell" : "none"}` }}>Nama Barang</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.kategori ? "table-cell" : "none"}` }}>Kategori</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.subkategori ? "table-cell" : "none"}` }}>Sub Kategori</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.satuan ? "table-cell" : "none"}` }}>Satuan</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.hargasatuan ? "table-cell" : "none"}` }}>Harga Satuan</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.hargagojek ? "table-cell" : "none"}` }}>Harga Gojek</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.hargagrab ? "table-cell" : "none"}` }}>Harga Grab</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.hargalain ? "table-cell" : "none"}` }}>Harga Lain</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.hargalain2 ? "table-cell" : "none"}` }}>Harga Lain 2</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.manajemenstok ? "table-cell" : "none"}` }}>Manajemen Stok </TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 300px', display: `${isShow.manajemenprinter ? "table-cell" : "none"}` }}>Manajemen  Printer</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listBarang.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {data.isInsert && (
                                                <DeleteIcon className="deleteDialog" onClick={deleteRow.bind(this, data)} />
                                            )}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className="colorWhite">
                                            {!data.isInsert && (data.kodebarang)}
                                            {data.isInsert && (
                                                <span className="tabelForm">
                                                    <TextField
                                                        id="kodebarang"
                                                        name="kodebarang"
                                                        label=""
                                                        fullWidth
                                                        required
                                                        multiline={false}
                                                        value={data.kodebarang}
                                                        variant="outlined"
                                                        onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                    />
                                                </span>
                                            )}
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.namabarang ? "table-cell" : "none"}` }}>
                                            <TextField
                                                id="namabarang"
                                                name="namabarang"
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.namabarang}
                                                variant="outlined"
                                                onChange={(ev) => handleChange(ev, data.kodebarang)}
                                            />
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.kategori ? "table-cell" : "none"}` }}>
                                            <FormControl fullWidth={true}>
                                                <Select
                                                    name="kategori"
                                                    id="kategori"
                                                    required
                                                    value="kategori"
                                                    value={data.kategori.kodekategori}
                                                    variant="outlined"
                                                    onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                >
                                                    {listKategori.map((el, index) => (
                                                        <MenuItem key={index} value={el.kodekategori}>{el.namakategori} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.subkategori ? "table-cell" : "none"}` }}>
                                            <FormControl fullWidth={true}>
                                                <Select
                                                    name="subkategori"
                                                    id="subkategori"
                                                    required
                                                    value={data.subkategori.kodesubkategori}
                                                    variant="outlined"
                                                    onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                >
                                                    {listSubKategori.map((el, index) => (
                                                        <MenuItem key={index} value={el.kodesubkategori}>{el.namasubkategori} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.satuan ? "table-cell" : "none"}` }}>
                                            <TextField
                                                id="satuan"
                                                name="satuan"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.satuan}
                                                variant="outlined"
                                                onChange={(ev) => handleChange(ev, data.kodebarang)}
                                            />
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.hargasatuan ? "table-cell" : "none"}` }}>
                                            <TextField
                                                id="hargasatuan"
                                                name="hargasatuan"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.hargasatuan}
                                                onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.hargagojek ? "table-cell" : "none"}` }}>
                                            <TextField
                                                id="hargagojek"
                                                name="hargagojek"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.hargagojek}
                                                onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.hargagrab ? "table-cell" : "none"}` }}>
                                            <TextField
                                                id="hargagrab"
                                                name="hargagrab"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.hargagrab}
                                                onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.hargalain ? "table-cell" : "none"}` }}>
                                            <TextField
                                                id="hargalain"
                                                name="hargalain"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.hargalain}
                                                onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.hargalain2 ? "table-cell" : "none"}` }}>
                                            <TextField
                                                id="hargalain2"
                                                name="hargalain2"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.hargalain2}
                                                onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                variant="outlined"
                                                type="number"
                                            />
                                        </TableCell>
                                        <TableCell className="colorWhite" style={{ display: `${isShow.manajemenstok ? "table-cell" : "none"}` }}>
                                            <FormControlLabel control={<Checkbox checked={data.isStock || false} onChange={(ev) => handleChange(ev, data.kodebarang)} />} id="isStock" name="isStock" label="Manajemen Stok" />
                                        </TableCell>
                                        <TableCell style={{ display: `${isShow.manajemenprinter ? "table-cell" : "none"}` }}>
                                            <FormControl fullWidth={true}>
                                                <Select
                                                    name="manajemenprinter"
                                                    id="manajemenprinter"
                                                    required
                                                    value={data.printer || "none"}
                                                    onChange={(ev) => handleChange(ev, data.kodebarang)}
                                                    variant="outlined"
                                                >
                                                    <MenuItem value="kitchen">Kitchen</MenuItem>
                                                    <MenuItem value="bartender">Bartender</MenuItem>
                                                    <MenuItem value="none">Tidak Cetak</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Row>
                <Row>
                    <Button className="marginTop15" variant="contained" color="primary" component="span" onClick={handleaddRow}>Add Row</Button>
                </Row>
                {filterColumn && (
                    <ModalFilterColumn open={filterColumn} setOpen={setFilterColumn} isShow={isShow} setIsShow={setIsShow} />
                )}
            </div>
        </Dialog>
    )
}
export default DialogEditBarang;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));