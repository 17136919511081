import React, { useEffect, useContext, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth-context'
import mPilihOutlet from './PilihOutlet.controller'
import { Row, Container } from 'react-bootstrap';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%',
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));


const PilihOutlet = (props) => {
    const classes = useStyles();
    const [listOutlet, setListOutlet] = useState([])
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [open, setOpen] = React.useState(false);
    const [outletToken, setOutletToken] = useState('');
    const [isReload, setIsReload] = useState(true)
    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSimpan = () => {
        mPilihOutlet.tambahOutlet(outletToken, clientID, token).then(resp => {
            if (resp) {
                alert("Outlet berhasil ditambahkan");
                setOpen(false);
                setIsReload(true);
            } else {
                alert("Server tidak dapat memproses permintaan Anda saat ini. Harap coba beberapa saat lagi")
            }
        }).catch(err => {
            console.log(err);
            alert("Server tidak dapat memproses permintaan Anda saat ini. Harap coba beberapa saat lagi")
        })
    }

    const handleTokenChanged = (ev) => {
        setOutletToken(ev.target.value);
    }
    const deleteOutlet = (data) => {
        const yakin = window.confirm(`Yakin akan menghapus outlet ${data.namaoutlet} ini? `);
        if (yakin) {
            mPilihOutlet.hapusOutlet(data.id, clientID, token).then(resp => {
                if (resp) {
                    alert(`${data.namaoutlet} Berhasil di hapus`);
                    setIsReload(true);
                } else {
                   
                    alert(`${data.namaoutlet} Gagal Di hapus!`)
                }
            })
        }
    }


    useEffect(() => {
        var render = true
        if (isReload) {
            if (clientID && token && userLogin) {
                mPilihOutlet.loadData(userLogin, clientID, token).then(resp => {
                    if (render) setListOutlet(resp);
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    setIsReload(false);
                })
            }
        }
        return () => {
            render = false;
        };
    }, [clientID, token, userLogin, isReload])

    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                <Container className={classes.cardGrid}>
                    <h3 className="text-center pilihOutlet">Pilih Outlet</h3>
                    <Button variant="contained" color="primary" style={{ float: 'right' }} onClick={handleClickOpen}>Tambah Outlet</Button>
                    {/* <Row style={{ 'marginTop': '55px' }}> */}
                        <Grid container spacing={4}>
                            {listOutlet.map(card => (
                                <Grid item key={card.kodeoutlet} xs={12} sm={6} md={4}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <DeleteIcon onClick={deleteOutlet.bind(this, card)} className="colorRed sizeIconHapusOutlet" style={{ float: 'right', cursor: 'pointer' }} />
                                            <Typography gutterBottom variant="h5" component="h2">{card.namaoutlet}</Typography>
                                            <Typography>{card.address}</Typography>
                                        </CardContent>
                                        <CardActions className="bgPilihOutlet">
                                            <Link className="hrefOutlet" to={`/manajemenoutlet/${card.id}`}> Pilih Outlet</Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    {/* </Row> */}
                </Container>
            </main>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" className="colorWhite">Tambah Token</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="toketOutlet"
                        label="Input Token Outlet"
                        type="text"
                        fullWidth
                        value={outletToken}
                        onChange={handleTokenChanged}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Back</Button>
                    <Button onClick={handleSimpan} color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default PilihOutlet;