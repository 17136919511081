import React, { useState } from "react";
import {
    Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, Table, TableRow, TableCell, TableHead, TableBody, TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import { Row } from "react-bootstrap";

const DialogEditCatatanOrder = (props) => {
    const classes = useStyles();
    const item = props.item
    const [addRow, setAddRow] = useState(0)
    console.log(item)

    const handleClose = () => {
        props.setOpen(false);
    }
    const SimpanKategori = () => {
        alert("Data Berhasil Di Simpan")
        props.setOpen(false);
    }
    const handleaddRow = () => {
        setAddRow(addRow + 1)
    }

    const renderRow = () => {
        let array = []
        for (var i = 0; i < addRow; i++) {
            console.log(i);
            array.push(
                <TableRow >
                    <TableCell component="th" scope="row" className="colorWhite">
                        <span className="tabelForm">
                            <TextField
                                id="kodecatatan"
                                name="kodecatatan"
                                label=""
                                fullWidth
                                required
                                multiline={false}
                                value="Insert Kode Catatan"
                                variant="outlined"
                            />
                        </span>
                    </TableCell>
                    <TableCell>
                        <span className="tabelForm">
                            <TextField
                                id="catatan"
                                name="catatan"
                                label=""
                                fullWidth
                                required
                                multiline={false}
                                value="Catatan 1.x"
                                variant="outlined"
                            />
                        </span>
                    </TableCell>
                    <TableCell>
                        <span className="tabelForm">
                            <TextField
                                id="harga"
                                name="harga"
                                label=""
                                fullWidth
                                required
                                multiline={false}
                                value="0"
                                variant="outlined"
                                type="number"
                            />
                        </span>
                    </TableCell>
                </TableRow>
            )
        }
        return array;
    }
    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Edit Catatan Order</Typography>
                    <Button color="inherit" onClick={SimpanKategori}>
                        save</Button>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                <Row className={classes.root}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className="colorWhite" style={{ minWidth: ' 250px' }}>Kode Catatan</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 350px' }}>Catatan</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 350px' }}>Harga</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                <TableCell component="th" scope="row" className="colorWhite">
                                    kode0002
                                    </TableCell>
                                <TableCell>
                                    <TextField
                                        id="catatan"
                                        name="catatan"
                                        label=""
                                        fullWidth
                                        required
                                        multiline={false}
                                        value="Catatan 1.x"
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell>
                                    <span className="tabelForm">
                                        <TextField
                                            id="harga"
                                            name="harga"
                                            label=""
                                            fullWidth
                                            required
                                            multiline={false}
                                            value="0"
                                            variant="outlined"
                                            type="number"
                                        />
                                    </span>
                                </TableCell>
                            </TableRow>
                            {renderRow()}
                        </TableBody>
                    </Table>
                </Row>
                <Row>
                    <Button className="marginTop15" variant="contained" color="primary" component="span" onClick={handleaddRow}>Add Row</Button>
                </Row>
            </Container>
        </Dialog>
    )
}
export default DialogEditCatatanOrder;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));