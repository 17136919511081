import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import mLaporan from '../Laporan.controler'
import moment from 'moment-timezone'
import AuthContext from '../../../../context/auth-context'
import { makeStyles } from '@material-ui/core/styles';
import { FaChevronLeft } from 'react-icons/fa';
import { Button } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import TransaksiDetail from './TransaksiDetail'

const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PaymentDetail = (props) => {
    const classes = useStyles();
    const kodeoulet = props.kodeoulet
    const typeTransaksi = props.typeTransaksi
    const [isRefresh, setIsRefresh] = useState(true)
    const { clientID, token } = useContext(AuthContext);
    const [listPayment, setlistPayment] = useState([])
    const [paymentDet, setPaymentDet] = useState({})
    const [isDetail, setIsDetail] = useState(false)
    var totalPayment = 0, urut = 0


    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.laporanTypeTransDet(clientID, token, kodeoulet, props.tglDari, props.tglSampai, typeTransaksi.tipeTrans, props.isTakeAway).then(resp => {
                setlistPayment(resp)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
            setIsRefresh(false)
        }
        return () => {
            render = false
        };
    }, [isRefresh])
    const handleDetail = (data) => {
        setPaymentDet(data)
        setIsDetail(true)
        props.setIsDetHeader(false)
    }
    const handleCloseDetail = () => {
        setIsDetail(false)
        props.setIsDetHeader(true)
    }

    return (
        <React.Fragment>
            {!isDetail && (
                <Row >
                    <Col xs={12} className={`${classes.contentBody} bodySessionDetail`}>
                        <Table id="laporanPayment" striped bordered hover responsive="lg">
                            <thead>
                                <tr className="colorWhite bgHeaderTabel tabelCenter">
                                    <th>Detail Invoice</th>
                                    <th>No Invoice</th>
                                    <th>Tanggal</th>
                                    <th>Payment</th>
                                    <th className="text-right">Jumlah</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listPayment.filter(a => {
                                    if (typeTransaksi.type === "Dine In") {
                                        if (a.nomeja !== "BL" && a.nomeja.substring(0, 4) !== "Take") return a
                                    } else if (typeTransaksi.type === "Take Away") {
                                        if (a.nomeja === "BL" || a.nomeja.substring(0, 4) === "Take") return a
                                    } else {
                                        return a
                                    }
                                }).map((data, index) => {
                                    totalPayment += data.amount
                                    urut++
                                    return (
                                        <tr className="colorWhite" key={index}>
                                            <td className="text-center">
                                                <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleDetail.bind(this, data)}><PageviewIcon /></Button>
                                            </td>
                                            <td >{data.noinvoice}</td>
                                            <td>
                                                {moment(data.tanggal).format("DD-MMM-YYYY HH:mm")}
                                            </td>
                                            <td className="text-center">{data.namapayment}</td>
                                            <td className="text-right">
                                                {numberWithCommas(data.amount)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr className="colorWhite">
                                    <th className="text-center" colSpan="4">Total</th>
                                    <th className="text-right">
                                        {numberWithCommas(totalPayment)}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {isDetail && (
                <React.Fragment>
                    <Row>
                        <Col xs={12}>
                            <div className="fixHeaderModal">
                                <h3 style={{ paddingBottom: 10 }} className="border-bottom btnBack"><FaChevronLeft onClick={handleCloseDetail} className="btnBack" />Tipe Transaksi Detail </h3>
                                <h5 className="colorBlack">No Invoice : {paymentDet.noinvoice}</h5>
                            </div>
                        </Col>
                        <TransaksiDetail noinvoice={paymentDet.noinvoice} kodeoulet={kodeoulet} />
                    </Row>
                </React.Fragment>
            )}
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default PaymentDetail;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));