import React, { useContext, useState, useEffect } from "react";
import {
    Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, Paper
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, Tab, Nav } from "react-bootstrap";
import AuthContext from '../../../context/auth-context'
import mLaporan from './Laporan.controler'
import SalesSummary from './Modal/SalesSummary'
import Barang from './Modal/Barang'
import Kategori from './Modal/Kategori'
import SubKategori from './Modal/SubKategori'
import Payment from './Modal/Payment'
import Transaksi from './Modal/Transaksi'
import Voids from './Modal/Voids'
import DiskonTaxService from './Modal/DiskonTaxService'
import Session from './Modal/Session'
import Harian from './Modal/Harian'
import ModalConfirm from '../../../Dialog/Modal.Confirm'
import { Collapse, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import MenuIcon from '@material-ui/icons/Menu';
import { isMobile } from "react-device-detect";
import TypeTransaksi from './Modal/TypeTransaksi'
import Salesman from './Modal/Salesman'

const ModalLaporanDet = (props) => {
    const classes = useStyles();
    const kodeOutlet = props.kodeOutlet
    const namaOutlet = props.namaOutlet
    const [isRefresh, setIsRefresh] = useState(true)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [idOutlet, setIdOutlet] = useState()
    const [isFinish, setIsFinish] = useState(false)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
    const [modalText, setModalText] = useState()
    const [collapse, setCollapse] = useState(false);
    const [laporanActive, setlaporanActive] = useState("Ringkasan Penjualan")

    const handleClose = () => {
        props.setOpen(false);
    }
    const handleConfirm = () => {
        setModalConfirmOpen(true)
        setModalText(`<p>Yakin ingin keluar dari laporan detail ${namaOutlet}?</p>`)
    }
    useEffect(() => {
        var render = true
        if (isRefresh && kodeOutlet) {
            mLaporan.getOutlet(clientID, token, userLogin, kodeOutlet).then(resp => {
                setIdOutlet(resp.id)
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setIsRefresh(false)
                setIsFinish(true)
            })
        }
        return () => {
            render = false
        };
    }, [isRefresh])
    const toggle = () => setCollapse(!collapse);
    const selectMenu = (namamenu) => {
        setlaporanActive(namamenu)
        setCollapse(!collapse)
    }
    const listMenu = () => {
        return (
            <Nav variant="pills" className="flex-column">
                <Nav.Item>
                    <Nav.Link eventKey="Sales-Summary" onClick={selectMenu.bind(this, "Ringkasan Penjualan")}>Ringkasan Penjualan</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Barang" onClick={selectMenu.bind(this, "Barang")}>Barang</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="SubKategori" onClick={selectMenu.bind(this, "SubKategori")}>Sub Kategori</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Transaksi" onClick={selectMenu.bind(this, "Transaksi")}>Transaksi</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Payment" onClick={selectMenu.bind(this, "Payment")}>Payment</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="DiskonTaxService" onClick={selectMenu.bind(this, "Diskon & Tax & Service")}>Diskon & Tax & Service</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Voids" onClick={selectMenu.bind(this, "Voids")}>Voids</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Session" onClick={selectMenu.bind(this, "Session")}>Session</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="TypeTransaksi" onClick={selectMenu.bind(this, "TypeTransaksi")}>Tipe Transaksi</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Harian" onClick={selectMenu.bind(this, "Harian")}>Harian</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Salesman" onClick={selectMenu.bind(this, "Salesman")}>Salesman</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
            <Nav.Link eventKey="Kategori">Kategori</Nav.Link>
        </Nav.Item> */}
            </Nav>
        )
    }
    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleConfirm} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Laporan detail {namaOutlet}</Typography>
                    <Button color="inherit" onClick={handleConfirm}>
                        Close</Button>
                </Toolbar>
            </AppBar>
            <div className="contentManual DialogFont bodyDialog">
                {/* <Row className={classes.root}>
                </Row> */}
                <Tab.Container id="left-tabs-example" defaultActiveKey="Sales-Summary">
                    <Row className={classes.root}>
                        <Col md={2} className="menuLaporan">
                            {window.innerWidth < 767 ? (
                                <React.Fragment>
                                    <Navbar light>
                                        <NavbarBrand>Pilih Laporan</NavbarBrand>
                                        <NavbarToggler onClick={toggle} className="mr-2" />
                                        <Collapse isOpen={collapse} navbar>
                                            {listMenu()}
                                        </Collapse>
                                    </Navbar>
                                    <h5 className="namaLaporan">Laporan {laporanActive}</h5>
                                </React.Fragment>
                            ) : (listMenu())}
                        </Col>
                        <Col md={10}>
                            <Tab.Content>
                                {/* <Tab.Pane eventKey="Kategori">
                                    <Kategori kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} />
                                </Tab.Pane> */}
                                {isFinish && (
                                    <React.Fragment>
                                        <Tab.Pane eventKey="Sales-Summary">
                                            <SalesSummary kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Barang">
                                            <Barang kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Transaksi">
                                            <Transaksi kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Payment">
                                            <Payment kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="DiskonTaxService">
                                            <DiskonTaxService kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Voids">
                                            <Voids kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Session">
                                            <Session kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="TypeTransaksi">
                                            <TypeTransaksi kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Harian">
                                            <Harian kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Salesman">
                                            <Salesman kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="SubKategori">
                                            <SubKategori kodeOutlet={kodeOutlet} tanggalDari={props.tanggalDari} tanggalSampai={props.tanggalSampai} idOutlet={idOutlet}  />
                                        </Tab.Pane>
                                    </React.Fragment>
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
            {modalConfirmOpen && (
                <ModalConfirm open={modalConfirmOpen} setOpen={setModalConfirmOpen} text={modalText} oke={handleClose} />
            )}
        </Dialog>
    )
}
export default ModalLaporanDet;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));