import GQL from '../../../GQL';
const UserKasir = {
    loadData: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listToppingGroup(idOutlet:${idOutlet}){
                        kodegroup
                        namagroup
                        kodesubkategori
                        subkategori{
                            namasubkategori
                        }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listToppingGroup) return (resolve(resp.data.listToppingGroup));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadDetail: (idOutlet, clientID, token, kodegroup) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    toppingGroupDetail(idOutlet:${idOutlet},kodegroup:"${kodegroup}"){
                        kodegroup
                        namagroup
                        topping {
                            kodetopping
                            namatopping
                            hargasatuan
                            hargagojek
                            hargagrab
                            hargalain
                        }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.toppingGroupDetail) return (resolve(resp.data.toppingGroupDetail));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    saveToppingGroup: (idOutlet, clientID, token, kodegroup, namagroup, datatopping, subkategori) => {
        return new Promise((resolve, reject) => {
            if (datatopping.length > 0) {
                let input = '[';
                for (var i = 0, j = datatopping.length; i < j; i++) {
                    if (i > 0) input += `,`;
                    input += `{kodetopping:"${datatopping[i].kodetopping}",namatopping:"${datatopping[i].namatopping}",hargasatuan:${datatopping[i].hargasatuan},hargagojek:${datatopping[i].hargagojek},hargagrab:${datatopping[i].hargagrab},hargalain:${datatopping[i].hargalain}}`;
                };
                input += ']';
                const query = `
                                mutation{
                                    simpanToppingGroup(idOutlet:${idOutlet},kodegroup:"${kodegroup}",
                                    namagroup:"${namagroup}"
                                    input:${input}, kodesubkategori:"${subkategori}"
                                    )
                                }
                                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.simpanToppingGroup) return (resolve(resp.data.simpanToppingGroup));
                    return (resolve([]))
                }).catch(err => {
                    return (reject(err));
                })
            } else {
                return (reject(new Error("Tidak menemukan topping yang di pilih")))
            }

        })
    },
    deleteGroupTopping: (idOutlet, clientID, token, kodegroup) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                hapusToppingGroup(idOutlet:${idOutlet},kodegroup:"${kodegroup}")
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusToppingGroup) return (resolve(resp.data.hapusToppingGroup));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    deleteItemGroupTopping: (idOutlet, clientID, token, kodegroup, kodetopping) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                hapusItemToppingGroup(idOutlet:${idOutlet},kodegroup:"${kodegroup}", kodetopping:"${kodetopping}")
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusItemToppingGroup) return (resolve(resp.data.hapusItemToppingGroup));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadSubKat: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
            query{
                    listSubKategoriApp(idOutlet:${idOutlet}){
                        kodesubkategori
                        namasubkategori
                    }
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                const kategori = resp.data.listKategoriApp
                const subkategori = resp.data.listSubKategoriApp
                if (resp.data && resp.data.listSubKategoriApp) return (resolve(resp.data.listSubKategoriApp));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },

}

export default UserKasir;