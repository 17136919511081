/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useMemo } from "react";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch } from '@material-ui/core';
import { Select, FormControl, MenuItem, InputLabel, Checkbox, Tooltip, Grid, Toolbar, Typography, IconButton } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import PropTypes from "prop-types";
import useStyles from '../../css/useStylesTabel'
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import mControl from './Diskon.Controler'
import PageviewIcon from '@material-ui/icons/Pageview';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dropdown from 'react-bootstrap/Dropdown'
import numberWithCommas from '../../Helper/numberWithCommas'
import ModalDiskonKategori from './Modal.AddDiskonByKategoriAndSub';
import { Tabs, Tab } from '@material-ui/core';
import moment from 'moment';
import SwipeableViews from 'react-swipeable-views';
import mBarang from './../Barang/Barang.controller'
import mKategori from './../Kategori/Kategori.controller'
import mSubkategori from './../SubKategori/SubKategori.controller'
import clsx from 'clsx';
import { isArray } from "util";
import DeleteIcon from '@material-ui/icons/Delete';
import useToolbarStyles from '../../css/useToolbarStyles'
import DialogConfirm from '../../Dialog/DialogConfirm'
import DialogInfo from '../../Dialog/DialogInfo'

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: "kodebarang", label: "Kode Barang" },
    { id: "namabarang", label: "Nama Barang" },
    { id: "jumlahDiskon", label: "Jumlah Diskon" },
];

const headRowsBerjalan = [
    { id: "namabarang", label: "Nama Barang" },
    { id: "namakategori", label: "Kategori" },
    { id: "namasubkategori", label: "Sub Kategori" },
    { id: "tglmulai", label: "Tanggal Mulai" },
    { id: "tglselesai", label: "Tanggal Selesai" },
    { id: "harga1", label: "Normal" },
    { id: "harga2", label: "Gojek" },
    { id: "harga3", label: "Grab" },
    { id: "harga4", label: "Lainnya" },
    { id: "harga5", label: "Lainnya 2" },
];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell>Action</TableCell>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const EnhancedTableHeadBerjalan = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
        numSelected,
        rowCount,
        onSelectAllClick
    } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };



    return (
        <TableHead>
            <TableRow>
                <TableCell style={{ width: ' 10px' }}>
                    <span className="checkBoxTabel">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </span>
                </TableCell>
                {headRowsBerjalan.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeadBerjalan.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const RiwayatTransaksi = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = 0;
    const defaultrowsPerPage = 5;
    const { clientID, token } = useContext(AuthContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("namabarang");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [dense, setDense] = React.useState(true);
    const [isRefresh, setIsRefresh] = useState(true);
    const [listBarang, setListBarang] = useState([]);

    const [isModalDiskonKategori, setIsModalDiskonKategori] = useState(false);
    const [isModalDiskonSubKategori, setIsModalDiskonSubKategori] = useState(false);

    const [rows, setRows] = useState([]);

    const [kategoriSelected, setKategoriSelected] = React.useState("");
    const [subKategoriSelected, setSubKategoriSelected] = React.useState("");

    const [isRefreshBerjalanMendatang, setIsRefreshBerjalanMendatang] = useState(true);
    const [rowsBerjalanMendatang, setRowsBerjalanMendatang] = useState([])
    const [rowsBerjalanMendatangOri, setRowsBerjalanMendatangOri] = useState([])
    const [orderBerjalanMendatang, setOrderBerjalanMendatang] = React.useState("asc");
    const [orderByBerjalanMendatang, setOrderByBerjalanMendatang] = React.useState("namabarang");
    const [pageBerjalanMendatang, setPageBerjalanMendatang] = React.useState(defaultPage);
    const [rowsPerPageBerjalanMendatang, setRowsPerPageBerjalanMendatang] = React.useState(defaultrowsPerPage);
    const [denseBerjalanMendatang, setDenseBerjalanMendatang] = React.useState(true);
    const [selected, setSelected] = React.useState([]);


    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}`)
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    useEffect(() => {
        mControl.loadItemDiskon(kodeoutlet, clientID, token)
            .then(resp => {
                setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        return () => {

        }
    }, [isRefresh]);


    const handleRequestSortBerjalanMendatang = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrderBerjalanMendatang(isDesc ? "asc" : "desc");
        setOrderByBerjalanMendatang(property);
    }

    const handleChangePageBerjalanMendatang = (event, newPage) => {
        setPageBerjalanMendatang(newPage);
    }

    const handleChangeRowsPerPageBerjalanMendatang = (event) => {
        setRowsPerPageBerjalanMendatang(+event.target.value);
        setPageBerjalanMendatang(0);
    }

    const handleChangeDenseBerjalanMendatang = (event) => {
        setDenseBerjalanMendatang(event.target.checked);
    }
    const emptyRowsBerjalanMendatang = rowsPerPageBerjalanMendatang - Math.min(rowsPerPageBerjalanMendatang, rowsBerjalanMendatang.length - pageBerjalanMendatang * rowsPerPageBerjalanMendatang);
    useEffect(() => {
        mControl.loadDataBerjalan(kodeoutlet, clientID, token)
            .then(resp => {
                setRowsBerjalanMendatang(resp);
                setRowsBerjalanMendatangOri(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefreshBerjalanMendatang(false))
        return () => {

        }
    }, [isRefreshBerjalanMendatang]);

    useEffect(() => {
        var render = true;

        mBarang.loadData(kodeoutlet, clientID, token).then(resp => {
            if (render) {
                setListBarang(resp);
            }
        }).catch(err => {
            console.log("err", err);
        })
        return () => {
            render = false;
        };
    }, [kodeoutlet, clientID, token]);


    const renderForm = useMemo(() => {
        if (isModalDiskonKategori) {
            return (
                <ModalDiskonKategori open={isModalDiskonKategori} setOpen={setIsModalDiskonKategori} kodeoutlet={kodeoutlet} clientID={clientID} token={token} type="Kategori" listBarang={listBarang} setIsRefresh={setIsRefresh} setIsRefreshBerjalanMendatang={setIsRefreshBerjalanMendatang} />
            )
        } else if (isModalDiskonSubKategori) {
            return (
                <ModalDiskonKategori open={isModalDiskonSubKategori} setOpen={setIsModalDiskonSubKategori} kodeoutlet={kodeoutlet} clientID={clientID} token={token} type="Sub Kategori" listBarang={listBarang} setIsRefresh={setIsRefresh} setIsRefreshBerjalanMendatang={setIsRefreshBerjalanMendatang} />
            )
        } else return null
    }, [isModalDiskonKategori, isModalDiskonSubKategori]);

    const [value, setValue] = useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }
    function handleChangeIndex(index) {
        setValue(index);
    }

    const [listKategori, setListKategori] = useState([])
    const [listSubKategori, setListSubKategori] = useState([])

    useEffect(() => {
        var render = true;

        mKategori.loadData(kodeoutlet, clientID, token).then(resp => {
            if (render) {
                setListKategori(resp);
            }
        })

        mSubkategori.loadData(kodeoutlet, clientID, token).then(resp => {
            if (render) {
                setListSubKategori(resp);
            }
        })
        return () => {
            render = false;
        };
    }, [kodeoutlet, clientID, token])

    function handleFilter(value, selected) {
        if (selected === "Kategori") {
            let Data = rowsBerjalanMendatangOri.filter(el => el.kodekategori === value);
            setRowsBerjalanMendatang(Data);
        } else {
            let Data = rowsBerjalanMendatangOri.filter(el => el.kodesubkategori === value);
            setRowsBerjalanMendatang(Data);
        }
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rowsBerjalanMendatang.map(n => n.kodediskon);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }
    const handleClick = (event, kodediskon) => {
        const selectedIndex = selected.indexOf(kodediskon);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, kodediskon);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }


    const EnhancedTableToolbar = props => {
        const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

        const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)

        const [dialogtext, setDialogtext] = useState()

        const classes = useToolbarStyles();
        const { numSelected, itemSelect } = props;

        const konfirmasiDeleteSelecter = (data) => {
            setOpenConfirmDelete(true);
            setDialogtext(`<p>Yakin Anda Ingin Menghapus ${data.length} kategori ini?<br/>Data tidak dapat dikembalikan setelah berhasil dihapus<p/>`)
        }


        const handleDelete = (data) => {
            return selected?.forEach((el, i) => {
                mControl
                    .hapusDiskon(kodeoutlet, clientID, token, el)
                    .then(resp => {
                        if (resp) {
                            if (i + 1 === data.length) {
                                setOpenConfirmDelete(false);
                                setOpenConfirmInfo(true)
                                setDialogtext(`<p>${data.length} berhasil di hapus<p/>`)
                                setIsRefreshBerjalanMendatang(true);
                            }
                        } else {
                            if (i + 1 === data.length) {
                                setOpenConfirmInfo(true)
                                setDialogtext(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
                            }
                        }
                    }).catch(err => {
                        if (isArray(err) && err.length > 0) {
                            setOpenConfirmInfo(true)
                            setDialogtext(`<p>Gagal hapus ${err[0].message}<p/>`)
                        } else if (err === "error") {
                            setOpenConfirmInfo(true)
                            setDialogtext(`<p>${err.message}<p/>`)
                        } else {
                            console.log(err);
                        }
                    })
            })

        }

        return numSelected > 0 && (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                    <Typography color="inherit" variant="subtitle1">{numSelected} selected</Typography>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <Grid container spacing={1}>

                        <Grid item xs={6}>
                            <Tooltip title="Delete" flex onClick={konfirmasiDeleteSelecter.bind(this, itemSelect)}>
                                <IconButton aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
                <DialogConfirm open={openConfirmDelete} setOpen={setOpenConfirmDelete} text={dialogtext} data={selected} oke={handleDelete} />
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
            </Toolbar>
        )
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />List Item Diskon</h3>

                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <LoyaltyIcon id="dropdown-basic" /> Add Diskon
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item><Button title="Edit" variant="contained" className="btnAddManual" onClick={() => props.history.push(`/manajemenoutlet/${kodeoutlet}/barang/1/5`)} ><AddCircleOutlineIcon /> Per Item</Button></Dropdown.Item>
                            <Dropdown.Item><Button title="Edit" variant="contained" className="btnAddMultiple" onClick={() => setIsModalDiskonKategori(true)} ><AddCircleOutlineIcon /> Per Kategori</Button></Dropdown.Item>
                            <Dropdown.Item><Button title="Edit" variant="contained" className="btnImportExcel" onClick={() => setIsModalDiskonSubKategori(true)} ><AddCircleOutlineIcon /> Per Sub Kategori</Button></Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>


                    {value === 1 && (
                        <>

                            <FormControl fullWidth={false} style={{ float: 'right', minWidth: 150 }}>

                                <InputLabel>Sub Kategori</InputLabel>
                                <Select
                                    name="subKategoriSelected"
                                    id="subKategoriSelected"
                                    required
                                    value={subKategoriSelected}
                                    onChange={(ev) => { setSubKategoriSelected(ev.target.value); handleFilter(ev.target.value, "Sub Kategori") }}
                                    disabled={kategoriSelected}
                                >

                                    {listSubKategori.map(el => (
                                        <MenuItem key={el.kodesubkategori} value={el.kodesubkategori}>{el.namasubkategori}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                            <FormControl fullWidth={false} style={{ float: 'right', marginRight: 15, minWidth: 150 }}>

                                <InputLabel>Kategori</InputLabel>
                                <Select
                                    name="kategoriSelected"
                                    id="kategoriSelected"
                                    required
                                    value={kategoriSelected}
                                    onChange={(ev) => { setKategoriSelected(ev.target.value); handleFilter(ev.target.value, "Kategori") }}
                                    disabled={subKategoriSelected}
                                >

                                    {listKategori.map(el => (
                                        <MenuItem key={el.kodekategori} value={el.kodekategori}>{el.namakategori}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                        </>
                    )}
                </Col>
                <Col xs={12} style={{ marginTop: 20 }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Per Item" className="font12" />
                        <Tab label="Sedang Berjalan & Mendatang" className="font12" />
                    </Tabs>
                    <SwipeableViews
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <div value={value} index={0}>


                            <Paper className={classes.paper}>
                                <div className="btnTable">
                                </div>


                                <div className={classes.tableWrapper}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>

                                            {stableSort(rows, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row._id}
                                                            className={((row.statusid === 8 || row.statusid === 9) && ("bgRowCancel")) || ("")}
                                                        >
                                                            <TableCell>
                                                                <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={() => props.history.push(`/manajemenoutlet/${kodeoutlet}/diskon/${row.kodebarang}`)}><PageviewIcon /></Button>
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.kodebarang}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.namabarang}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.jumlahDiskon}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {/* {emptyRows === 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={7} className="text-center"> Tidak ada data </TableCell>
                                                </TableRow>
                                            )}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={7} />
                                                </TableRow>
                                            )} */}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page"
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </div>

                        <div value={value} index={1}>
                            <Paper className={classes.paper}>

                                <EnhancedTableToolbar numSelected={selected.length} itemSelect={selected} />
                                <div className={classes.tableWrapper}>

                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                    >
                                        <EnhancedTableHeadBerjalan
                                            classes={classes}
                                            order={orderBerjalanMendatang}
                                            numSelected={selected.length}
                                            orderBy={orderByBerjalanMendatang}
                                            onRequestSort={handleRequestSortBerjalanMendatang}
                                            onSelectAllClick={handleSelectAllClick}
                                            rowCount={rowsBerjalanMendatang.length}
                                        />
                                        <TableBody>

                                            {stableSort(rowsBerjalanMendatang, getSorting(orderBerjalanMendatang, orderByBerjalanMendatang))
                                                .slice(page * rowsPerPageBerjalanMendatang, pageBerjalanMendatang * rowsPerPageBerjalanMendatang + rowsPerPageBerjalanMendatang)
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.kodediskon);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row._id}
                                                            className={((row.statusid === 8 || row.statusid === 9) && ("bgRowCancel")) || ("")}
                                                        >
                                                            <TableCell>
                                                                <span className="checkBoxTabel">
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={isItemSelected}
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        onClick={event => handleClick(event, row.kodediskon)}
                                                                    />
                                                                </span>
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.namabarang}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.namakategori}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.namasubkategori}
                                                            </TableCell>

                                                            <TableCell>
                                                                {moment(row.tglmulai).format("DD-MM-YYYY HH-mm")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(row.tglselesai).format("DD-MM-YYYY HH-mm")}
                                                            </TableCell>

                                                            <TableCell >
                                                                {numberWithCommas(row.harga1)}
                                                            </TableCell>

                                                            <TableCell >
                                                                {numberWithCommas(row.harga2)}
                                                            </TableCell>

                                                            <TableCell >
                                                                {numberWithCommas(row.harga3)}
                                                            </TableCell>

                                                            <TableCell >
                                                                {numberWithCommas(row.harga4)}
                                                            </TableCell>

                                                            <TableCell >
                                                                {numberWithCommas(row.harga5)}
                                                            </TableCell>

                                                        </TableRow>
                                                    );
                                                })}
                                            {/* {emptyRowsBerjalanMendatang === 0 && (
                                                <TableRow style={{ height: 49 * emptyRowsBerjalanMendatang }}>
                                                    <TableCell colSpan={11} className="text-center"> Tidak ada data </TableCell>
                                                </TableRow>
                                            )}
                                            {emptyRowsBerjalanMendatang > 0 && (
                                                <TableRow style={{ height: 49 * emptyRowsBerjalanMendatang }}>
                                                    <TableCell colSpan={11} />
                                                </TableRow>
                                            )} */}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={rowsBerjalanMendatang.length}
                                    rowsPerPage={rowsPerPageBerjalanMendatang}
                                    page={pageBerjalanMendatang}
                                    backIconButtonProps={{
                                        "aria-label": "previous page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page"
                                    }}
                                    onChangePage={handleChangePageBerjalanMendatang}
                                    onChangeRowsPerPage={handleChangeRowsPerPageBerjalanMendatang}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={denseBerjalanMendatang} onChange={handleChangeDenseBerjalanMendatang} />}
                                label="Dense padding"
                            />
                        </div>

                    </SwipeableViews>

                </Col>
            </Row>

            {renderForm}



        </Container>
    );
}
export default RiwayatTransaksi;