/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import AuthContext from '../../context/auth-context'
import { Container, Row, Col } from 'react-bootstrap';
import { isBrowser, isMobile } from "react-device-detect";
import { Link } from 'react-router-dom'
import ClearCache from "react-clear-cache";


const Home = (props) => {
  const { userLogin } = useContext(AuthContext);
  return (
    <Container>
      <Row>
        <ClearCache>
          {({ isLatestVersion, emptyCacheStorage }) => (
            <div>
              {!isLatestVersion && (
                <p>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      emptyCacheStorage();
                    }}
                  >
                    Update version
                </a>
                </p>
              )}
            </div>
          )}
        </ClearCache>
        <p style={{ marginTop: '20px' }}>Welcome {userLogin}</p>
      </Row>
      {isMobile && (
        <Row style={{ marginTop: '45px' }}>
          <Col xs={6} lg>
            <Link to={`/pilih-outlet`}>
              <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/managemen_outlet-min.png`)} />
            </Link>
          </Col>
          <Col xs={6} lg>
            <Link to={`/laporan/menu`}>
              <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/laporan-min.png`)} />
            </Link>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Home;