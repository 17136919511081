import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';

const MenuLaporan = () => {
    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                <Container>
                    {/* <h3 className="text-center" style={{marginTop: '15px',color: '#ffbf61'}}>Laporan</h3> */}
                    <Row style={{marginTop: '45px'}}>
                        <Col xs={6} lg>
                            <Link to={ `/laporan/dashboard`}>
                                <img className="img-fluid img-margin"  alt="logo" width="auto" src={require(`../../../Icon/dashboard-min.png`)}/>
                            </Link>
                        </Col>
                        <Col xs={6} lg>
                            <Link to={`/laporan/grafik-penjualan`}>
                                <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../../Icon/grafikPenjualan-min.png`)}/>
                            </Link>
                        </Col>
                        <Col xs={6} lg>
                            <Link to={`/laporan/laporanV2`}>
                                <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../../Icon/laporan-min.png`)}/>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </main>
        </React.Fragment>
    )
}
export default MenuLaporan;