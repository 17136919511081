import React, { useState, useContext, useEffect } from 'react'
import AuthContext from '../../context/auth-context'
import mControl from './Paket.controller'
import { isArray } from 'util';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, InputBase, Checkbox, Toolbar, Typography, Tooltip, IconButton, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import mBarang from '../Barang/Barang.controller'
import PropTypes from "prop-types";
import useToolbarStyles from '../../css/useToolbarStyles'
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import SearchIcon from '@material-ui/icons/Search'
import useStyles from '../../css/useStylesTabel'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Modal = (props) => {
    const classes = useStyles();
    const kodeoutlet = props.kodeoutlet;
    const kodebarang = props.kodebarang
    const namabarang = props.namabarang
    const hargasatuan = props.hargasatuan || 0
    const hargagojek = props.hargagojek || 0
    const hargagrab = props.hargagrab || 0
    const hargalain = props.hargalain || 0
    const satuan = props.satuan || ""
    const isStock = props.isStock || false
    const { clientID, token } = useContext(AuthContext);
    const [isReload, setIsReload] = useState(true)
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("idcust");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([])
    const [search, setSearch] = useState("")
    const [noData, setNoData] = useState(false)
    const [itemAdd, setItemAdd] = useState([])
    const [isAddItem, setIsAddItem] = useState(false)
    const handleClose = () => {
        props.setOpen(false)
    }
    useEffect(() => {
        var render = true;
        mBarang.loadData(kodeoutlet, clientID, token).then(resp => {
            if (render) setRows(resp);
        }).catch(err => {
            console.log(err);
        }).finally(() => setIsReload(false))
        return () => {
        };
    }, [kodebarang, isReload])
    const headRows = [
        {
            id: "kodebarang", label: "Kode"
        },
        { id: "namabarang", label: "Barang" },
        { id: "kategori", label: "Kategori" },
        { id: "subkkategori", label: "Subk Kategori" },
    ];
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }
    const handleClick = (event, kodebarang) => {
        const selectedIndex = selected.indexOf(kodebarang);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, kodebarang);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const isSelected = name => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const searchHandler = (val) => {
        setSearch(val.target.value);
    }
    const formSubmitHandler = (event) => {
        setNoData(false);
        if (search) {
            mBarang.search(kodeoutlet, search, clientID, token).then(resp => {
                if (resp.length <= 0) {
                    setNoData(true);
                    setRows(resp);
                } else {
                    setNoData(false);
                    setRows(resp);
                }
            }).catch(err => {
                console.log(err);
            })
        } else {
            setIsReload(true);
        }
    }
    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
            numSelected,
            rowCount,
            onSelectAllClick
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: ' 10px' }}>
                        <span className="checkBoxTabel">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all' }}
                            />
                        </span>
                    </TableCell>
                    {headRows.map(row => (
                        <TableCell key={row.id}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired
    };
    const EnhancedTableToolbar = props => {
        const { numSelected, itemSelect } = props;
        const classes = useToolbarStyles();
        const addItem = () => {
            props.handleAddItem()
        }
        return numSelected > 0 && (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                    <Typography color="inherit" variant="subtitle1">{numSelected} selected</Typography>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <Grid container spacing={1}>
                        <Grid xs={12}>
                            <Button variant="contained" color="primary" onClick={addItem}><PlaylistAddIcon />Add</Button>
                        </Grid>
                    </Grid>
                </div>
            </Toolbar>
        )
    };
    const handleAddItem = () => {
        const newData = []
        selected.map(el => {
            newData.push({
                kodebarang: el.kodebarang,
                namabarang: el.namabarang,
                qty: 1
            })
        })
        setItemAdd(newData)
        setIsAddItem(true)
    }
    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            formSubmitHandler()
        }
    }
    const loadingRow = [0, 1, 2, 3, 4];
    const handleChangeQty = (ev) => {
        const kodebarang = ev.target.id
        const qty = ev.target.value
        const newDataUpdate = []
        itemAdd.map(el => {
            if (el.kodebarang === kodebarang) {
                newDataUpdate.push({
                    ...el,
                    qty: qty * 1
                })
            } else {
                newDataUpdate.push({
                    ...el
                })
            }
        })
        setItemAdd(newDataUpdate)
    }
    const handleSimpanItemDetail = () => {
        if (props.isNew) {
            mControl.simpanPaketV2(kodeoutlet, namabarang, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, clientID, token, kodebarang, itemAdd).then((resp) => {
                if (resp) {
                    alert("Paket berhasil di simpan")
                    props.history.push(`/manajemenoutlet/${kodeoutlet}/paket-detail/${kodebarang}/1/5`)
                } else {
                    alert('Data Gagal Di simpan')
                }
            }).catch(err => {
                if (isArray(err) && err.length > 0) {
                    alert(`Gagal Simpan : ${err[0].message}`)
                } else if (typeof err == 'error') {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        } else {
            mControl.simpanPaketDetail(kodeoutlet, clientID, token, kodebarang, itemAdd).then(resp => {
                if (resp) {
                    props.setIsLoading(true)
                    handleClose()
                } else {
                    alert("Gagal Update Data")
                }
            }).catch(err => {
                if (isArray(err) && err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err == 'error') {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Add Item
            </Typography>
                </Toolbar>
            </AppBar>

            <div className="contentManual DialogFont bodyDialog">
                <h5 className="text-center colorBlack">{namabarang}</h5>
                {!isAddItem ? (
                    <Row>
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <div className="btnTable">
                                    <Row>
                                        <Col>
                                            <div className={classes.search}>
                                                <div className={classes.searchIcon}>
                                                    <SearchIcon />
                                                </div>
                                                <InputBase onChange={searchHandler} onKeyPress={handleKeyPress}
                                                    placeholder="Search…"
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: classes.inputInput,
                                                    }}
                                                    inputProps={{ 'aria-label': 'search' }}
                                                />
                                                <Button style={{ float: 'right' }} onClick={formSubmitHandler} variant="contained" color="primary">Cari</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <EnhancedTableToolbar numSelected={selected.length} itemSelect={selected} handleAddItem={handleAddItem} />
                                <div className={classes.tableWrapper}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {noData && (
                                                <TableRow style={{ height: 15 * emptyRows }}>
                                                    <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                        <b>Tidak ada data</b>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {rows.length == 0 && !noData && loadingRow.map(el => (
                                                <TableRow
                                                    key={el.toString()}
                                                    style={{ height: 50 }}
                                                >
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" >
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {stableSort(rows, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.kodebarang}
                                                        >
                                                            <TableCell>
                                                                <span className="checkBoxTabel">
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={isItemSelected}
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        onClick={event => handleClick(event, row)}

                                                                    />
                                                                </span>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                {row.kodebarang}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.namabarang}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.kategori}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.subkategori}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={7} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page"
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </div>
                    </Row>
                ) : (
                        <Row>
                            <Table className={classes.table}
                                aria-labelledby="tableTitle"
                                size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Kode Barang</TableCell>
                                        <TableCell>Nama Barang</TableCell>
                                        <TableCell>QTY</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {itemAdd.map((data, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{data.kodebarang}</TableCell>
                                                <TableCell>{data.namabarang}</TableCell>
                                                <TableCell>
                                                    <input type="number"
                                                        value={data.qty}
                                                        onChange={handleChangeQty}
                                                        id={data.kodebarang}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            <Col>
                                <Button style={{ backgroundColor: ' #7f0000', marginTop: '5px', color: '#fff' }} variant="contained" fullWidth onClick={() => setIsAddItem(false)}>Cancel</Button>
                            </Col>
                            <Col>
                                <Button style={{ marginTop: '5px' }} variant="contained" color="primary" fullWidth onClick={handleSimpanItemDetail}>Simpan</Button>
                            </Col>
                        </Row>
                    )}
            </div>
        </Dialog>
    )
}

export default Modal;