/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Tabs, Tab } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import SwipeableViews from 'react-swipeable-views';
import KasTransaksi from './KasMasuk.transaksi'
import KasItem from './KasMasuk.item'






const RiwayatTransaksi = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const { clientID, token } = useContext(AuthContext)
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}`)
    }
    const [value, setValue] = useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }
    function handleChangeIndex(index) {
        setValue(index);
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Laporan Kas Masuk</h3>
                    <div >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Per Transaksi" className="font12" />
                            <Tab label="Per Item" className="font12" />
                        </Tabs>
                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <div value={value} index={0}>
                            <KasTransaksi kodeoutlet={kodeoutlet} token={token} clientID={clientID}/>
                         </div>
                            <div value={value} index={1}>
                            <KasItem kodeoutlet={kodeoutlet} token={token} clientID={clientID}/>
                            </div>
                        </SwipeableViews>

                    </div>
                </Col>
            </Row>

        </Container>
    );
}
export default RiwayatTransaksi;