import React, { useState, useEffect } from 'react'
import mControl from './Poktan.Controler'
import { Row, Col } from 'react-bootstrap';
import { Toolbar, Typography, DialogContent, DialogContentText, Button, TextField} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft } from 'react-icons/fa';
import Alert from '@material-ui/lab/Alert';
import validate from 'validate.js'
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const Modal = (props) => {
    const kodeoutlet = props.kodeoutlet
    const outlet = props.outlet
    const clientID = props.clientID
    const token = props.token
    const detail = props.dataDialog || null
    const id = detail.id || ""
    const [form, setForm] = useState({})
    const [isAlert, setIsAlert] = useState(false)
    const [textAlert, setTextAlert] = useState("")
    const [usernameValid, setUsernameValid] = useState(true)
    const [isLoad, setIsLoad] = useState(false)
    useEffect(() => {
        if (detail.id) {
            setForm({
                username: detail.username,
                fullname: detail.fullname,
                hp: detail.hp,
                alamat: detail.alamat,
            })
        } else {
            setForm({
                username: '',
                password: '',
                fullname: '',
                hp: '',
                alamat: ''
            })
        }
        return () => {

        }
    }, [detail])
    const handleClose = () => {
        props.setOpen(false)
    }
    useEffect(() => {
        if (form.username && form.password && form.username.length > 2 && form.password.length > 4) {
            setUsernameValid(true)
            setIsLoad(true)
            mControl.cekUserPoktan(clientID, token, form.username).then(resp => {
                if (resp) {
                    setIsAlert(true)
                    setTextAlert(`username ${form.username} sudah ada terdaftar di outlet lain`)
                    setUsernameValid(false)
                } else {
                    setUsernameValid(true)
                    setIsAlert(false)
                    setTextAlert("")
                }
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsLoad(false))
        }
        return () => {

        }
    }, [form.username, form.password])
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const validateForm = () => {
        const { username, fullname, hp, alamat } = form;
        const promKategori = new Promise((resolve, reject) => {
            const constraint = {
                username: {
                    length: {
                        minimum: 3,
                        message: "Username minimal terdiri dari 3 huruf"
                    },
                },
                fullname: {
                    length: {
                        minimum: 3,
                        message: "Nama Lengkap minimal terdiri dari 3 huruf"
                    },
                },
                hp: {
                    length: {
                        minimum: 8,
                        message: "Nomor HP minimal terdiri dari 8 digit"
                    },
                },
                alamat: {
                    length: {
                        minimum: 5,
                        message: "Harap mengisi alamat dengan benar"
                    },
                },
            }
            const validator = validate({ username, fullname, hp, alamat }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promKategori]);
    }
    const handleSimpan = () => {
        if (!usernameValid) {
            setIsAlert(true)
            setTextAlert(`Username tidak valid`)
            return
        }
        if (form.password <= 5) {
            setIsAlert(true)
            setTextAlert(`Password minimal 5 digit`)
            return
        }
        validateForm().then(() => {
            mControl.savePoktan(clientID, token, outlet.kodeoutlet, form.fullname, form.hp, form.alamat, form.username, form.password).then(resp => {
                if (resp) {
                    alert("Data Berhasil Disimpan")
                    handleClose()
                    props.setIsRefresh(true)
                } else return alert("Gagal simpan data")
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + ". ";
            }
            setIsAlert(true)
            setTextAlert(errorMessage)
        })

    }
    const handleUpdate = () => {
        validateForm().then(() => {
            mControl.updatePoktan(clientID, token, id, form.fullname, form.hp, form.alamat).then(resp => {
                if (resp) {
                    alert("Data Berhasil Diupdate")
                    handleClose()
                    props.setIsRefresh(true)
                } else return alert("Gagal simpan data")
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })

        }).catch(err => {
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + ". ";
            }
            setIsAlert(true)
            setTextAlert(errorMessage)
        })
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <FaChevronLeft color="inherit" onClick={handleClose} className="tangan" />
                    <Typography variant="h6">
                        {detail.id ? ("Update") : ("Add")} Member Messaging</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="DialogFont bodyDialog">
                <DialogContentText className="colorWhite">
                    <Row>
                        <Col xs={12} md={12}>
                            {isAlert && <Alert severity="error" onClose={() => setIsAlert(false)}>{textAlert}</Alert>}
                            <TextField
                                id="username"
                                name="username"
                                label="Username"
                                fullWidth
                                value={form.username}
                                onChange={handleChange}
                                disabled={detail.id ? true : false}
                            />
                        </Col>
                        {!detail.id && (
                            <Col xs={12} md={12}>
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Password"
                                    fullWidth
                                    value={form.password}
                                    onChange={handleChange}
                                    type="password"
                                />
                            </Col>
                        )}
                        <Col xs={12} md={12}>
                            <TextField
                                id="fullname"
                                name="fullname"
                                label="Nama Lengkap"
                                fullWidth
                                value={form.fullname}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={12}>
                            <TextField
                                id="hp"
                                name="hp"
                                label="Hp"
                                fullWidth
                                value={form.hp}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={12}>
                            <TextField
                                id="alamat"
                                name="alamat"
                                label="Alamat"
                                fullWidth
                                value={form.alamat}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 20 }}>
                        <Col xs={12} className="text-center">
                            {detail.id ? (
                                <Button variant="contained" disabled={isLoad} color="primary" fullWidth onClick={handleUpdate}>{isLoad ? <CircularProgress /> : "Update"} </Button>
                            ) : (
                                    <Button variant="contained" disabled={isLoad} color="primary" fullWidth onClick={handleSimpan}>{isLoad ? <CircularProgress /> : "Simpan"} </Button>
                                )}

                        </Col>
                    </Row>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;