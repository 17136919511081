import GQL from '../../../GQL';
const UserKasir = {
    loadUserKasir: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listUserKasir(idOutlet:${idOutlet}){
                      username
                      fullname
                      phone
                      email
                      userrole
                      hakakses{
                        kodeakses
                        namaakses
                    }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listUserKasir) return (resolve(resp.data.listUserKasir));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadHakAkses: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listHakAksesKasir(idOutlet:${idOutlet}){
                        kodeakses
                        namaakses
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listHakAksesKasir) return (resolve(resp.data.listHakAksesKasir));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    saveUserKasir: (idOutlet, clientID, token, username, namalengkap, hp, email, password, hakakses) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                simpanUserKasir(idOutlet:${idOutlet},
                  input:{
                    username:"${username}",
                    fullname:"${namalengkap}",
                    email:"${email}",
                    phone:"${hp}",
                    password:"${password}",
                    userrole:"${hakakses}"
                  }
                )
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanUserKasir) return (resolve(resp.data.simpanUserKasir));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    deleteUserKasir: (idOutlet, clientID, token, username) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                hapusUserKasir(idOutlet:${idOutlet},username:"${username}")
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusUserKasir) return (resolve(resp.data.hapusUserKasir));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    saveHakAksesKasir: (idOutlet, clientID, token, form) => {
        return new Promise((resolve, reject) => {
            const { kodeakses, namaakses, hakKategori, hakSubKategori, hakBarang, hakPayment, hakBuatTransaksi, hakVoidTransaksi, hakCreatePayment, hakLaporanKategori, hakLaporanSubKategori, hakLaporanBarang, hakLaporanTransaksi, hakLaporanPembayaran, hakLaporanTaxService, hakLaporanHarian, hakSettingTaxService, hakPrinterSetting, hakUserAccess, hakRegisterUser, hakListUser, hakViewHistory, hakReprintBill, hakResentEmail, hakImportData, hakVoidHistory, hakHapusUser, hakEditUser, hakDiskon, hakVoidAfterPreBill,hakVoidOrderan, hakPrintPreBill, hakSentEmail, hakCashDrawer, hakKasMasuk, hakKasKeluar, hakLaporanSesi, hakBukaLaci, cetakRekapProdukTotal , hakReprintKitchen, hakTutupSesi, hakLihatSemuaTransaksi} = form;
            const query = `
            mutation{
                simpanHakAksesKasir(idOutlet:${idOutlet},
                    input:{
                        kodeakses:"${kodeakses}",
                        namaakses:"${namaakses}",
                        hakKategori:${hakKategori},
                        hakSubKategori:${hakSubKategori},
                        hakBarang:${hakBarang},
                        hakPayment:${hakPayment},
                        hakBuatTransaksi:${hakBuatTransaksi},
                        hakVoidTransaksi:${hakVoidTransaksi},
                        hakCreatePayment:${hakCreatePayment},
                        hakLaporanKategori:${hakLaporanKategori},
                        hakLaporanSubKategori:${hakLaporanSubKategori},
                        hakLaporanBarang:${hakLaporanBarang},
                        hakLaporanTransaksi:${hakLaporanTransaksi},
                        hakLaporanPembayaran:${hakLaporanPembayaran},
                        hakLaporanTaxService:${hakLaporanTaxService},
                        hakLaporanHarian:${hakLaporanHarian},
                        hakSettingTaxService:${hakSettingTaxService},
                        hakPrinterSetting:${hakPrinterSetting},
                        hakUserAccess:${hakUserAccess},
                        hakRegisterUser:${hakRegisterUser},
                        hakListUser:${hakListUser},
                        hakViewHistory:${hakViewHistory},
                        hakReprintBill:${hakReprintBill},
                        hakResentEmail:${hakResentEmail},
                        hakImportData:${hakImportData},
                        hakVoidHistory:${hakVoidHistory},
                        hakHapusUser:${hakHapusUser},
                        hakEditUser:${hakEditUser},                 
                        hakDiskon:${hakDiskon},
                        hakVoidAfterPreBill:${hakVoidAfterPreBill},
                        hakVoidOrderan:${hakVoidOrderan},
                        hakPrintPreBill:${hakPrintPreBill},
                        hakSentEmail:${hakSentEmail},
                        hakCashDrawer:${hakCashDrawer},
                        hakKasMasuk:${hakKasMasuk},
                        hakKasKeluar:${hakKasKeluar},
                        hakLaporanSesi:${hakLaporanSesi},
                        hakBukaLaci: ${hakBukaLaci},
                        cetakRekapProdukTotal: ${cetakRekapProdukTotal},
                        hakReprintKitchen: ${hakReprintKitchen},
                        hakLihatSemuaTransaksi: ${hakLihatSemuaTransaksi},
                        hakTutupSesi: ${hakTutupSesi},
                      }
                )
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanHakAksesKasir) return (resolve(resp.data.simpanHakAksesKasir));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    deleteHakAksesKasir: (idOutlet, clientID, token, kodeakses) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                hapusHakAksesKasir(idOutlet:${idOutlet},kodeakses:"${kodeakses}")
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusHakAksesKasir) return (resolve(resp.data.hapusHakAksesKasir));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    detailHakAkses: (idOutlet, clientID, token, kodeakses) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    detailHakAksesKasir(idOutlet:${idOutlet}, kodeakses:"${kodeakses}"){
                        kodeakses
                        namaakses
                        hakKategori
                        hakSubKategori
                        hakBarang
                        hakPayment
                        hakBuatTransaksi
                        hakVoidTransaksi
                        hakCreatePayment
                        hakLaporanKategori
                        hakLaporanSubKategori
                        hakLaporanBarang
                        hakLaporanTransaksi
                        hakLaporanPembayaran
                        hakLaporanTaxService
                        hakLaporanHarian
                        hakSettingTaxService
                        hakPrinterSetting
                        hakUserAccess
                        hakRegisterUser
                        hakListUser
                        hakViewHistory
                        hakReprintBill
                        hakResentEmail
                        hakImportData
                        hakVoidHistory
                        hakHapusUser
                        hakEditUser 
                        hakDiskon
                        hakVoidAfterPreBill
                        hakVoidOrderan
                        hakPrintPreBill
                        hakSentEmail
                        hakCashDrawer
                        hakKasMasuk
                        hakKasKeluar
                        hakLaporanSesi
                        hakBukaLaci
                        cetakRekapProdukTotal
                        hakReprintKitchen
                        hakTutupSesi
                        hakLihatSemuaTransaksi
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.detailHakAksesKasir) return (resolve(resp.data.detailHakAksesKasir));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },

}

export default UserKasir;