/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import mControl from './Diskon.Controler'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalAddDiskon from './Modal.AddDiskon'
import { FaChevronLeft } from 'react-icons/fa';
import SettingsIcon from '@material-ui/icons/Settings';
import moment from 'moment'
import numberWithCommas from '../../Helper/numberWithCommas'
import BlockIcon from '@material-ui/icons/Block';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';


const tglBesok = moment().add(1, 'days')
const Diskon = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const kodebarang = props.match.params.kodebarang;
    const { clientID, token } = useContext(AuthContext);
    const [dataDialog, setdataDialog] = useState({})
    const [isModalPaketAdd, setIsModalPaketAdd] = useState(false)
    const [detail, setDetail] = useState({ namabarang: "tes" })
    const [isRefresh, setIsRefresh] = useState(true)
    const [listData, setListData] = useState([])

    useEffect(() => {
        mControl.loadData(kodeoutlet, clientID, token, kodebarang).then(resp => {
            setListData(resp);
        }).catch(err => {
            console.error(err);
        }).finally(() => setIsRefresh(false))
        return () => {

        }
    }, [clientID, isRefresh, kodebarang, kodeoutlet, token])
    useEffect(() => {
        mControl.loadDetailBarang(kodeoutlet, clientID, token, kodebarang).then(resp => {
            setDetail(resp);
        }).catch(err => {
            console.error(err);
        })
        return () => {

        }
    }, [clientID, kodebarang, kodeoutlet, token])
    const handleHapus = (data) => {
        const oke = window.confirm(`Yakin ingin hapus diskon ini?`)
        if (oke) {
            mControl.hapusDiskon(kodeoutlet, clientID, token, data.kodediskon).then(resp => {
                if (resp) {
                    alert("Berhasil di hapus")
                    setIsRefresh(true)
                } else {
                    alert("Gagal Di hapus")
                }
            }).catch(err => {
                if (isArray(err) && err.length > 0) {
                    alert(`Gagal hapus ${err[0].message}`)
                } else if (typeof err === 'error') {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }

    const handleEdit = (data) => {
        setdataDialog(data)
        setIsModalPaketAdd(true)
    }

    const handleAddItem = () => {
        setdataDialog({})
        setIsModalPaketAdd(true)
    }
    const handleActive = (data) => {
        let aktif = false
        if (data.isHide) aktif = data.isHide
        mControl.handleActive(kodeoutlet, data.kodediskon, clientID, token, !aktif).then(resp => {
            if (resp) {
                alert(`Diskon berhasil di ${aktif ? ("activated ") : ("deactivated")}`)
                setIsRefresh(true)
            } else {
                alert(`Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi`)
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                alert(`Gagal hapus ${err[0].message}`)
            } else if (typeof err == 'error') {
                alert(`${err.message}`)
            } else {
                console.log(err);
            }
        });
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={() => props.history.goBack()} className="btnBack" />Setting Diskon - {detail.namabarang}</h4>
                    <Button variant="contained" color="primary" onClick={handleAddItem} ><AddCircleOutlineIcon />Add Diskon</Button>
                </Col>
                <Col xs={12}>
                    <Table striped bordered hover responsive="lg">
                        <thead>
                            <tr className="colorWhite bgHeaderTabel tabelCenter">
                                <th>Kode Diskon</th>
                                <th>Tanggal Mulai</th>
                                <th>Tanggal Selesai</th>
                                <th>Normal</th>
                                <th>Gojek</th>
                                <th>Grab</th>
                                <th>Lainnya</th>
                                <th>Lainnya 2</th>
                                <th>Aktif</th>
                                <th><SettingsIcon /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center">{data.kodediskon}</td>
                                        <td className="text-center">{moment(data.tglmulai).format("DD-MM-YYYY HH-mm")}</td>
                                        <td className="text-center">{moment(data.tglselesai).format("DD-MM-YYYY HH-mm")}</td>
                                        <td className="text-right">{numberWithCommas(data.harga1)}</td>
                                        <td className="text-right">{numberWithCommas(data.harga2)}</td>
                                        <td className="text-right">{numberWithCommas(data.harga3)}</td>
                                        <td className="text-right">{numberWithCommas(data.harga4)}</td>
                                        <td className="text-right">{numberWithCommas(data.harga5)}</td>
                                        <td className="text-center">
                                            {data.isHide ? <CloseIcon /> : <CheckIcon />}
                                        </td>
                                        <td className="text-center">
                                            {(moment(data.tglmulai) >= tglBesok) && (
                                                <React.Fragment>
                                                    <EditIcon className="pointer" onClick={handleEdit.bind(this, data)} />
                                                    <DeleteIcon className="pointer" onClick={handleHapus.bind(this, data)} />
                                                </React.Fragment>
                                            )}
                                            {moment() < (moment(data.tglselesai)) && (
                                                <React.Fragment>
                                                    {data.isHide ? <VerifiedUserIcon className="pointer" onClick={handleActive.bind(this, data)}/> : <BlockIcon className="pointer" onClick={handleActive.bind(this, data)}/>}
                                                </React.Fragment>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {isModalPaketAdd && (
                <ModalAddDiskon open={isModalPaketAdd} setOpen={setIsModalPaketAdd} kodeoutlet={kodeoutlet} kodebarang={kodebarang} namabarang={detail.namabarang} clientID={clientID} token={token} dataDialog={dataDialog} setIsRefresh={setIsRefresh} />
            )}
        </Container>
    )
}

export default Diskon;