import GQL from '../../GQL';
const RiwayatTrans = {
    getOutlet: (idOutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    getOutlet(idOutlet:${idOutlet}){
                        namaoutlet
                        kodeoutlet
                        tipeOutlet
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.getOutlet) return(resolve(resp.data.getOutlet));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadData: (idOutlet,clientID,token, tglDari, tglSampai, cariNotrans) =>{
        return new Promise((resolve,reject)=>{
            var noTrans = `kodetrans: "${cariNotrans}"`
            if(!cariNotrans) noTrans = '';
            const query = `
                query {
                    listHistoryTransaksiApp(idOutlet:${idOutlet}, tglDari: "${tglDari}", tglSampai: "${tglSampai}", ${noTrans} ){
                        _id
                        noinvoice
                        tanggal
                        nomejaint
                        statusid
                        lastJamBayar
                        payment{
                            total
                        }
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listHistoryTransaksiApp) return(resolve(resp.data.listHistoryTransaksiApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDataSimple: (idOutlet,clientID,token, tglDari, tglSampai, cariNotrans) =>{
        return new Promise((resolve,reject)=>{
            var noTrans = `kodetrans: "${cariNotrans}"`
            if(!cariNotrans) noTrans = '';
            const query = `
                query {
                    listHistoryTransaksiApp(idOutlet:${idOutlet}, tglDari: "${tglDari}", tglSampai: "${tglSampai}", ${noTrans} ){
                        _id
                        noinvoice
                        tanggal
                        paymentMethod
                        statusid
                        lastJamBayar
                        total
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listHistoryTransaksiApp) return(resolve(resp.data.listHistoryTransaksiApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDetail: (idOutlet,clientID,token, kodetrans) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    detailHistoryTransaksiApp(idOutlet:${idOutlet}, kodeTrans: "${kodetrans}"){
                        _id
                        noinvoice
                        tanggal
                        nomeja
                        namacustomer
                        nomejaint
                        voidReason
                        noretur
                        sessionRetur
                        userRetur
                        jamretur
                        detail {
                            kodebarang
                            namabarang
                            qty
                            harga
                            jumlah
                            discountAmount
                            discountPercent
                        }
                        userin
                        userupt
                        jamin
                        jamupt
                        lastJamBayar
                        statusid
                        displayHarga
                        payment {
                            noinvoice
                            item {
                                kodebarang
                               namabarang
                               qty
                               harga
                               jumlah
                               discountAmount
                               discountPercent
                           }
                           payment{
                               kodepayment
                               namapayment
                               remark
                               nomorkartu
                               transRef
                               amount
                           }
                            discountAmount
                            discountPercent
                            serviceAmount
                            servicePercent
                            taxAmount
                            taxPercent
                            total
                            kembalian
                            totalPayment
                            userin
                            jamin
                            sessionId
                        }
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));
                if(resp.data && resp.data.detailHistoryTransaksiApp) return(resolve(resp.data.detailHistoryTransaksiApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDetailRetail: (idOutlet,clientID,token, kodetrans) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    detailHistoryTransaksiApp(idOutlet:${idOutlet}, kodeTrans: "${kodetrans}"){
                        _id
                        noinvoice
                        tanggal
                        paymentMethod
                        statusid
                        lastJamBayar
                        paymentMethod
                        subtotal
                        discount
                        diskonAmt
                        service
                        serviceAmt
                        tax
                        taxAmt
                        total
                        kembalian
                        totalBayar
                        userupt
                        detail {
                            kodebarang
                            namabarang
                            qty
                            harga
                            jumlah
                        }
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));
                if(resp.data && resp.data.detailHistoryTransaksiApp) return(resolve(resp.data.detailHistoryTransaksiApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    }
}

export default RiwayatTrans;