import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import PaymentForm from './PaymentForm'
import AuthContext from '../../context/auth-context'
import mPayment from './Payment.controller'
import { isArray } from 'util';
import validate from 'validate.js'
import DialogInfo from '../../Dialog/DialogInfo'

const PaymentDetail = (props) => {
    const defaultPage = parseInt(props.match.params.page, 10);
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const kodeoutlet = props.match.params.kodeoutlet;
    const kodepayment = props.match.params.kodepayment;
    const { clientID, token } = useContext(AuthContext)
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({
        kodepayment: '',
        namapayment: '',
        isCard: false,
        isAutoFill: false
    })

    useEffect(() => {
        var render = true;
        mPayment.loadDetail(kodeoutlet, kodepayment, clientID, token).then(resp => {
            if (render) setState(resp);
        })
        return () => {
            render = false;
        };
    }, [kodeoutlet, kodepayment, clientID, token])
    const validateForm = () => {
        const { namapayment } = state;
        const prompayment = new Promise((resolve, reject) => {
            const constraint = {
                namapayment: {
                    length: {
                        minimum: 3,
                        message: "Nama Payment minimal terdiri dari 3 huruf"
                    },
                }
            }
            const validator = validate({ namapayment }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([prompayment]);
    }
    const handleSimpan = () => {
        setIsLoading(true)
        validateForm().then(() => {
            mPayment.updatePayment(kodeoutlet, kodepayment, state.namapayment, state.isAutoFill, state.isCard, clientID, token).then(resp => {
                setIsLoading(false)
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${state.namapayment} berhasil di update<p/>`)
                    setDialogUrl(`/manajemenoutlet/${kodeoutlet}/payment/${defaultPage}/${defaultrowsPerPage}`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Menyimpan Datai<p/>`)
                }
            }).catch(err => {
                setIsLoading(false)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setIsLoading(false)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }

    const handleChange = (key, value) => {
        const newState = Object.assign({}, state);
        newState[key] = value;
        setState(newState);
    }

    return (
        <Container>
            <PaymentForm history={props.history} kodeoutlet={kodeoutlet} kodepayment={kodepayment} namapayment={state.namapayment} isCard={state.isCard} isAutoFill={state.isAutoFill} defaultPage={defaultPage} defaultrowsPerPage={defaultrowsPerPage} handleChange={handleChange} />
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12} className="text-center">
                    <Button variant="contained" color="primary" onClick={handleSimpan}  disabled={isLoading}>Update</Button>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    )
}

export default PaymentDetail;