import GQL from '../../GQL';
const Waiter = {
    loadData: (idOutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listWaiter(idOutlet:${idOutlet}){
                        kodewaiter
                        namawaiter
                        isHide
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listWaiter) return(resolve(resp.data.listWaiter));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    search: (idOutlet,search,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listWaiter(idOutlet:${idOutlet},search:"${search}"){
                        kodewaiter
                        namawaiter
                        isHide
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listWaiter) return(resolve(resp.data.listWaiter));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDetail: (idOutlet,kodewaiter,clientID,token)=> {
        return new Promise((resolve,reject)=> {
            const query =`
                query{
                    waiterDetail(idOutlet:${idOutlet},kodewaiter:"${kodewaiter}"){
                        kodewaiter
                        namawaiter
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.waiterDetail) return (resolve(resp.data.waiterDetail))
            }).catch(err => reject(err))
        })
    },
    simpanWaiter:(idOutlet, namawaiter, clientID,token)=>{
         return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanWaiter(idOutlet:${idOutlet},input:{namawaiter:"${namawaiter}"})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanWaiter){
                    return(resolve(resp.data.simpanWaiter));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    updateWaiter:(idOutlet,kodewaiter,namawaiter,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanWaiter(idOutlet:${idOutlet},input:{namawaiter:"${namawaiter}",kodewaiter:"${kodewaiter}"})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanWaiter != undefined){
                    return(resolve(resp.data.simpanWaiter));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    deleteWaiter:(idOutlet,kodewaiter,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    hapusWaiter(idOutlet:${idOutlet},kodewaiter:"${kodewaiter}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.hapusWaiter != undefined ){
                    return (resolve(resp.data.hapusWaiter));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    importWaiter:(idOutlet,listWaiter,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(listWaiter.length > 0){
                let input = '[';
                for(var i=0,j=listWaiter.length;i<j;i++){
                    if(i>0) input += `,`;
                    var _kodekategori = listWaiter[i].kodewaiter ? `,kodewaiter:"${listWaiter[i].kodewaiter}"` : '';
                    input += `{namawaiter:"${listWaiter[i].namawaiter}"${_kodekategori}}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiWaiter(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.simpanMultiWaiter != undefined ){
                        return (resolve(resp.data.simpanMultiWaiter));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    deleteMultiWaiter:(idOutlet,kodewaiter,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(kodewaiter.length > 0){
               // console.log(kodewaiter)
                let input = '[';
                for(var i=0,j=kodewaiter.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodewaiter[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        hapusMultiWaiter(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.hapusMultiWaiter != undefined ){
                        return (resolve(resp.data.hapusMultiWaiter));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    getData: (idOutlet,clientID,token, kodewaiter) =>{
        return new Promise((resolve,reject)=>{
            let input = '[';
                for(var i=0,j=kodewaiter.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodewaiter[i]}"`;
                };
                input += ']';
            const query = `
                query {
                    getMultiWaiter(idOutlet:${idOutlet}, kodewaiter: ${input}){
                        kodewaiter
                        namawaiter
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.getMultiWaiter) return(resolve(resp.data.getMultiWaiter));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    handleActive:(idOutlet,kodewaiter,clientID,token, hide)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    handleActiveWaiter(idOutlet:${idOutlet},kodewaiter:"${kodewaiter}",hide:${hide})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.handleActiveWaiter != undefined ){
                    return (resolve(resp.data.handleActiveWaiter));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default Waiter;