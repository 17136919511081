/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/auth-context'
import { Row, Container, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa'
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { ButtonGroup, Button } from '@material-ui/core';
import Table from 'react-bootstrap/Table'
import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import moment from 'moment'
import Moment from 'react-moment';
import 'moment-timezone';
import mLaporan from './Laporan.controler'
import CurrencyFormat from 'react-currency-format';
import ModalLaporanDet from './ModalLaporanDet'
import DescriptionIcon from '@material-ui/icons/Description';
const dateNow = moment();
const Laporan = (props) => {
    return window.location=process.env.REACT_APP_REPORT
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [semuaBranch, setSemuaBranch] = useState(true)
    const [detBranch, setDetBranch] = useState(false)
    const [kotaBranch, setKotaBranch] = useState(false)
    const [lapHarian, setLapHarian] = useState(true)
    const [lapBulanan, setLapBulanan] = useState(false)
    const [lapTahunan, setLapTahunan] = useState(false)
    const [tglKmrin2, setTglKmrin2] = useState(moment(dateNow).add(-2, 'days').format("D MMM YYYY"))
    const [tglKmrin1, setTglKmrin1] = useState(moment(dateNow).add(-1, 'days').format("D MMM YYYY"))
    const [tglSkrg, setTglSkrg] = useState(moment(dateNow).format("D MMM YYYY"))
    const [isRefresh, setIsRefresh] = useState(true)
    const [listKota, setListKota] = useState([])
    const [listOutlet, setListOutlet] = useState([])
    const [listLapSemua, setListLapSemua] = useState([])
    const [hitung1, setHitung1] = useState(0)
    const [hitung2, setHitung2] = useState(0)
    const [hitung3, setHitung3] = useState(0)
    const [kota, setKota] = useState()
    const [kodeoutlet, setKodeoutlet] = useState()
    const [namaOutlet, setNamaOutlet] = useState()
    const [tglPilih, setTglPilih] = useState(tglSkrg)
    const [modalDetail, setModalDetail] = useState(false)
    const [modalKodeOutlet, setModalKodeOutlet] = useState()
    const [modalNamaOutlet, setModalNamaOutlet] = useState()
    const [modalTglDari, setModalTglDari] = useState()
    const [modalTglSampai, setModalTglSampai] = useState()
    const goBack = () => {
        props.history.push(`/laporan/menu`)
    }
    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.loadKota(clientID, token, userLogin).then(resp => {
                setListKota(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
            mLaporan.loadOutlet(clientID, token, userLogin).then(resp => {
                setListOutlet(resp)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
            lapHarianSemua(tglSkrg)
            hitungHarianSemua(tglSkrg)
        }
        return () => {
            render = false
        };
    }, [clientID, token, isRefresh])

    // Laporan Semua kota
    const lapHarianSemua = (tanggal) => {
        const tglLaporan = moment(tanggal).format("YYMMDD");
        setListLapSemua([])
        mLaporan.lapSemuaKota(clientID, token, userLogin, tglLaporan).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }

    const lapBulanSemua = (tanggal) => {
        setListLapSemua([])
        const periode = moment(tanggal).tz("Asia/Jakarta").set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapSemuaKota(clientID, token, userLogin, periode).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }
    const lapTahunSemua = (tanggal) => {
        setListLapSemua([])
        const periode = moment(tanggal).tz("Asia/Jakarta").set({ months: 0, date: 1, hour: 0, minute: 0, second: 0 }).format("YY");
        mLaporan.lapSemuaKota(clientID, token, userLogin, periode).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }
    const hitungHarianSemua = (tanggal) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const tglLaporan1 = moment(tanggal).add(-2, 'days').format("YYMMDD");
        const tglLaporan2 = moment(tanggal).add(-1, 'days').format("YYMMDD");
        const tglLaporan3 = moment(tanggal).format("YYMMDD");
        mLaporan.lapSemuaKota(clientID, token, userLogin, tglLaporan1).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })
        mLaporan.lapSemuaKota(clientID, token, userLogin, tglLaporan2).then(resp => {
            var total2 = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total2 += resp[i].jumlah
            }
            setHitung2(total2)
        }).catch(err => {
            console.error(err);
        })
        mLaporan.lapSemuaKota(clientID, token, userLogin, tglLaporan3).then(resp => {
            var total3 = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total3 += resp[i].jumlah
            }
            setHitung3(total3)
        }).catch(err => {
            console.error(err);
        })
    }

    const hitungBulananSemua = (tanggal) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const periode1 = moment(tanggal).tz("Asia/Jakarta").add(-2, 'months').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapSemuaKota(clientID, token, userLogin, periode1).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })

        const periode2 = moment(tanggal).tz("Asia/Jakarta").add(-1, 'months').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");

        mLaporan.lapSemuaKota(clientID, token, userLogin, periode2).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung2(total)
        }).catch(err => {
            console.error(err);
        })

        const periode3 = moment(tanggal).tz("Asia/Jakarta").set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapSemuaKota(clientID, token, userLogin, periode3).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung3(total)
        }).catch(err => {
            console.error(err);
        })

    }
    const hitungTahunSemua = (tanggal) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const periode1 = moment(tanggal).tz("Asia/Jakarta").add(-2, 'year').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");
        mLaporan.lapSemuaKota(clientID, token, userLogin, periode1).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })

        const periode2 = moment(tanggal).tz("Asia/Jakarta").add(-1, 'year').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");

        mLaporan.lapSemuaKota(clientID, token, userLogin, periode2).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung2(total)
        }).catch(err => {
            console.error(err);
        })

        const periode3 = moment(tanggal).tz("Asia/Jakarta").set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");
        mLaporan.lapSemuaKota(clientID, token, userLogin, periode3).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung3(total)
        }).catch(err => {
            console.error(err);
        })

    }
    // Laporan Filter kota
    const lapHarianKota = (tanggal, kota) => {
        const tglLaporan = moment(tanggal).format("YYMMDD");
        setListLapSemua([])
        mLaporan.lapFilterKota(clientID, token, userLogin, tglLaporan, kota).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }
    const lapBulananKota = (tanggal, kota) => {
        const tglLaporan = moment(tanggal).format("YYMM");
        setListLapSemua([])
        mLaporan.lapFilterKota(clientID, token, userLogin, tglLaporan, kota).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }
    const lapTahunanKota = (tanggal, kota) => {
        const tglLaporan = moment(tanggal).format("YY");
        setListLapSemua([])
        mLaporan.lapFilterKota(clientID, token, userLogin, tglLaporan, kota).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }
    const hitungHarianKota = (tanggal, kota) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const periode1 = moment(tanggal).add(-2, 'days').format("YYMMDD");
        const periode2 = moment(tanggal).add(-1, 'days').format("YYMMDD");
        const periode3 = moment(tanggal).format("YYMMDD");
        mLaporan.lapFilterKota(clientID, token, userLogin, periode1, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })

        mLaporan.lapFilterKota(clientID, token, userLogin, periode2, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung2(total)
        }).catch(err => {
            console.error(err);
        })
        mLaporan.lapFilterKota(clientID, token, userLogin, periode3, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung3(total)
        }).catch(err => {
            console.error(err);
        })
    }
    const hitungBulananKota = (tanggal, kota) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const periode1 = moment(tanggal).tz("Asia/Jakarta").add(-2, 'months').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapFilterKota(clientID, token, userLogin, periode1, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })

        const periode2 = moment(tanggal).tz("Asia/Jakarta").add(-1, 'months').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapFilterKota(clientID, token, userLogin, periode2, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung2(total)
        }).catch(err => {
            console.error(err);
        })

        const periode3 = moment(tanggal).tz("Asia/Jakarta").set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapFilterKota(clientID, token, userLogin, periode3, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung3(total)
        }).catch(err => {
            console.error(err);
        })
    }
    const hitungTahunanKota = (tanggal, kota) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const periode1 = moment(tanggal).tz("Asia/Jakarta").add(-2, 'year').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");
        mLaporan.lapFilterKota(clientID, token, userLogin, periode1, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })

        const periode2 = moment(tanggal).tz("Asia/Jakarta").add(-1, 'year').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");

        mLaporan.lapFilterKota(clientID, token, userLogin, periode2, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung2(total)
        }).catch(err => {
            console.error(err);
        })

        const periode3 = moment(tanggal).tz("Asia/Jakarta").set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");
        mLaporan.lapFilterKota(clientID, token, userLogin, periode3, kota).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung3(total)
        }).catch(err => {
            console.error(err);
        })

    }
    // Laporan Filter outlet
    const lapHarianOutlet = (tanggal, kodeoutlet) => {
        const tglLaporan = moment(tanggal).format("YYMMDD");
        setListLapSemua([])
        mLaporan.lapFilterOutlet(clientID, token, userLogin, tglLaporan, kodeoutlet).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }
    const lapBulananOutlet = (tanggal, kodeoutlet) => {
        const tglLaporan = moment(tanggal).format("YYMM");
        setListLapSemua([])
        mLaporan.lapFilterOutlet(clientID, token, userLogin, tglLaporan, kodeoutlet).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }
    const lapTahunanOutlet = (tanggal, kodeoutlet) => {
        const tglLaporan = moment(tanggal).format("YY");
        setListLapSemua([])
        mLaporan.lapFilterOutlet(clientID, token, userLogin, tglLaporan, kodeoutlet).then(resp => {
            setListLapSemua(resp)
        }).catch(err => {
            console.error(err);
        })
    }
    const hitungHarianOutlet = (tanggal, kodeoutlet) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const periode1 = moment(tanggal).add(-2, 'days').format("YYMMDD");
        const periode2 = moment(tanggal).add(-1, 'days').format("YYMMDD");
        const periode3 = moment(tanggal).format("YYMMDD");
        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode1, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })

        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode2, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung2(total)
        }).catch(err => {
            console.error(err);
        })
        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode3, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung3(total)
        }).catch(err => {
            console.error(err);
        })
    }
    const hitungBulananOutlet = (tanggal, kodeoutlet) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const periode1 = moment(tanggal).tz("Asia/Jakarta").add(-2, 'months').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode1, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })

        const periode2 = moment(tanggal).tz("Asia/Jakarta").add(-1, 'months').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode2, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung2(total)
        }).catch(err => {
            console.error(err);
        })

        const periode3 = moment(tanggal).tz("Asia/Jakarta").set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YYMM");
        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode3, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung3(total)
        }).catch(err => {
            console.error(err);
        })
    }
    const hitungTahunanOutlet = (tanggal, kodeoutlet) => {
        setHitung1(0)
        setHitung2(0)
        setHitung3(0)
        const periode1 = moment(tanggal).tz("Asia/Jakarta").add(-2, 'year').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");
        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode1, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung1(total)
        }).catch(err => {
            console.error(err);
        })

        const periode2 = moment(tanggal).tz("Asia/Jakarta").add(-1, 'year').set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");

        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode2, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung2(total)
        }).catch(err => {
            console.error(err);
        })

        const periode3 = moment(tanggal).tz("Asia/Jakarta").set({ date: 1, hour: 0, minute: 0, second: 0 }).format("YY");
        mLaporan.lapFilterOutlet(clientID, token, userLogin, periode3, kodeoutlet).then(resp => {
            var total = 0;
            for (var i = 0, j = resp.length; i < j; i++) {
                total += resp[i].jumlah
            }
            setHitung3(total)
        }).catch(err => {
            console.error(err);
        })

    }
    const BukaLaporan = (jenisRpt, data, namaOutlet) => {
        if (jenisRpt == "Semua") {
            setSemuaBranch(true)
            setDetBranch(false)
            setKotaBranch(false)
            if (lapHarian) {
                lapHarianSemua(tglPilih)
                hitungHarianSemua(tglSkrg)
            } else if (lapBulanan) {
                lapBulanSemua(tglPilih)
                hitungBulananSemua(tglSkrg)
            } else if (lapTahunan) {
                lapTahunSemua(tglPilih)
                hitungTahunSemua(tglSkrg)
            }
        } else if (jenisRpt == "Kota") {
            setKota(data);
            setKotaBranch(true)
            setSemuaBranch(false)
            setDetBranch(false)
            if (lapHarian) {
                lapHarianKota(tglPilih, data)
                hitungHarianKota(tglSkrg, data)
            }
            else if (lapBulanan) {
                lapBulananKota(tglPilih, data)
                hitungBulananKota(tglSkrg, data)
            } else if (lapTahunan) {
                lapTahunanKota(tglPilih, data)
                hitungTahunanKota(tglSkrg, data)
            }
        } else if (jenisRpt == "Detail") {
            setSemuaBranch(false)
            setDetBranch(true)
            setKotaBranch(false)
            setKodeoutlet(data)
            setNamaOutlet(namaOutlet)
            if (lapHarian) {
                lapHarianOutlet(tglPilih, data)
                hitungHarianOutlet(tglSkrg, data)
            }
            else if (lapBulanan) {
                lapBulananOutlet(tglPilih, data)
                hitungBulananOutlet(tglSkrg, data)
            } else if (lapTahunan) {
                lapTahunanOutlet(tglPilih, data)
                hitungTahunanOutlet(tglSkrg, data)
            }
        }
    }
    const LaporanHarian = () => {
        if (!lapHarian) {
            setTglKmrin2(moment(tglSkrg).add(-2, 'days').format("D MMM YYYY"))
            setTglKmrin1(moment(tglSkrg).add(-1, 'days').format("D MMM YYYY"))
            setTglSkrg(moment(tglSkrg).format("D MMM YYYY"))
        }
        setLapHarian(true)
        setLapBulanan(false)
        setLapTahunan(false)
        if (semuaBranch) {
            lapHarianSemua(tglSkrg)
            hitungHarianSemua(tglSkrg)
        } else if (kotaBranch) {
            lapHarianKota(tglSkrg, kota)
            hitungHarianKota(tglSkrg, kota)
        } else if (detBranch) {
            lapHarianOutlet(tglSkrg, kodeoutlet)
            hitungHarianOutlet(tglSkrg, kodeoutlet)
        }
    }
    const LaporanBulanan = () => {
        if (!lapBulanan) {
            setTglKmrin2(moment(tglSkrg).add(-2, 'months').format("D MMM YYYY"))
            setTglKmrin1(moment(tglSkrg).add(-1, 'months').format("D MMM YYYY"))
            setTglSkrg(moment(tglSkrg).format("D MMM YYYY"))
        }
        setLapHarian(false)
        setLapBulanan(true)
        setLapTahunan(false)
        if (semuaBranch) {
            lapBulanSemua(tglSkrg)
            hitungBulananSemua(tglSkrg)
        } else if (kotaBranch) {
            lapBulananKota(tglSkrg, kota)
            hitungBulananKota(tglSkrg, kota)
        } else if (detBranch) {
            lapBulananOutlet(tglSkrg, kodeoutlet)
            hitungBulananOutlet(tglSkrg, kodeoutlet)
        }
    }
    const LaporanTahunan = () => {
        if (!lapTahunan) {
            setTglKmrin2(moment(tglSkrg).add(-2, 'year').format("D MMM YYYY"))
            setTglKmrin1(moment(tglSkrg).add(-1, 'year').format("D MMM YYYY"))
            setTglSkrg(moment(tglSkrg).format("D MMM YYYY"))
        }
        setLapHarian(false)
        setLapBulanan(false)
        setLapTahunan(true)
        if (semuaBranch) {
            lapTahunSemua(tglSkrg)
            hitungTahunSemua(tglSkrg)
        } else if (kotaBranch) {
            lapTahunanKota(tglSkrg, kota)
            hitungTahunanKota(tglSkrg, kota)
        } else if (detBranch) {
            lapTahunanOutlet(tglSkrg, kodeoutlet)
            hitungTahunanOutlet(tglSkrg, kodeoutlet)
        }
    }
    const slidePrev = () => {
        setListLapSemua([])
        if (lapHarian) {
            setTglKmrin2(moment(tglKmrin2).add(-3, 'days').format("D MMM YYYY"))
            setTglKmrin1(moment(tglKmrin2).add(-2, 'days').format("D MMM YYYY"))
            setTglSkrg(moment(tglKmrin2).add(-1, 'days').format("D MMM YYYY"))
            if (semuaBranch) {
                hitungHarianSemua(moment(tglSkrg).add(-3, 'days').format("D MMM YYYY"))
            } else if (kotaBranch) {
                hitungHarianKota(moment(tglSkrg).add(-3, 'days').format("D MMM YYYY"), kota)
            } else if (detBranch) {
                hitungHarianOutlet(moment(tglSkrg).add(-3, 'days').format("D MMM YYYY"), kodeoutlet)
            }
        } else if (lapBulanan) {
            setTglKmrin2(moment(tglKmrin2).add(-3, 'months').format("D MMM YYYY"))
            setTglKmrin1(moment(tglKmrin2).add(-2, 'months').format("D MMM YYYY"))
            setTglSkrg(moment(tglKmrin2).add(-1, 'months').format("D MMM YYYY"))
            if (semuaBranch) {
                hitungBulananSemua(moment(tglSkrg).add(-3, 'months').format("D MMM YYYY"))
            } else if (kotaBranch) {
                hitungBulananKota(moment(tglSkrg).add(-3, 'months').format("D MMM YYYY"), kota)
            } else if (detBranch) {
                hitungBulananOutlet(moment(tglSkrg).add(-3, 'months').format("D MMM YYYY"), kodeoutlet)
            }
        } else if (lapTahunan) {
            setTglKmrin2(moment(tglKmrin2).add(-3, 'year').format("D MMM YYYY"))
            setTglKmrin1(moment(tglKmrin2).add(-2, 'year').format("D MMM YYYY"))
            setTglSkrg(moment(tglKmrin2).add(-1, 'year').format("D MMM YYYY"))
            if (semuaBranch) {
                hitungTahunSemua(moment(tglSkrg).add(-3, 'year').format("D MMM YYYY"))
            } else if (kotaBranch) {
                hitungTahunanKota(moment(tglSkrg).add(-3, 'year').format("D MMM YYYY"), kota)
            } else if (detBranch) {
                hitungTahunanOutlet(moment(tglSkrg).add(-3, 'year').format("D MMM YYYY"), kodeoutlet)
            }
        }
    }
    const slideNext = () => {
        setListLapSemua([])
        if (lapHarian) {
            setTglKmrin2(moment(tglSkrg).add(1, 'days').format("D MMM YYYY"))
            setTglKmrin1(moment(tglSkrg).add(2, 'days').format("D MMM YYYY"))
            setTglSkrg(moment(tglSkrg).add(3, 'days').format("D MMM YYYY"))
            if (semuaBranch) {
                hitungHarianSemua(moment(tglSkrg).add(3, 'days').format("D MMM YYYY"))
            } else if (kotaBranch) {
                hitungHarianKota(moment(tglSkrg).add(3, 'days').format("D MMM YYYY"), kota)
            } else if (detBranch) {
                hitungHarianOutlet(moment(tglSkrg).add(3, 'days').format("D MMM YYYY"), kodeoutlet)
            }
        } else if (lapBulanan) {
            setTglKmrin2(moment(tglSkrg).add(1, 'months').format("D MMM YYYY"))
            setTglKmrin1(moment(tglSkrg).add(2, 'months').format("D MMM YYYY"))
            setTglSkrg(moment(tglSkrg).add(3, 'months').format("D MMM YYYY"))
            if (semuaBranch) {
                hitungBulananSemua(moment(tglSkrg).add(3, 'months').format("D MMM YYYY"))
            } else if (kotaBranch) {
                hitungBulananKota(moment(tglSkrg).add(3, 'months').format("D MMM YYYY"), kota)
            } else if (detBranch) {
                hitungBulananOutlet(moment(tglSkrg).add(3, 'months').format("D MMM YYYY"), kodeoutlet)
            }
        } else if (lapTahunan) {
            setTglKmrin2(moment(tglSkrg).add(1, 'year').format("D MMM YYYY"))
            setTglKmrin1(moment(tglSkrg).add(2, 'year').format("D MMM YYYY"))
            setTglSkrg(moment(tglSkrg).add(3, 'year').format("D MMM YYYY"))
            if (semuaBranch) {
                hitungTahunSemua(moment(tglSkrg).add(3, 'year').format("D MMM YYYY"))
            } else if (kotaBranch) {
                hitungTahunanKota(moment(tglSkrg).add(3, 'year').format("D MMM YYYY"), kota)
            } else if (detBranch) {
                hitungTahunanOutlet(moment(tglSkrg).add(3, 'year').format("D MMM YYYY"), kodeoutlet)
            }
        }
    }
    const pilihTanggalLap = (tanggal) => {
        setTglPilih(tanggal)
        if (semuaBranch) {
            if (lapHarian) {
                lapHarianSemua(tanggal)
            } else if (lapBulanan) {
                lapBulanSemua(tanggal)
            } else if (lapTahunan) {
                lapTahunSemua(tanggal)
            }
        } else if (kotaBranch) {
            if (lapHarian) {
                lapHarianKota(tanggal, kota)
            } else if (lapBulanan) {
                lapBulananKota(tanggal, kota)
            } else if (lapTahunan) {
                lapTahunanKota(tanggal, kota)
            }
        } else if (detBranch) {
            if (lapHarian) {
                lapHarianOutlet(tanggal, kodeoutlet)
            } else if (lapBulanan) {
                lapBulananOutlet(tanggal, kodeoutlet)
            } else if (lapTahunan) {
                lapTahunanOutlet(tanggal, kodeoutlet)
            }
        }

    }
    const openDetail = (data) => {
        setModalKodeOutlet(data.outlet.kodeoutlet)
        setModalNamaOutlet(data.outlet.namaoutlet)
        setModalDetail(true)
        if (lapHarian) {
            setModalTglDari(tglPilih)
            const dateAkhir = moment(tglPilih).tz("Asia/Jakarta").set({
                hour: 23,
                minute: 59,
                second: 59
            });
            setModalTglSampai(dateAkhir)  
        } else if (lapBulanan) {
            const dateSkrg = moment(tglPilih).tz("Asia/Jakarta").set({
                date: 1,
                hour: 0,
                minute: 0,
                second: 0
            });
            const bulanDepan = moment(tglPilih).add(1, 'months');
            const dateAkhir = moment(bulanDepan).tz("Asia/Jakarta").set({
                date: 1,
                hour: 23,
                minute: 59,
                second: 59
            }).subtract(1, 'days');
            setModalTglDari(dateSkrg)
            setModalTglSampai(dateAkhir)  
            if (dateNow < dateAkhir) {
                setModalTglSampai(dateNow)
            }  
        } else if (lapTahunan) {
            const dateSkrg = moment(tglPilih).tz("Asia/Jakarta").set({
                date: 1,
                months: 0,
                hour: 0,
                minute: 0,
                second: 0
            });
            const bulanDepan = moment(tglPilih).add(1, 'year');
            const dateAkhir = moment(bulanDepan).tz("Asia/Jakarta").set({
                months: 0,
                date: 1,
                hour: 23,
                minute: 59,
                second: 59
            }).subtract(1, 'days');
            setModalTglDari(dateSkrg)
            setModalTglSampai(dateAkhir)
            if (dateNow < dateAkhir) {
                setModalTglSampai(dateNow)
            }
        }
    }
    return (
        <React.Fragment>
            <main>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Laporan
                            <span onClick={() => props.history.push(`/laporan/laporanV2`)} className="backOldReport"> (Lihat Laporan Baru)</span></h3>
                        </Col>
                        <Col xs={12} md={3} lg={4}>
                            <div className="box">
                                <div className="judul-kotak bg-laporan">
                                    <h6>Daftar Outlet</h6>
                                </div>
                                <div className="box-content bg-laporan" >
                                    <div className={`MuiTreeItem-content ${semuaBranch && ('activeList')}`} onClick={BukaLaporan.bind(this, 'Semua', 1)}>
                                        <div className="MuiTreeItem-iconContainer">
                                            <FolderSharedIcon className="colorIconBranch" />
                                        </div>
                                        <div className="MuiTypography-root MuiTreeItem-label MuiTypography-body1"> Semua</div>
                                    </div>
                                    {listKota.map((kota, index) => (
                                        <TreeView key={index} className={kotaBranch ? ("activeList") : (" ")}
                                            defaultCollapseIcon={<FolderSharedIcon className="colorIconBranch" />}
                                            defaultExpandIcon={<FolderIcon className="colorIconBranch" />}>
                                            <TreeItem label={kota.city} nodeId="1"
                                                onClick={BukaLaporan.bind(this, 'Kota', `${kota.city}`)}>
                                                {
                                                    listOutlet.filter(outlet => {
                                                        return outlet.city == kota.city
                                                    }).map(outlet => (
                                                        <TreeItem className={(kodeoutlet == outlet.kodeoutlet) && detBranch ? ("activeList") : ("")} key={outlet.id} nodeId={outlet.id} label={outlet.namaoutlet} onClick={BukaLaporan.bind(this, 'Detail', `${outlet.kodeoutlet}`, `${outlet.namaoutlet}`)} />
                                                    ))
                                                }
                                            </TreeItem>
                                        </TreeView>
                                    ))

                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={9} lg={8}>
                            <div className={`judul-kotak bg-laporan`}>
                                <h6>Informasi Total Penjualan
                                {semuaBranch && (<span> Semua</span>)}
                                    {kotaBranch && (<span> {kota}</span>)}
                                    {detBranch && (<span> {namaOutlet}</span>)}
                                </h6>
                            </div>
                            <div className={`box-content bg-laporan`} >
                                <span className="right" style={{ marginTop: '-10px' }}>
                                    <ButtonGroup
                                        variant="contained"
                                        color="primary"
                                        aria-label="full-width contained primary button group"
                                    >
                                        <Button className={lapHarian && "active-btnGroup"} onClick={LaporanHarian.bind()}>Harian</Button>
                                        <Button className={lapBulanan && "active-btnGroup"} onClick={LaporanBulanan.bind()}>Bulanan</Button>
                                        <Button className={lapTahunan && "active-btnGroup"} onClick={LaporanTahunan.bind()}>Tahunan</Button>
                                    </ButtonGroup>
                                </span>
                                <div style={{ marginTop: '40px' }}>
                                    <Row style={{ padding: '10px 0' }}>
                                        <Col xs={12} md={4}>
                                            <div id="slide" className={`box-report bg-report1 tangan`} onClick={pilihTanggalLap.bind(this, tglKmrin2)}>
                                                <h6>
                                                    {lapHarian && (
                                                        <Moment format="D MMM YYYY">{tglKmrin2}</Moment>
                                                    )}
                                                    {lapBulanan && (
                                                        <Moment format="MMM YYYY">{tglKmrin2}</Moment>
                                                    )}
                                                    {lapTahunan && (
                                                        <Moment format="YYYY">{tglKmrin2}</Moment>
                                                    )}
                                                </h6>
                                                <h3 className="right totLaporan">
                                                    <CurrencyFormat value={parseFloat(Math.round(hitung1 * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                </h3>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <div id="slide" className={`box-report bg-report2 tangan`} onClick={pilihTanggalLap.bind(this, tglKmrin1)}>
                                                <h6>
                                                    {lapHarian && (
                                                        <Moment format="D MMM YYYY">{tglKmrin1}</Moment>
                                                    )}
                                                    {lapBulanan && (
                                                        <Moment format="MMM YYYY">{tglKmrin1}</Moment>
                                                    )}
                                                    {lapTahunan && (
                                                        <Moment format="YYYY">{tglKmrin1}</Moment>
                                                    )}
                                                </h6>
                                                <h3 className="right totLaporan">
                                                    <CurrencyFormat value={parseFloat(Math.round(hitung2 * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                </h3>
                                            </div>
                                        </Col>
                                        <Col id="slide" xs={12} md={4}>
                                            <div className={`box-report bg-report3 tangan`} onClick={pilihTanggalLap.bind(this, tglSkrg)}>
                                                <h6>
                                                    {lapHarian && (
                                                        <Moment format="D MMM YYYY">{tglSkrg}</Moment>
                                                    )}
                                                    {lapBulanan && (
                                                        <Moment format="MMM YYYY">{tglSkrg}</Moment>
                                                    )}
                                                    {lapTahunan && (
                                                        <Moment format="YYYY">{tglSkrg}</Moment>
                                                    )}
                                                </h6>
                                                <h3 className="right totLaporan">
                                                    <CurrencyFormat value={parseFloat(Math.round(hitung3 * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                </h3>
                                            </div>
                                        </Col>
                                        <NavigateBeforeIcon className="prev" onClick={slidePrev} />
                                        <NavigateNextIcon className="next next-right" onClick={slideNext} />
                                    </Row>
                                    <Row>
                                        <Table striped bordered hover size="sm" responsive>
                                            {semuaBranch && (
                                                <thead>
                                                    <tr>
                                                        <th>Nama Kota</th>
                                                        <th className="text-center">Tot penjualan</th>
                                                        <th className="text-center">Tot Transaksi</th>
                                                    </tr>
                                                </thead>
                                            )}
                                            {(kotaBranch || detBranch) && (
                                                <thead>
                                                    <tr>
                                                        <th>Kode Outlet</th>
                                                        <th>Nama Outlet</th>
                                                        <th className="text-center">Tot penjualan</th>
                                                        <th className="text-center">Tot Transaksi</th>
                                                        <th>Detail</th>
                                                    </tr>
                                                </thead>
                                            )}
                                            <tbody>
                                                {semuaBranch && listLapSemua.map((lap, index) => {

                                                    const totJumlah = parseFloat(Math.round(lap.jumlah * 100) / 100).toFixed(2);
                                                    return (
                                                        <tr key={index}>
                                                            <td>{lap.kota}</td>
                                                            <td align="right">
                                                                <CurrencyFormat value={totJumlah} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                            </td>
                                                            <td align="center">{lap.hitung}</td>
                                                        </tr>
                                                    )

                                                })}
                                                {(kotaBranch || detBranch) && listLapSemua.map((lap, index) => {
                                                    const totJumlah = parseFloat(Math.round(lap.jumlah * 100) / 100).toFixed(2);

                                                    return (
                                                        <tr key={index}>
                                                            <td>{lap.outlet.kodeoutlet}</td>
                                                            <td>{lap.outlet.namaoutlet}</td>
                                                            <td align="right">
                                                                <CurrencyFormat value={totJumlah} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                            </td>
                                                            <td align="center">{lap.hitung}</td>
                                                            <td align="center">
                                                                <a className="tangan hrefColor" onClick={openDetail.bind(this, lap)}><DescriptionIcon className="btnColor" /> detail</a>
                                                            </td>
                                                        </tr>
                                                    )

                                                }

                                                )}
                                            </tbody>
                                        </Table>
                                    </Row>


                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
            {modalDetail && (
                <ModalLaporanDet open={modalDetail} setOpen={setModalDetail} namaOutlet={modalNamaOutlet} kodeOutlet={modalKodeOutlet} tanggalDari={modalTglDari} tanggalSampai={modalTglSampai} isHarian={lapHarian} isBulanan={lapBulanan} isTahuanan={lapTahunan} />
            )}
        </React.Fragment>
    )
}
export default Laporan;