import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import mLaporan from '../Laporan.controler'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone'
import CurrencyFormat from 'react-currency-format';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PageviewIcon from '@material-ui/icons/Pageview';
import DiskonTaxServiceDetail from './DiskonTaxService.Detail'
import { FaChevronLeft } from 'react-icons/fa';

const numberWithCommas = (x) => {
    if (!x) x = 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const DiskonTaxService = (props) => {
    const kodeoulet = props.kodeOutlet
    const [isRefresh, setIsRefresh] = useState(true)
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const { clientID, token } = useContext(AuthContext);
    const [listLaporan, setListLaporan] = useState([])
    const [isDetail, setIsDetail] = useState(false)
    const [jenisdetail, setJenisdetail] = useState()
    const [isDetHeader, setIsDetHeader] = useState(false)
    var diskon = 0
    var tax = 0;
    var service = 0, qtyDiskon = 0, qtyService = 0


    useEffect(() => {
        lihatLaporan()

        return () => {
        };
    }, [])


    const lihatLaporan = () => {
        setIsRefresh(true)
        mLaporan.diskonTaxService(clientID, token, kodeoulet, tglDari, tglSampai).then(resp => {
            setListLaporan(resp)
        }).catch(err => {
            console.error(err);
        }).finally(() => setIsRefresh(false))
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    const handleDetail = x => {
        setIsDetail(true)
        setJenisdetail(x)
        setIsDetHeader(true)
    }
    const handleCloseDetail = () => {
        setIsDetail(false)
        setIsDetHeader(false)
    }
    if (!isDetail) {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} lg={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                            <KeyboardDateTimePicker
                                fullWidth={true}
                                margin="normal"
                                id="tanggal"
                                label="Tanggal Dari"
                                format="DD-MM-YYYY HH:mm"
                                value={tglDari}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                onChange={handleChangeTglDari}
                                ampm={false}
                                disableFuture
                            />
                        </MuiPickersUtilsProvider>
                    </Col>
                    <Col xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                            <KeyboardDateTimePicker
                                fullWidth={true}
                                margin="normal"
                                id="tanggal"
                                label="Tanggal Sampai"
                                format="DD-MM-YYYY HH:mm"
                                value={tglSampai}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ color: '#000 !important' }}
                                onChange={handleChangeTglSampai}
                                ampm={false}
                            />
                        </MuiPickersUtilsProvider>
                    </Col>
                    <Col xs={12} md={2} className="text-center"><br />
                        <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="marginGrafik">
                        <ReactHTMLTableToExcel
                            className="buttonExportExcel"
                            table="laporanDiskonTaxService"
                            filename={`Laporan diskon service & tax ${moment(tglDari).format("DD-MM-YYYY HH mm")} sampai ${moment(tglSampai).format("DD-MM-YYYY HH mm")}`}
                            sheet="Sheet1"
                            buttonText={namaExport()} />
                        {listLaporan.map(el => {
                            diskon += el.discountAmount
                            service += el.serviceAmount
                            tax += el.taxAmount
                            qtyDiskon += el.qtyDiskon
                            qtyService += el.qtyService

                        })}
                        <Table id="laporanDiskonTaxService" striped bordered hover responsive="lg">
                            <thead>
                                <tr className="colorWhite bgHeaderTabel tabelCenter">
                                    <th width="10">Detail</th>
                                    <th>Name</th>
                                    <th width="70" className="text-center">Qty</th>
                                    <th width="200" className="text-right">Jumlah</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="colorWhite">
                                    <td className="text-center">
                                        <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleDetail.bind(this, "diskon")}><PageviewIcon /></Button>
                                    </td>
                                    <td>Diskon</td>
                                    <td className="text-center">{qtyDiskon}</td>
                                    <td className="text-right">
                                        <CurrencyFormat value={parseFloat(Math.round(diskon * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                    </td>
                                </tr>
                                <tr className="colorWhite">
                                    <td className="text-center">
                                        <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleDetail.bind(this, "service")}><PageviewIcon /></Button>
                                    </td>
                                    <td>Service</td>
                                    <td className="text-center">{qtyService}</td>
                                    <td className="text-right">
                                        <CurrencyFormat value={parseFloat(Math.round(service * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                    </td>
                                </tr>
                                <tr className="colorWhite">
                                    <td className="text-center">
                                    </td>
                                    <td colSpan="2">Tax</td>
                                    <td className="text-right" >
                                        <CurrencyFormat value={parseFloat(Math.round(tax * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                    </td>
                                </tr>
                                <tr className="colorWhite">
                                    <td className="text-center">
                                    </td>
                                    <th colSpan="2">Total</th>
                                    <th className="text-right">
                                        <CurrencyFormat value={parseFloat(Math.round((diskon + service + tax) * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="marginGrafik"></Row>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                {isDetHeader && (
                    <Row>
                        <Col xs={12}>
                            <div className="fixHeaderModal">
                                <h3 style={{ paddingBottom: 10 }} className="border-bottom btnBack"><FaChevronLeft onClick={handleCloseDetail} className="btnBack" />Detail {jenisdetail}</h3>
                            </div>
                        </Col>
                    </Row>
                )}
                <DiskonTaxServiceDetail tglDari={tglDari} tglSampai={tglSampai} jenisdetail={jenisdetail} kodeoulet={kodeoulet} setIsDetHeader={setIsDetHeader}/>
            </React.Fragment>
        )
    }
}


export default DiskonTaxService;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));