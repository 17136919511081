import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import mLaporan from '../Laporan.controler'
import CurrencyFormat from 'react-currency-format';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PageviewIcon from '@material-ui/icons/Pageview';
import { FaChevronLeft } from 'react-icons/fa';
import PaymentDetail from './PaymentDetail'

const numberWithCommas = (x) => {
    if (!x) x = 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Payment = (props) => {
    const [isRefresh, setIsRefresh] = useState(true)
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const [kodeoulet, setKodeoulet] = useState(props.kodeOutlet)
    const [listPayment, setListPayment] = useState([])
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [idOutlet, setIdOutlet] = useState(props.idOutlet)
    const [dataDetail, setDataDetail] = useState({})
    const [isDetail, setIsDetail] = useState(false)
    const [isDetHeader, setIsDetHeader] = useState(false)
    var totalPayment = 0;

    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.laporanPayment(clientID, token, userLogin, kodeoulet, idOutlet, tglDari, tglSampai).then(resp => {
                setListPayment(resp.listLaporanPayment)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])



    const lihatLaporan = () => {
        setListPayment([])
        setIsRefresh(true)

    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    const handleDetail = (data) => {
        setIsDetail(true)
        setDataDetail(data)
        setIsDetHeader(true)
    }
    const handleCloseDetail = () => {
        setIsDetail(false)
        setIsDetHeader(false)
    }
    return (
        <React.Fragment>
            {!isDetail && (
                <React.Fragment>
                    <Row>
                        <Col xs={12} lg={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDatePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Dari"
                                    format="DD-MM-YYYY"
                                    value={tglDari}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    onChange={handleChangeTglDari}
                                    ampm={false}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDatePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Sampai"
                                    format="DD-MM-YYYY"
                                    value={tglSampai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{ color: '#000 !important' }}
                                    onChange={handleChangeTglSampai}
                                    ampm={false}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={2} className="text-center"><br />
                            <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="marginGrafik">
                            <ReactHTMLTableToExcel
                                className="buttonExportExcel"
                                table="laporanPayment"
                                filename={`Laporan payment ${moment(tglDari).format("DD-MM-YYYY HH mm")} sampai ${moment(tglSampai).format("DD-MM-YYYY HH mm")}`}
                                sheet="Sheet1"
                                buttonText={namaExport()} />
                            <Table id="laporanPayment" striped bordered hover responsive="lg">
                                <thead>
                                    <tr className="colorWhite bgHeaderTabel tabelCenter">
                                        <th>Detail</th>
                                        {/* <th>Kode Payment</th> */}
                                        <th>Payment</th>
                                        <th>Jumlah</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listPayment.map((data, index) => {
                                        totalPayment += data.total
                                        return (
                                            <tr className="colorWhite" key={index}>
                                                <td className="text-center">
                                                    <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleDetail.bind(this, data)}><PageviewIcon /></Button>
                                                </td>
                                                {/* <td >{data.kodepayment}</td> */}
                                                <td>{data.payment}</td>
                                                <td className="text-center">
                                                    <CurrencyFormat value={parseFloat(Math.round(data.total * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr className="colorWhite">
                                        <th className="text-center" colSpan="2">Total</th>
                                        <th className="text-center">
                                            <CurrencyFormat value={parseFloat(Math.round(totalPayment * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
            {isDetail && (
                <React.Fragment>
                    {isDetHeader && (
                        <Row>
                            <Col xs={12}>
                                <div className="fixHeaderModal">
                                    <h3 style={{ paddingBottom: 10 }} className="border-bottom btnBack"><FaChevronLeft onClick={handleCloseDetail} className="btnBack" />Payment Detail {dataDetail.payment}</h3>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <PaymentDetail dataDetail={dataDetail} idOutlet={idOutlet} tglDari={tglDari} tglSampai={tglSampai} setIsDetHeader={setIsDetHeader} />
                </React.Fragment>
            )
            }
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default Payment;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));