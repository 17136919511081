import React, { useState, useContext } from 'react'
import AuthContext from '../../context/auth-context'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import mControl from './Paket.controller'
import { isArray } from 'util';


const Modal = (props) => {
    const kodeoutlet = props.kodeoutlet;
    const kodebarang = props.kodebarang
    const [data, setData] = useState(props.data)
    const { clientID, token } = useContext(AuthContext);
    const handleClose = () => {
        props.setOpen(false)
    }
    const handleSimpan = () => {
        mControl.updatePaketDetail(kodeoutlet, clientID, token, kodebarang, data).then(resp => {
            if (resp) {
                props.setIsLoading(true)
                handleClose()
            } else {
                alert("Gagal Update Data")
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                alert(err[0].message)
            } else if (typeof err == 'error') {
                alert(err.message)
            } else {
                console.log(err);
            }
        })
    }
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Update Item</DialogTitle>
            <DialogContent>
                <h6> {data.namabarang}</h6>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Qty"
                    type="number"
                    min={1}
                    value={data.qty}
                    onChange={(qty) => setData({
                        ...data,
                        qty: qty.target.value
                    })}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
          </Button>
                <Button onClick={handleSimpan} color="primary">
                    Update
          </Button>
            </DialogActions>

        </Dialog>
    )
}

export default Modal;