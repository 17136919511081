/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import PaketForm from './PaketForm'
import mControl from './Paket.controller'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util';
import validate from 'validate.js';
import DialogInfo from '../../Dialog/DialogInfo'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogConfirm from '../../Dialog/DialogConfirm'
import ModalAddPaketItem from './Modal.AddPaketItem'
import ModalUpdatePaketDet from './Modal.UpdatePaketItem'
import { stableSort, getSorting } from '../../Helper/Sorting'


const BarangDetail = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const kodebarang = props.match.params.kodebarang;
    let urut = 0
    const defaultPage = parseInt(props.match.params.page, 10);
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const { clientID, token } = useContext(AuthContext);
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [paketDetail, setPaketDetail] = useState([])
    const [dataDialog, setdataDialog] = useState({})
    const [isModalUpt, setIsModalUpt] = useState(false)
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("namabarang");
    const [isModalPaketAdd, setIsModalPaketAdd] = useState(false)
    const [state, setState] = useState({
        kodebarang: '',
        namabarang: '',
        hargasatuan: 0,
        hargagojek: 0,
        hargagrab: 0,
        hargalain: 0,
        satuan: '',
        isStock: false,
    })
    useEffect(() => {
        var render = true;
        mControl.loadDetail(kodeoutlet, kodebarang, clientID, token).then(resp => {
            if (render) {
                setState(resp)
            }

        }).catch(err => {
            console.log(err);
        }).finally(() => setIsLoading(false))
        return () => {
            render = false;
        };
    }, [kodeoutlet, kodebarang, clientID, token])
    useEffect(() => {
        mControl.loadDetailPaket(kodeoutlet, kodebarang, clientID, token).then(resp => {
            setPaketDetail(resp)
        }).catch(err => {
            console.log(err);
        }).finally(() => setIsLoading(false))
        return () => {
        };
    }, [isLoading])
    const handleChange = (key, value) => {
        var newState = { ...state }
        newState[key] = value;
        setState(newState);
    }
    const validateForm = () => {
        const { namabarang, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock, printer } = state;
        const promBarang = new Promise((resolve, reject) => {
            const constraint = {
                namabarang: {
                    length: {
                        minimum: 3,
                        message: "Nama Barang minimal terdiri dari 3 huruf !"
                    },
                },
                satuan: {
                    length: {
                        minimum: 1,
                        message: "Satuan Wajib Isi !"
                    }
                },
                hargasatuan: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga satuan yang sesuai !"
                    }
                },
                hargagojek: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga gojek yang sesuai !"
                    }
                },
                hargagrab: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga grab yang sesuai !"
                    }
                },
                hargalain: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga lain yang sesuai !"
                    }
                }
            }
            const validator = validate({ namabarang, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promBarang]);
    }
    const handleSimpan = () => {
        setOpenConfirmInfo(true)
        validateForm().then(() => {
            const { kodebarang, namabarang, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock } = state;
            mControl.simpanPaket(kodeoutlet, namabarang, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, clientID, token, kodebarang).then((resp) => {
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Data paket berhasil di update<p/>`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext("<p>Data gagal diupdate</p>")
                }
            }).catch(err => {
                setOpenConfirmInfo(true)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setOpenConfirmInfo(true)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }
    const konfirmasiDeleteSelecter = (data) => {
        setOpenConfirmDelete(true);
        setdataDialog(data)
        setDialogtext(`<p>Yakin anda ingin menghapus ${data.namabarang} dari paket ini?<br/>Data tidak dapat dikembalikan setelah berhasil dihapus<p/>`)
    }
    const handleEdit = (data) => {
        setdataDialog(data)
        setIsModalUpt(true)
    }
    const handleHapus = (data) => {
        mControl.hapusPaketDetail(kodeoutlet, state.kodebarang, clientID, token, data.kodebarang).then(resp => {
            if (resp) {
                setOpenConfirmDelete(false);
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${data.namabarang} berhasil di hapus<p/>`)
                setIsLoading(true);
            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${data.namabarang} Gagal Di hapus!<p/>`)
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Gagal hapus ${err[0].message}<p/>`)
            } else if (typeof err == 'error') {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${err.message}<p/>`)
            } else {
                console.log(err);
            }
        })
    }
    const handleAddItem = () => {
        setIsModalPaketAdd(true)
    }
    return (
        <Container>
            <PaketForm
                history={props.history}
                kodeoutlet={kodeoutlet}
                kodebarang={state.kodebarang}
                namabarang={state.namabarang}
                satuan={state.satuan}
                hargasatuan={state.hargasatuan}
                hargagojek={state.hargagojek}
                hargagrab={state.hargagrab}
                hargalain={state.hargalain}
                isStock={state.isStock}
                handleChange={handleChange}
                type="edit"
                defaultPage={defaultPage}
                defaultrowsPerPage={defaultrowsPerPage}
            />
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12} className="text-center">
                    <Button variant="contained" color="primary" onClick={handleSimpan} disabled={isLoading}>Update</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="garis-bawah">
                    </div>
                    <h4 className="text-center">Detail Item</h4>
                </Col>
                <Col xs={12}>
                    <Button variant="contained" color="primary" onClick={handleAddItem} ><AddCircleOutlineIcon />Add Item</Button>
                </Col>
                <Col xs={12}>
                    <Table striped bordered hover responsive="lg">
                        <thead>
                            <tr className="colorWhite bgHeaderTabel tabelCenter">
                                <th style={{ "width": "5px" }}>No</th>
                                <th>Kode Barang</th>
                                <th>Nama Barang</th>
                                <th>Qty</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stableSort(paketDetail, getSorting(order, orderBy))
                                .map((data, index) => {
                                    urut++
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{urut}</td>
                                            <td>{data.kodebarang}</td>
                                            <td>{data.namabarang}</td>
                                            <td className="text-center">{data.qty}</td>
                                            <td className="text-center">
                                                <EditIcon className="pointer" onClick={handleEdit.bind(this, data)} />
                                                <DeleteIcon className="pointer" onClick={konfirmasiDeleteSelecter.bind(this, data)} />
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            <DialogConfirm open={openConfirmDelete} setOpen={setOpenConfirmDelete} text={dialogtext} data={dataDialog} oke={handleHapus} />
            {isModalUpt && (
                <ModalUpdatePaketDet open={isModalUpt} setOpen={setIsModalUpt} data={dataDialog} setIsLoading={setIsLoading} kodeoutlet={kodeoutlet} kodebarang={state.kodebarang}/>
            )}
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            {isModalPaketAdd && (
                <ModalAddPaketItem open={isModalPaketAdd} setOpen={setIsModalPaketAdd} setIsLoading={setIsLoading} kodeoutlet={kodeoutlet} kodebarang={state.kodebarang} namabarang={state.namabarang} />
            )}
        </Container>
    )
}

export default BarangDetail;