import GQL from '../../GQL'

const pilihoutlet = {
    loadData: (userlogin, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    customer(email:"${userlogin}"){
                        outlet{
                            id
                            kodeoutlet
                            namaoutlet
                            fullName
                            address
                        }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.customer && resp.data.customer.outlet) return (resolve(resp.data.customer.outlet));
                return (resolve([]))
            }).catch(err => reject(err))
        })
    },
    tambahOutlet:(outletToken,clientID,token)=> {
        return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    tambahOutlet(token:"${outletToken}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.tambahOutlet != null){
                    return(resolve(resp.data.tambahOutlet))
                }else{
                    return(reject(new Error("Gagal menyimpan data")));
                }
            }).catch(err => reject(err))
        })
    },
    hapusOutlet:(idOutlet,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    hapusOutlet(idOutlet:${idOutlet})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.hapusOutlet != null){
                    return(resolve(resp.data.hapusOutlet))
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    }
}

export default pilihoutlet;