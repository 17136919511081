import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import mLaporan from '../Laporan.controler'
import moment from 'moment-timezone'
import AuthContext from '../../../../context/auth-context'
import { makeStyles } from '@material-ui/core/styles';
import { FaChevronLeft } from 'react-icons/fa';
import { Button, TableRow, TableCell, TableBody, TableHead, } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import CurrencyFormat from 'react-currency-format';

const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const TransaksiDetail = (props) => {
    const classes = useStyles();
    const { clientID, token } = useContext(AuthContext);
    const [detOrder, setDetOrder] = useState([])
    const [detBayar, setDetBayar] = useState([])
    const [hitungItem, setHitungItem] = useState({})
    let subTotalItem = 0
    let detTotBayar = 0;

    useEffect(() => {
        mLaporan.laporanPaymentDetailTrans(clientID, token, props.kodeoulet, props.noinvoice).then(resp => {
            setDetOrder(resp.item)
            setHitungItem(resp.itemTotal)
            setDetBayar(resp.payment);
        }).catch(err => {
            console.error(err);
        })
        return () => {
        };
    }, [props.noinvoice, props.kodeoulet, clientID, token])

    return (
        <React.Fragment>
            <Col xs={12} className={`${classes.contentBody} bodySessionDetail`}>
                <h6 className="colorBlack">List Orderan</h6>
                <div className={`bgContent-orderan ${classes.tabel}`}>
                    <Table size="small">
                        <TableHead>
                            <TableRow className="bgTabel">
                                <TableCell className="colorBlack" style={{ width: '50%' }}>Item</TableCell>
                                <TableCell className="colorBlack" style={{ width: ' 10%' }}>Qty</TableCell>
                                <TableCell className="colorBlack"
                                    align="right" style={{ width: ' 20%' }}>Harga</TableCell>
                                <TableCell className="colorBlack"
                                    align="right" style={{ width: ' 20%' }}>Jumlah</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {detOrder.map((data, idx) => {
                                subTotalItem += data.jumlah
                                return (
                                    <TableRow key={idx.toString()}>
                                        <TableCell className="colorWhite">
                                            {data.namabarang}
                                        </TableCell>
                                        <TableCell className="colorWhite">
                                            {data.qty}
                                        </TableCell>
                                        <TableCell align="right" className="colorWhite">
                                            {numberWithCommas(data.harga)}
                                        </TableCell>
                                        <TableCell align="right" className="colorWhite">
                                            {numberWithCommas(data.jumlah)}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell className="colorWhite" colSpan="3">Sub Total</TableCell>
                                <TableCell align="right" className="colorWhite">
                                    {numberWithCommas(subTotalItem)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
                <div className={classes.tabel}>
                    <Table size="small">
                        <TableBody>
                            <TableRow >
                                <TableCell className="colorWhite" style={{ width: '50%' }}>
                                    Discount
                                        </TableCell>
                                <TableCell className="colorWhite" style={{ width: '10%' }}>
                                    {hitungItem.discountPercent}%
                                        </TableCell>
                                <TableCell align="right" className="colorWhite" style={{ width: '40%' }}>
                                    <CurrencyFormat value={parseFloat(Math.round(hitungItem.discountAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell className="colorWhite">
                                    Service
                                        </TableCell>
                                <TableCell className="colorWhite">
                                    {hitungItem.servicePercent}%
                                        </TableCell>
                                <TableCell align="right" className="colorWhite">
                                    <CurrencyFormat value={parseFloat(Math.round(hitungItem.serviceAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell className="colorWhite">
                                    tax
                                        </TableCell>
                                <TableCell className="colorWhite">
                                    {hitungItem.taxPercent}%
                                        </TableCell>
                                <TableCell align="right" className="colorWhite">
                                    <CurrencyFormat value={parseFloat(Math.round(hitungItem.taxAmount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell className="colorWhite" colSpan="2">
                                    <b>Total</b>
                                </TableCell>
                                <TableCell align="right" className="colorWhite">
                                    <b>   {numberWithCommas(hitungItem.total)} </b>

                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <h6 className="colorBlack">List Pembayaran</h6>
                <div className={`bgContent-orderan ${classes.tabel}`}>
                    <Table size="small">
                        <TableHead>
                            <TableRow className="bgTabel">
                                <TableCell className="colorBlack" style={{ width: '30%' }}>Payment</TableCell>
                                <TableCell className="colorBlack" style={{ width: '50%' }} align="center">Remark</TableCell>
                                <TableCell className="colorBlack" style={{ width: '20%' }} align="right">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {detBayar.map((data, idx) => {
                                detTotBayar += data.amount;
                                return (
                                    <TableRow key={idx}>
                                        <TableCell className="colorWhite">
                                            {data.namapayment}
                                        </TableCell>
                                        <TableCell className="colorWhite" align="right">
                                            {data.remark}
                                        </TableCell>
                                        <TableCell align="right" className="colorWhite">
                                            {numberWithCommas((data.amount * 1) + (hitungItem.kembalian * 1))}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <TableHead>
                            <TableRow >
                                <TableCell className="colorWhite" colSpan="2">Total</TableCell>
                                <TableCell className="colorWhite" align="right">
                                    {numberWithCommas((detTotBayar * 1) + (hitungItem.kembalian * 1))}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
                <div className={classes.tabel}>
                    <Table size="small">
                        <TableBody>
                            <TableRow >
                                <TableCell className="colorWhite" style={{ width: '80%' }}>
                                    Kembalian
                                        </TableCell>
                                <TableCell align="right" className="colorWhite" style={{ width: '20%' }}>
                                    <CurrencyFormat value={parseFloat(Math.round(hitungItem.kembalian * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </Col>
        </React.Fragment>

    )

}


export default TransaksiDetail;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));