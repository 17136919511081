import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import mRegister from './Register.controller'
import DialogInfo from '../../Dialog/DialogInfo'
import ForgetPassword from '../ForgetPassword/ForgetPassword'


function MadeWithLove() {
    if (process.env.REACT_APP_COMPANY == "MonkeyPOS") {
        return (
            <Typography variant="body2" align="center">
                {'Developed by: '}
                <a target="_blank" color="inherit" className="colorLogin" href="https://homansystem.com/">
                    Homan System
          </a>
                {' team.'}
            </Typography>
        );
    } else if (process.env.REACT_APP_COMPANY == "Ipos") {
        return (
            <Typography variant="body2" align="center">
                {'Developed by: '}
                <a target="_blank" color="inherit" className="colorLogin" href="https://www.ipos.co.id/">
                    iPOS
          </a>
                {' team.'}
            </Typography>
        );
    }

}


const Register = (props) => {
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isForget, setIsForget] = useState(false)
    const [state, setState] = useState({
        email: '',
        namacustomer: '',
        password: '',
        alamat: '',
        nik: '',
        gender: '',
        nohp: ''
    })


    const onRegister = (ev) => {
        const { email, namacustomer, password, alamat, nik, gender, nohp } = state;
        mRegister.register(email, namacustomer, password, alamat, nik, gender, nohp).then((val) => {
            if (val) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Registrasi Anda berhasil. Buka E-mail anda untuk mengaktifkan akun anda<p/>`)
                setDialogUrl(`/login`)
            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Server tidak dapat menjalankan permintaan Anda. Harap coba lagi beberapa saat lagi<p/>`)
            }
        }).catch(err => {
            console.log(err);
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${err[0].message}<p/>`)
        })
        ev.preventDefault();
    }

    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        const newValue = { ...state };
        newValue[key] = value;
        setState(newValue);
    }
    const handleForget = () => {
        setIsForget(true)
    }
    const handleForgetInfo = (info) => {
        setIsForget(false)
        setOpenConfirmInfo(true)
        setDialogtext(info)
    }

    return (
        <Container component="main" maxWidth="xs" style={{ marginTop: '-50px' }}>
            <CssBaseline />
            <div className="paper">
                <Avatar className="avatar">
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">Sign Up</Typography>
                <form className="form" onSubmit={onRegister}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={state.email}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={state.password}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="namacustomer"
                        label="Nama"
                        name="namacustomer"
                        autoComplete="namacustomer"
                        value={state.namacustomer}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="alamat"
                        label="Alamat"
                        name="alamat"
                        autoComplete="alamat"
                        rows={3}
                        multiline={true}
                        value={state.alamat}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="nik"
                        label="NIK"
                        name="nik"
                        autoComplete="nik"
                        type="text"
                        value={state.nik}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="nohp"
                        label="No Handphone"
                        name="nohp"
                        autoComplete="nohp"
                        type="tel"
                        value={state.nohp}
                        onChange={handleChange}
                    />
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name="gender"
                        onChange={handleChange}
                        value={state.gender}
                    >
                        <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                        <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                    </RadioGroup>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="submit"
                    >Sign Up</Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to="#" onClick={handleForget} className="colorLogin" variant="body2">Forgot password?</Link>
                        </Grid>
                        <Grid item>
                            <Link to="login" className="colorLogin" variant="body2">
                                {"Have an account? Sign In"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {isForget && (
                <ForgetPassword open={isForget} setOpen={setIsForget} info={handleForgetInfo} />
            )}
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            <Box mt={5}>
                <MadeWithLove />
            </Box>
        </Container>
    );
}

export default Register;