import GQL from '../../../GQL';
import moment from 'moment-timezone'

const dashboard = {
  loadDashboard: (clientID, token, userlogin) => {
    return new Promise((resolve, reject) => {
      const query = `
      query{
        V2AllLaporanRingkasanTrans(tanggal:"${moment().format("YYYY-MM-DD")}"){
          totalPenjualan
          totalRetur
          jumlahRetur
          totalRetur
          totalPenerimaan
          totalTransaksi
        }
        V2AllLaporanRingkasanPay(tanggal:"${moment().format("YYYY-MM-DD")}"){
          tunai
          nonTunai
        }
        V2AllGrafikAllHarian( tanggal:"${moment().format("YYYY-MM-DD")}"){
          namaoutlet
          nilaiPenjualan
        }
        customer(email:"${userlogin}"){
          outlet{
            detail{
                expiryDate
            }
          }
        }
      }
      `
      GQL(query, clientID, token).then(resp => {
        const transaksi = resp.data.V2AllLaporanRingkasanTrans
        const payment = resp.data.V2AllLaporanRingkasanPay
        const namaOutlet = [];
        const nilaiPenjualan = [];
        for (var i = 0, j = resp.data.V2AllGrafikAllHarian.length; i < j; i++) {
          namaOutlet.push(resp.data.V2AllGrafikAllHarian[i].namaoutlet);
          nilaiPenjualan.push(resp.data.V2AllGrafikAllHarian[i].nilaiPenjualan)
        }
        const listCabang = [];
        resp.data.customer.outlet.reduce((a, b) => {
          const maxExpiryDate = moment.max(b.detail.map(el => moment(el.expiryDate, 'DD MMM YYYY')));
          if (moment().isSameOrBefore(maxExpiryDate)) {
            if (!a["aktif"]) {
              a["aktif"] = {
                aktif: true,
                hitung: 0
              }
              listCabang.push(a["aktif"]);
            }
            a["aktif"].hitung += 1;
          } else {
            if (!a["tidakaktif"]) {
              a["tidakaktif"] = {
                aktif: false,
                hitung: 0
              }
              listCabang.push(a["tidakaktif"]);
            }
            a["tidakaktif"].hitung += 1;
          }
          return a;
        }, {})
        const cabang = {
          aktif: listCabang.find(el => el.aktif) && listCabang.find(el => el.aktif).hitung || 0,
          tidakaktif: listCabang.find(el => !el.aktif) && listCabang.find(el => !el.aktif).hitung || 0
        };
        return (resolve({
          cabang,
          transaksi,
          payment,
          namaOutlet,
          nilaiPenjualan
        }))
      }).catch(err => reject(err))
    })
  }
}

export default dashboard;