/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch } from '@material-ui/core';
import useStyles from '../../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import Dropdown from 'react-bootstrap/Dropdown'
import mControl from './ListTopping.Controler'
import TouchAppIcon from '@material-ui/icons/TouchApp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ModalListTopping from './Modal.ListTopping'
import numberWithCommas from '../../../Helper/numberWithCommas'
import ImportExportIcon from '@material-ui/icons/ImportExport';

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: "kodetopping", label: "Kode Topping" },
    { id: "namatopping", label: "Nama Topping" },
    { id: "kodekategori", label: "Kategori" },
    { id: "kodesubkategori", label: "Sub Kategori" },
    { id: "hargasatuan", label: "Harga Satuan" },
    { id: "hargagojek", label: "Harga Gojek" },
    { id: "hargagrab", label: "Harga Grab" },
    { id: "hargalain", label: "Harga Lain" },
    { id: "hargalain2", label: "Harga Lain 2" },


];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell>Action</TableCell>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const ListTopping = (props) => {
    const kodeoutlet = props.kodeoutlet;
    const defaultPage = 0;
    const defaultrowsPerPage = 5;
    const clientID = props.clientID
    const token = props.token
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [dense, setDense] = React.useState(true);
    const [isRefresh, setIsRefresh] = useState(true)
    const [rows, setRows] = useState([])
    const [open, setOpen] = useState(false);
    const [detTopping, setDetTopping] = useState({})
    useEffect(() => {
        var render = true
        if (isRefresh) {
            setRows([])
            mControl.loadData(kodeoutlet, clientID, token).then(resp => {
                if (render) setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh]);
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const loadingRow = [0, 1, 2, 3, 4];
    const openEdit = (data) => {
        setDetTopping(data)
        setOpen(true)
    }
    const handleHapus = (data) => {
        const oke = window.confirm(`Yakin ingin hapus list topping ${data.namatopping}?`)
        if (oke) {
            mControl.deleteListTopping(kodeoutlet, clientID, token, data.kodetopping).then(resp => {
                alert("List topping berhasil dihapus")
                setIsRefresh(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }
    const goImport = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/topping/import`)
    }
    return (
        <React.Fragment>
            <div >
                <Paper className={classes.paper}>
                    <div className="btnTable">
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <AddIcon id="dropdown-basic" /> Tambah
                        </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item><Button title="Edit" variant="contained" className="btnAddManual" onClick={() => {
                                    setDetTopping({})
                                    setOpen(true)
                                }}><EditIcon /> Tambah Manual</Button></Dropdown.Item>
                                <Dropdown.Item><Button title="Hapus" variant="contained" className="btnImportExcel" onClick={goImport}><ImportExportIcon /> Import Excel</Button></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className={classes.tableWrapper}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {isRefresh && loadingRow.map(el => (
                                    <TableRow
                                        key={el.toString()}
                                        style={{ height: 50 }}
                                    >
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell >
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {stableSort(rows, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className={(row.statusid === 8 || row.statusid === 9) && ("bgRowCancel") || ("")}
                                            >
                                                <TableCell>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <TouchAppIcon id="dropdown-basic" /></Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item><Button title="Edit" variant="contained" style={{ backgroundColor: '#376275', marginRight: 10, color: "#fff", width: '145px' }} onClick={openEdit.bind(this, row)}><EditIcon /> Edit/lihat</Button></Dropdown.Item>
                                                            <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: 'red', marginRight: 10, color: "#fff", width: '145px' }} onClick={handleHapus.bind(this, row)}><DeleteIcon /> Hapus</Button></Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </TableCell>
                                                <TableCell >
                                                    {row.kodetopping}
                                                </TableCell>
                                                <TableCell >
                                                    {row.namatopping}
                                                </TableCell>
                                                <TableCell >
                                                    {row.kategori ? row.kategori.namakategori : (`Kode Kategori ${row.kodekategori} belum didaftarkan`)}
                                                </TableCell>
                                                <TableCell >
                                                    {row.subkategori ? row.subkategori.namasubkategori : (`Kode Sub Kategori ${row.kodesubkategori} belum didaftarkan`)}
                                                </TableCell>
                                                <TableCell >
                                                    {numberWithCommas(row.hargasatuan)}
                                                </TableCell>
                                                <TableCell >
                                                    {numberWithCommas(row.hargagojek)}
                                                </TableCell>
                                                <TableCell >
                                                    {numberWithCommas(row.hargagrab)}
                                                </TableCell>
                                                <TableCell >
                                                    {numberWithCommas(row.hargalain)}
                                                </TableCell>
                                                <TableCell >
                                                    {numberWithCommas(row.hargalain2)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={10} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "previous page"
                        }}
                        nextIconButtonProps={{
                            "aria-label": "next page"
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                />
            </div>
            <ModalListTopping open={open} setOpen={setOpen} setIsRefresh={setIsRefresh} kodeoutlet={kodeoutlet} clientID={clientID} token={token} detTopping={detTopping} />
        </React.Fragment>
    );
}
export default ListTopping;