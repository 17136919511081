import GQL from '../../GQL';
const KasKeluar = {
    loadDataTransaksi: (idOutlet,clientID,token, tglDari, tglSampai) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    laporanTransKasMasuk(idOutlet:${idOutlet}, tglDari: "${tglDari}", tglSampai: "${tglSampai}" ){
                        noinvoice
                        tanggal
                        total
                        sessionId
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.laporanTransKasMasuk) return(resolve(resp.data.laporanTransKasMasuk));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDataTransaksiDetail: (idOutlet,clientID,token, noinvoice) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    laporanTransKasMasukDetail(idOutlet:${idOutlet}, noinvoice: "${noinvoice}" ){
                        kodebiaya
                        namabiaya
                        amount
                        keterangan
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.laporanTransKasMasukDetail) return(resolve(resp.data.laporanTransKasMasukDetail));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDataItem: (idOutlet,clientID,token, tglDari, tglSampai) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    laporanTransKasMasukItem(idOutlet:${idOutlet}, tglDari: "${tglDari}", tglSampai: "${tglSampai}" ){
                        kodebiaya
                       namabiaya
                       amount
                       qty
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.laporanTransKasMasukItem) return(resolve(resp.data.laporanTransKasMasukItem));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDataItemDetail: (idOutlet,clientID,token,tglDari, tglSampai, kodebiaya) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    laporanTransKasMasuk(idOutlet:${idOutlet}, tglDari: "${tglDari}", tglSampai: "${tglSampai}", kodebiaya:"${kodebiaya}"){
                        noinvoice
                        tanggal
                        total
                     }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.laporanTransKasMasuk) return(resolve(resp.data.laporanTransKasMasuk));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    
}

export default KasKeluar;