/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Tabs, Tab } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import SwipeableViews from 'react-swipeable-views';
import UserKasir from '../Pengaturan/UserKasir/UserKasir'
import TaxService from '../Pengaturan/TaxService/TaxService'
import General from '../Pengaturan/General/General'
import Info from './Info/Info'


const Pengaturan = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const { clientID, token } = useContext(AuthContext)
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}`)
    }
    const [value, setValue] = useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }
    function handleChangeIndex(index) {
        setValue(index);
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Pengaturan</h3>
                    <div >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="User Kasir" className="font12" />
                            <Tab label="Info" className="font12" />
                            <Tab label="Tax & Service" className="font12" />
                            <Tab label="General" className="font12" />
                        </Tabs>
                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <div value={value} index={0}>
                                <UserKasir kodeoutlet={kodeoutlet} token={token} clientID={clientID} redirect={props.history.push} />
                            </div>
                            <div value={value} index={1}>
                                <Info kodeoutlet={kodeoutlet} token={token} clientID={clientID} />
                            </div>
                            <div value={value} index={2}>
                                <TaxService kodeoutlet={kodeoutlet} token={token} clientID={clientID} />
                            </div>
                            <div value={value} index={3}>
                                <General kodeoutlet={kodeoutlet} token={token} clientID={clientID} />
                            </div>
                        </SwipeableViews>

                    </div>
                </Col>
            </Row>

        </Container>
    );
}
export default Pengaturan;