import React, { useState, useEffect } from 'react'
import mControl from './Poin.Controler'
import { Row, Col } from 'react-bootstrap';
import { Toolbar, Typography, DialogContent, DialogContentText, Button, TextField, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft } from 'react-icons/fa';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const Modal = (props) => {
    const kodeoutlet = props.kodeoutlet
    const outlet = props.outlet
    const clientID = props.clientID
    const token = props.token
    const detail = props.dataDialog || null
    const id = detail.id || ""
    const [form, setForm] = useState({
        hargaPoint: '',
        dariTgl: moment(),
        sampaiTgl: moment(),
        isTgl: false,
    })
    useEffect(() => {
        if (detail.id) {
            let isTgl = false
            isTgl = (detail.dariTgl || detail.sampaiTgl) ? true : false
            setForm({
                hargaPoint: detail.hargaPoint * 1,
                dariTgl: detail.dariTgl,
                sampaiTgl: detail.sampaiTgl,
                isTgl
            })
        }
        return () => {

        }
    }, [detail])
    const handleClose = () => {
        props.setOpen(false)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const handleSimpan = () => {
        mControl.savePoint(clientID, token, id, outlet.kodeoutlet, form.hargaPoint, form.isTgl, form.dariTgl, form.sampaiTgl).then(resp => {
            alert("Data Berhasil Disimpan")
            handleClose()
            props.setIsRefresh(true)
        }).catch(err => {
            if (err.length > 0) {
                alert(err[0].message)
            } else if (typeof err) {
                alert(err.message)
            } else {
                console.log(err);
            }
        })

    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <FaChevronLeft color="inherit" onClick={handleClose} className="tangan" />
                    <Typography variant="h6">
                        {detail.id ? ("Update") : ("Add")} Point</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="DialogFont bodyDialog">
                <DialogContentText className="colorWhite">
                    <Row>
                        <Col xs={12} md={12}>
                            <TextField
                                id="hargaPoint"
                                name="hargaPoint"
                                label="Harga Point"
                                fullWidth
                                value={form.hargaPoint}
                                onChange={handleChange}
                                type="number"
                            />
                        </Col>
                        <Col xs={12} lg={12}>
                            <FormControl fullWidth={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox id="isTgl" checked={form.isTgl}
                                            value={!form.isTgl}
                                            onChange={(e) => setForm({
                                                ...form, isTgl: !form.isTgl
                                            })}
                                        />
                                    }
                                    label='Mulai digunakan pada tanggal'
                                />
                            </FormControl>
                        </Col>
                        {form.isTgl && (
                            <>
                                <Col xs={12} lg={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                        <KeyboardDateTimePicker
                                            fullWidth={true}
                                            margin="normal"
                                            id="tanggal"
                                            label="Dari Tanggal"
                                            format="DD-MM-YYYY HH:mm"
                                            value={form.dariTgl}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            onChange={(e) => setForm({ ...form, dariTgl: e })}
                                            ampm={false}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Col>
                                <Col xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                        <KeyboardDateTimePicker
                                            fullWidth={true}
                                            margin="normal"
                                            id="tanggal"
                                            label="Sampai Tanggal"
                                            format="DD-MM-YYYY HH:mm"
                                            value={form.sampaiTgl}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ color: '#000 !important' }}
                                            onChange={(e) => setForm({ ...form, sampaiTgl: e })}
                                            ampm={false}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 20 }}>
                        <Col xs={12} className="text-center">
                            <Button variant="contained" color="primary" fullWidth onClick={handleSimpan}>Simpan</Button>
                        </Col>
                    </Row>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;