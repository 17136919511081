/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import useStyles from '../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import PageviewIcon from '@material-ui/icons/Pageview';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'react-moment';
import mRiwayat from './RiwayatTrans.controller'
import numberWithCommas from '../../Helper/numberWithCommas'
import moment from 'moment-timezone'
moment.tz("Asia/Jakarta")
const dateNow = moment();
const dateSkrg = moment(dateNow).tz("Asia/Jakarta").set({
    date: 1,
    hour: 0,
    minute: 0,
    second: 0
});
const bulanDepan = moment(dateNow).add(1, 'months');
const dateAkhir = moment(bulanDepan).tz("Asia/Jakarta").set({
    date: 1,
    hour: 23,
    minute: 59,
    second: 59
}).subtract(1, 'days');
const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    {
        id: "noinvoice", numeric: false, disablePadding: true, label: "No Trans"
    },
    { id: "tanggal", numeric: true, disablePadding: false, label: "Tgl Trans" },
    { id: "lastJamBayar", numeric: true, disablePadding: false, label: "Tgl Lunas" },


];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
        rowCount,
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell>Action</TableCell>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {props.tipeOutlet === "NORMAL" && (
                    <TableCell align="center">Meja</TableCell>
                )}
                <TableCell align="right">Tagihan</TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const RiwayatTransaksi = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = parseInt(props.match.params.page, 10) - 1;
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const { clientID, token } = useContext(AuthContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [dense, setDense] = React.useState(true);
    const [isRefresh, setIsRefresh] = useState(true)
    const [rows, setRows] = useState([])
    const [openRiwayat, setOpenRiwayat] = useState(true)
    const [openTgl, setOpenTgl] = useState(false)
    const [tglDari, setTglDari] = useState(dateSkrg)
    const [tglSampai, setTglSampai] = useState(dateAkhir)
    const [cariNotrans, setCariNotrans] = useState()
    const [tipeOutlet, setTipeOutlet] = useState()


    useEffect(() => {
        if (!tipeOutlet) {
            mRiwayat.getOutlet(kodeoutlet, clientID, token).then(resp => {
                setTipeOutlet(resp.tipeOutlet);
                loadData(resp.tipeOutlet)
            }).catch(err => {
                console.error(err);
            })
        } else {
            loadData(tipeOutlet)
        }
        return () => {
        };
    }, [kodeoutlet, isRefresh])
    const loadData = (tipe) => {
        if (tipe === "NORMAL") {
            mRiwayat.loadData(kodeoutlet, clientID, token, tglDari, tglSampai, cariNotrans).then(resp => {
                if (resp.length === 0) {
                    alert("Tidak ada transaksi")
                    handleOpenTgl()
                    return
                }
                setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        } else if (tipe === "SIMPLE") {
            mRiwayat.loadDataSimple(kodeoutlet, clientID, token, tglDari, tglSampai, cariNotrans).then(resp => {
                if (resp.length === 0) {
                    alert("Tidak ada transaksi")
                    handleOpenTgl()
                    return
                }
                setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
    }
    const handleView = (kodetrans) => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/transhistory-detail/${kodetrans}/${page + 1}/${rowsPerPage}`);
    }
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}`)
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const loadingRow = [0, 1, 2, 3, 4];
    const TglDariHandler = (val) => {
        setTglDari(moment(val).format("D MMM YYYY"))
    }
    const TglSampaiHandler = (val) => {
        setTglSampai(moment(val).format("D MMM YYYY"))
    }
    const handlerNotrans = (val) => {
        setCariNotrans(val.target.value)
    }
    const handleOpenRiwayat = () => {
        setOpenTgl(false)
        setOpenRiwayat(true);
        setIsRefresh(true);
    }
    const handleOpenTgl = () => {
        setOpenRiwayat(false);
        setOpenTgl(true)
        setPage(defaultPage)
        setRowsPerPage(defaultrowsPerPage)
    }
    const renderContentTabel = () => {
        if (tipeOutlet === "NORMAL") {
            return (
                <React.Fragment>
                    {stableSort(rows, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            var total
                            if (row.statusid === 20) {
                                total = row.payment.map(el => el.total).reduce((a, b) => a + b, 0)
                            } else if (row.statusid === 9) {
                                if (row._id.match(/^retur-/)) {
                                    total = row.payment.map(el => el.total).reduce((a, b) => a + b, 0)
                                } else {
                                    total = 0
                                }
                            } else {
                                total = 0
                            }
                            const NoTrans = row._id.substring(27)
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row._id}
                                    className={(row.statusid === 8 || row.statusid === 9) && ("bgRowCancel") || ("")}
                                >
                                    <TableCell>
                                        <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleView.bind(this, row._id)}><PageviewIcon /></Button>
                                    </TableCell>
                                    <TableCell >
                                        {row.noinvoice === null ? NoTrans : row.noinvoice}
                                    </TableCell>
                                    <TableCell>
                                        <Moment format="D-MMM-YYYY">{moment(row.tanggal).tz("Asia/Jakarta").format("DD-MMM-YYYY")}</Moment>
                                    </TableCell>
                                    <TableCell>
                                        <Moment format="D-MMM-YYYY">{row.lastJamBayar}</Moment>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.nomejaint}
                                    </TableCell>
                                    <TableCell align="right">
                                        {numberWithCommas(total)}
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </React.Fragment>
            )
        } else if (tipeOutlet === "SIMPLE") {
            return (
                <React.Fragment>
                    {stableSort(rows, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const NoTrans = row._id.substring(27)
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row._id}
                                    className={(row.statusid === 8 || row.statusid === 9) && ("bgRowCancel") || ("")}
                                >
                                    <TableCell>
                                        <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleView.bind(this, row._id)}><PageviewIcon /></Button>
                                    </TableCell>
                                    <TableCell >
                                        {row.noinvoice === null ? NoTrans : row.noinvoice}
                                    </TableCell>
                                    <TableCell>
                                        <Moment format="D-MMM-YYYY">{moment(row.tanggal).tz("Asia/Jakarta").format("DD-MMM-YYYY")}</Moment>
                                    </TableCell>
                                    <TableCell>
                                        <Moment format="D-MMM-YYYY">{row.lastJamBayar}</Moment>
                                    </TableCell>
                                    <TableCell align="right">
                                        {numberWithCommas(row.total)}
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </React.Fragment>
            )
        }
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Riwayat Transaksi</h3>
                    {openTgl && (
                        <Row>
                            <Col xs={12} lg={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                    <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        id="tanggal"
                                        label="Tanggal Dari"
                                        format="DD MMM YYYY"
                                        value={tglDari}
                                        onChange={TglDariHandler}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                            <Col xs={12} lg={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                    <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        id="tanggal"
                                        label="Tanggal Sampai"
                                        format="DD MMM YYYY"
                                        value={tglSampai}
                                        onChange={TglSampaiHandler}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                            <Col xs={12} md={12} className="text-center">
                                <TextField
                                    id="kodetransaksi"
                                    name="kodetransaksi"
                                    label="Nomor Transaksi (boleh dikosongkan)"
                                    fullWidth
                                    value={cariNotrans}
                                    onChange={handlerNotrans}
                                />
                            </Col>
                            <Col xs={12} md={12} className="text-center"><br />
                                <Button variant="contained" color="primary" onClick={handleOpenRiwayat}>Lihat Riwayat</Button>
                            </Col>
                        </Row>
                    )}
                    {openRiwayat && (
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <div className="btnTable">
                                    <span>
                                        <b>
                                            <Moment format="D MMM YYYY">{tglDari}</Moment>
                                            &nbsp;-&nbsp;
                                            <Moment format="D MMM YYYY">{tglSampai}</Moment>
                                            {cariNotrans && (
                                                <span>, No Trans : {cariNotrans}</span>
                                            )}
                                        </b>
                                    </span>
                                    <span className="ubahTanggal" onClick={handleOpenTgl}> (Ubah Pencarian)</span>
                                </div>
                                <div className={classes.tableWrapper}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                            tipeOutlet={tipeOutlet}
                                        />
                                        <TableBody>
                                            {rows.length == 0 && loadingRow.map(el => (
                                                <TableRow
                                                    key={el.toString()}
                                                    style={{ height: 50 }}
                                                >
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell >
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    {tipeOutlet === "NORMAL" && (
                                                        <TableCell>
                                                            <Skeleton />
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                            {renderContentTabel()}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={7} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page"
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
export default RiwayatTransaksi;