import GQL from '../../GQL';
import moment from 'moment-timezone'
const Member = {
    loadData: (kodeoutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listMember(idOutlet:${kodeoutlet}){
                        nama
                        nohandphone
                        alamat
                        kodezona
                        namazona
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listMember) return (resolve(resp.data.listMember));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
}

export default Member;