import React, { useState, useEffect } from 'react'
import mControl from './Barang.controller'
import { Row, Col } from 'react-bootstrap';
import { Toolbar, Typography, DialogContent, DialogContentText, Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft } from 'react-icons/fa';
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const tglBesok = moment().add(0, 'days')

const Modal = (props) => {
    const kodeoutlet = props.kodeoutlet
    const clientID = props.clientID
    const token = props.token
    const detail = props.detVariant || {}
    const kodebarangori = props.kodebarang
    const [form, setForm] = useState({
        kodebarang: '',
        namabarang: '',
        hargasatuan: 0,
        hargagojek: 0,
        hargagrab: 0,
        hargalain: 0,
        hargalain2: 0,
    })
    useEffect(() => {
        if (detail.kodebarang) {
            setForm({
                kodebarang: detail.kodebarang,
                namabarang: detail.namabarang,
                hargasatuan: detail.hargasatuan,
                hargagojek: detail.hargagojek,
                hargagrab: detail.hargagrab,
                hargalain: detail.hargalain,
                hargalain2: detail.hargalain2,
            })
        }
        return () => {

        }
    }, [detail])
    const handleClose = () => {
        props.setOpen(false)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const handleSimpan = () => {
        if (form.namabarang) {
            mControl.saveVariantBarang(kodeoutlet, clientID, token, kodebarangori, form.kodebarang, form.namabarang, form.hargasatuan, form.hargagojek, form.hargagrab, form.hargalain, form.hargalain2).then(resp => {
                alert("Data Berhasil Disimpan")
                handleClose()
                props.setIsLoadVariant(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        } else {
            alert("Nama wajib isi")
        }
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <FaChevronLeft color="inherit" onClick={handleClose} className="tangan" />
                    <Typography variant="h6">
                        {detail.kodebarang ? ("Update") : ("Add")} Variant</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="DialogFont bodyDialog">
                <DialogContentText className="colorWhite">
                    <Row>
                        <Col xs={12} md={12}>
                            <TextField
                                id="namabarang"
                                name="namabarang"
                                label="Nama Variant"
                                fullWidth
                                value={form.namabarang}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargasatuan"
                                name="hargasatuan"
                                label="Harga Satuan"
                                fullWidth
                                type="number"
                                value={form.hargasatuan}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargagojek"
                                name="hargagojek"
                                label="Harga Gojek"
                                fullWidth
                                type="number"
                                value={form.hargagojek || 0}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargagrab"
                                name="hargagrab"
                                label="Harga Grab"
                                fullWidth
                                type="number"
                                value={form.hargagrab || 0}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargalain"
                                name="hargalain"
                                label="Harga Lain"
                                fullWidth
                                type="number"
                                value={form.hargalain || 0}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargalain2"
                                name="hargalain2"
                                label="Harga Lain 2"
                                fullWidth
                                type="number"
                                value={form.hargalain2 || 0}
                                onChange={handleChange}
                            />
                        </Col>

                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 20 }}>
                        <Col xs={12} className="text-center">
                            <Button variant="contained" color="primary" fullWidth onClick={handleSimpan}>Simpan</Button>
                        </Col>
                    </Row>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;