import React, { useState, useEffect } from 'react'
import mControl from './Barang.controller'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Row, Col } from 'react-bootstrap';
import FileBase64 from 'react-file-base64';
import NoImage from '../../Helper/NoImage'
import { Button } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';


const ModalGambar = (props) => {
    const kodeoutlet = props.kodeoutlet
    const clientID = props.clientID
    const token = props.token
    const detail = props.data || {}
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();
    const [gambar, setGambar] = useState(detail.gambar)
    const [isLoading, setIsLoading] = useState(true)
    const [gambarUpload, setGambarUpload] = useState({})

    useEffect(() => {
        if (detail.isGambar) {
            mControl.loadGambar(kodeoutlet, clientID, token, detail.kodebarang).then(resp => {
                if (resp) {
                    setGambar(`data:image/jpeg;base64,${resp}`)
                } else {
                    setGambar(null)
                }
            }).catch(err => {
                console.log(err);
                setGambar(null)
                setIsLoading(false)
            }).finally(() => setIsLoading(false))
        } else {
            setIsLoading(false)
        }
        return () => {

        }
    }, [])

    const handleClose = () => {
        props.setOpen(false);
    };
    const handleGambar = (file) => {
        if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
            setGambar(file.base64)
            setGambarUpload(file.file)
        } else {
            alert("Format gambar PNG ,JPG atau JPEG")
        }
    }
    const uploadGambar = () => {
        const formData = new FormData();
        formData.append('file', gambarUpload);
        formData.append('kodeoutlet', kodeoutlet);
        formData.append('kodebarang', detail.kodebarang);
        return fetch(`${process.env.REACT_APP_URL}/upload/upload-barang`, {
            method: "POST",
            headers: {
                'X-API-CLIENT-ID': clientID,
                'X-API-TOKEN': token,
            },
            body: formData
        }).then(res => res.json())
            .then(resp => {
                if (resp) {
                    props.setIsReload(true)
                    alert("Gambar berhasil disimpan")
                    handleClose()
                } else {
                    alert("Gagal simpan data")
                }
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        // const image = gambar.replace(/^data:image\/[a-z]+;base64,/, "");
        // mControl.simpanGambarBarang(kodeoutlet, clientID, token, detail.kodebarang, image).then(resp => {
        //     if (resp) {
        //         props.setIsReload(true)
        //         alert("Gambar berhasil disimpan")
        //         handleClose()
        //     } else {
        //         alert("Gagal simpan data")
        //     }
        // }).catch(err => {
        //     if (err.length > 0) {
        //         alert(err[0].message)
        //     } else if (typeof err) {
        //         alert(err.message)
        //     } else {
        //         console.log(err);
        //     }
        // })
    }
    const resetGambar = () => {
        const oke = window.confirm(`Yakin ingin reset gambar ini?`)
        if (oke) {
            mControl.resetGambarBarang(kodeoutlet, clientID, token, detail.kodebarang).then(resp => {
                if (resp) {
                    props.setIsReload(true)
                    alert("Gambar berhasil direset")
                    handleClose()
                } else {
                    alert("Gagal simpan data")
                }
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }
    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Row>
                    <Col xs={12} className="text-center">
                        <h4 id="text-center">{detail.namabarang}</h4>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 3 }}>
                        {isLoading ? (
                            <Skeleton height={250} />
                        ) : (
                                <img className="imgForm mx-auto d-block" alt="print logo" src={gambar || `data:image/jpeg;base64,${NoImage}`} />
                            )}
                    </Col>
                    <Col xs={12} className="text-center">
                        <FileBase64
                            style={{ marginTop: 3 }}
                            multiple={false}
                            onDone={handleGambar.bind(this)} />
                    </Col>
                    <Col xs={6} className="text-center">
                        <Button variant="contained" color="primary" fullWidth style={{ marginTop: 3 }} onClick={uploadGambar} >Upload</Button>
                    </Col>
                    <Col xs={6} className="text-center">
                        <Button variant="contained" color="primary" fullWidth className="btnHapus" style={{ marginTop: 3 }} onClick={resetGambar} >Reset Gambar</Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ModalGambar;



function getModalStyle() {
    const top = 40;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))