/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Table } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import mSubkategori from './SubKategori.controller'


const Export = (props) => {
    const clientID = props.clientID
    const token = props.token
    const kodeoutlet = props.kodeoutlet
    const [isReload, setIsReload] = useState(true)
    const [listData, setListData] = useState([])
    useEffect(() => {
        mSubkategori.loadData(kodeoutlet, clientID, token).then(resp => {
            setListData(resp);
        }).catch(err => {
            console.error(err);
        }).finally(() => setIsReload(false))
        return () => {

        }
    }, [isReload])
    return (
        <Row>
            <Col xs={12}>
                {!isReload && (
                    <ReactHTMLTableToExcel
                        className="buttonExportExcel"
                        table="exportData"
                        filename="Sub Kategori"
                        sheet="Sheet1"
                        buttonText=" Export Excel " />
                )}
                <Table id="exportData" striped bordered hover responsive="lg" style={{ display: "none" }}>
                    <thead>
                        <tr className="colorWhite bgHeaderTabel tabelCenter">
                            <th>Kode Sub Kategori</th>
                            <th>Nama Sub Kategori</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.map((el, i) => {
                            return (
                                <tr key={i}>
                                    <td>{el.kodesubkategori}</td>
                                    <td>{el.namasubkategori}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
export default Export;