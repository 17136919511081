import React,  {useReducer, useContext, useState, useEffect} from 'react'
import { TextField, FormLabel } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AuthContext from '../../context/auth-context'
import mCatatan from './CatatanOrder.controller'

const CatatanForm = (props) => {
    const defaultPage = props.defaultPage * 1 || 1;
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const kodeoutlet = props.kodeoutlet
    const kodebarang = props.kodebarang;
    const { clientID, token } = useContext(AuthContext)
    const [namasubkategori, setNamasubkategori] = useState()
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/catatan/${kodebarang}/${defaultPage}/${defaultrowsPerPage}`)
    }

    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        props.handleChange(key, value)
    }
    useEffect(() => {
        var render = true
        mCatatan.getKategori(kodeoutlet, clientID, token, kodebarang).then(resp => {
           if (render) setNamasubkategori(resp.namasubkategori)
        }).catch(err => {
            console.error(err);
        })
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, kodebarang]);
    return (
        <Row>
            <Col xs={12}>
                <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} style={{
                    color: " rgba(255,117,0,1)", cursor: 'pointer'
                }} />Form Catatan Order {namasubkategori}</h4>
            </Col>
            <Col xs={12} lg={12}>
                {(props.type == "edit" && !props.cacatan) ? (
                    <React.Fragment>
                        <FormLabel>Catatan</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                        <TextField
                            id="catatan"
                            name="catatan"
                            label="catatan"
                            fullWidth
                            required
                            multiline={false}
                            value={props.catatan}
                            onChange={handleChange}
                        />

                    )}

            </Col>
            <Col xs={12} lg={12}>
                {(props.type == "edit" && !props.harga) ? (
                    <React.Fragment>
                        <FormLabel>Cacatan</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                        <TextField
                            id="harga"
                            name="harga"
                            label="harga"
                            fullWidth
                            required
                            multiline={false}
                            value={props.harga}
                            onChange={handleChange}
                            type="number"
                        />

                    )}

            </Col>
        </Row>

    )
}
export default CatatanForm;