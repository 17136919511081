import React, { useEffect, useReducer, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from './context/auth-context';
import { useCookies } from 'react-cookie';
import createGuest from 'cross-domain-storage/guest'
import Login from './Components/Login/Login';
import Home from './Components/Home/Home';
import MyNavbar from './Components/MyNavbar/MyNavbar';
import PilihOutlet from './Components/PilihOutlet/PilihOutlet'
import GQL from './GQL'
import Kategori from './Components/Kategori/Kategori'
import KategoriAdd from './Components/Kategori/KategoriAdd'
import KategoriDetail from './Components/Kategori/KategoriDetail'
import KategoriImport from './Components/Kategori/KategoriImport'
import SubKategori from './Components/SubKategori/SubKategori'
import SubKategoriAdd from './Components/SubKategori/SubKategoriAdd'
import SubKategoriDetail from './Components/SubKategori/SubKategoriDetail'
import SubKategoriImport from './Components/SubKategori/SubKategoriImport'
import Payment from './Components/Payment/Payment'
import PaymentAdd from './Components/Payment/PaymentAdd'
import PaymentDetail from './Components/Payment/PaymentDetail'
import PaymentImport from './Components/Payment/PaymentImport'
import Register from './Components/Register/Register'
import Barang from './Components/Barang/Barang'
import BarangAdd from './Components/Barang/BarangAdd'
import BarangDetail from './Components/Barang/BarangDetail'
import BarangImport from './Components/Barang/BarangImport'
import ManajementOutlet from './Components/ManajemenOutlet/ManajemenOutlet'
import MenuLaporan from './Components/Laporan/Menu/Menu'
import Dashboard from './Components/Laporan/Dashboard/Dashboard'
import GrafikPenjualan from './Components/Laporan/GrafikPenjualan/GrafikPenjualan'
import Laporan from './Components/Laporan/Laporan/Laporan'
import CatatanOrder from './Components/SubKategori/CatatanOrder'
import CatatanOrderAdd from './Components/SubKategori/CatatanOrderAdd'
import CatatanOrderEdit from './Components/SubKategori/CatatanOrderEdit'
import CatatanOrderImport from './Components/SubKategori/CatatanOrderImport'
import RiwayatTransaksi from './Components/RiawayatTransaksi/RiwayatTransaksi'
import RiwayatTransaksiDetail from './Components/RiawayatTransaksi/RiwayatTransaksiDetail'
import DialogInfo from './Dialog/DialogInfo'
import Aktivasi from './Components/Aktivasi'
import SettlementPassti from './Components/SettlementPassti/SettlementPassti'
import HistorySettlementPassti from './Components/HistorySettlementPassti/HistorySettlementPassti'
import Waiter from './Components/Waiter/Waiter'
import WaiterAdd from './Components/Waiter/WaiterAdd'
import WaiterDetail from './Components/Waiter/WaiterDetail'
import WaiterImport from './Components/Waiter/WaiterImport'
import MasterUser from './Master/User/User'
import UserOpname from './Components/Opname/User/UserOpname'
import UserOpnameAdd from './Components/Opname/User/UserOpname.Add'
import UserOpnameDetail from './Components/Opname/User/UserOpnameDetail'
import ResetPassword from './Components/ForgetPassword/ResetPassword'
import ListKasMasuk from './Components/ListKasMasuk/KasMasuk'
import ListKasMasukAdd from './Components/ListKasMasuk/KasMasukAdd'
import ListKasMasukDetail from './Components/ListKasMasuk/KasMasukDetail'
import ListKasMasukImport from './Components/ListKasMasuk/KasMasukImport'
import ListKasKeluar from './Components/ListKasKeluar/KasKeluar'
import ListKasKeluarAdd from './Components/ListKasKeluar/KasKeluarAdd'
import ListKasKeluarDetail from './Components/ListKasKeluar/KasKeluarDetail'
import ListKasKeluarImport from './Components/ListKasKeluar/KasKeluarImport'
import v2Laporan from './Components/Laporan/LaporanV2/Laporan'
import Paket from './Components/Paket/Paket'
import PaketAdd from './Components/Paket/PaketAdd'
import PaketDetail from './Components/Paket/PaketDetail'
import PaketImport from './Components/Paket/PaketImport'
import KasKeluar from './Components/KasKeluar/KasKeluar'
import KasMasuk from './Components/KasMasuk/KasMasuk'
import Pengaturan from './Components/Pengaturan/Pengaturan'
import HakAksesKasir from './Components/Pengaturan/UserKasir/HakAksesKasir'
import Diskon from './Components/Diskon/Diskon'
import ListDiskon from './Components/Diskon/ListDiskon'
import Promo from './Components/Promo/Promo'
import Zona from './Components/Zona/Zona'
import ZonaAdd from './Components/Zona/ZonaAdd'
import ZonaDetail from './Components/Zona/ZonaDetail'
import ZonaImport from './Components/Zona/ZonaImport'
import Topping from './Components/Topping/Topping'
import ToppingImport from './Components/Topping/ListTopping/ToppingImport'
import Member from './Components/Member/Member'
import Poin from './Components/Poin/Poin'
import Poktan from './Components/Poktan/Poktan'

const bazStorage = createGuest(`${process.env.REACT_APP_REPORT}`);
const initialValue = {
  token: null,
  clientID: null,
  userLogin: null,
  isCookieLoad: false,
  isLogin: false
}

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.token,
        clientID: action.clientID,
        userLogin: action.userLogin,
        isLogin: true
      }
    case "LOGOUT":
      return {
        ...state,
        token: null,
        clientID: null
      }
    case "COOKIE":
      return {
        ...state,
        token: action.token,
        clientID: action.clientID,
        userLogin: action.userLogin,
        isCookieLoad: true
      }
    default: throw new Error("Invalid Action");
  }
}

function App(props) {
  const [state, dispatch] = useReducer(loginReducer, initialValue)
  const [cookies, setCookie, removeCookie] = useCookies([`${process.env.REACT_APP_COMPANY}-portal`]);
  const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
  const [dialogtext, setDialogtext] = useState()
  useEffect(() => {
    var render = true;
    if (cookies[`${process.env.REACT_APP_COMPANY}-portal-auth`]) {
      const { clientID, token, userLogin } = cookies[`${process.env.REACT_APP_COMPANY}-portal-auth`] || { clientID: null, token: null, userLogin: null };
      if (render) {
        //console.log(clientID,token);
        dispatch({ type: "COOKIE", clientID, token, userLogin })
      }
    } else {
      dispatch({ type: "COOKIE", clientID, token })

    }
    return () => {
      render = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies[`${process.env.REACT_APP_COMPANY}-portal-auth`]]);

  const login = (username, password) => {
    const userLogin = username
    const query = `
      query{
        loginPortal(email:"${username}",password:"${password}"){
          clientID
          token
        }
      }
    `
    GQL(query).then(res => {
      if (res.errors) throw res.errors;
      const token = res.data.loginPortal.token
      const clientID = res.data.loginPortal.clientID
      // eslint-disable-next-line no-useless-escape
      const dataLogin = {"auth":`{\"isLogin\":true,\"accessToken\":\"${token}\",\"username\":\"${userLogin}\",\"clientID\":\"${clientID}\"}`,"router":"{\"locationBeforeTransitions\":null}","_persist":"{\"version\":-1,\"rehydrated\":true}"}
      bazStorage.set('persist:root', JSON.stringify(dataLogin))
      setCookie(`${process.env.REACT_APP_COMPANY}-portal-auth`, { clientID, token, userLogin }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
      dispatch({ type: "LOGIN", clientID, token, userLogin })
    }).catch(err => {
      if (err[0].message) {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
      }
    })
  }

  const logout = () => {
    const query = `
                mutation {
                    logoutCustomer
                }
            `
    GQL(query, state.clientID, state.token).then(resp => {
      if (resp.errors) throw resp.errors;
      if (resp.data && resp.data.logoutCustomer) {
        const clientID = null, token = null, userLogin = null
        const dataLogin = {"auth":"{\"isLogin\":false,\"accessToken\":null,\"username\":null,\"clientID\":null}","router":"{\"locationBeforeTransitions\":null}","_persist":"{\"version\":-1,\"rehydrated\":true}"}
        bazStorage.set('persist:root', JSON.stringify(dataLogin))
        setCookie(`${process.env.REACT_APP_COMPANY}-portal-auth`, { clientID, token, userLogin }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
        removeCookie(`${process.env.REACT_APP_COMPANY}-portal-auth`)
        dispatch({ type: "LOGOUT" })
      } else {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>Gagal Logout<p/>`)
      }
    }).catch(err => {
      console.log(err)
      if (err[0].message) {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
      }
    })
  }
  const { clientID, token, isCookieLoad, isLogin, userLogin } = state;


  // console.log(clientID, token,isCookieLoad)
  return (
    <Router>
      <React.Fragment>
        <AuthContext.Provider
          value={{
            token,
            clientID,
            login,
            logout,
            userLogin
          }}
        >
          {/* <Online> */}
          <MyNavbar onLogout={logout} isLogin={(token && clientID)} />
          <Switch>
            <Route path="/aktivasi/:salt" component={Aktivasi} />
            <Route path="/reset/:email/:salt" component={ResetPassword} />
            {(clientID && token && isCookieLoad) &&
              <React.Fragment>
                <Route path="/" exact component={Home} />
                <Route path="/pilih-outlet" exact component={PilihOutlet} />
                <Route path="/manajemenoutlet/:kodeoutlet" exact component={ManajementOutlet} />
                {/* kategori */}
                <Route path="/manajemenoutlet/:kodeoutlet/kategori/:page/:rowsPerPage" exact component={Kategori} />
                <Route path="/manajemenoutlet/:kodeoutlet/kategori-add" exact component={KategoriAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/kategori-detail/:kodekategori/:page/:rowsPerPage" exact component={KategoriDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/kategori/import" exact component={KategoriImport} />
                {/* Sub kategori */}
                <Route path="/manajemenoutlet/:kodeoutlet/subkategori/:page/:rowsPerPage" exact component={SubKategori} />
                <Route path="/manajemenoutlet/:kodeoutlet/subkategori-add" exact component={SubKategoriAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/subkategori-detail/:kodesubkategori/:page/:rowsPerPage" exact component={SubKategoriDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/subkategori/import" exact component={SubKategoriImport}></Route>
                {/* catatan Order */}
                <Route path="/manajemenoutlet/:kodeoutlet/catatan/:kodebarang/:page/:rowsPerPage" exact component={CatatanOrder} />
                <Route path="/manajemenoutlet/:kodeoutlet/catatan-add/:kodebarang/:page/:rowsPerPage" exact component={CatatanOrderAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/catatan-detail/:kodecatatan/:kodebarang/:page/:rowsPerPage" exact component={CatatanOrderEdit} />
                <Route path="/manajemenoutlet/:kodeoutlet/subkategori/importcatatanorder" exact component={CatatanOrderImport}></Route>
                {/* Payment */}
                <Route path="/manajemenoutlet/:kodeoutlet/payment/:page/:rowsPerPage" exact component={Payment} />
                <Route path="/manajemenoutlet/:kodeoutlet/payment-add" exact component={PaymentAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/payment-detail/:kodepayment/:page/:rowsPerPage" exact component={PaymentDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/payment/import" exact component={PaymentImport} />
                {/* Barang */}
                <Route path="/manajemenoutlet/:kodeoutlet/barang/:page/:rowsPerPage" exact component={Barang} />
                <Route path="/manajemenoutlet/:kodeoutlet/barang-add" exact component={BarangAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/barang-detail/:kodebarang/:page/:rowsPerPage" exact component={BarangDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/barang/import" exact component={BarangImport} />

                {/* Paket */}
                <Route path="/manajemenoutlet/:kodeoutlet/paket/:page/:rowsPerPage" exact component={Paket} />
                <Route path="/manajemenoutlet/:kodeoutlet/paket-add" exact component={PaketAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/paket-detail/:kodebarang/:page/:rowsPerPage" exact component={PaketDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/paket/import" exact component={PaketImport} />
                {/* 
                <Route path="/manajemenoutlet/:kodeoutlet/barang/import" exact component={BarangImport} /> */}

                {/*List  Kas Masuk */}
                <Route path="/manajemenoutlet/:kodeoutlet/listkasmasuk/:page/:rowsPerPage" exact component={ListKasMasuk} />
                <Route path="/manajemenoutlet/:kodeoutlet/listkasmasuk-add" exact component={ListKasMasukAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/listkasmasuk-detail/:kodebiaya/:page/:rowsPerPage" exact component={ListKasMasukDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/listkasmasuk/import" exact component={ListKasMasukImport} />

                {/*List  Kas Keluar */}
                <Route path="/manajemenoutlet/:kodeoutlet/listkaskeluar/:page/:rowsPerPage" exact component={ListKasKeluar} />
                <Route path="/manajemenoutlet/:kodeoutlet/listkaskeluar-add" exact component={ListKasKeluarAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/listkaskeluar-detail/:kodebiaya/:page/:rowsPerPage" exact component={ListKasKeluarDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/listkaskeluar/import" exact component={ListKasKeluarImport} />

                {/* Laporan */}
                <Route path="/laporan/menu" exact component={MenuLaporan} />
                <Route path="/laporan/dashboard" exact component={Dashboard} />
                <Route path="/laporan/grafik-penjualan" exact component={GrafikPenjualan} />
                <Route path="/laporan/laporan" exact component={Laporan} />
                <Route path="/laporan/laporanV2" exact component={v2Laporan} />

                {/* Riawayat Transaksi */}
                <Route path="/manajemenoutlet/:kodeoutlet/transhistory/:page/:rowsPerPage" exact component={RiwayatTransaksi} />
                <Route path="/manajemenoutlet/:kodeoutlet/transhistory-detail/:kodetrans/:page/:rowsPerPage" exact component={RiwayatTransaksiDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/transhistory-detail/:kodetrans/:page/:rowsPerPage/:rpt" exact component={RiwayatTransaksiDetail} />

                {/*Laporan Kas Keluar*/}
                <Route path="/manajemenoutlet/:kodeoutlet/kaskeluar/:page/:rowsPerPage" exact component={KasKeluar} />

                {/*Laporan Kas masuk*/}
                <Route path="/manajemenoutlet/:kodeoutlet/kasmasuk/:page/:rowsPerPage" exact component={KasMasuk} />

                {/*Pengaturan*/}
                <Route path="/manajemenoutlet/:kodeoutlet/pengaturan" exact component={Pengaturan} />
                <Route path="/manajemenoutlet/:kodeoutlet/hakakseskasir" exact component={HakAksesKasir} />

                {/*Diskon*/}
                <Route path="/manajemenoutlet/:kodeoutlet/diskon/:kodebarang" exact component={Diskon} />
                <Route path="/manajemenoutlet/:kodeoutlet/list-diskon/:page/:rowsPerPage" exact component={ListDiskon} />

                {/*Promo*/}
                <Route path="/manajemenoutlet/:kodeoutlet/promo/:page/:rowsPerPage" exact component={Promo} />
                {/* Settlement Passti */}
                <Route path="/manajemenoutlet/:kodeoutlet/settlementpassti/:page/:rowsPerPage" exact component={SettlementPassti} />
                {isLogin && <Redirect from="/login/" to="/" exact />}
                <Route path="/manajemenoutlet/:kodeoutlet/historysettlementpassti/:page/:rowsPerPage" exact component={HistorySettlementPassti} />
                {isLogin && <Redirect from="/login/" to="/" exact />}

                {/* Waiter */}
                <Route path="/manajemenoutlet/:kodeoutlet/waiter/:page/:rowsPerPage" exact component={Waiter} />
                <Route path="/manajemenoutlet/:kodeoutlet/waiter-add" exact component={WaiterAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/waiter-detail/:kodewaiter/:page/:rowsPerPage" exact component={WaiterDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/waiter/import" exact component={WaiterImport} />

                {/* User Opname */}
                <Route path="/manajemenoutlet/:kodeoutlet/opname-user/:page/:rowsPerPage" exact component={UserOpname} />
                <Route path="/manajemenoutlet/:kodeoutlet/opname-user-add" exact component={UserOpnameAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/opname-user-detail/:username/:page/:rowsPerPage" exact component={UserOpnameDetail} />

                {/* zona */}
                <Route path="/manajemenoutlet/:kodeoutlet/zona/:page/:rowsPerPage" exact component={Zona} />
                <Route path="/manajemenoutlet/:kodeoutlet/zona-add" exact component={ZonaAdd} />
                <Route path="/manajemenoutlet/:kodeoutlet/zona-detail/:kodezona/:page/:rowsPerPage" exact component={ZonaDetail} />
                <Route path="/manajemenoutlet/:kodeoutlet/zona/import" exact component={ZonaImport} />

                {/*Topping*/}
                <Route path="/manajemenoutlet/:kodeoutlet/topping" exact component={Topping} />
                <Route path="/manajemenoutlet/:kodeoutlet/topping/import" exact component={ToppingImport} />
                {/*Member */}
                <Route path="/manajemenoutlet/:kodeoutlet/member/:page/:rowsPerPage" exact component={Member} />
                {/*Poin*/}
                <Route path="/manajemenoutlet/:kodeoutlet/poin/:page/:rowsPerPage" exact component={Poin} />
                {/*Poktan*/}
                <Route path="/manajemenoutlet/:kodeoutlet/member-messaging/:page/:rowsPerPage" exact component={Poktan} />
                {/* Master Data */}
                <Route path="/user" exact component={MasterUser} />
              </React.Fragment>

            }
            {((!clientID || !token) && isCookieLoad) &&
              (
                <React.Fragment>
                  <Route path="/login/" component={Login} />
                  <Route path="/register/" component={Register} />
                  <Redirect to="/login/" />

                </React.Fragment>
              )
            }

          </Switch>
          {/* </Online> */}
        </AuthContext.Provider>
        <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
      </React.Fragment>
      {/* <Offline>
        <div style={{marginTop:'30px'}}>
          <p className="text-center">Saat ini anda sedang <b>offline</b><br/> periksa koneksi Internet anda</p>
        </div>
      </Offline> */}
    </Router>
  );
}

export default App;
