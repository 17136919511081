import GQL from '../../../GQL';
const TaxService = {
    loadTaxService: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    getOutlet(idOutlet:${idOutlet}){
                        tax
                        service
                        taxNormal
                        taxGojek
                        taxGrab
                        taxLain
                        taxLain2
                        serviceNormal
                        serviceGojek
                        serviceGrab
                        serviceLain
                        serviceLain2
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.getOutlet) return (resolve(resp.data.getOutlet));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    updateTaxService: (idOutlet, clientID, token, tax, service,form) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                updateTaxService(idOutlet:${idOutlet},
                    input: {
                    tax:${tax}, service:${service}, 
                    taxNormal:${form.taxNormal || 0},
                    serviceNormal:${form.serviceNormal || 0 },
                    
                    taxGojek:${form.taxGojek || 0},
                    serviceGojek:${form.serviceGojek || 0 },
                    
                    taxGrab:${form.taxGrab || 0},
                    serviceGrab:${form.serviceGrab || 0 },
                    
                    taxLain:${form.taxLain || 0},
                    serviceLain:${form.serviceLain || 0 },
                    
                    taxLain2:${form.taxLain2 || 0},
                    serviceLain2:${form.serviceLain2 || 0 }})
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.updateTaxService) return (resolve(resp.data.updateTaxService));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },

}

export default TaxService;