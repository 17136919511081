import React, { useState, useEffect } from 'react'
import mControl from './ListTopping.Controler'
import { Row, Col } from 'react-bootstrap';
import { Toolbar, Typography, DialogContent, DialogContentText, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft } from 'react-icons/fa';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Modal = (props) => {
    const kodeoutlet = props.kodeoutlet
    const clientID = props.clientID
    const token = props.token
    const detail = props.detTopping || {}
    const [listKategori, setListKategori] = useState([])
    const [listSubKategori, setListSubKategori] = useState([])
    const [form, setForm] = useState({
        kodetopping: '',
        namatopping: '',
        hargasatuan: 0,
        hargagojek: 0,
        hargagrab: 0,
        hargalain: 0,
        hargalain2: 0,
        kodekategori: '',
        kodesubkategori: ''

    })
    useEffect(() => {
        mControl.loadKatNSub(kodeoutlet, clientID, token).then(resp => {
            setListKategori(resp.kategori)
            setListSubKategori(resp.subkategori)
        }).catch(err => {
            console.log(err);
        })
        return () => {

        }
    }, [kodeoutlet, clientID, token])
    useEffect(() => {
        if (detail.kodetopping) {
            setForm({
                kodetopping: detail.kodetopping,
                namatopping: detail.namatopping,
                hargasatuan: detail.hargasatuan,
                hargagojek: detail.hargagojek,
                hargagrab: detail.hargagrab,
                hargalain: detail.hargalain,
                hargalain2: detail.hargalain2,
                kodekategori: detail.kodekategori || '',
                kodesubkategori: detail.kodesubkategori || ''
            })
        }
        return () => {

        }
    }, [detail])
    const handleClose = () => {
        setForm({
            kodetopping: '',
            namatopping: '',
            hargasatuan: 0,
            hargagojek: 0,
            hargagrab: 0,
            hargalain: 0,
            hargalain2: 0,
            kodekategori: '',
            kodesubkategori: ''
        })
        props.setOpen(false)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const handleSimpan = () => {
        if (form.namatopping) {
            mControl.saveTopping(kodeoutlet, clientID, token, form.kodetopping, form.namatopping, form.hargasatuan, form.hargagojek, form.hargagrab, form.hargalain, form.hargalain2, form.kodekategori, form.kodesubkategori).then(resp => {
                alert("Data Berhasil Disimpan")
                handleClose()
                props.setIsRefresh(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        } else {
            alert("Nama wajib isi")
        }
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <FaChevronLeft color="inherit" onClick={handleClose} className="tangan" />
                    <Typography variant="h6">
                        {detail.kodetopping ? ("Update") : ("Add")} Topping</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="DialogFont bodyDialog">
                <DialogContentText className="colorWhite">
                    <Row>
                        <Col xs={12} md={12}>
                            <TextField
                                id="namatopping"
                                name="namatopping"
                                label="Nama Topping"
                                fullWidth
                                value={form.namatopping}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl fullWidth={true}>
                                <InputLabel>Kategori</InputLabel>
                                <Select
                                    name="kodekategori"
                                    id="kodekategori"
                                    required
                                    value={form.kodekategori}
                                    onChange={handleChange}
                                >
                                    {listKategori.map(el => (
                                        <MenuItem key={el.kodekategori} value={el.kodekategori}>{el.namakategori}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormControl fullWidth={true}>
                                <InputLabel>Sub Kategori</InputLabel>
                                <Select
                                    name="kodesubkategori"
                                    id="kodesubkategori"
                                    required
                                    value={form.kodesubkategori}
                                    onChange={handleChange}
                                >
                                    {listSubKategori.map(el => (
                                        <MenuItem key={el.kodesubkategori} value={el.kodesubkategori}>{el.namasubkategori}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargasatuan"
                                name="hargasatuan"
                                label="Harga Satuan"
                                fullWidth
                                type="number"
                                value={form.hargasatuan}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargagojek"
                                name="hargagojek"
                                label="Harga Gojek"
                                fullWidth
                                type="number"
                                value={form.hargagojek || 0}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargagrab"
                                name="hargagrab"
                                label="Harga Grab"
                                fullWidth
                                type="number"
                                value={form.hargagrab || 0}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargalain"
                                name="hargalain"
                                label="Harga Lain"
                                fullWidth
                                type="number"
                                value={form.hargalain || 0}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <TextField
                                id="hargalain2"
                                name="hargalain2"
                                label="Harga Lain 2"
                                fullWidth
                                type="number"
                                value={form.hargalain2 || 0}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 20 }}>
                        <Col xs={12} className="text-center">
                            <Button variant="contained" color="primary" fullWidth onClick={handleSimpan}>Simpan</Button>
                        </Col>
                    </Row>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;