import GQL from '../../GQL';
const catatanOrder = {

    getKategori: (idOutlet, clientID, token, kodesubkategori) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    subKategoriApp(idOutlet:${idOutlet},kodesubkategori:"${kodesubkategori}"){
                        kodesubkategori
                        namasubkategori
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.subKategoriApp) return (resolve(resp.data.subKategoriApp))
            }).catch(err => reject(err))
        })
    },
    loadData: (idOutlet, clientID, token, kodesubkategori) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listCatatanOrder(idOutlet:${idOutlet}, kodesubkategori:"${kodesubkategori}"){
                        kodecatatan
                        catatan
                        harga
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                var id = 1;
                const listCatatan = [];
                for (var i = 0, j = resp.data.listCatatanOrder.length; i < j; i++) {
                    id = i + 1;
                    const catatan = resp.data.listCatatanOrder[i].catatan;
                    const harga = resp.data.listCatatanOrder[i].harga;
                    const kodecatatan = resp.data.listCatatanOrder[i].kodecatatan;
                    const data = {
                        id,
                        catatan,
                        harga,
                        kodecatatan
                    }
                    listCatatan.push(data);
                }
              //  console.log(listCatatan)
                if(resp.data && resp.data.listCatatanOrder) return(resolve(listCatatan));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    search: (idOutlet, clientID, token, kodesubkategori, search) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listCatatanOrder(idOutlet:${idOutlet}, kodesubkategori:"${kodesubkategori}"search:"${search}"){
                        kodecatatan
                        catatan
                        harga
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                var id = 1;
                const listCatatan = [];
                for (var i = 0, j = resp.data.listCatatanOrder.length; i < j; i++) {
                    id = i + 1;
                    const catatan = resp.data.listCatatanOrder[i].catatan;
                    const harga = resp.data.listCatatanOrder[i].harga;
                    const kodecatatan = resp.data.listCatatanOrder[i].kodecatatan;
                    const data = {
                        id,
                        catatan,
                        harga,
                        kodecatatan
                    }
                    listCatatan.push(data);
                }
                if (resp.data && resp.data.listCatatanOrder) return (resolve(listCatatan));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    simpanCatatan: (idOutlet, kodesubkategori, catatan, harga, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanCatatanOrder(idOutlet:${idOutlet},input:{catatan:"${catatan}", harga:${harga}, kodeSubKategori: "${kodesubkategori}"})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanCatatanOrder) {
                    return (resolve(resp.data.simpanCatatanOrder));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    UpdateCatatan: (idOutlet, kodesubkategori, kodecatatan, catatan, harga, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanCatatanOrder(idOutlet:${idOutlet},input:{catatan:"${catatan}", harga:${harga}, kodeSubKategori: "${kodesubkategori}", kodecatatan: "${kodecatatan}"})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanCatatanOrder) {
                    return (resolve(resp.data.simpanCatatanOrder));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    getCatatan: (idOutlet, kodecatatan, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    getCatatan(idOutlet:${idOutlet},kodecatatan:"${kodecatatan}"){
                        kodecatatan
                        catatan
                        harga
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.getCatatan) return (resolve(resp.data.getCatatan))
            }).catch(err => reject(err))
        })
    },
    HapusCatatan: (idOutlet, kodecatatan, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusCatatanOrder(idOutlet:${idOutlet},kodecatatan:"${kodecatatan}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusCatatanOrder) {
                    return (resolve(resp.data.hapusCatatanOrder));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    importCatatanOrder:(idOutlet,listCatatanOrder,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(listCatatanOrder.length > 0){
                let input = '[';
                for(var i=0,j=listCatatanOrder.length;i<j;i++){
                    if(i>0) input += `,`;
                    var _kodecatatan = listCatatanOrder[i].kodecatatan ? `,kodecatatan:"${listCatatanOrder[i].kodecatatan}"` : '';
                    input += `{catatan:"${listCatatanOrder[i].catatan}"${_kodecatatan}, harga:${listCatatanOrder[i].harga}, kodeSubKategori:"${listCatatanOrder[i].kodesubkategori}"}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiCatatanOrder(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.simpanMultiCatatanOrder != undefined ){
                        return (resolve(resp.data.simpanMultiCatatanOrder));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    }
}

export default catatanOrder;