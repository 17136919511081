import {makeStyles} from '@material-ui/core';
if (process.env.REACT_APP_COMPANY == "MonkeyPOS") {
    var bgColor = "rgb(0, 117, 16)"
} else if (process.env.REACT_APP_COMPANY == "Ipos") {
    var bgColor = "#b43f3a"
}
const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: `${bgColor}`,
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        padding: '0 15px',
    },
    title: {
        flex: '0 0 auto',
    },
}));

export default useToolbarStyles;