import React, { useState, useEffect } from 'react'
import mControl from './ToppingGroup.Controler'
import { Row, Col } from 'react-bootstrap';
import { Toolbar, Typography, DialogContent, DialogContentText, Button, TextField, Paper, InputBase, Checkbox, Table, TableBody, TableSortLabel, TableCell, TableHead, TablePagination, TableRow, Select, MenuItem, FormControl, InputLabel  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import useStyles from '../../../css/useStylesTabel'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from "prop-types";
import CheckIcon from '@material-ui/icons/Check';
import numberWithCommas from '../../../Helper/numberWithCommas'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: "kodetopping", label: "Kode Topping" },
    { id: "namatopping", label: "Nama Topping" },
    { id: "hargasatuan", label: "Harga Satuan" },
    { id: "hargagojek", label: "Harga Gojek" },
    { id: "hargagrab", label: "Harga Grab" },
    { id: "hargalain", label: "Harga Lain" },


];

const Modal = (props) => {
    const classes = useStyles();
    const kodeoutlet = props.kodeoutlet
    const clientID = props.clientID
    const token = props.token
    const detail = props.detTopping || {}
    const listToppingOri = props.listToppingOri
    const [form, setForm] = useState({ kodegroup: '', namagroup: '', })
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("idcust");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState([])
    const [search, setSearch] = useState("")
    const [noData, setNoData] = useState(false)
    const [isReload, setIsReload] = useState(true)
    const [listToppingIn, setListToppingIn] = useState([])
    const [isInsert, setIsInsert] = useState(true)
    const [isRefresh, setIsRefresh] = useState(true)
    const [listSubKategori, setListSubKategori] = useState([])
    useEffect(() => {
        if (detail.kodegroup) {
            setIsInsert(false)
            setForm({
                kodegroup: detail.kodegroup,
                namagroup: detail.namagroup,
            })
            mControl.loadDetail(kodeoutlet, clientID, token, detail.kodegroup).then(resp => {
                setListToppingIn(resp.topping);
                const newTopping = []
                listToppingOri.map(el => {
                    const ada = resp.topping.find(data => data.kodetopping === el.kodetopping)
                    if (ada) {
                        newTopping.push({
                            ...el,
                            selected : true
                        })
                    }else{
                        newTopping.push(el)
                    }
                })
                setRows(newTopping);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        } else {
            setIsInsert(true)
            setRows(listToppingOri);
            setIsRefresh(false)
        }
        return () => {

        }
    }, [clientID, detail, kodeoutlet, listToppingOri, token, isRefresh])
    useEffect(() => {
        mControl.loadSubKat(kodeoutlet, clientID, token).then(resp => {
            setListSubKategori(resp)
        }).catch(err => {
            console.log(err);
        })
        return () => {

        }
    }, [kodeoutlet, clientID, token])
    const handleClose = () => {
        props.setOpen(false)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const handleSimpan = () => {
        if (form.namagroup) {
            const datatopping = rows.filter(el => { return el.selected })
            mControl.saveToppingGroup(kodeoutlet, clientID, token, form.kodegroup, form.namagroup, datatopping, form.kodesubkategori).then(resp => {
                alert("Data Berhasil Disimpan")
                handleClose()
                props.setIsRefresh(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        } else {
            alert("Nama wajib isi")
        }
    }
    const searchHandler = (val) => {
        setSearch(val.target.value);
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            formSubmitHandler()
        }
    }
    const formSubmitHandler = (event) => {
        setNoData(false);
        if (search) {
            const newData = listToppingOri.filter(el => {
                return el.namatopping.toUpperCase().match(search.toUpperCase()) || el.kodetopping.toUpperCase() === search.toUpperCase();
            })
            setRows(newData)
        } else {
            setRows(listToppingOri)
            setIsReload(true);
        }
    }
    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: ' 10px' }}>
                        <CheckIcon />
                    </TableCell>
                    {headRows.map(row => (
                        <TableCell key={row.id}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired
    };
    const loadingRow = [0, 1, 2, 3, 4];
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const handleClick = (data) => {
        const newDataUpdate = []
        rows.map(el => {
            if (el.kodetopping === data.kodetopping) {

                newDataUpdate.push({
                    ...el,
                    selected: el.selected ? false : true
                })
            } else {
                newDataUpdate.push({
                    ...el
                })
            }
        })
        setRows(newDataUpdate)
    }
    const handleHapus = (data) => {
        const oke = window.confirm(`Yakin ingin hapus topping ${data.namatopping} dari group ini?`)
        if (oke) {
            mControl.deleteItemGroupTopping(kodeoutlet, clientID, token, form.kodegroup, data.kodetopping).then(resp => {
                if (resp) {
                    alert("Berhasil dihapus")
                    setIsRefresh(true)
                } else {
                    alert("Gagal hapus data")
                }
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <FaChevronLeft color="inherit" onClick={handleClose} className="tangan" />
                    <Typography variant="h6">
                        {detail.kodegroup ? ("Update") : ("Add")} Group Topping</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="DialogFont bodyDialog">
                <DialogContentText className="colorWhite">
                    <Row>
                        <Col xs={12} md={12}>
                            <TextField
                                id="namagroup"
                                name="namagroup"
                                label="Nama Topping"
                                fullWidth
                                value={form.namagroup}
                                onChange={handleChange}
                            />
                            <FormControl fullWidth={true}>
                                <InputLabel>Sub Kategori</InputLabel>
                                <Select
                                    name="kodesubkategori"
                                    id="kodesubkategori"
                                    required
                                    value={form.kodesubkategori}
                                    onChange={handleChange}
                                >
                                    {listSubKategori.map(el => (
                                        <MenuItem key={el.kodesubkategori} value={el.kodesubkategori}>{el.namasubkategori}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <h4 className="text-center">List Topping</h4>
                        </Col>
                    </Row>
                    {isInsert ? (
                        <Paper className={classes.paper}>
                            <div className="btnTable">
                                <Row style={{display:"none"}}>
                                    <Col>
                                        <div className={classes.search}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase onChange={searchHandler} onKeyPress={handleKeyPress}
                                                placeholder="Search…"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                                inputProps={{ 'aria-label': 'search' }}
                                            />
                                            <Button style={{ float: 'right' }} onClick={formSubmitHandler} variant="contained" color="primary">Cari</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className={classes.tableWrapper}>
                                {detail.kodegroup && (
                                    <Col xs={12}>
                                        <Button variant="contained" onClick={() => setIsInsert(false)} style={{ backgroundColor: 'red', color: '#fff' }}><CloseIcon />Batal</Button>
                                    </Col>
                                )}
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={"small"}
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        rowCount={rows.length}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {rows.length === 0 && !noData && isReload && loadingRow.map(el => (
                                            <TableRow
                                                key={el.toString()}
                                                style={{ height: 50 }}
                                            >
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell component="th" scope="row" >
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {stableSort(rows, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                    >
                                                        <TableCell>
                                                            <span className="checkBoxTabel">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={row.selected || false}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    onClick={handleClick.bind(this, row)}

                                                                />
                                                            </span>
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.kodetopping}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.namatopping}
                                                        </TableCell>
                                                        <TableCell >
                                                            {numberWithCommas(row.hargasatuan)}
                                                        </TableCell>
                                                        <TableCell >
                                                            {numberWithCommas(row.hargagojek)}
                                                        </TableCell>
                                                        <TableCell >
                                                            {numberWithCommas(row.hargagrab)}
                                                        </TableCell>
                                                        <TableCell >
                                                            {numberWithCommas(row.hargalain)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "previous page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "next page"
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    ) : (
                            <Row>
                                <Col xs={12}>
                                    <Button variant="contained" color="primary" onClick={() => setIsInsert(true)} style={{ margin: '5px 0px' }}><AddCircleOutlineIcon />Add</Button>
                                </Col>
                                <Col xs={12}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={"small"}
                                    >
                                        <TableHead>
                                            <TableRow className="colorWhite bgHeaderTabel">
                                                <TableCell>
                                                    Kode Topping
                                                </TableCell>
                                                <TableCell>
                                                    Nama Topping
                                                </TableCell>
                                                <TableCell>
                                                    Harga Satuan
                                                </TableCell>
                                                <TableCell>
                                                    Harga Gojek
                                                </TableCell>
                                                <TableCell>
                                                    Harga Grab
                                                </TableCell>
                                                <TableCell>
                                                    Harga Lain
                                                </TableCell>
                                                <TableCell>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listToppingIn.map((row, index) => {
                                                return (
                                                    <TableRow hover key={index}>
                                                        <TableCell >
                                                            {row.kodetopping}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.namatopping}
                                                        </TableCell>
                                                        <TableCell >
                                                            {numberWithCommas(row.hargasatuan)}
                                                        </TableCell>
                                                        <TableCell >
                                                            {numberWithCommas(row.hargagojek)}
                                                        </TableCell>
                                                        <TableCell >
                                                            {numberWithCommas(row.hargagrab)}
                                                        </TableCell>
                                                        <TableCell >
                                                            {numberWithCommas(row.hargalain)}
                                                        </TableCell>
                                                        <TableCell>
                                                            <DeleteIcon className="pointer" onClick={handleHapus.bind(this, row)} />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </Col>
                            </Row>
                        )}
                    <Row style={{ marginTop: 20, marginBottom: 20 }}>
                        <Col xs={12} className="text-center">
                            <Button variant="contained" color="primary" fullWidth onClick={handleSimpan}>Simpan</Button>
                        </Col>
                    </Row>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;