/* eslint-disable valid-typeof */
/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TextField, Button } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import PublishIcon from '@material-ui/icons/Publish';
import { ExcelRenderer } from 'react-excel-renderer';
import mKategori from './Kategori.controller'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util'
import validate from 'validate.js'
import DialogInfo from '../../Dialog/DialogInfo'
import CircularProgress from '@material-ui/core/CircularProgress';

const KategoriImport = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = props.defaultPage * 1 || 1;
    const { clientID, token } = useContext(AuthContext);
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/kategori/${defaultPage}/${defaultrowsPerPage}`)
    }


    const importHandler = (val) => {
        //console.log(val.target.files);
        var fileObj = val.target.files[0];
        // console.log( val.target.files)
        ExcelRenderer(fileObj, (err, resp) => {
            if (resp) {
                const listKategori = [];
                //VALIDASI Header
                const [kodekategori, namakategori] = resp.rows[0];
                if (kodekategori.toUpperCase() !== 'KODE KATEGORI' || namakategori.toUpperCase() !== 'NAMA KATEGORI') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Format Data Di Excel Tidak Sesuai<p/>`)
                    return
                }
                for (var i = 1, j = resp.rows.length; i < j; i++) {
                    if (resp.rows[i].length > 0) {
                        const [kodekategori, namakategori] = resp.rows[i];
                        //VALIDASI
                        const constraint = {
                            namakategori: {
                                length: {
                                    minimum: 3,
                                    message: "Nama Kategori minimal terdiri dari 3 huruf"
                                },
                            }
                        }
                        const validator = validate({ namakategori }, constraint, { fullMessages: false });
                        if (validator !== undefined) {
                            const keys = Object.keys(validator);
                            var errorMessage = "";
                            for (const key of keys) {
                                errorMessage += validator[key] + "\n";
                            }
                            setOpenConfirmInfo(true)
                            setDialogtext(`<p>${errorMessage}<p/>`)
                            return
                        }
                        if (namakategori) {
                            if ((kodekategori || '').toUpperCase() !== 'KODE KATEGORI' || namakategori.toUpperCase() !== 'NAMA KATEGORI') {
                                listKategori.push({ kodekategori, namakategori })
                            }
                        }
                    }
                }
                setIsLoading(true);
                mKategori.importKategori(kodeoutlet, listKategori, clientID, token).then(resp => {
                    setIsLoading(false);
                    if (resp) {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Data Berhasil di Import<p/>`)
                        setDialogUrl(`/manajemenoutlet/${kodeoutlet}/kategori/1/5`)
                    } else {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Data Gagal di import<p/>`)
                    }
                }).catch(err => {
                    if (isArray(err) && err.length > 0) {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Gagal Simpan :  ${err[0].message}<p/>`)
                    } else if (typeof err == 'error') {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>${err.message}<p/>`)
                    } else {
                        console.log(err);
                    }
                })
            }
            else {
                console.log(err);
            }
        });
    }
    return (
        <Container>
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12}>
                    <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom">
                        <FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />
                        Import Kategori
                    </h4>
                </Col>
                <Col xs={12} style={{ marginTop: 20 }}>
                    <TextField
                        id="importFile"
                        label="Gambar"
                        type="file"
                        onChange={importHandler.bind(this)}
                        accept=".csv"
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="importFile">
                        <Button variant="contained" color="primary" component="span"><PublishIcon /> Pilih file</Button>
                        {isLoading && (
                            <span><CircularProgress /> Mohon tunggu sedang import data...</span>
                        )}
                    </label>
                    <p>
                        File harus dalam format XLSX (namafile.xlsx).<br />
                        Contoh format sebagai berikut :
                    </p>
                    <ul>
                        <li>Untuk contoh format, Anda bisa menggunakan <a className="hrefColor" href="/Import/ImportKategori.xlsx" download>Template ini</a></li>
                        <li>Setiap kode kategori tidak boleh sama</li>
                        <li>Kode kategori tidak boleh sama dan yang berawal dari angka 0(NOL) ditulis di excel dengan format tanda petik 1 didepan kode. Contoh : '0001</li>
                    </ul>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr className="tabelWhite">
                                <th>Kode Kategori</th>
                                <th>Nama Kategori</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="tabelWhite">
                                <td>0001</td>
                                <td>Makanan</td>
                            </tr>
                            <tr className="tabelWhite">
                                <td>0002</td>
                                <td>Minuman</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    )
}

export default KategoriImport;