import GQL from '../../GQL';
const zona = {
    loadData: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listZona(idOutlet:${idOutlet}){
                        kodezona
                        namazona
                        tarifongkir
                        isHide
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listZona) return (resolve(resp.data.listZona));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    search: (idOutlet, search, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listZona(idOutlet:${idOutlet},search:"${search}"){
                        kodezona
                        namazona
                        tarifongkir
                        isHide
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listZona) return (resolve(resp.data.listZona));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadDetail: (idOutlet, kodezona, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    zonaDetail(idOutlet:${idOutlet},kodezona:"${kodezona}"){
                        kodezona
                        namazona
                        tarifongkir
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.zonaDetail) return (resolve(resp.data.zonaDetail))
            }).catch(err => reject(err))
        })
    },
    simpanZona: (idOutlet, namazona, tarifongkir, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanZona(idOutlet:${idOutlet},input:{namazona:"${namazona}", tarifongkir:${tarifongkir}})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanZona) {
                    return (resolve(resp.data.simpanZona));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    updateZona: (idOutlet, kodezona, namazona, tarifongkir, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanZona(idOutlet:${idOutlet},input:{namazona:"${namazona}",kodezona:"${kodezona}", , tarifongkir:${tarifongkir}})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanZona != undefined) {
                    return (resolve(resp.data.simpanZona));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    deleteZona: (idOutlet, kodezona, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusZona(idOutlet:${idOutlet},kodezona:"${kodezona}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusZona != undefined) {
                    return (resolve(resp.data.hapusZona));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    importZona: (idOutlet, listZona, clientID, token) => {
        return new Promise((resolve, reject) => {
            if (listZona.length > 0) {
                let input = '[';
                for (var i = 0, j = listZona.length; i < j; i++) {
                    if (i > 0) input += `,`;
                    var _kodezona = listZona[i].kodezona ? `,kodezona:"${listZona[i].kodezona}"` : '';
                    input += `{namazona:"${listZona[i].namazona}",tarifongkir:${listZona[i].tarifongkir} ${_kodezona}}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiZona(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.simpanMultiZona != undefined) {
                        return (resolve(resp.data.simpanMultiZona));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                })
            } else {
                return (reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    deleteMultiZona: (idOutlet, kodezona, clientID, token) => {
        return new Promise((resolve, reject) => {
            if (kodezona.length > 0) {
                // console.log(kodezona)
                let input = '[';
                for (var i = 0, j = kodezona.length; i < j; i++) {
                    if (i > 0) input += `,`;
                    input += `"${kodezona[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        hapusMultiZona(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.hapusMultiZona !== undefined) {
                        return (resolve(resp.data.hapusMultiZona));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                })
            } else {
                return (reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    getData: (idOutlet, clientID, token, kodezona) => {
        return new Promise((resolve, reject) => {
            let input = '[';
            for (var i = 0, j = kodezona.length; i < j; i++) {
                if (i > 0) input += `,`;
                input += `"${kodezona[i]}"`;
            };
            input += ']';
            const query = `
                query {
                    getMultiZona(idOutlet:${idOutlet}, kodezona: ${input}){
                        kodezona
                        namazona
                        tarifongkir
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.getMultiZona) return (resolve(resp.data.getMultiZona));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    handleActive:(idOutlet,kodezona,clientID,token, hide)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    handleActiveZona(idOutlet:${idOutlet},kodezona:"${kodezona}",hide:${hide})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.handleActiveZona != undefined ){
                    return (resolve(resp.data.handleActiveZona));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default zona;