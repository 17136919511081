import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import mLaporan from '../Laporan.controler'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone'
import CurrencyFormat from 'react-currency-format';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const numberWithCommas = (x) => {
    if (!x) x = 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const DiskonTaxService = (props) => {
    const classes = useStyles();
    const [isRefresh, setIsRefresh] = useState(true)
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [jumlahHari, setJumlahHari] = useState()
    const [idOutlet, setIdOutlet] = useState(props.idOutlet)
    const [listLaporan, setListLaporan] = useState([])
    var diskon = 0
    var tax = 0;
    var service = 0;


    useEffect(() => {
        var render = true
        if (isRefresh) {
            sumDate()
            mLaporan.diskonTaxService(clientID, token, userLogin, idOutlet, tglDari, tglSampai).then(resp => {
                setListLaporan(resp.laporanFilter)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])


    const sumDate = () => {
        var totTanggal = []
        for (var d = new Date(tglDari); d <= new Date(tglSampai); d.setDate(d.getDate() + 1)) {
            totTanggal.push(d)
        }
        setJumlahHari(totTanggal.length)
    }
    const lihatLaporan = () => {
        sumDate()
        setIsRefresh(true)
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} lg={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Dari"
                            format="DD-MM-YYYY HH:mm"
                            value={tglDari}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            onChange={handleChangeTglDari}
                            ampm={false}
                            disableFuture
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Sampai"
                            format="DD-MM-YYYY HH:mm"
                            value={tglSampai}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ color: '#000 !important' }}
                            onChange={handleChangeTglSampai}
                            ampm={false}
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={2} className="text-center"><br />
                    <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="marginGrafik">
                <ReactHTMLTableToExcel
                    className="buttonExportExcel"
                    table="laporanDiskonTaxService"
                    filename={`Laporan diskon service & tax ${moment(tglDari).format("DD-MM-YYYY HH mm")} sampai ${moment(tglSampai).format("DD-MM-YYYY HH mm")}`}
                    sheet="Sheet1"
                    buttonText={namaExport()} />
                    {listLaporan.map(el => {
                        diskon += el.diskon
                        service += el.service
                        tax += el.tax

                    })}
                    <Table id="laporanDiskonTaxService" striped bordered hover responsive="lg">
                        <thead>
                            <tr className="colorWhite bgHeaderTabel tabelCenter">
                                <th>Name</th>
                                <th className="text-right">Jumlah</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="colorWhite">
                                <td>Diskon</td>
                                <td className="text-right">
                                    <CurrencyFormat value={parseFloat(Math.round(diskon * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </td>
                            </tr>
                            <tr className="colorWhite">
                                <td>Service</td>
                                <td className="text-right">
                                    <CurrencyFormat value={parseFloat(Math.round(service * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </td>
                            </tr>
                            <tr className="colorWhite">
                                <td>Tax</td>
                                <td className="text-right">
                                    <CurrencyFormat value={parseFloat(Math.round(tax * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </td>
                            </tr>
                            <tr className="colorWhite">
                                <th>Total</th>
                                <th className="text-right">
                                    <CurrencyFormat value={parseFloat(Math.round((diskon + service + tax) * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default DiskonTaxService;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));