/* eslint-disable no-redeclare */
import GQL from '../../GQL';
const Diskon = {
    loadData: (idOutlet, clientID, token , kodebarang) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listDiskon(idOutlet:${idOutlet}, kodebarang: "${kodebarang}"){
                        kodediskon
                        kodebarang
                        namabarang
                        harga1
                        harga2
                        harga3
                        harga4
                        harga5
                        tglmulai
                        tglselesai
                        isHide
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listDiskon) return (resolve(resp.data.listDiskon));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadDataBerjalan: (idOutlet, clientID, token ) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listDiskonBerjalanMendatang(idOutlet:${idOutlet}){
                        kodediskon
                        kodebarang
                        namabarang
                        harga1
                        harga2
                        harga3
                        harga4
                        harga5
                        tglmulai
                        tglselesai

                    }
                    listBarangApp2(idOutlet:${idOutlet}){
                        kodebarang
                        namabarang
                        kodekategori
                        kodesubkategori
                        kategori{
                            kodekategori
                            namakategori
                        }
                        subkategori{
                            kodesubkategori
                            namasubkategori
                        }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                const listBarang = resp.data.listBarangApp2;
                const listDiskonBerjalanMendatang = []
                resp.data.listDiskonBerjalanMendatang.forEach((el) => {
                    const ada = listBarang.find(data => data.kodebarang === el.kodebarang);
                    if (ada) {
                        listDiskonBerjalanMendatang.push({
                        ...el,
                        kodekategori: ada.kodekategori,
                        kodesubkategori: ada.kodesubkategori,
                        namakategori: ada.kategori.namakategori,
                        namasubkategori: ada.subkategori.namasubkategori
                      });
                    } 
                })
                
                if (resp.data && resp.data.listDiskonBerjalanMendatang) return (resolve(listDiskonBerjalanMendatang));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadItemDiskon: (idOutlet, clientID, token ) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listDiskonAll(idOutlet:${idOutlet}){
                        kodebarang
                        namabarang
                        jumlahDiskon
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listDiskonAll) return (resolve(resp.data.listDiskonAll));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadDetailBarang: (idOutlet, clientID, token , kodebarang) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    barangApp(idOutlet:${idOutlet},kodebarang:"${kodebarang}"){
                        kodebarang
                        namabarang
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.barangApp) return (resolve(resp.data.barangApp));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },

    saveDiskon: (idOutlet, clientID, token, kodediskon, kodebarang,namabarang, tglmulai, tglselesai, harga1, harga2, harga3, harga4,harga5) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanDiskon(idOutlet:${idOutlet}, input:{
                        kodediskon:"${kodediskon}",
                        kodebarang:"${kodebarang}"
                        namabarang:"${namabarang}",
                        tglmulai:"${tglmulai}",
                        tglselesai:"${tglselesai}",
                        harga1:${harga1},
                        harga2:${harga2},
                        harga3:${harga3},
                        harga4:${harga4}
                        harga5:${harga5}
                      })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanDiskon !== undefined) {
                    return (resolve(resp.data.simpanDiskon));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    hapusDiskon: (idOutlet, clientID, token, kodediskon) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusDiskon(idOutlet:${idOutlet},kodediskon:"${kodediskon}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusDiskon !== undefined) {
                    return (resolve(resp.data.hapusDiskon));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    handleActive:(idOutlet,kodediskon,clientID,token, hide)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    handleActiveDiskon(idOutlet:${idOutlet},kodediskon:"${kodediskon}",hide:${hide})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.handleActiveDiskon !== undefined ){
                    return (resolve(resp.data.handleActiveDiskon));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default Diskon;