import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import validate from 'validate.js'
import mControl from './ManajemenOutletControler'



const Modal = (props) => {
    const [form, setForm] = useState({
        kodevoucher: ''
    })

    const handleClose = () => {
        props.setOpen(false)
    }
    const validateForm = () => {
        const { kodevoucher } = form;
        const promForm = new Promise((resolve, reject) => {
            const constraint = {
                kodevoucher: {
                    length: {
                        minimum: 1,
                        message: "Wajib isi kode voucher"
                    },
                },
            }
            const validator = validate({ kodevoucher }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promForm]);
    }

    const simpan = () => {
        validateForm().then(() => {
            const { kodevoucher } = form;
            mControl.claimVoucher(props.clientID, props.token, props.kodeoutlet, kodevoucher).then(resp => {
                props.setInfoText("Voucher berhasil di claim")
                props.setIsInfo(true)
                handleClose()
            }).catch(err => {
                if (err.length > 0) {
                    props.setIsInfo(true)
                    props.setInfoText(err[0].message)
                } else if (typeof err) {
                    props.setIsInfo(true)
                    props.setInfoText(err.message)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            console.log(err)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            props.setInfoText(errorMessage);
        })
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Claim Vocuher</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="kodevoucher"
                    label="Masukan Kode Voucher "
                    fullWidth
                    multiline={true}
                    value={form.kodevoucher}
                    onChange={handleChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="">Cancel</Button>
                <Button onClick={simpan} color="secondary">Claim Voucher</Button>
            </DialogActions>
        </Dialog>
    )
}
export default Modal;
