/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { TextField,  FormControlLabel, Checkbox, FormLabel } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const BarangForm = (props) => {
    const kodeoutlet = props.kodeoutlet
    const defaultPage = props.defaultPage * 1 || 1;
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/paket/${defaultPage}/${defaultrowsPerPage}`)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        if (['isStock'].indexOf(key) > -1) {
            value = !!!props[key];
        } else if (ev.target.type == "number") {
            value = +value;
        }
        props.handleChange(key, value)
    }


    return (
        <Row>
            <Col xs={12}>
                <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Paket Form</h4>
            </Col>
            <Col xs={12} lg={6}>
                {(props.type == "edit" && !props.namabarang) ? (
                    <React.Fragment>
                        <FormLabel>Nama Paket</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                        <TextField
                            id="namabarang"
                            name="namabarang"
                            label="Nama Paket"
                            fullWidth
                            required
                            value={props.namabarang}
                            onChange={handleChange}
                        />
                    )}
                {(props.type == "edit" && !props.satuan) ? (
                    <React.Fragment>
                        <FormLabel>Satuan</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                        <TextField
                            id="satuan"
                            name="satuan"
                            label="satuan"
                            fullWidth
                            value={props.satuan}
                            onChange={handleChange}
                        />
                    )}

                <div className="">
                    <FormControlLabel control={<Checkbox checked={props.isStock || false} onChange={handleChange} />} id="isStock" name="isStock" label="Manajemen Stok" />
                </div>
            </Col>
            <Col xs={12} lg={6}>
                {(props.type == "edit" && !props) ? (
                    <React.Fragment>
                        <FormLabel>Harga Satuan</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                        <TextField
                            id="hargasatuan"
                            name="hargasatuan"
                            label="Harga Satuan"
                            fullWidth
                            type="number"
                            value={props.hargasatuan}
                            onChange={handleChange}
                        />
                    )}
                {(props.type == "edit" && !props) ? (
                    <React.Fragment>
                        <FormLabel>Harga Gojek</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                        <TextField
                            id="hargagojek"
                            name="hargagojek"
                            label="Harga Gojek"
                            fullWidth
                            type="number"
                            value={props.hargagojek || 0}
                            onChange={handleChange}
                        />
                    )}
                {(props.type == "edit" && !props) ? (
                    <React.Fragment>
                        <FormLabel>Harga Grab</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                        <TextField
                            id="hargagrab"
                            name="hargagrab"
                            label="Harga Grab"
                            fullWidth
                            type="number"
                            value={props.hargagrab || 0}
                            onChange={handleChange}
                        />
                    )}
                {(props.type == "edit" && !props) ? (
                    <React.Fragment>
                        <FormLabel>Harga Lain</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                        <TextField
                            id="hargalain"
                            name="hargalain"
                            label="Harga Lain"
                            fullWidth
                            type="number"
                            value={props.hargalain || 0}
                            onChange={handleChange}
                        />
                    )}
            </Col>
        </Row>

    )
}
export default BarangForm;