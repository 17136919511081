import React, { useState } from "react";
import {
    Button, Dialog, Slide, DialogTitle, DialogActions, DialogContent, DialogContentText
} from '@material-ui/core';
import { TextField } from '@material-ui/core';
import GQL from '../../GQL'

const ForgetPassword = (props) => {
    const [emailForget, setEmailForget] = useState()
    const handleOke = () => {
        const query = `
        mutation{
            forgetPasswordCustomer(input:{email:"${emailForget}",domain:"${process.env.REACT_APP_DOMAIN}", emailCS:"${process.env.REACT_APP_EMAIL}", telpCS:"${process.env.REACT_APP_TELP}"})
        }
    `
        GQL(query).then(res => {
            if (res.errors) throw res.errors;
            props.info("Reset password anda berhasil. Periksa e-mail anda untuk memasukan ulang password anda")
        }).catch(err => {
            if (err[0].message) {
                props.info(err[0].message)
            } else {
                alert("Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti")
            }
        })
        props.info("Reset password anda berhasil. Periksa e-mail anda untuk memasukan ulang password anda")
    }
    const handleChange = (ev) => {
        setEmailForget(ev.target.value)
    }
    const handleClose = () => {
        props.setOpen(false);
    }
    return (
        <Dialog open={props.open} TransitionComponent={Transition}>
            <DialogTitle className="colorWhite">Portal {process.env.REACT_APP_COMPANY} says</DialogTitle>
            <DialogContent className="colorWhite">

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="email"
                    autoFocus
                    label="Masukan Alamat E-mail anda"
                    value={emailForget}
                    type="email"
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                    Cancel
          </Button>
                <Button onClick={handleOke} color="primary" autoFocus>
                    Reset Password</Button>
            </DialogActions>
        </Dialog>
    )
}
export default ForgetPassword;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});