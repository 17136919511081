import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Row, Col } from 'react-bootstrap';
import { FormControlLabel, Checkbox, Button } from '@material-ui/core';


const ModalGambar = (props) => {
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();


    const handleClose = () => {
        props.setOpen(false);
    };
    const handleChange = (ev) => {
        let value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        let newState = { ...props.isShow }
        value = !!!props.isShow[key];
        newState[key] = value;
        props.setIsShow(newState);
    }
    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Row>
                    <Col xs={12} className="text-center">
                        <h4 id="text-center">Filter Column</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.namabarang || false} onChange={handleChange} />} id="namabarang" name="namabarang" label="Nama Barang" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.kategori || false} onChange={handleChange} />} id="kategori" name="kategori" label="Kategori" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.subkategori || false} onChange={handleChange} />} id="subkategori" name="subkategori" label="Sub Kategori" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.satuan || false} onChange={handleChange} />} id="satuan" name="satuan" label="Satuan" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.hargasatuan || false} onChange={handleChange} />} id="hargasatuan" name="hargasatuan" label="Harga Satuan" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.hargagojek || false} onChange={handleChange} />} id="hargagojek" name="hargagojek" label="Harga Gojek" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.hargagrab || false} onChange={handleChange} />} id="hargagrab" name="hargagrab" label="Harga Grab" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.hargalain || false} onChange={handleChange} />} id="hargalain" name="hargalain" label="harga Lain" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.hargalain2 || false} onChange={handleChange} />} id="hargalain2" name="hargalain2" label="harga Lain 2" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.manajemenstok || false} onChange={handleChange} />} id="manajemenstok" name="manajemenstok" label="Manajemen Stok" />
                    </Col>
                    <Col xs={12}>
                        <FormControlLabel control={<Checkbox checked={props.isShow.manajemenprinter || false} onChange={handleChange} />} id="manajemenprinter" name="manajemenprinter" label="Manajemen Printer" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-center">
                        <Button variant="contained" color="primary" fullWidth style={{ marginTop: 3 }} onClick={handleClose} >Oke</Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ModalGambar;



function getModalStyle() {
    const top = 40;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))