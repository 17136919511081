/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from 'react-bootstrap';
import { Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, Dialog, DialogContent, DialogContentText, Slide, Typography, AppBar, Toolbar, TextField, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import useStyles from '../../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import Dropdown from 'react-bootstrap/Dropdown'
import mControl from './UserKasir.Controler'
import { FaChevronLeft } from 'react-icons/fa';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DnsIcon from '@material-ui/icons/Dns';
import validate from 'validate.js'

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: "username", label: "Username" },
    { id: "fullname", label: "Nama Lengkap" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Telp" },
    { id: "userrole", label: "Level" },


];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell>Action</TableCell>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const KasKeluarTransaksi = (props) => {
    const kodeoutlet = props.kodeoutlet;
    const defaultPage = 0;
    const defaultrowsPerPage = 5;
    const clientID = props.clientID
    const token = props.token
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [dense, setDense] = React.useState(true);
    const [isRefresh, setIsRefresh] = useState(true)
    const [rows, setRows] = useState([])
    const scroll = 'paper';
    const [open, setOpen] = useState(false);
    const [hakAkses, setHakAkses] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [form, setForm] = useState({
        username: '',
        namalengkap: '',
        hp: '',
        email: '',
        password: '',
        password2: '',
        hakakses: 'ADMIN'
    })
    useEffect(() => {
        var render = true
        if (isRefresh) {
            setRows([])
            mControl.loadUserKasir(kodeoutlet, clientID, token).then(resp => {
                if (render) setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh]);
    useEffect(() => {
        mControl.loadHakAkses(kodeoutlet, clientID, token).then(resp => {
            setHakAkses(resp);
        }).catch(err => {
            console.error(err);
        })
        return () => {

        };
    }, [])
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const loadingRow = [0, 1, 2, 3, 4];
    function handleCloseMoidalDetPem() {
        setOpen(false);
    }
    const openEdit = (data) => {
        setForm({
            username: data.username,
            namalengkap: data.fullname,
            hp: data.phone,
            email: data.email,
            password: '',
            password2: '',
            hakakses: data.userrole
        })
        setIsUpdate(true)
        setOpen(true)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const validateForm = () => {
        const { username, namalengkap, hp, email, password, password2 } = form;
        const promKategori = new Promise((resolve, reject) => {
            const constraint = {
                username: {
                    length: {
                        minimum: 3,
                        message: "Username minimal terdiri dari 3 huruf"
                    },
                },
                namalengkap: {
                    length: {
                        minimum: 3,
                        message: "Nama Lengkap minimal terdiri dari 3 huruf"
                    },
                },
                email: {
                    email: {
                        message: "Email tidak valid"
                    }
                },
            }
            const validator = validate({ username, namalengkap, hp, email, password, password2 }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promKategori]);
    }
    const handleSaveUser = () => {
        validateForm().then(() => {
            if (form.password === form.password2) {
                const { username, namalengkap, hp, email, password, hakakses } = form;
                const passwordString = password.toString()
                if (passwordString.length > 0 && passwordString.length < 5) {
                    alert("Password minimal 5 digit")
                } else {
                    mControl.saveUserKasir(kodeoutlet, clientID, token, username, namalengkap, hp, email, passwordString, hakakses).then(resp => {
                        if(resp){
                            alert("User kasir berhasil disimpan")
                            setIsRefresh(true)
                            setOpen(false)
                        }else{
                            alert("User kasir gagal disimpan")
                        }
                    }).catch(err => {
                        if (err.length > 0) {
                            alert(err[0].message)
                        } else if (typeof err) {
                            alert(err.message)
                        } else {
                            console.log(err);
                        }
                    })
                }
            } else {
                alert("Password yang anda input tidak sama")
            }
        }).catch(err => {
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            alert(errorMessage)
        })

    }
    const handleHapus = (data) => {
        const oke = window.confirm(`Yakin ingin hapus user ${data.username}`)
        if (oke) {
            mControl.deleteUserKasir(kodeoutlet, clientID, token, data.username).then(resp => {
                alert("User kasir berhasil dihapus")
                setIsRefresh(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }
    return (
        <React.Fragment>
            <div >
                <Paper className={classes.paper}>
                    <div className="btnTable">
                        <Button variant="contained" color="primary" onClick={() => {
                            setOpen(true)
                            setIsUpdate(false)
                        }}><AddIcon /> Add Kasir</Button>
                        <Button variant="contained" color="secondary" style={{ marginLeft: 10 }} onClick={() => props.redirect(`/manajemenoutlet/${kodeoutlet}/hakakseskasir`)}><DnsIcon />List Hak Akses</Button>
                    </div>
                    <div className={classes.tableWrapper}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {rows.length == 0 && loadingRow.map(el => (
                                    <TableRow
                                        key={el.toString()}
                                        style={{ height: 50 }}
                                    >
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell >
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {stableSort(rows, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row._id}
                                                className={(row.statusid === 8 || row.statusid === 9) && ("bgRowCancel") || ("")}
                                            >
                                                <TableCell>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <TouchAppIcon id="dropdown-basic" /></Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item><Button title="Edit" variant="contained" style={{ backgroundColor: '#376275', marginRight: 10, color: "#fff", width: '145px' }} onClick={openEdit.bind(this, row)}><EditIcon /> Edit/lihat</Button></Dropdown.Item>
                                                            <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: 'red', marginRight: 10, color: "#fff", width: '145px' }} onClick={handleHapus.bind(this, row)}><DeleteIcon /> Hapus</Button></Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </TableCell>
                                                <TableCell >
                                                    {row.username}
                                                </TableCell>
                                                <TableCell >
                                                    {row.fullname}
                                                </TableCell>
                                                <TableCell >
                                                    {row.email}
                                                </TableCell>
                                                <TableCell >
                                                    {row.phone}
                                                </TableCell>
                                                <TableCell >
                                                    {row.hakakses ? row.hakakses.namaakses : row.userrole}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "previous page"
                        }}
                        nextIconButtonProps={{
                            "aria-label": "next page"
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                />
            </div>
            <Dialog
                open={open}
                onClose={handleCloseMoidalDetPem}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar>
                    <Toolbar>
                        <FaChevronLeft color="inherit" onClick={handleCloseMoidalDetPem} className="tangan" />
                        <Typography variant="h6">
                            Add / Update User</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers={scroll === 'paper'} className="DialogFont bodyDialog">
                    <DialogContentText className="colorWhite">
                        <Row>
                            <Col xs={12} lg={12}>
                                {isUpdate ? (
                                    <TextField
                                        id="username"
                                        name="username"
                                        label="Username"
                                        fullWidth
                                        required
                                        multiline={false}
                                        value={form.username}
                                        disabled
                                    />
                                ) : (
                                        <TextField
                                            id="username"
                                            name="username"
                                            label="Username"
                                            fullWidth
                                            required
                                            multiline={false}
                                            value={form.username}
                                            onChange={handleChange}
                                        />
                                    )}
                                <TextField
                                    id="namalengkap"
                                    name="namalengkap"
                                    label="Nama Lengkap"
                                    fullWidth
                                    required
                                    multiline={false}
                                    value={form.namalengkap}
                                    onChange={handleChange}
                                />
                                <TextField
                                    id="hp"
                                    name="hp"
                                    label="Hp"
                                    fullWidth
                                    required
                                    multiline={false}
                                    value={form.hp}
                                    onChange={handleChange}
                                    type="number"
                                />
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    required
                                    multiline={false}
                                    value={form.email}
                                    onChange={handleChange}
                                    type="email"
                                />
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Password"
                                    fullWidth
                                    required
                                    multiline={false}
                                    value={form.password}
                                    onChange={handleChange}
                                    type="password"
                                />
                                <TextField
                                    id="password2"
                                    name="password2"
                                    label="Password Repeat"
                                    fullWidth
                                    required
                                    multiline={false}
                                    value={form.password2}
                                    onChange={handleChange}
                                    type="password"
                                />
                                <FormControl fullWidth={true}>
                                    <InputLabel>Hak Akses</InputLabel>
                                    <Select
                                        name="hakakses"
                                        id="hakakses"
                                        required
                                        value={form.hakakses}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="ADMIN">Admin</MenuItem>
                                        {hakAkses.map((el, i) => (
                                            <MenuItem key={i} value={el.kodeakses}>{el.namaakses}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20, marginBottom: 20 }}>
                            <Col xs={12} className="text-center">
                                <Button variant="contained" color="primary" fullWidth onClick={handleSaveUser}>Simpan</Button>
                            </Col>
                        </Row>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    );
}
export default KasKeluarTransaksi;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});