import GQL from '../../GQL';
import moment from 'moment-timezone'
const kategori = {
    getOutlet: (idOutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    getOutlet(idOutlet:${idOutlet}){
                        namaoutlet
                        kodeoutlet
                     }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.getOutlet) return(resolve(resp.data.getOutlet));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadData: (kodeoutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listKasMasuk(kodeoutlet:"${kodeoutlet}"){
                        kodebiaya
                        namabiaya
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listKasMasuk) return(resolve(resp.data.listKasMasuk));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    search: (kodeoutlet,search,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listKasMasuk(kodeoutlet:"${kodeoutlet}",search:"${search}"){
                        kodebiaya
                        namabiaya
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listKasMasuk) return(resolve(resp.data.listKasMasuk));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDetail: (kodeoutlet,kodebiaya,clientID,token)=> {
        return new Promise((resolve,reject)=> {
            const query =`
                query{
                    getKasMasuk(kodeoutlet:"${kodeoutlet}",kodebiaya:"${kodebiaya}"){
                        kodebiaya
                        namabiaya
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.getKasMasuk) return (resolve(resp.data.getKasMasuk))
            }).catch(err => reject(err))
        })
    },
    simpan:(kodeoutlet, namabiaya, clientID,token)=>{
         return new Promise((resolve,reject)=> {
            const kodebiaya = `${namabiaya.substring(0,3)}${moment().tz("Asia/Jakarta").format("YYMMDDHHmmss")}`
            const query = `
                mutation{
                    simpanKasMasuk(kodeoutlet:"${kodeoutlet}",kodebiaya:"${kodebiaya}",namabiaya:"${namabiaya}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanKasMasuk){
                    return(resolve(resp.data.simpanKasMasuk));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    update:(kodeoutlet,kodebiaya,namabiaya,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    updateKasMasuk(kodeoutlet:"${kodeoutlet}",kodebiaya:"${kodebiaya}",namabiaya:"${namabiaya}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.updateKasMasuk != undefined){
                    return(resolve(resp.data.updateKasMasuk));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    delete:(kodeoutlet,kodebiaya,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    hapusKasMasuk(kodeoutlet:"${kodeoutlet}",kodebiaya:"${kodebiaya}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.hapusKasMasuk != undefined ){
                    return (resolve(resp.data.hapusKasMasuk));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    import:(kodeoutlet,listBiaya,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(listBiaya.length > 0){
                const listBiayaNew = []
                var urut = 1
                listBiaya.map(el => {
                    listBiayaNew.push({
                        namabiaya: el.namabiaya,
                        kodebiaya: `${el.namabiaya.substring(0,3)}${moment().tz("Asia/Jakarta").format("YYMMDDHHmmss")}${urut++}`,
                    })
                })
                let input = '[';
                for(var i=0,j=listBiayaNew.length;i<j;i++){
                    if(i>0) input += `,`;
                    var _kodebiaya = listBiayaNew[i].kodebiaya ? `,kodebiaya:"${listBiayaNew[i].kodebiaya}"` : '';
                    input += `{namabiaya:"${listBiayaNew[i].namabiaya}"${_kodebiaya}}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiKasMasuk(kodeoutlet:"${kodeoutlet}",input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.simpanMultiKasMasuk != undefined ){
                        return (resolve(resp.data.simpanMultiKasMasuk));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    deleteMultiKategori:(idOutlet,kodebiaya,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(kodebiaya.length > 0){
               // console.log(kodebiaya)
                let input = '[';
                for(var i=0,j=kodebiaya.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodebiaya[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        hapusMultiKategoriApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.hapusMultiKategoriApp != undefined ){
                        return (resolve(resp.data.hapusMultiKategoriApp));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    getData: (idOutlet,clientID,token, kodebiaya) =>{
        return new Promise((resolve,reject)=>{
            let input = '[';
                for(var i=0,j=kodebiaya.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodebiaya[i]}"`;
                };
                input += ']';
            const query = `
                query {
                    getMultiKategori(idOutlet:${idOutlet}, kodebiaya: ${input}){
                        kodebiaya
                        namabiaya
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.getMultiKategori) return(resolve(resp.data.getMultiKategori));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
}

export default kategori;