/* eslint-disable no-redeclare */
import GQL from '../../GQL';
import moment from 'moment'


const Point = {
    getOutlet: (clientID, token, kodeoutlet) => {
        return new Promise((resolve, reject) => {
            const query = `
          query {
            getOutlet(idOutlet:${kodeoutlet}){
                kodeoutlet
                namaoutlet
             }
        }
    
          `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.getOutlet != null) {
                    return (resolve(resp.data.getOutlet));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err));
        })
    },
    loadData: (kodeoutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listPoint(kodeoutlet:"${kodeoutlet}"){
                        id
                        hargaPoint
                        dariTgl
                        sampaiTgl
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listPoint) return (resolve(resp.data.listPoint));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    savePoint: (clientID, token, id, kodeoutlet, hargaPoint,isTgl, dariTgl, sampaiTgl) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanPoint(input:{
                        id:${id || null},
                        kodeoutlet:"${kodeoutlet}",
                        hargaPoint:${hargaPoint}
                        dariTgl: ${isTgl ? `"${moment(dariTgl).format("YYYY-MM-DD HH:mm")}"`: null}
                        sampaiTgl:${isTgl ? `"${moment(sampaiTgl).format("YYYY-MM-DD HH:mm")}"`: null}
                    })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanPoint !== undefined) {
                    return (resolve(resp.data.simpanPoint));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    hapusPoint: (clientID, token, id) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusPoint(id:${id})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusPoint !== undefined) {
                    return (resolve(resp.data.hapusPoint));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default Point;