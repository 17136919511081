import React from 'react'
import { TextField, FormLabel, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const UserOpnameForm = (props) => {
    const defaultPage = props.defaultPage * 1 || 1;
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const kodeoutlet = props.kodeoutlet
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/opname-user/${defaultPage}/${defaultrowsPerPage}`)
    }

    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        props.handleChange(key, value)
    }

    return (
        <Row>
            <Col xs={12}>
                <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" /> User Opname Form</h4>
            </Col>
            <Col xs={12} lg={12}>
                {props.isInsert && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoFocus
                        value={props.username}
                        onChange={handleChange}
                    />
                )}
                {!props.isInsert && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoFocus
                        value={props.username}
                        disabled
                    />
                )}
                {props.isInsert && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={props.password}
                        onChange={handleChange}
                    />
                )}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="noHp"
                    label="No Handphone"
                    name="noHp"
                    type="tel"
                    value={props.noHp}
                    onChange={handleChange}
                /><br /><br />
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                    aria-label="gender"
                    name="gender"
                    onChange={handleChange}
                    value={props.gender}
                >
                    <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                    <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                </RadioGroup>

            </Col>
        </Row>

    )
}
export default UserOpnameForm;