import React, { useEffect, useContext, useState, useReducer } from 'react';
import AuthContext from '../../context/auth-context'
import Muser from './User.Controler'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import createGuest from 'cross-domain-storage/guest'

const bazStorage = createGuest(`${process.env.REACT_APP_REPORT}`);
const loginReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                token: action.token,
                clientID: action.clientID,
                userLogin: action.userLogin,
                isLogin: true
            }
        case "LOGOUT":
            return {
                ...state,
                token: null,
                clientID: null
            }
        case "COOKIE":
            return {
                ...state,
                token: action.token,
                clientID: action.clientID,
                userLogin: action.userLogin,
                isCookieLoad: true
            }
        default: throw new Error("Invalid Action");
    }
}

const MasterUser = (props) => {
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [isReload, setIsReload] = useState(true)
    const [listUser, setlistUser] = useState([])
    const [state, dispatch] = useReducer(loginReducer)
    const [cookies, setCookie, removeCookie] = useCookies([`${process.env.REACT_APP_COMPANY}-portal`]);
    var urut = 0
    useEffect(() => {
        var render = true
        if (isReload) {
            if (clientID && token && (userLogin.toUpperCase() === "JACKY" || userLogin.toUpperCase() === "IPOS")) {
                Muser.loadData(userLogin, clientID, token).then(resp => {
                    if (render) setlistUser(resp);
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    setIsReload(false);
                })
            } else {
                window.location.href = '/';
            }
        }
        return () => {
            render = false;
        };
    }, [clientID, token, userLogin, isReload])



    const handleLoginAs = (email) => {
        Muser.loginAsCustomer(clientID, token, email).then(resp => {
            const token = resp.token
            const clientID = resp.clientID
            const userLogin = email
            // eslint-disable-next-line no-useless-escape
            const dataLogin = { "auth": `{\"isLogin\":true,\"accessToken\":\"${token}\",\"username\":\"${userLogin}\",\"clientID\":\"${clientID}\"}`, "router": "{\"locationBeforeTransitions\":null}", "_persist": "{\"version\":-1,\"rehydrated\":true}" }
            bazStorage.set('persist:root', JSON.stringify(dataLogin))
            setCookie(`${process.env.REACT_APP_COMPANY}-portal-auth`, { clientID, token, userLogin }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
            dispatch({ type: "LOGIN", clientID, token, userLogin })
            window.location.href = '/';
        }).catch(err => {
            console.error(err);
        })
    }

    return (
        <React.Fragment>
            <Container>
                <Row style={{ marginTop: '20px' }}>
                    {(userLogin.toUpperCase() === "JACKY" || userLogin.toUpperCase() === "IPOS") && (
                        <React.Fragment>
                            <Col xs={12}>
                                <h3 className="text-center">List User Customer</h3>
                            </Col>
                            <Col xs={12}>
                                <Table striped bordered hover responsive="lg">
                                    <thead>
                                        <tr className="colorWhite bgHeaderTabel tabelCenter">
                                            <th style={{ "width": "5px" }}>No</th>
                                            <th>Email</th>
                                            <th>Customer</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listUser.map((data, index) => {
                                            urut++
                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{urut}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.namacustomer}</td>
                                                    <td className="text-center">
                                                        <Button variant="contained" color="primary" onClick={handleLoginAs.bind(this, data.email)}>Login </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </React.Fragment>
                    )}
                </Row>
            </Container>
        </React.Fragment>
    );
}
export default MasterUser;