import {makeStyles} from '@material-ui/core';
if (process.env.REACT_APP_COMPANY == "MonkeyPOS") {
    var bgColor = "#151a35"
} else if (process.env.REACT_APP_COMPANY == "Ipos") {
    var bgColor = "#c5c5c5"
}
const useStylesTabel = makeStyles(theme => ({
    root: {
        width: "100%",  paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    tableWrapper: {
        overflowX: "auto",
        minHeight: "300px"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: bgColor,
        '&:hover': {
            backgroundColor: bgColor,
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
        float: 'right',
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '0px',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
    avatar: {
        backgroundColor: "#b43f3a",
        color: "#1e88e5",
      },
}));

export default useStylesTabel;