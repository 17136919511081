import GQL from '../../../GQL';
const Waiter = {
    loadData: (idOutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listOpname(idOutlet:${idOutlet}){
                        username
                        noHp
                        gender
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listOpname) return(resolve(resp.data.listOpname));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    search: (idOutlet,search,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listOpname(idOutlet:${idOutlet},search:"${search}"){
                        username
                        noHp
                        gender
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listOpname) return(resolve(resp.data.listOpname));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    detailOpname: (idOutlet,username,clientID,token)=> {
        return new Promise((resolve,reject)=> {
            const query =`
                query{
                    detailOpname(idOutlet:${idOutlet},username:"${username}"){
                        username
                        noHp
                        gender
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.detailOpname) return (resolve(resp.data.detailOpname))
            }).catch(err => reject(err))
        })
    },
    simpan:(idOutlet, username, password, noHp, gender, clientID, token)=>{
         return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanUserOpname(input:{username:"${username}", password:"${password}", noHp:"${noHp}", gender:${gender}, idOutlet:${idOutlet}})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanUserOpname){
                    return(resolve(resp.data.simpanUserOpname));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    updateOpname:(idOutlet,clientID,token,  username, noHp, gender)=>{
        return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    updateUserOpname(input:{username:"${username}",noHp:"${noHp}", gender:${gender}})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.updateUserOpname != undefined){
                    return(resolve(resp.data.updateUserOpname));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    resetPassword:(idOutlet,clientID,token, username,  password)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    resetPasswordOpname(username:"${username}",password:"${password}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.resetPasswordOpname != undefined ){
                    return (resolve(resp.data.resetPasswordOpname));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    deleteOpname:(idOutlet,username,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    hapusUserOpname(username:"${username}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.hapusUserOpname != undefined ){
                    return (resolve(resp.data.hapusUserOpname));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
   
}

export default Waiter;