/* eslint-disable no-redeclare */
import GQL from '../../GQL';
const subkategori = {
    loadData: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listPaket(idOutlet:${idOutlet}){
                        kodebarang
                        namabarang
                        satuan
                        hargasatuan
                        hargagojek
                        hargagrab
                        hargalain
                        isStock
                        isHide
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listPaket) return (resolve(resp.data.listPaket));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadDetail: (idOutlet, kodebarang, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    detailPaket(idOutlet:${idOutlet},kodebarang:"${kodebarang}"){
                        kodebarang
                        namabarang
                        satuan
                        hargasatuan
                        hargagojek
                        hargagrab
                        hargalain
                        isStock
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data) return (resolve(resp.data.detailPaket))
            }).catch(err => reject(err))
        })
    },
    loadDetailPaket: (idOutlet, kodebarang, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    detailPaket(idOutlet:${idOutlet},kodebarang:"${kodebarang}"){
                        detail {
                            kodebarang
                            namabarang
                            qty
                        }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.detailPaket.detail) return (resolve(resp.data.detailPaket.detail))
            }).catch(err => reject(err))
        })
    },
    simpanPaket: (idOutlet, namabarang, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, clientID, token, kodebarang) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanPaket(idOutlet:${idOutlet},input:{
                        kodebarang:"${kodebarang}",
                        namabarang:"${namabarang}",
                        satuan:"${satuan}",
                        hargasatuan:${hargasatuan},
                        hargagojek:${hargagojek},
                        hargagrab: ${hargagrab},
                        hargalain: ${hargalain},
                        isStock: ${isStock}
                    })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanPaket) {
                    return (resolve(resp.data.simpanPaket));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    deletePaket: (idOutlet, kodebarang, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusPaket(idOutlet:${idOutlet},kodebarang:"${kodebarang}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusPaket != undefined) {
                    return (resolve(resp.data.hapusPaket));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    hapusPaketDetail: (idOutlet, kodebarang, clientID, token, kodebarangdet) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusPaketDetail(idOutlet:${idOutlet},kodebarang:"${kodebarang}", kodebarangdet: "${kodebarangdet}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusPaketDetail != undefined) {
                    return (resolve(resp.data.hapusPaketDetail));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    updatePaketDetail: (idOutlet, clientID, token, kodebarang, data) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanPaketDetail(idOutlet:${idOutlet},kodebarang:"${kodebarang}", input:[
                        {kodebarang:"${data.kodebarang}", namabarang:"${data.namabarang}", qty:${data.qty}}
                    ])
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanPaketDetail != undefined) {
                    return (resolve(resp.data.simpanPaketDetail));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    simpanPaketV2: (idOutlet, namapaket, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, clientID, token, kodepaket, listItem) => {
        return new Promise((resolve, reject) => {
            if (listItem.length > 0) {
                let detail = '[';
                for (var i = 0, j = listItem.length; i < j; i++) {
                    const { kodebarang, namabarang, qty } = listItem[i];
                    if (i > 0) detail += `,`;
                    detail += `{ kodebarang:"${kodebarang}", namabarang:"${namabarang}", qty:${qty}}`;
                };
                detail += ']';
                const query = `
                    mutation{
                        simpanPaketV2(idOutlet:${idOutlet},input:{
                            kodebarang:"${kodepaket}",
                            namabarang:"${namapaket}",
                            satuan:"${satuan}",
                            hargasatuan:${hargasatuan},
                            hargagojek:${hargagojek},
                            hargagrab: ${hargagrab},
                            hargalain: ${hargalain},
                            isStock: ${isStock}
                        }, detail:${detail})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.simpanPaketV2) {
                        return (resolve(resp.data.simpanPaketV2));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                }).catch(err => reject(err))
            }
        })
    },
    simpanPaketDetail: (idOutlet, clientID, token, kodepaket, listItem) => {
        return new Promise((resolve, reject) => {
            if (listItem.length > 0) {
                let input = '[';
                for (var i = 0, j = listItem.length; i < j; i++) {
                    const { kodebarang, namabarang, qty } = listItem[i];
                    if (i > 0) input += `,`;
                    input += `{ kodebarang:"${kodebarang}", namabarang:"${namabarang}", qty:${qty}}`;
                };
                input += ']';
                const query = `
                mutation{simpanPaketDetail(idOutlet:${idOutlet},kodebarang:"${kodepaket}",input:${input})
                    }
            `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.simpanPaketDetail != undefined) {
                        return (resolve(resp.data.simpanPaketDetail));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                })
            }
        })
    },
    hapusMultiPaket: (idOutlet, kodebarang, clientID, token) => {
        return new Promise((resolve, reject) => {
            if (kodebarang.length > 0) {
                let input = '[';
                for (var i = 0, j = kodebarang.length; i < j; i++) {
                    if (i > 0) input += `,`;
                    input += `"${kodebarang[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        hapusMultiPaket(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.hapusMultiPaket != undefined) {
                        return (resolve(resp.data.hapusMultiPaket));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                })
            } else {
                return (reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    handleActive:(idOutlet,kodepaket,clientID,token, hide)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    handleActivePaket(idOutlet:${idOutlet},kodepaket:"${kodepaket}",hide:${hide})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.handleActivePaket != undefined ){
                    return (resolve(resp.data.handleActivePaket));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    duplicatePaket: (idOutlet, clientID, token, idOutletDup, kodepaket) => {
        return new Promise((resolve, reject) => {
            if (kodepaket.length > 0) {
                let input = '[';
                for (var i = 0, j = kodepaket.length; i < j; i++) {
                    if (i > 0) input += `,`;
                    input += `"${kodepaket[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        duplicatePaket(idOutlet:${idOutlet},idOutletDup:${idOutletDup},kodepaket:${input})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));
                    if (resp.data && resp.data.duplicatePaket != undefined) {
                        return (resolve(resp.data.duplicatePaket));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                })
            } else {
                return (reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    simpanMultiPaket: (idOutlet,clientID, token, listPaket) => {
        return new Promise((resolve, reject) => {
            if (listPaket.length > 0) {
                let detail = '[';
                for (var i = 0, j = listPaket.length; i < j; i++) {
                    const { kodepaket, namapaket, satuan, hargasatuan, hargagojek, hargagrab, hargalain, isStock, kodebarang, namabarang, qty } = listPaket[i];
                    if (i > 0) detail += `,`;
                    detail += `{kodepaket:"${kodepaket}",namapaket:"${namapaket}",satuan:"${satuan}",hargasatuan:${hargasatuan},hargagojek:${hargagojek},hargagrab:${hargagrab},hargalain:${hargalain},kodebarang:"${kodebarang}", namabarang:"${namabarang}", qty:${qty}}`;
                };
                detail += ']';
                const query = `
                    mutation{
                        simpanMultiPaketV2(idOutlet:${idOutlet},input:${detail})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.simpanMultiPaketV2) {
                        return (resolve(resp.data.simpanMultiPaketV2));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                }).catch(err => reject(err))
            }else{
                return (reject(new Error("Data tidak ditemukan")))
            }
        })
    },
}

export default subkategori;