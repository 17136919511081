import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import mLaporan from '../Laporan.controler'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { stableSort, getSorting } from '../../../../Helper/Sorting'
import PropTypes from "prop-types";
import { TableSortLabel } from '@material-ui/core';
import numberWithCommas from '../../../../Helper/numberWithCommas'


const TipeTransSubKat = (props) => {
    const classes = useStyles();
    const [isRefresh, setIsRefresh] = useState(true)
    const { clientID, token } = useContext(AuthContext);
    const [listLaporan, setListLaporan] = useState([])
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("jumlah");
    var totJumlah = 0, totQty = 0;
    useEffect(() => {
        if (isRefresh) {
            mLaporan.laporanTipeTransSubKategori(clientID, token, props.tglDari, props.tglSampai, props.kodeoulet, props.typeTransaksi.tipeTrans, props.isTakeAway).then(resp => {
                setListLaporan(resp)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
        };
    }, [clientID, isRefresh, props.kodeoulet, props.tglDari, props.tglSampai, props.typeTransaksi.tipeTrans, token])


    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    const headRows = [
        { id: "namasubkategori", label: "Nama Sub Kategori" },
        { id: "qty", label: "Qty" },
        { id: "jumlah", label: "Jumlah" },

    ];
    const EnhancedTableHead = (props) => {
        const {
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (

            <thead>
                <tr className="colorWhite bgHeaderTabel tabelCenter">
                    {headRows.map(row => (
                        <th key={row.id} >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }



    return (
        <React.Fragment>
            <Row className=" bodySessionDetail">
                <Col xs={12} className="marginGrafik">
                    <ReactHTMLTableToExcel
                        className="buttonExportExcel"
                        table="laporanSubKategori"
                        filename={`Laporan Sub Kategori Tipe Transaksi ${props.typeTransaksi.type} ${moment(props.tglDari).format("DD-MM-YYYY")} sampai ${moment(props.tglSampai).format("DD-MM-YYYY")}`}
                        sheet="Sheet1"
                        buttonText={namaExport()} />
                    <Table id="laporanSubKategori" striped bordered hover responsive="lg">
                        <EnhancedTableHead
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                        />
                        <tbody className={`${classes.contentBody}`}>
                            {stableSort(listLaporan, getSorting(order, orderBy))
                                .map((data, index) => {
                                    totJumlah += data.jumlah
                                    totQty += data.qty
                                    return (
                                        <tr className="colorWehite" key={index}>
                                            <td>{data.namasubkategori}</td>
                                            <td className="text-center">{data.qty}</td>
                                            <td className="text-right">
                                                {numberWithCommas(data.jumlah)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            <tr className="colorWhite">
                                <th className="text-center">Total</th>
                                <th className="text-center">
                                    {totQty}
                                </th>
                                <th className="text-right">
                                    {numberWithCommas(totJumlah)}
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>

    )
}


export default TipeTransSubKat;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));