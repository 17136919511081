import React, { useContext, useState, useEffect } from "react";
import {
    Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, Table, TableRow, TableCell, TableHead, TableBody, TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import AuthContext from '../../context/auth-context'
import mSubkategori from './SubKategori.controller'
import { isArray } from 'util'
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment'
import { Row, Col } from "react-bootstrap";
import validate from 'validate.js';

var dateNow = moment();
const DialogEditkategori = (props) => {
    const classes = useStyles();
    const kodesubkategori = props.item
    const kodeoutlet = props.kodeoutlet
    const { clientID, token } = useContext(AuthContext)
    const [addRow, setAddRow] = useState(dateNow.format("YYMMDDHHmmss") * 1 + 0)
    const [listSubKategori, setListSubKategori] = useState([])


    const handleClose = () => {
        props.setOpen(false);
    }
    useEffect(() => {
        if (kodesubkategori == null) {
            setAddRow(addRow + 1)
            setListSubKategori([
                {
                    kodesubkategori: addRow,
                    namasubkategori: "",
                    isInsert: true
                }
            ])
        } else {
            var render = true
            mSubkategori.getData(kodeoutlet, clientID, token, kodesubkategori).then(resp => {
                if (render) setListSubKategori(resp);
            }).catch(err => {
                console.error(err);
            })
            return () => {
                render = false
            };
        }

    }, [kodeoutlet, clientID, token]);

    const SimpanKategori = () => {
        const validasiError = []
        listSubKategori.map(el => {
            const { namasubkategori } = el;
            const constraint = {
                namasubkategori: {
                    length: {
                        minimum: 3,
                        message: "Nama Sub Kategori minimal terdiri dari 3 huruf"
                    },
                }
            }
            const validator = validate({ namasubkategori }, constraint, { fullMessages: false });
            if (validator) {
                const err = [validator][0]
                const keys = Object.keys(err);
                var errorMessage = "";
                for (const key of keys) {
                    errorMessage += err[key] + "\n";
                }
                validasiError.push(errorMessage)
                return
            }
        })
        if (validasiError.length > 0) return alert(validasiError[0])
        mSubkategori.importSubKategori(kodeoutlet, listSubKategori, clientID, token).then(resp => {
            if (resp) {
                alert("Data Berhasil Di Simpan")
                props.setOpen(false);
                props.setIsRefresh(true);
                props.setSelected([])
                props.notif()
            } else {
                alert("Data Gagal Di Simpan")
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                alert(`Gagal Simpan :  ${err[0].message}`)
            } else if (typeof err == 'error') {
                alert(err.message)
            } else {
                console.log(err);
            }
        })
    }
    const handleaddRow = () => {
        setAddRow(addRow + 1)
        setListSubKategori([
            ...listSubKategori,
            {
                kodesubkategori: addRow,
                namasubkategori: "",
                isInsert: true
            }
        ])
    }
    const handleChange = (ev, kodesubkategori) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        let b = listSubKategori.map(el => {
            if (el.kodesubkategori == kodesubkategori) {
                if (key == "namasubkategori") {
                    return {
                        ...el,
                        kodesubkategori: kodesubkategori,
                        namasubkategori: value
                    }
                } else if (key == "kodesubkategori") {
                    return {
                        ...el,
                        kodesubkategori: value,
                        namasubkategori: el.namasubkategori
                    }
                }
            } else return el
        })
        setListSubKategori(b)
    }
    const deleteRow = (data) => {
        let a = listSubKategori.filter(el => {
            return el.kodesubkategori !== data.kodesubkategori
        })
        setListSubKategori(a)
    }

    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Edit Sub Kategori</Typography>
                    <Button color="inherit" onClick={SimpanKategori}>
                        save</Button>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                <Row className={classes.root}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className="colorWhite" style={{ minWidth: ' 250px' }}>Kode Sub Kategori</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 350px' }}>Nama Sub Kategori</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listSubKategori.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row" className="colorWhite">
                                            {!data.isInsert && (data.kodesubkategori)}
                                            {data.isInsert && (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col xs={2} md={1}>
                                                            <DeleteIcon className="deleteDialog" onClick={deleteRow.bind(this, data)} />
                                                        </Col>
                                                        <Col xs={10} md={11}>
                                                            <span className="tabelForm">
                                                                <TextField
                                                                    id="kodesubkategori"
                                                                    name="kodesubkategori"
                                                                    label=""
                                                                    fullWidth
                                                                    required
                                                                    multiline={false}
                                                                    value={data.kodesubkategori}
                                                                    variant="outlined"
                                                                    onChange={(ev) => handleChange(ev, data.kodesubkategori)}
                                                                />
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                id="namasubkategori"
                                                name="namasubkategori"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.namasubkategori}
                                                variant="outlined"
                                                onChange={(ev) => handleChange(ev, data.kodesubkategori)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Row>
                <Row>
                    <Button className="marginTop15" variant="contained" color="primary" component="span" onClick={handleaddRow}>Add Row</Button>
                </Row>
            </Container>
        </Dialog>
    )
}
export default DialogEditkategori;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));