import GQL from '../../../GQL';
const UserKasir = {
    loadData: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listTopping(idOutlet:${idOutlet}){
                        kodetopping
                        namatopping
                        hargasatuan
                        hargagojek
                        hargagrab
                        hargalain
                        hargalain2
                        kodekategori
                        kodesubkategori
                        kategori{
                            namakategori
                        }
                        subkategori{
                            namasubkategori
                        }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listTopping) return (resolve(resp.data.listTopping));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    search: (idOutlet, search, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listTopping(idOutlet:${idOutlet},search:"${search}"){
                        kodetopping
                        namatopping
                        hargasatuan
                        hargagojek
                        hargagrab
                        hargalain
                        kodekategori
                        kodesubkategori
                        kategori{
                            namakategori
                        }
                        subkategori{
                            namasubkategori
                        }
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listTopping) return (resolve(resp.data.listTopping));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    saveTopping: (idOutlet, clientID, token, kodetopping, namatopping, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, kodekategori, kodesubkategori) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                simpanTopping(idOutlet:${idOutlet},
                  input:{
                    kodetopping:"${kodetopping}",
                    namatopping:"${namatopping}",
                    hargasatuan:${hargasatuan},
                    hargagojek:${hargagojek},
                    hargagrab:${hargagrab},
                    hargalain:${hargalain},
                    hargalain2:${hargalain2},
                    kodekategori:"${kodekategori}",
                    kodesubkategori: "${kodesubkategori}"
                  }
                )
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanTopping) return (resolve(resp.data.simpanTopping));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    deleteListTopping: (idOutlet, clientID, token, kodetopping) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                hapusTopping(idOutlet:${idOutlet},kodetopping:"${kodetopping}")
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusTopping) return (resolve(resp.data.hapusTopping));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadKatNSub: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
            query{
                    listKategoriApp(idOutlet:${idOutlet}){
                        kodekategori
                        namakategori
                    }
                    listSubKategoriApp(idOutlet:${idOutlet}){
                        kodesubkategori
                        namasubkategori
                    }
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                const kategori = resp.data.listKategoriApp
                const subkategori = resp.data.listSubKategoriApp
                if (resp.data && resp.data.listKategoriApp && resp.data.listSubKategoriApp) return (resolve({ kategori, subkategori }));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    importTopping: (idOutlet, listTopping, clientID, token) => {
        return new Promise((resolve, reject) => {
            if (listTopping.length > 0) {
                let input = '[';
                for (var i = 0, j = listTopping.length; i < j; i++) {
                    const { kodetopping, namatopping, kategori, subkategori, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2} = listTopping[i];
                    if (i > 0) input += `,`;
                    var _kodetopping = kodetopping ? `,kodetopping:"${kodetopping}"` : '';
                    input += `{namatopping:"${namatopping}",kodekategori:"${kategori}",kodesubkategori:"${subkategori}",hargasatuan:${hargasatuan},hargagojek:${hargagojek},hargagrab:${hargagrab},hargalain:${hargalain},hargalain2:${hargalain2}${_kodetopping}}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiTopping(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query, clientID, token).then(resp => {
                    if (resp.errors) return (reject(resp.errors));;
                    if (resp.data && resp.data.simpanMultiTopping != undefined) {
                        return (resolve(resp.data.simpanMultiTopping));
                    } else {
                        return (reject(new Error("Gagal menyimpan data")))
                    }
                })
            } else {
                return (reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },

}

export default UserKasir;