import React, { useState, useEffect, useContext } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { BoxInformasi, Item1, Item2 } from './BoxInformasi'
import { FaChevronLeft } from 'react-icons/fa'
import { HorizontalBar, Line } from 'react-chartjs-2';
import { makeStyles, Paper } from '@material-ui/core';
import AuthContext from '../../../context/auth-context'
import mDashboard from './Dashboard.controller'
// import moment from 'moment'
const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    }
}));
//var date = moment('2:50:00 PM', 'h:mm:ss A').add(30, 'minutes')
// console.log(moment(date).format("DD-MM-YYYY HH:mm:ss"))
const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Dashboard = (props) => {

    const { clientID, token, userLogin } = useContext(AuthContext);
    const [transaksi, setTransaksi] = useState({})
    const [payment, setPayment] = useState({})
    const [cabang, setCabang] = useState({
        aktif: 0,
        tidakaktif: 0
    })
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Grafik Harian',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgb(230, 123, 33)',
                borderColor: 'rgb(255, 117, 0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(255, 117, 0)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255, 117, 0)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: []
            }
        ]
    })

    useEffect(() => {
        var render = true
        mDashboard.loadDashboard(clientID, token, userLogin).then(resp => {
            setTransaksi(resp.transaksi)
            setPayment(resp.payment)
            setCabang(resp.cabang)
            const newData = {
                ...data,
                labels: resp.namaOutlet,
                datasets: [
                    {
                        ...data.datasets[0],
                        data: resp.nilaiPenjualan
                    }
                ]
            }
            setData(newData);
        })
        return () => {
            render = false
        };
    }, [clientID, token])

    const goBack = () => {
        props.history.push(`/laporan/menu`)
    }
    return (
        <React.Fragment>
            <main>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Dashboard</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '45px' }}>
                        <BoxInformasi bg="bg-totPenerimaan" bgHari="" nama="Total Penerimaan" jumlah={numberWithCommas(transaksi.totalPenjualan || 0)} value={(
                            <Item1 judul="Total Penjualan" total={numberWithCommas(transaksi.totalPenerimaan || 0)} />
                        )} value2={(
                            <Item2 judul="Total Return" total={numberWithCommas(transaksi.totalRetur || 0)} />
                        )} />
                        <BoxInformasi bg="bg-todTrans" nama="Total Transaksi" jumlah={`${transaksi.totalTransaksi || 0} Transaksi`} value={(
                            <Item1 judul="Transaksi Penjualan" total={transaksi.totalTransaksi || 0} />
                        )} value2={(
                            <Item2 judul="Transaksi Return" total={transaksi.totalRetur} />
                        )} />
                        <BoxInformasi bg="bg-todCabang" nama="Total Cabang" jumlah={`${cabang.aktif + cabang.tidakaktif} Cabang`} cabang aktif={cabang.aktif} nonAktif={cabang.tidakaktif} />
                        <BoxInformasi bg="bg-pembayaran" nama="Pembayaran" Pembayaran tunai={numberWithCommas(payment.tunai)} nonTunai={numberWithCommas(payment.nonTunai)} />
                    </Row>
                    <div className={useStyles.root}>
                        <Paper className={useStyles.paper}>
                            <HorizontalBar data={data} />
                        </Paper>
                    </div>
                    <br />
                </Container>
            </main>
        </React.Fragment>
    )
}
export default Dashboard;