/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col } from 'react-bootstrap';
import { Row as RowReactStarp, Col as ColReactStarp } from "reactstrap";
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, Dialog, DialogContent, DialogContentText, Slide, Typography, AppBar, Toolbar, TextField, FormControl, InputLabel, MenuItem, Select, Checkbox } from '@material-ui/core';
import useStyles from '../../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import Dropdown from 'react-bootstrap/Dropdown'
import mControl from './UserKasir.Controler'
import { FaChevronLeft } from 'react-icons/fa';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import AuthContext from '../../../context/auth-context'
import validate from 'validate.js'
import MyPopover from "../../../MyPopover/MyPopover";

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: "namaakses", label: "Nama Akses" }


];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell>Action</TableCell>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const HakAksesKasir = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = 0;
    const defaultrowsPerPage = 5;
    const { clientID, token } = useContext(AuthContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [dense, setDense] = React.useState(true);
    const [isRefresh, setIsRefresh] = useState(true)
    const [rows, setRows] = useState([])
    const scroll = 'paper';
    const [open, setOpen] = useState(false);
    const [hakAkses, setHakAkses] = useState([])
    const [form, setForm] = useState({
        kodeakses: '',
        namaakses: '',
        hakKategori: false,
        hakSubKategori: false,
        hakBarang: false,
        hakPayment: false,
        hakBuatTransaksi: false,
        hakVoidTransaksi: false,
        hakCreatePayment: false,
        hakLaporanKategori: false,
        hakLaporanSubKategori: false,
        hakLaporanBarang: false,
        hakLaporanTransaksi: false,
        hakLaporanPembayaran: false,
        hakLaporanTaxService: false,
        hakLaporanHarian: false,
        hakSettingTaxService: false,
        hakPrinterSetting: false,
        hakUserAccess: false,
        hakRegisterUser: false,
        hakListUser: false,
        hakViewHistory: false,
        hakReprintBill: false,
        hakResentEmail: false,
        hakImportData: false,
        hakVoidHistory: false,
        hakHapusUser: false,
        hakEditUser: false,
        hakDiskon: false,
        hakVoidAfterPreBill: false,
        hakVoidOrderan: false,
        hakPrintPreBill: false,
        hakSentEmail: false,
        hakCashDrawer: false,
        hakKasMasuk: false,
        hakKasKeluar: false,
        hakLaporanSesi: false,
        hakBukaLaci: false,
        cetakRekapProdukTotal: false,
        hakReprintKitchen: false,
        hakLihatSemuaTransaksi: false,
        hakTutupSesi: false,
    })
    useEffect(() => {
        var render = true
        if (isRefresh) {
            setRows([])
            mControl.loadHakAkses(kodeoutlet, clientID, token).then(resp => {
                if (render) setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh]);

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const loadingRow = [0, 1, 2, 3, 4];
    function handleCloseMoidalDetPem() {
        setOpen(false);
    }
    const openEdit = (data) => {
        mControl.detailHakAkses(kodeoutlet, clientID, token, data.kodeakses).then(resp => {
            setForm({
                kodeakses: resp.kodeakses,
                namaakses: resp.namaakses,
                hakKategori: resp.hakKategori || false,
                hakSubKategori: resp.hakSubKategori || false,
                hakBarang: resp.hakBarang || false,
                hakPayment: resp.hakPayment || false,
                hakBuatTransaksi: resp.hakBuatTransaksi || false,
                hakVoidTransaksi: resp.hakVoidTransaksi || false,
                hakCreatePayment: resp.hakCreatePayment || false,
                hakLaporanKategori: resp.hakLaporanKategori || false,
                hakLaporanSubKategori: resp.hakLaporanSubKategori || false,
                hakLaporanBarang: resp.hakLaporanBarang || false,
                hakLaporanTransaksi: resp.hakLaporanTransaksi || false,
                hakLaporanPembayaran: resp.hakLaporanPembayaran || false,
                hakLaporanTaxService: resp.hakLaporanTaxService || false,
                hakLaporanHarian: resp.hakLaporanHarian || false,
                hakSettingTaxService: resp.hakSettingTaxService || false,
                hakPrinterSetting: resp.hakPrinterSetting || false,
                hakUserAccess: resp.hakUserAccess || false,
                hakRegisterUser: resp.hakRegisterUser || false,
                hakListUser: resp.hakListUser || false,
                hakViewHistory: resp.hakViewHistory || false,
                hakReprintBill: resp.hakReprintBill || false,
                hakResentEmail: resp.hakResentEmail || false,
                hakImportData: resp.hakImportData || false,
                hakVoidHistory: resp.hakVoidHistory || false,
                hakHapusUser: resp.hakHapusUser || false,
                hakEditUser: resp.hakEditUser || false,
                hakDiskon: resp.hakDiskon || false,
                hakVoidAfterPreBill: resp.hakVoidAfterPreBill || false,
                hakVoidOrderan: resp.hakVoidOrderan || false,
                hakPrintPreBill: resp.hakPrintPreBill || false,
                hakSentEmail: resp.hakSentEmail || false,
                hakCashDrawer: resp.hakCashDrawer || false,
                hakKasMasuk: resp.hakKasMasuk || false,
                hakKasKeluar: resp.hakKasKeluar || false,
                hakLaporanSesi: resp.hakLaporanSesi || false,
                hakBukaLaci: resp.hakBukaLaci || false,
                cetakRekapProdukTotal: resp.cetakRekapProdukTotal || false,
                hakReprintKitchen: resp.hakReprintKitchen || false,
                hakLihatSemuaTransaksi: resp.hakReprintKitchen || false,
                hakTutupSesi: resp.hakTutupSesi || false,
            })
            setOpen(true)
        }).catch(err => {
            console.error(err);
        })
    }
    const handleChange = (ev) => {
        const key = ev.target.id || ev.target.name;
        let value
        if (key === "namaakses") {
            value = ev.target.value;
        } else {
            value = ev.target.checked;
        }
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const validateForm = () => {
        const { namaakses, } = form;
        const promKategori = new Promise((resolve, reject) => {
            const constraint = {
                namaakses: {
                    length: {
                        minimum: 2,
                        message: "Nama Akses minimal terdiri dari 2 huruf"
                    },
                },
            }
            const validator = validate({ namaakses }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promKategori]);
    }
    const handleSaveUser = () => {
        validateForm().then(() => {
            mControl.saveHakAksesKasir(kodeoutlet, clientID, token, form).then(resp => {
                alert("Hak akses kasir berhasil disimpan")
                setIsRefresh(true)
                setOpen(false)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            alert(errorMessage)
        })

    }
    const handleHapus = (data) => {
        const oke = window.confirm(`Yakin ingin hapus hak akses ${data.namaakses}`)
        if (oke) {
            mControl.deleteHakAksesKasir(kodeoutlet, clientID, token, data.kodeakses).then(resp => {
                alert("Hak akses berhasil dihapus")
                setIsRefresh(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }
    const addAkses = () => {
        setForm({
            kodeakses: '',
            namaakses: '',
            hakKategori: false,
            hakSubKategori: false,
            hakBarang: false,
            hakPayment: false,
            hakBuatTransaksi: false,
            hakVoidTransaksi: false,
            hakCreatePayment: false,
            hakLaporanKategori: false,
            hakLaporanSubKategori: false,
            hakLaporanBarang: false,
            hakLaporanTransaksi: false,
            hakLaporanPembayaran: false,
            hakLaporanTaxService: false,
            hakLaporanHarian: false,
            hakSettingTaxService: false,
            hakPrinterSetting: false,
            hakUserAccess: false,
            hakRegisterUser: false,
            hakListUser: false,
            hakViewHistory: false,
            hakReprintBill: false,
            hakResentEmail: false,
            hakImportData: false,
            hakVoidHistory: false,
            hakHapusUser: false,
            hakEditUser: false,
            hakDiskon: false,
            hakVoidAfterPreBill: false,
            hakVoidOrderan: false,
            hakPrintPreBill: false,
            hakSentEmail: false,
            hakCashDrawer: false,
            hakKasMasuk: false,
            hakKasKeluar: false,
            hakLaporanSesi: false,
            hakBukaLaci: false,
            cetakRekapProdukTotal: false,
            hakReprintKitchen: false,
            hakLihatSemuaTransaksi: false,
            hakTutupSesi: false
        })
        setOpen(true)
    }
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={() => props.history.push(`/manajemenoutlet/${kodeoutlet}/pengaturan`)} className="btnBack" />Hak Akses Kasir</h3>
                        <div >
                            <Paper className={classes.paper}>
                                <div className="btnTable">
                                    <Button variant="contained" color="primary" onClick={addAkses}><AddIcon /> Add Hak Akses</Button>
                                </div>
                                <div className={classes.tableWrapper}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {rows.length == 0 && loadingRow.map(el => (
                                                <TableRow
                                                    key={el.toString()}
                                                    style={{ height: 50 }}
                                                >
                                                    <TableCell>
                                                        <Skeleton />
                                                    </TableCell>
                                                    <TableCell >
                                                        <Skeleton />
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                            {stableSort(rows, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row._id}
                                                            className={(row.statusid === 8 || row.statusid === 9) && ("bgRowCancel") || ("")}
                                                        >
                                                            <TableCell>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <TouchAppIcon id="dropdown-basic" /></Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item><Button title="Edit" variant="contained" style={{ backgroundColor: '#376275', marginRight: 10, color: "#fff", width: '145px' }} onClick={openEdit.bind(this, row)}><EditIcon /> Edit/lihat</Button></Dropdown.Item>
                                                                        <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: 'red', marginRight: 10, color: "#fff", width: '145px' }} onClick={handleHapus.bind(this, row)}><DeleteIcon /> Hapus</Button></Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.namaakses}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={7} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page"
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </div>
                    </Col>
                </Row>

            </Container>
            <Dialog
                open={open}
                onClose={handleCloseMoidalDetPem}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar>
                    <Toolbar>
                        <FaChevronLeft color="inherit" onClick={handleCloseMoidalDetPem} className="tangan" />
                        <Typography variant="h6">
                            Add / Update Hak Akses</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers={scroll === 'paper'} className="DialogFont bodyDialog">
                    <DialogContentText className="colorWhite">
                        <Row>
                            <Col xs={12} lg={12}>
                                <TextField
                                    id="namaakses"
                                    name="namaakses"
                                    label="Nama Akses"
                                    fullWidth
                                    required
                                    multiline={false}
                                    value={form.namaakses}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={12} lg={12}>
                                <div className="garis-bawah" style={{ backgroundColor: '#c3bfbe' }}>
                                    <h5 className="mLeftHeader">Manajemen Data</h5>
                                </div>
                                <Row>
                                    <Col xs={6} lg={3} sm={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakKategori || false} onChange={handleChange} />} id="hakKategori" name="hakKategori" label="Kategori" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakSubKategori || false} onChange={handleChange} />} id="hakSubKategori" name="hakSubKategori" label=" Sub Kategori" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakBarang || false} onChange={handleChange} />} id="hakBarang" name="hakBarang" label="Barang" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakPayment || false} onChange={handleChange} />} id="hakPayment" name="hakPayment" label="Payment" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakImportData || false} onChange={handleChange} />} id="hakImportData" name="hakImportData" label="Import Data" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={12}>
                                <div className="garis-bawah" style={{ backgroundColor: '#c3bfbe' }}>
                                    <h5 className="mLeftHeader">Transaksi</h5>
                                </div>
                                <Row>
                                    <Col xs={{ span: 12, order: 1 }} lg={{ span: 3, order: 1 }} md={{ span: 4, order: 1 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakBuatTransaksi || false} onChange={handleChange} />} id="hakBuatTransaksi" name="hakBuatTransaksi" label=" Buat Transaksi" />
                                    </Col>
                                    <Col xs={{ span: 12, order: 2 }} lg={{ span: 3, order: 2 }} md={{ span: 4, order: 2 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakDiskon || false} onChange={handleChange} />} id="hakDiskon" name="hakDiskon" label="  Diskon Item" />
                                    </Col>

                                    <Col xs={{ span: 12, order: 3 }} lg={{ span: 3, order: 3 }} md={{ span: 4, order: 3 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakCashDrawer || false} onChange={handleChange} />} id="hakCashDrawer" name="hakCashDrawer" label=" Buka Cash Drawer" />
                                    </Col>


                                    <Col xs={{ span: 12, order: 11 }} lg={{ span: 3, order: 4 }} md={{ span: 4, order: 4 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakVoidTransaksi || false} onChange={handleChange} />} id="hakVoidTransaksi" name="hakVoidTransaksi" label=" Void / Batal Transaksi" />
                                        <MyPopover description="Fitur ini memungkinkan Anda untuk membatalkan seluruh transaksi sebelum mencetak pre-bill. Ini berguna jika ada kesalahan atau perubahan yang perlu dilakukan sebelum mengonfirmasi pesanan." />
                                    </Col>

                                    <Col xs={{ span: 12, order: 4 }} lg={{ span: 3, order: 5 }} md={{ span: 4, order: 5 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakPrintPreBill || false} onChange={handleChange} />} id="hakPrintPreBill" name="hakPrintPreBill" label=" Print Pre-Bill" />
                                    </Col>


                                    <Col xs={{ span: 12, order: 5 }} lg={{ span: 3, order: 6 }} md={{ span: 4, order: 6 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakCreatePayment || false} onChange={handleChange} />} id="hakCreatePayment" name="hakCreatePayment" label="Pembayaran Transaksi" />
                                    </Col>

                                    <Col xs={{ span: 12, order: 6 }} lg={{ span: 3, order: 7 }} md={{ span: 4, order: 7 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakSentEmail || false} onChange={handleChange} />} id="hakSentEmail" name="hakSentEmail" label="Kirim Tagihan via Email" />
                                    </Col>

                                    <Col xs={{ span: 12, order: 12 }} lg={{ span: 3, order: 8 }} md={{ span: 4, order: 8 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakVoidAfterPreBill || false} onChange={handleChange} />} id="hakVoidAfterPreBill" name="hakVoidAfterPreBill" label="Void / Batal Transaksi (Setelah Pre Bill)" />
                                        <MyPopover description="Fitur ini memungkinkan Anda untuk membatalkan seluruh transaksi setelah mencetak pre-bill. Gunakan ini jika ada perubahan besar atau pembatalan pesanan setelah pelanggan melihat ringkasan pesanannya." />
                                    </Col>



                                    <Col xs={{ span: 12, order: 7 }} lg={{ span: 3, order: 9 }} md={{ span: 4, order: 9 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakKasMasuk || false} onChange={handleChange} />} id="hakKasMasuk" name="hakKasMasuk" label=" Kas Masuk" />
                                    </Col>
                                    <Col xs={{ span: 12, order: 8 }} lg={{ span: 3, order: 10 }} md={{ span: 4, order: 10 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakKasKeluar || false} onChange={handleChange} />} id="hakKasKeluar" name="hakKasKeluar" label=" Kas Keluar" />
                                    </Col>
                                    <Col xs={{ span: 12, order: 9 }} lg={{ span: 3, order: 11 }} md={{ span: 4, order: 11 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakBukaLaci || false} onChange={handleChange} />} id="hakBukaLaci" name="hakBukaLaci" label="Buka Laci Saat Buka dan Tutup Sesi" />
                                    </Col>


                                    <Col xs={{ span: 12, order: 12 }} lg={{ span: 3, order: 15 }} md={{ span: 4, order: 15 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakVoidOrderan || false} onChange={handleChange} />} id="hakVoidOrderan" name="hakVoidOrderan" label="Void / Batalkan Item Pesanan" />
                                        <MyPopover description=" Dengan fitur ini, Anda dapat membatalkan satu atau beberapa item dalam transaksi sebelum pelanggan membayar sepenuhnya. Ini membantu dalam mengatasi perubahan pesanan atau kesalahan dalam mencatat item tertentu." />
                                    </Col>


                                    <Col xs={{ span: 12, order: 15 }} lg={{ span: 6, order: 12 }} md={{ span: 4, order: 12 }}></Col>
                                    <Col xs={{ span: 12, order: 10 }} lg={{ span: 3, order: 12 }} md={{ span: 4, order: 12 }}>
                                        <FormControlLabel control={<Checkbox checked={form.hakReprintKitchen || false} onChange={handleChange} />} id="hakReprintKitchen" name="hakReprintKitchen" label="Cetak Ulang Dapur & Bartender" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={12}>
                                <div className="garis-bawah" style={{ backgroundColor: '#c3bfbe' }}>
                                    <h5 className="mLeftHeader">Riwayat Transaksi</h5>
                                </div>
                                <Row>
                                    <Col xs={6} lg={3} sm={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakViewHistory || false} onChange={handleChange} />} id="hakViewHistory" name="hakViewHistory" label="Melihat Riwayat Transaksi" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakResentEmail || false} onChange={handleChange} />} id="hakResentEmail" name="hakResentEmail" label="Ulang Kirim Tagihan via Email" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakReprintBill || false} onChange={handleChange} />} id="hakReprintBill" name="hakReprintBill" label=" Ulang Print Tagihan" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakVoidHistory || false} onChange={handleChange} />} id="hakVoidHistory" name="hakVoidHistory" label="  Void Settled Transaction (Void tagihan yang lunas)" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={12}>
                                <div className="garis-bawah" style={{ backgroundColor: '#c3bfbe' }}>
                                    <h5 className="mLeftHeader">Laporan</h5>
                                </div>
                                <Row>
                                    <Col xs={6} lg={3} sm={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakLaporanKategori || false} onChange={handleChange} />} id="hakLaporanKategori" name="hakLaporanKategori" label="Laporan Penjualan (Kategori)" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakLaporanSubKategori || false} onChange={handleChange} />} id="hakLaporanSubKategori" name="hakLaporanSubKategori" label="Laporan Penjualan (Sub Kategori)" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakLaporanBarang || false} onChange={handleChange} />} id="hakLaporanBarang" name="hakLaporanBarang" label="  Laporan Penjualan (Barang)" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakLaporanTransaksi || false} onChange={handleChange} />} id="hakLaporanTransaksi" name="hakLaporanTransaksi" label=" Laporan Penjualan (Transaksi)" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakLaporanPembayaran || false} onChange={handleChange} />} id="hakLaporanPembayaran" name="hakLaporanPembayaran" label="  Laporan Penjualan (Pembayaran)" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakLaporanTaxService || false} onChange={handleChange} />} id="hakLaporanTaxService" name="hakLaporanTaxService" label=" Laporan Penjualan (Diskon & Tax & Service)" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakLaporanHarian || false} onChange={handleChange} />} id="hakLaporanHarian" name="hakLaporanHarian" label="Laporan Penjualan (Harian)" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakLaporanSesi || false} onChange={handleChange} />} id="hakLaporanSesi" name="hakLaporanSesi" label="Laporan Sesi" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={12}>
                                <div className="garis-bawah" style={{ backgroundColor: '#c3bfbe' }}>
                                    <h5 className="mLeftHeader">Setting</h5>
                                </div>
                                <Row>
                                    <Col xs={6} lg={3} sm={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakSettingTaxService || false} onChange={handleChange} />} id="hakSettingTaxService" name="hakSettingTaxService" label="  Tax & Service" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakPrinterSetting || false} onChange={handleChange} />} id="hakPrinterSetting" name="hakPrinterSetting" label="Printer" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakUserAccess || false} onChange={handleChange} />} id="hakUserAccess" name="hakUserAccess" label=" User Access" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={12}>
                                <div className="garis-bawah" style={{ backgroundColor: '#c3bfbe' }}>
                                    <h5 className="mLeftHeader">User</h5>
                                </div>
                                <Row>
                                    <Col xs={6} lg={3} sm={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakRegisterUser || false} onChange={handleChange} />} id="hakRegisterUser" name="hakRegisterUser" label=" Register User" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakListUser || false} onChange={handleChange} />} id="hakListUser" name="hakListUser" label="List User" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakEditUser || false} onChange={handleChange} />} id="hakEditUser" name="hakEditUser" label="  Edit User" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakHapusUser || false} onChange={handleChange} />} id="hakHapusUser" name="hakHapusUser" label="Hapus User" />
                                    </Col>
                                    <Col xs={6} lg={3} sm={3} md={4}>
                                        <FormControlLabel control={<Checkbox checked={form.hakTutupSesi || false} onChange={handleChange} />} id="hakTutupSesi" name="hakTutupSesi" label="Hak Tutup Sesi" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20, marginBottom: 20 }}>
                            <Col xs={12} className="text-center">
                                <Button variant="contained" color="primary" fullWidth onClick={handleSaveUser}>Simpan</Button>
                            </Col>
                        </Row>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    );
}
export default HakAksesKasir;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});