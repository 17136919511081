/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, InputBase, Checkbox, Toolbar, Typography, Tooltip, IconButton, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dropdown from 'react-bootstrap/Dropdown'
import { FaChevronLeft } from 'react-icons/fa';
import useStyles from '../../css/useStylesTabel'
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Skeleton from 'react-loading-skeleton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { isArray } from "util";
import clsx from 'clsx';
import DialogEditCatatanOrder from './DialogEditCatatanOrder'
import useToolbarStyles from '../../css/useToolbarStyles'
import DialogConfirm from '../../Dialog/DialogConfirm'
import DialogInfo from '../../Dialog/DialogInfo'
import mCatatan from './CatatanOrder.controller'
import windowSize from 'react-window-size';
import CurrencyFormat from 'react-currency-format';

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    {
        id: "kodecatatan", numeric: false, disablePadding: true, label: "kode"
    },
    { id: "catatan", numeric: true, disablePadding: false, label: "Catatan" },
    { id: "harga", numeric: true, disablePadding: false, label: "Harga" },


];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
        numSelected,
        rowCount,
        onSelectAllClick
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {/* <TableCell style={{ width: ' 10px' }}>
                    <span className="checkBoxTabel">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </span>
                </TableCell> */}
                <TableCell>Action</TableCell>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};
const EnhancedTableToolbar = props => {
    const { numSelected, itemSelect } = props;
    const [open, setOpen] = React.useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dataDialog, setdataDialog] = useState()
    const [dialogtext, setDialogtext] = useState()
    const openDialogEdit = () => {
        setOpen(true);
    }
    const classes = useToolbarStyles();
    const konfirmasiDeleteSelecter = (data) => {
        setOpenConfirmDelete(true);
        setdataDialog(data)
        setDialogtext(`<p>Yakin Anda Ingin Menghapus ${data.length} kategori ini?<br/>Data tidak dapat dikembalikan setelah berhasil dihapus<p/>`)
    }
    const deleteSelected = (data) => {
        // TODO : Query
        setOpenConfirmDelete(false);
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${data.length} data berhasil di hapus<p/>`)
    }
    return numSelected > 0 && (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                <Typography color="inherit" variant="subtitle1">{numSelected} selected</Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip title="Edit" flex onClick={openDialogEdit}>
                            <IconButton aria-label="edit">
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Delete" flex onClick={konfirmasiDeleteSelecter.bind(this, itemSelect)}>
                            <IconButton aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
            <DialogEditCatatanOrder open={open} setOpen={setOpen} item={itemSelect} />
            <DialogConfirm open={openConfirmDelete} setOpen={setOpenConfirmDelete} text={dialogtext} data={dataDialog} oke={deleteSelected} />
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
        </Toolbar>
    )
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
const initialValue = {
    isLoading: false,
    data: [],
    error: null,
    isReload: true,
    search: ''
}

const reducer = (state, action) => {
    switch (action.type) {

        default: return state
    }
}
const CatatanOrder = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const kodebarang = props.match.params.kodebarang;
    const defaultPage = parseInt(props.match.params.page, 10) - 1;
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const [state, dispatch] = useReducer(reducer, initialValue)
    const { clientID, token } = useContext(AuthContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [isRefresh, setIsRefresh] = useState(true)
    const [rows, setRows] = useState([])
    const [search, setSearch] = useState('')
    const [noData, setNoData] = useState(false)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dataDialog, setdataDialog] = useState()
    const [dialogtext, setDialogtext] = useState()
    const [namasubkategori, setNamasubkategori] = useState()
    const [isDesktop, setIsDesktop] = useState(true)
    const [halDes, setHalDes] = useState(2)
    useEffect(() => {
        mCatatan.getKategori(kodeoutlet, clientID, token, kodebarang).then(resp => {
            if (render) setNamasubkategori(resp.namasubkategori)
        }).catch(err => {
            console.error(err);
        })
        if (props.windowWidth < 1024) {
            setIsDesktop(false)
            setHalDes(1)
        } else {
            setIsDesktop(true);
            setHalDes(2)
        }
        var render = true
        if (isRefresh) {
            mCatatan.loadData(kodeoutlet, clientID, token, kodebarang).then(resp => {
                if (resp.length <= 0) {
                    setNoData(true);
                    setRows(resp);
                } else {
                    setNoData(false);
                    setRows(resp);
                }
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh, kodebarang, props.windowWidth]);

    const addCatatan = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/catatan-add/${kodebarang}/${defaultPage + 1}/${defaultrowsPerPage}`);
    }
    const handleEdit = (kodecatatan) => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/catatan-detail/${kodecatatan}/${kodebarang}/${defaultPage + 1}/${defaultrowsPerPage}`)
    }
    const konfirmasiDelete = (data) => {
        setOpenConfirmDelete(true);
        setdataDialog(data)
        setDialogtext(`<p>Yakin Anda Ingin Menghapus ${data.catatan} ini?<br/>Data tidak dapat dikembalikan setelah berhasil dihapus<p/>`)
    }
    const handleDelete = (data) => {
        mCatatan.HapusCatatan(kodeoutlet, data.kodecatatan, clientID, token).then(resp => {
            if (resp) {
                setOpenConfirmDelete(false);
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${data.catatan} berhasil di hapus<p/>`)
                setIsRefresh(true);
            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Gagal hapus ${err[0].message}<p/>`)
            } else if (typeof err == 'error') {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${err.message}<p/>`)
            } else {
                console.log(err);
            }
        })
    }
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/subkategori/${defaultPage + 1}/${defaultrowsPerPage}`)
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.kode);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }
    const handleClick = (event, kode) => {
        const selectedIndex = selected.indexOf(kode);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, kode);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const isSelected = name => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const searchHandler = (val) => {
        setSearch(val.target.value)
    }
    const formSubmitHandler = (event) => {
        setNoData(false);
        if (search) {
            mCatatan.search(kodeoutlet, clientID, token, kodebarang, search).then(resp => {
                if (resp.length <= 0) {
                    setNoData(true);
                    setRows(resp);
                } else {
                    setNoData(false);
                    setRows(resp);
                }
            })
        } else {
            setIsRefresh(true)
        }
    }
    const loadingRow = [0, 1, 2, 3, 4];
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            formSubmitHandler()
          }
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Catatan Order {namasubkategori}</h3>
                    <Button variant="contained" color="primary" onClick={addCatatan}>  <AddCircleOutlineIcon />Tambah</Button>
                    <div className={classes.root}>
                        <EnhancedTableToolbar numSelected={selected.length} itemSelect={selected} />
                        <Paper className={classes.paper}>
                            <div className="btnTable">
                                <Row>
                                    <Col>
                                        <div className={classes.search}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase onChange={searchHandler}  onKeyPress={handleKeyPress}
                                                placeholder="Search…"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                                inputProps={{ 'aria-label': 'search' }}
                                            />
                                            <Button style={{ float: 'right' }} onClick={formSubmitHandler} variant="contained" color="primary">Cari</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className={classes.tableWrapper}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                >
                                    <Container>
                                        <Row>
                                            <Col lg={6} xs={12} md={12}>
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={rows.length}
                                                />
                                                <TableBody>
                                                    {rows.length == 0 && !noData && loadingRow.map(el => (
                                                        <TableRow
                                                            key={el.toString()}
                                                            style={{ height: 50 }}
                                                        >
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))

                                                    }
                                                    {noData && (
                                                        <TableRow style={{ height: 15 * emptyRows }}>
                                                            <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                                <b>Tidak ada data</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {stableSort(rows.filter(data => {
                                                        if (isDesktop) {
                                                            var h = page;
                                                            if (page > 0) {
                                                                h = page * 5;
                                                            }
                                                            const hal = 0 + h
                                                            return data.id > hal
                                                        }
                                                        return data;
                                                    }), getSorting(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row, index) => {
                                                            //  console.log(row)
                                                            const substrKodecatatan = row.kodecatatan.substring(0, 9)
                                                            //const isItemSelected = isSelected(row.kode);
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                <TouchAppIcon id="dropdown-basic" /></Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item><Button title="Edit" variant="contained" style={{ backgroundColor: '#376275', marginRight: 10, color: "#fff", width: '135px' }} onClick={handleEdit.bind(this, row.kodecatatan)}><EditIcon /> Edit/lihat</Button></Dropdown.Item>
                                                                                <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: 'red', marginRight: 10, color: "#fff", width: '135px' }} onClick={konfirmasiDelete.bind(this, row)}><DeleteIcon /> Hapus</Button></Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" >
                                                                        {substrKodecatatan}...
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.catatan}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                    <CurrencyFormat value={row.harga} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 49 * emptyRows }}>
                                                            <TableCell colSpan={7} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Col>
                                            {isDesktop && (
                                                <Col lg={6} xs={12} md={12}>
                                                    <EnhancedTableHead
                                                        classes={classes}
                                                        numSelected={selected.length}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onSelectAllClick={handleSelectAllClick}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={rows.length}
                                                    />
                                                    <TableBody>
                                                        {rows.length == 0 && !noData && loadingRow.map(el => (
                                                            <TableRow
                                                                key={el.toString()}
                                                                style={{ height: 50 }}
                                                            >
                                                                <TableCell>
                                                                    <Skeleton />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row" >
                                                                    <Skeleton />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Skeleton />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Skeleton />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))

                                                        }
                                                        {noData && (
                                                            <TableRow style={{ height: 15 * emptyRows }}>
                                                                <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                                    <b>Tidak ada data</b>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                        {stableSort(rows.filter(data => {
                                                            const hal = (page + 1) * rowsPerPage
                                                            return data.id > hal
                                                        }), getSorting(order, orderBy))
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((row, index) => {
                                                                const substrKodecatatan = row.kodecatatan.substring(0, 9)
                                                                //const isItemSelected = isSelected(row.kode);
                                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        key={index}
                                                                    >
                                                                        <TableCell>
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                    <TouchAppIcon id="dropdown-basic" /></Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item><Button title="Edit" variant="contained" style={{ backgroundColor: '#376275', marginRight: 10, color: "#fff", width: '135px' }} onClick={handleEdit.bind(this, row.kodecatatan)}><EditIcon /> Edit/lihat</Button></Dropdown.Item>
                                                                                    <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: 'red', marginRight: 10, color: "#fff", width: '135px' }} onClick={konfirmasiDelete.bind(this, row)}><DeleteIcon /> Hapus</Button></Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row" >
                                                                            {substrKodecatatan}...
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.catatan}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                        <CurrencyFormat value={row.harga} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        {emptyRows > 0 && (
                                                            <TableRow style={{ height: 49 * emptyRows }}>
                                                                <TableCell colSpan={7} />
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Col>
                                            )}

                                        </Row>
                                    </Container>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage * halDes}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "previous page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "next page"
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label="Dense padding"
                        />
                    </div>
                </Col>
            </Row>
            <DialogConfirm open={openConfirmDelete} setOpen={setOpenConfirmDelete} text={dialogtext} data={dataDialog} oke={handleDelete} />
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
        </Container>
    );
}
export default windowSize(CatatanOrder);