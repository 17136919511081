import React from 'react'
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
const PaymentForm = (props) => {
    const defaultPage = props.defaultPage * 1 || 1;
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const kodeoutlet = props.kodeoutlet
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/payment/${defaultPage}/${defaultrowsPerPage}`)
    }

    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        props.handleChange(key, value)
    }
    const handleChangeCheckBox = name => event => {
        var value = event.target.checked;
        const key = name;
        props.handleChange(key, value)
    };
    return (
        <Row>
            <Col xs={12}>
                <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} style={{
                    color: " rgba(255,117,0,1)", cursor: 'pointer'
                }} /> Payment Form</h4>
            </Col>
            <Col xs={12} lg={12}>
                <TextField
                    id="namapayment"
                    name="namapayment"
                    label="Nama Payment"
                    fullWidth
                    required
                    multiline={false}
                    value={props.namapayment}
                    onChange={handleChange}
                />
            </Col>
            <Col xs={12} lg={12}>
                {/* <FormControlLabel id="isAutoFill" name="isAutoFill" onChange={handleChange} control={<Checkbox checked={props.isAutoFill} />} label="Jumlah pembayaran diisi sistem seacara otomatis saat pembayaran" /> */}
                {/* <FormControlLabel id="isCard" name="isCard" control={<Checkbox onChange={handleChange} checked={props.isCard} />} label="Pembayaran menggunkan mesin EDC" /> */}
                <FormControlLabel control={<Checkbox checked={props.isAutoFill}
                    onChange={handleChangeCheckBox('isAutoFill')}
                    value="isAutoFill" />} label="Jumlah pembayaran diisi sistem secara otomatis saat pembayaran" />
                <FormControlLabel control={<Checkbox checked={props.isCard}
                    onChange={handleChangeCheckBox('isCard')}
                    value="isCard" />} label="Pembayaran menggunkan mesin EDC" />
            </Col> 

            <Col xs={12} lg={12}>
            </Col>
        </Row>

    )
}
export default PaymentForm;