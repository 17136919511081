import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import mLaporan from '../Laporan.controler'
import moment from 'moment-timezone'
import CurrencyFormat from 'react-currency-format';
import AuthContext from '../../../../context/auth-context'
import { makeStyles } from '@material-ui/core/styles';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const SessionDetail = (props) => {
    const classes = useStyles();
    const dataDetail = props.dataDetail
    const IDSession = props.IDSession
    const kodeoulet = props.kodeoulet
    const [isRefresh, setIsRefresh] = useState(true)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [itemDetail, setItemDetail] = useState([])
    const [subkategori, setSubkategori] = useState([])
    const [hasil, setHasil] = useState({})
    const [pembayaran, setPembayaran] = useState({})
    const [listNonTunai, setListNonTunai] = useState([])
    const [payHitung, setPayHitung] = useState({})
    const [kasKeluar, setKasKeluar] = useState({})
    const [kasMasuk, setKasMasuk] = useState({})
    const [tunai, setTunai] = useState(0)
    var subTotal = 0
    let totalNonTunai = 0, discountAmount = 0, taxAmount = 0, serviceAmount = 0, totalItemLain= 0;

    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.laporanSessionDetail(clientID, token, kodeoulet, props.idOutlet, IDSession).then(resp => {
                setTunai(resp.tunai[0] || 0)
                setSubkategori(resp.subkategoriFinal)
                setItemDetail(resp.detaiItemGroupSort)
                setListNonTunai(resp.listNonTunai)
                setPayHitung(resp.payhitung)
                setKasKeluar(resp.kaskeluar)
                setKasMasuk(resp.kasmasuk)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])

    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Row >
                <Col xs={12} c className={`${classes.contentBody} bodySessionDetail`}>
                    <ReactHTMLTableToExcel
                        className="buttonExportExcel"
                        table="laporanPerSession"
                        filename={`Laporan per session id ${IDSession}`}
                        sheet="Sheet1"
                        buttonText={namaExport()} />
                    <div className="sessionDetail">
                        <Table id="laporanPerSession" bordered hover responsive size="sm">
                            <thead>
                                <tr>
                                    <th colSpan="3">Mulai : {moment(dataDetail.tanggalBuka).format("DD-MM-YYYY HH:mm")}</th>
                                </tr>
                                {dataDetail.userTutup && (
                                    <tr>
                                        <th colSpan="3">Selesai : {(moment(dataDetail.tanggalTutup).format("DD-MM-YYYY HH:mm"))}</th>
                                    </tr>
                                )}
                                <tr>
                                    <th colSpan="3">Akun : {dataDetail.userBuka} </th>
                                </tr>
                                <tr>
                                    <th colSpan="3">Saldo Awal : {numberWithCommas(dataDetail.saldoAwal)} </th>
                                </tr>
                                <tr>
                                    <th colSpan="3">Jumlah Tamu : {dataDetail.pengunjung} </th>
                                </tr>
                            </thead>
                            <tbody>
                                {subkategori.map((subkategori, index) => {
                                    console.log(subkategori)
                                    var totalItem = 0
                                    return (
                                        <React.Fragment key={index}>
                                            <tr className="boderSession">
                                                <td colSpan="3">{subkategori.namasubkategori}</td>
                                            </tr>
                                            {itemDetail.filter(item => {
                                                return item.kodesubkategori === subkategori.kodesubkategori
                                            }).map((item, idx) => {
                                                totalItem += item.jumlah
                                                subTotal += item.jumlah
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.namabarang}</td>
                                                        <td style={{ width: '20px' }}>{item.qty}</td>
                                                        <td className="text-right"><CurrencyFormat value={parseFloat(Math.round(item.jumlah * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} /></td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className="boderSession">
                                                <td colSpan="2">Total</td>
                                                <td className="text-right"><CurrencyFormat value={parseFloat(Math.round(totalItem * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} /></td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                                <React.Fragment>
                                    <tr className="boderSession">
                                        <td colSpan="3">~</td>
                                    </tr>
                                    {itemDetail.filter(item => {
                                        return item.kodesubkategori === null
                                    }).map((item, idx) => {
                                        totalItemLain += item.jumlah
                                        return (
                                            <tr key={idx}>
                                                <td>{item.namabarang}</td>
                                                <td style={{ width: '20px' }}>{item.qty}</td>
                                                <td className="text-right"><CurrencyFormat value={parseFloat(Math.round(item.jumlah * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} /></td>
                                            </tr>
                                        )
                                    })}
                                    <tr className="boderSession">
                                        <td colSpan="2">Total</td>
                                        <td className="text-right"><CurrencyFormat value={parseFloat(Math.round(totalItemLain * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} /></td>
                                    </tr>
                                </React.Fragment>
                                <tr className="boderSession">
                                    <th colSpan="2">Sub Total</th>
                                    <th className="text-right">
                                        {numberWithCommas(subTotal + totalItemLain)}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="2">Diskon</th>
                                    <th className="text-right">
                                        {numberWithCommas(payHitung.discountAmount)}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="2">Service</th>
                                    <th className="text-right">
                                        {numberWithCommas(payHitung.serviceAmount)}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="2">Tax</th>
                                    <th className="text-right">
                                        {numberWithCommas(payHitung.taxAmount)}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="2">Total</th>
                                    <th className="text-right">
                                        {numberWithCommas((subTotal + totalItemLain) - payHitung.discountAmount + payHitung.taxAmount + payHitung.serviceAmount)}
                                    </th>
                                </tr>
                                <tr className="boderSession">
                                    <th colSpan="2">Tunai</th>
                                    <th className="text-right">
                                        {tunai.amount ? numberWithCommas(tunai.amount) : 0}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="2">Kas Keluar</th>
                                    <th className="text-right">
                                        {kasKeluar.total ? numberWithCommas(kasKeluar.total) : 0}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="2">Kas Masuk</th>
                                    <th className="text-right">
                                        {kasMasuk.total ? numberWithCommas(kasMasuk.total) : 0}
                                    </th>
                                </tr>
                                {dataDetail.userTutup && (
                                    <React.Fragment>
                                        <tr>
                                            <th colSpan="2">Kas Akhir</th>
                                            <th className="text-right">
                                                {numberWithCommas(tunai.amount + dataDetail.saldoAwal)}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan="2">Saldo Akhir</th>
                                            <th className="text-right">
                                                {numberWithCommas(dataDetail.saldoAkhir)}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan="2">Selisih</th>
                                            <th className="text-right">
                                                {numberWithCommas((dataDetail.saldoAkhir - (dataDetail.saldoAwal + tunai.amount) + kasKeluar.total + kasMasuk.total))}
                                            </th>
                                        </tr>
                                    </React.Fragment>
                                )}
                                {listNonTunai.map((data, index) => {
                                    totalNonTunai += data.amount * 1
                                    return (
                                        <tr key={index} className={index == 0 && ("boderSession")}>
                                            <th colSpan="2">{data.namapayment}</th>
                                            <th className="text-right"><CurrencyFormat value={parseFloat(Math.round(data.amount * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} /></th>
                                        </tr>
                                    )
                                })}

                                <tr>
                                    <th colSpan="2">Total Non Tunai</th>
                                    <th className="text-right"><CurrencyFormat value={parseFloat(Math.round(totalNonTunai * 100) / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} /></th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default SessionDetail;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));