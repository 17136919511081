import GQL from '../../GQL';

const ManajemenOutlet = {
  Outlet: (userLogin, clientID, token, kodeoutlet) => {
    return new Promise((resolve, reject) => {
      const query = `
      query {
        getOutlet(idOutlet:${kodeoutlet}){
          kodeoutlet
            namaoutlet
            tipeOutlet
            usePoktan
         }
    }

      `
      GQL(query, clientID, token).then(resp => {
        if(resp.errors) return(reject(resp.errors));;
        if(resp && resp.data && resp.data.getOutlet != null){
          return(resolve(resp.data.getOutlet));
        }else{
          return(reject(new Error("Gagal menyimpan data")))
        }
      }).catch(err => reject(err));
    })
  },
  claimVoucher: (clientID, token,kodeoutlet, kodevoucher) => {
    return new Promise((resolve, reject) => {
        const query = `
            mutation{
              claimVoucher(kodevoucher:"${kodevoucher}",kodeoutlet:"${kodeoutlet}")
            }
        `
        GQL(query, clientID, token).then(resp => {
            if (resp.errors) return (reject(resp.errors));;
            if (resp.data && resp.data.claimVoucher) return (resolve(resp.data.claimVoucher));
            return (resolve([]))
        }).catch(err => {
            return (reject(err));
        })
    })
},
}

export default ManajemenOutlet;