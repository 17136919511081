import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import mControl from './Poktan.Controler'


const Modal = (props) => {
    const dataSelect = props.dataSelect
    const [password, setPassword] = useState('')
    const clientID = props.clientID
    const token = props.token

    const handleClose = () => {
        props.setOpen(false);
    };


    const simpan = () => {
        if (password < 5) return alert("Password minimal 5 digit")
        mControl.resetUserPoktan(clientID, token, dataSelect.id, password).then(resp => {
            if (resp) {
                alert("Data Berhasil Disimpan")
                handleClose()
                props.setIsRefresh(true)
            } else return alert("Gagal simpan data")
        }).catch(err => {
            if (err.length > 0) {
                alert(err[0].message)
            } else if (typeof err) {
                alert(err.message)
            } else {
                console.log(err);
            }
        })
    }


    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
            <DialogContent>
                <TextField
                    disabled
                    margin="dense"
                    id="username"
                    label="Username"
                    value={dataSelect.username}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="New Password"
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    value={password}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <Button onClick={simpan} color="primary">Reset Password</Button>
            </DialogActions>
        </Dialog>
    )
}
export default Modal;
