/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import BarangForm from './BarangForm'
import mBarang from './Barang.controller'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util';
import validate from 'validate.js';
import DialogInfo from '../../Dialog/DialogInfo'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import numberWithCommas from '../../Helper/numberWithCommas'
import ModalVarint from './Modal.Variant'
import mControl from './Barang.controller'

const BarangDetail = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const kodebarang = props.match.params.kodebarang;
    const defaultPage = parseInt(props.match.params.page, 10);
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const { clientID, token } = useContext(AuthContext);
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoadVariant, setIsLoadVariant] = useState(true)
    const [isModalVariant, setIsModalVariant] = useState(false)
    const [detVariant, setDetVariant] = useState({})
    const [listVariant, setListVariant] = useState([])
    const [listTopping, setListTopping] = useState([])
    const [state, setState] = useState({
        kodebarang: '',
        namabarang: '',
        kategori: {
            kodekategori: '',
            namakategori: ''
        },
        subkategori: {
            kodesubkategori: '',
            namasubkategori: ''
        },
        hargasatuan: 0,
        hargagojek: 0,
        hargagrab: 0,
        hargalain: 0,
        hargalain2: 0,
        satuan: '',
        printer: 'none',
        isStock: false,
        favorite: false,
        useVarian: false,
        bgColor: null,
        colorText: null,
        topping: ''
    })
    const [listKategori, setListKategori] = useState([])
    const [listSubKategori, setListSubKategori] = useState([])
    useEffect(() => {
        mBarang.loadDetail(kodeoutlet, kodebarang, clientID, token).then(resp => {
            if (!resp.barangApp.printer || resp.barangApp.printer === 'null') resp.barangApp.printer = 'none';
            setState(resp.barangApp)
            setListKategori(resp.listKategoriApp)
            setListSubKategori(resp.listSubKategoriApp)
            setListTopping(resp.listToppingGroup || [])
            if (resp.barangApp.variant) setListVariant(resp.barangApp.variant)
        }).catch(err => {
            console.log(err);
        }).finally(() => setIsLoadVariant(false))
        return () => {
        };
    }, [kodeoutlet, kodebarang, clientID, token, isLoadVariant])

    const handleChange = (key, value) => {
        var newState = { ...state }
        if (key == 'kategori') {
            newState.kategori.kodekategori = value;
        } else if (key == 'subkategori') {
            newState.subkategori.kodesubkategori = value;
        } else {
            newState[key] = value;
        }
        setState(newState);
    }
    const validateForm = () => {
        const { namabarang, kategori, subkategori, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock, printer } = state;
        const kategoriFix = kategori.kodekategori
        const subKategoriFix = subkategori.kodesubkategori
        const promBarang = new Promise((resolve, reject) => {
            const constraint = {
                namabarang: {
                    length: {
                        minimum: 3,
                        message: "Nama Barang minimal terdiri dari 3 huruf"
                    },
                },
                kategoriFix: {
                    length: {
                        minimum: 1,
                        message: "Kategori Wajib isi"
                    },
                },
                subKategoriFix: {
                    length: {
                        minimum: 1,
                        message: "Sub Kategori Wajib Isi"
                    }
                },
                satuan: {
                    length: {
                        minimum: 1,
                        message: "Satuan Wajib Isi"
                    }
                },
                hargasatuan: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga satuan yang sesuai"
                    }
                },
                hargagojek: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga gojek yang sesuai"
                    }
                },
                hargagrab: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga grab yang sesuai"
                    }
                },
                hargalain: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga lain yang sesuai"
                    }
                }
            }
            const validator = validate({ namabarang, kategoriFix, subKategoriFix, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock, printer }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promBarang]);
    }
    const handleSimpan = () => {
        setOpenConfirmInfo(true)
        validateForm().then(() => {
            const { kodebarang, namabarang, kategori, subkategori, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, satuan, isStock, printer, bgColor, colorText, favorite, useVarian, topping } = state;
            mBarang.updateBarang(kodeoutlet, kodebarang, namabarang, kategori.kodekategori, subkategori.kodesubkategori, satuan, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, isStock, printer, clientID, token, bgColor, colorText, favorite, useVarian, topping).then((resp) => {
                setOpenConfirmInfo(true)
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>data barang berhasil di update<p/>`)
                    setDialogUrl(`/manajemenoutlet/${kodeoutlet}/barang/${defaultPage}/${defaultrowsPerPage}`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext("<p>Data gagal diupdate</p>")
                }
            }).catch(err => {
                setOpenConfirmInfo(true)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setOpenConfirmInfo(true)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }
    const handleAddVarint = () => {
        setDetVariant({})
        setIsModalVariant(true)
    }
    const handleEdit = (data) => {
        setDetVariant(data)
        setIsModalVariant(true)
    }

    const handleHapus = (data) => {
        const ok = window.confirm(`Yakin ingin hapus variant ${data.namabarang}`);
        if (ok == true) {
            mControl.hapusVariantBarang(kodeoutlet, clientID, token, state.kodebarang, data.kodebarang).then(resp => {
                if (resp) {
                    alert(`${data.namabarang} berhasil di hapus`)
                    setIsLoadVariant(true);
                } else {
                    alert(`${data.namabarang} Gagal Di hapus!`)
                }
            }).catch(err => {
                if (isArray(err) && err.length > 0) {
                    alert(`Gagal hapus ${err[0].message}`)
                } else if (typeof err == 'error') {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }
    return (
        <Container>
            <BarangForm
                history={props.history}
                kodeoutlet={kodeoutlet}
                kodebarang={state.kodebarang}
                listKategori={listKategori}
                listSubKategori={listSubKategori}
                namabarang={state.namabarang}
                kategori={state.kategori}
                subkategori={state.subkategori}
                satuan={state.satuan}
                hargasatuan={state.hargasatuan}
                hargagojek={state.hargagojek}
                hargagrab={state.hargagrab}
                hargalain={state.hargalain}
                hargalain2={state.hargalain2}
                isStock={state.isStock}
                printer={state.printer || 'none'}
                handleChange={handleChange}
                type="edit"
                defaultPage={defaultPage}
                defaultrowsPerPage={defaultrowsPerPage}
                dBgColor={state.bgColor}
                dColor={state.colorText}
                favorite={state.favorite}
                useVarian={state.useVarian}
                topping={state.topping}
                listTopping={listTopping}
            />
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12} className="text-center">
                    <Button variant="contained" color="primary" onClick={handleSimpan} disabled={isLoadVariant}>Update</Button>
                </Col>
            </Row>
            {state.useVarian && (
                <Row>
                    <Col xs={12}>
                        <div className="garis-bawah">
                        </div>
                        <h4 className="text-center">List Variant</h4>
                    </Col>
                    <Col xs={12}>
                        <Button variant="contained" color="primary" onClick={handleAddVarint} ><AddCircleOutlineIcon />Add Variant</Button>
                    </Col>
                    <Col xs={12}>
                        <Table striped bordered hover responsive="lg">
                            <thead>
                                <tr className="colorWhite bgHeaderTabel tabelCenter">
                                    <th>Kode</th>
                                    <th>Nama Varian</th>
                                    <th>Harga Satuan</th>
                                    <th>Harga Gojek</th>
                                    <th>Harga Grab</th>
                                    <th>Harga Lain</th>
                                    <th>Harga Lain 2</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listVariant.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data.kodebarang}</td>
                                            <td>{data.namabarang}</td>
                                            <td className="text-right">{numberWithCommas(data.hargasatuan)}</td>
                                            <td className="text-right">{numberWithCommas(data.hargagojek)}</td>
                                            <td className="text-right">{numberWithCommas(data.hargagrab)}</td>
                                            <td className="text-right">{numberWithCommas(data.hargalain)}</td>
                                            <td className="text-right">{numberWithCommas(data.hargalain2)}</td>
                                            <td className="text-center">
                                                <EditIcon className="pointer" onClick={handleEdit.bind(this, data)} />
                                                <DeleteIcon className="pointer" onClick={handleHapus.bind(this, data)} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            {isModalVariant && (
                <ModalVarint open={isModalVariant} setOpen={setIsModalVariant} setIsLoadVariant={setIsLoadVariant} kodebarang={kodebarang} kodeoutlet={kodeoutlet} clientID={clientID} token={token} detVariant={detVariant} />
            )}
        </Container>
    )
}

export default BarangDetail;