import GQL from '../../GQL';

const HistorySettlementPasstiCtrl = {
  loadData: (idOutlet, clientID, token, tanggalDari, tanggalSampai) => {
    return new Promise((resolve, reject) => {
      const query = `
      query {
        historyTransaksiPassti(idOutlet:${idOutlet}, tanggalDari:"${tanggalDari}",tanggalSampai:"${tanggalSampai}"){
            nosettlement
            tanggal
            MIDReader
         }
    }

      `
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) return (reject(resp.errors));;
        if (resp && resp.data && resp.data.historyTransaksiPassti != null) {
          return (resolve(resp.data.historyTransaksiPassti));
        } else {
          return (reject(new Error("Gagal menyimpan data")))
        }
      }).catch(err => reject(err));
    })
  }
}

export default HistorySettlementPasstiCtrl;