import GQL from '../../../GQL';
const PengaturanUmum = {
    loadDetail: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    detailSetting(idOutlet:${idOutlet}){
                        _id
                        useCaptainOrder
                        directPrint
                        useKitchenPrinter
                        printInvoice
                        printCO
                        useGambar
                        printLogo
                        printFooter
                        footerText
                        transaksiHitungTax
                        usePreBill
                        tampilKeterangan
                        taxServicePerDisplayHarga
                        rounding
                        tampilNamaCustomer
                        roundingDisplay
                        logo
                        footer
                        kitchenFontSize
                        jumlahBeep
                        jumlahItemOrderan
                        cetakRekapProduk
                        cetakDelivery
                        cetakRekapPriceType
                        cetakRekapProdukTotal
                        hakReprintKitchen
                        tutupSesiOffline
                        hakOutletSetting
                        tutupSesiButuhHakAkses
                        closeBill
                        displayTaxServicePerc
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp.data && resp.data.detailSetting) return (resolve(resp.data.detailSetting));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    simpanPengaturanUmum: (idOutlet, clientID, token, form) => {
        return new Promise((resolve, reject) => {
            const footerText = form.footerText.replace(/\n/g,"<BR>");
            const query = `
            mutation{
                simpanSetting(idOutlet:${idOutlet},input:{
                    useCaptainOrder:${form.useCaptainOrder},
                    directPrint:${form.directPrint},
                    useKitchenPrinter:${form.useKitchenPrinter},
                    printInvoice:${form.printInvoice}, 
                    printCO:${form.printCO}, 
                    useGambar:${form.useGambar}, 
                    printLogo:${form.printLogo}, 
                    printFooter:${form.printFooter}, 
                    footerText:"${footerText}",
                    transaksiHitungTax:${form.transaksiHitungTax}, 
                    usePreBill:${form.usePreBill}, 
                    tampilKeterangan:${form.tampilKeterangan},
                    taxServicePerDisplayHarga:${form.taxServicePerDisplayHarga},
                    rounding:${parseInt(form.rounding)}, 
                    tampilNamaCustomer:${form.tampilNamaCustomer}, 
                    roundingDisplay:${parseInt(form.roundingDisplay)},
                    kitchenFontSize:${form.kitchenFontSize},
                    jumlahBeep:${form.jumlahBeep},
                    jumlahItemOrderan:${form.jumlahItemOrderan},
                    cetakRekapProduk:${form.cetakRekapProduk},
                    cetakDelivery:${form.cetakDelivery},
                    cetakRekapPriceType:${form.cetakRekapPriceType},
                    cetakRekapProdukTotal:${form.cetakRekapProdukTotal},
                    hakReprintKitchen:${form.hakReprintKitchen},
                    tutupSesiOffline:${form.tutupSesiOffline},
                    hakOutletSetting:${form.hakOutletSetting},
                    tutupSesiButuhHakAkses:${form.tutupSesiButuhHakAkses},
                    closeBill:${form.closeBill},
                    displayTaxServicePerc:${form.displayTaxServicePerc},
                })
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanSetting) return (resolve(resp.data.simpanSetting));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    simpanLogo: (idOutlet, clientID, token, image) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                simpanLogo(idOutlet:${idOutlet},image:"${image}")
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanLogo) return (resolve(resp.data.simpanLogo));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    simpanImageFooter: (idOutlet, clientID, token, image) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                simpanImageFooter(idOutlet:${idOutlet},image:"${image}")
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp.data && resp.data.simpanImageFooter) return (resolve(resp.data.simpanImageFooter));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    resetLogo: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                resetLogo(idOutlet:${idOutlet})
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.resetLogo) return (resolve(resp.data.resetLogo));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    resetImageFooter: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                resetImageFooter(idOutlet:${idOutlet})
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.resetImageFooter) return (resolve(resp.data.resetImageFooter));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },

}

export default PengaturanUmum;