/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TextField, Button } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import PublishIcon from '@material-ui/icons/Publish';
import { ExcelRenderer } from 'react-excel-renderer';
import mBarang from './Barang.controller'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util';
import validate from 'validate.js'
import DialogInfo from '../../Dialog/DialogInfo'
import CircularProgress from '@material-ui/core/CircularProgress';

const BarangImport = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = props.defaultPage * 1 || 1;
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const { clientID, token } = useContext(AuthContext)
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/barang/${defaultPage}/${defaultrowsPerPage}`)
    }
    const importHandler = (val) => {
        //console.log(val.target.files);
        var fileObj = val.target.files[0];
        // console.log( val.target.files)
        ExcelRenderer(fileObj, (err, resp) => {
            if (resp) {
                const listBarang = [];
                //VALIDASI Header
                const [kodeBrg, namabarang, kategori, subkategori, satuan, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, isStock, printer] = resp.rows[0];
                if (kodeBrg.toUpperCase() !== 'KODE BARANG' || namabarang.toUpperCase() !== 'NAMA BARANG' || kategori.toUpperCase() !== 'KODE KATEGORI' || subkategori.toUpperCase() !== 'KODE SUB KATEGORI' || satuan.toUpperCase() !== 'SATUAN' || hargasatuan.toUpperCase() !== 'HARGA SATUAN' || hargagojek.toUpperCase() !== 'HARGA GOJEK' || hargagrab.toUpperCase() !== 'HARGA GRAB' || hargalain.toUpperCase() !== 'HARGA LAIN' || hargalain2.toUpperCase() !== 'HARGA LAIN 2' || isStock.toUpperCase() !== 'MANAJEMEN STOK' || printer.toUpperCase() !== 'MANAJEMEN PRINTER') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Format data di excel tidak sesuai<p/>`)
                    return
                }
                for (var i = 1, j = resp.rows.length; i < j; i++) {
                    if (resp.rows[i].length > 0) {
                        const [kodeBrg, namabarang, kategori, subkategori, satuan, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, isStock, printer] = resp.rows[i];
                        //VALIDASI Data
                        var kodebarang = kodeBrg.toString();
                        const constraint = {
                            kodebarang: {
                                length: {
                                    minimum: 1,
                                    message: "Kode Barang wajib isi dan minimal terdiri dari 3 huruf atau angka"
                                },
                            },
                            namabarang: {
                                length: {
                                    minimum: 3,
                                    message: "Nama Barang minimal terdiri dari 3 huruf"
                                },
                            },
                            kategori: {
                                length: {
                                    minimum: 1,
                                    message: "Kategori Wajib isi"
                                },
                            },
                            subkategori: {
                                length: {
                                    minimum: 1,
                                    message: "Sub Kategori Wajib Isi"
                                }
                            },
                            satuan: {
                                length: {
                                    minimum: 1,
                                    message: "Satuan Wajib Isi"
                                }
                            },
                            hargasatuan: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga satuan yang sesuai"
                                }
                            },
                            hargagojek: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga gojek yang sesuai"
                                }
                            },
                            hargagrab: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga grab yang sesuai"
                                }
                            },
                            hargalain: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga lain yang sesuai"
                                }
                            },
                            hargalain1: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: "Harap mengisi harga lain 2 yang sesuai"
                                }
                            }
                        }
                        const validator = validate({ kodebarang, namabarang, kategori, subkategori, hargasatuan, hargagojek, hargagrab, hargalain, hargalain2, satuan, isStock, printer }, constraint, { fullMessages: false });
                        if (validator !== undefined) {
                            const keys = Object.keys(validator);
                            var errorMessage = "";
                            for (const key of keys) {
                                errorMessage += validator[key] + "\n";
                            }
                            setOpenConfirmInfo(true)
                            setDialogtext(`<p>${errorMessage}<p/>`)
                            return
                        }
                        if (namabarang) {
                            if ((kodebarang || '').toUpperCase() !== 'KODE BARANG' || namabarang.toUpperCase() !== 'NAMA BARANG') {
                                listBarang.push({ kodebarang, namabarang, kategori, subkategori, satuan, hargasatuan: +hargasatuan, hargagojek: +hargagojek, hargagrab: +hargagrab, hargalain: +hargalain, hargalain2: +hargalain2, isStock: !!isStock, printer: printer.toLowerCase() })
                            }
                        }
                    }
                }
                setIsLoading(true);
                mBarang.importBarang(kodeoutlet, listBarang, clientID, token).then(resp => {
                    setIsLoading(false);
                    if (resp) {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Data Berhasil di Import<p/>`)
                        setDialogUrl(`/manajemenoutlet/${kodeoutlet}/barang/1/5`)
                    } else {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Data Gagal di import<p/>`)
                    }
                }).catch(err => {
                    if (isArray(err) && err.length > 0) {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>Gagal Simpan :  ${err[0].message}<p/>`)
                    } else if (typeof err == 'error') {
                        setOpenConfirmInfo(true)
                        setDialogtext(`<p>${err.message}<p/>`)
                    } else {
                        console.log(err);
                    }
                })
            }
            else {
                console.log(err);
            }
        });
    }
    return (
        <Container>
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12}>
                    <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Import Barang</h4>
                </Col>
                <Col xs={12} style={{ marginTop: 20 }}>
                    <TextField
                        id="importFile"
                        label="Gambar"
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={importHandler.bind(this)}
                    />
                    <label htmlFor="importFile">
                        <Button variant="contained" color="primary" disabled component="span"><PublishIcon /> Pilih file</Button>
                        {isLoading && (
                            <span><CircularProgress /> Mohon tunggu sedang import data...</span>
                        )}
                    </label>
                    <p>
                        File harus dalam format XLSX (namafile.xlsx).<br />
                        Contoh format sebagai berikut :
                    </p>
                    <ul>
                        <li>Untuk contoh format, Anda bisa menggunakan <a href="/Import/ImportBarang.xlsx" download>Template ini</a></li>
                        <li>Setiap kode barang tidak boleh sama</li>
                        <li>Kode barang tidak boleh sama dan yang berawal dari angka 0(NOL) ditulis di excel dengan format tanda petik 1 didepan kode. Contoh : '0001</li>
                    </ul>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr className="tabelWhite">
                                <th>Kode Barang</th>
                                <th>Nama Barang</th>
                                <th>Kode Kategori</th>
                                <th>Kode Sub Kategori</th>
                                <th>Satuan</th>
                                <th>Harga Satuan</th>
                                <th>Harga Gojek</th>
                                <th>Harga Grab</th>
                                <th>Harga Lain</th>
                                <th>Harga Lain 2</th>
                                <th>Manajemen Stok</th>
                                <th>Manajemen Printer</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="tabelWhite">
                                <td>0001</td>
                                <td>Capcay</td>
                                <td>Mak190319155022</td>
                                <td>Cap190323171431</td>
                                <td>Porsi</td>
                                <td>5000</td>
                                <td>7000</td>
                                <td>8000</td>
                                <td>0</td>
                                <td>0</td>
                                <td>TRUE</td>
                                <td>Kitchen</td>
                            </tr>
                            <tr className="tabelWhite">
                                <td>0002</td>
                                <td>Kangkung Belacan</td>
                                <td>Mak190319155022</td>
                                <td>Kan190323171501</td>
                                <td>Porsi</td>
                                <td>15000</td>
                                <td>17000</td>
                                <td>18000</td>
                                <td>0</td>
                                <td>0</td>
                                <td>FALSE</td>
                                <td>Bartender</td>
                            </tr>
                            <tr className="tabelWhite">
                                <td>0003</td>
                                <td>Baby Kaylan</td>
                                <td>Mak190319155022</td>
                                <td>Bab190323171546</td>
                                <td>Porsi</td>
                                <td>10000</td>
                                <td>12000</td>
                                <td>13000</td>
                                <td>0</td>
                                <td>0</td>
                                <td>TRUE</td>
                                <td>none</td>
                            </tr>
                            <tr className="tabelWhite">
                                <td>0004</td>
                                <td>Es Longan</td>
                                <td>Min190319155059</td>
                                <td>Es 190323171646</td>
                                <td>Porsi</td>
                                <td>5000</td>
                                <td>7000</td>
                                <td>8000</td>
                                <td>0</td>
                                <td>0</td>
                                <td>FALSE</td>
                                <td>Kitchen2</td>
                            </tr>
                            <tr className="tabelWhite">
                                <td>0005</td>
                                <td>Sup Ayam</td>
                                <td>Mak190319155022</td>
                                <td>Cap190323171431</td>
                                <td>Porsi</td>
                                <td>5000</td>
                                <td>7000</td>
                                <td>8000</td>
                                <td>0</td>
                                <td>0</td>
                                <td>TRUE</td>
                                <td>Bartender2</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    )
}

export default BarangImport;