/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { TextField, Select, FormControl, MenuItem, InputLabel, FormControlLabel, Checkbox, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

var dBgColor, dColor
if (process.env.REACT_APP_COMPANY == "MonkeyPOS") {
    dBgColor = "#c30404"
    dColor = "#000000"
} else if (process.env.REACT_APP_COMPANY == "Ipos") {
    dBgColor = "#eb6f65"
    dColor = "#ffffff"
}
const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const BarangForm = (props) => {
    const kodeoutlet = props.kodeoutlet
    const kodebarang = props.kodebarang || null
    const defaultPage = props.defaultPage * 1 || 1;
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/barang/${defaultPage}/${defaultrowsPerPage}`)
    }
    const [bgColor, setBgColor] = useState(props.dBgColor)
    const [colorText, setColorText] = useState(props.dColor)
    const [namaBarang, setNamaBarang] = useState(props.namabarang)
    const [hargaBarang, setHargaBarang] = useState(props.hargasatuan)
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        if (['isStock', 'favorite', 'useVarian'].indexOf(key) > -1) {
            value = !!!props[key];
        } else if (ev.target.type == "number") {
            value = +value;
        }
        if (key === 'namabarang') setNamaBarang(value)
        if (key === 'hargasatuan') setHargaBarang(value)
        props.handleChange(key, value)
    }
    const changeBgColor = (ev) => {
        var value = ev.target.value;
        if (value === '#cccccc' || value === '#ccc') return alert("Pilih kode warna selain dari #cccccc")
        const key = 'bgColor'
        setBgColor(ev.target.value)
        props.handleChange(key, value)
    }
    const changeColorText = (ev) => {
        var value = ev.target.value;
        const key = 'colorText'
        setColorText(ev.target.value)
        props.handleChange(key, value)
    }
    return (
        <Row>
            <Col xs={12}>
                <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Barang Form</h4>
            </Col>
            <Col xs={12} lg={6}>
                {(props.type == "edit" && !props.namabarang) ? (
                    <React.Fragment>
                        <FormLabel>Nama Barang</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                    <TextField
                        id="namabarang"
                        name="namabarang"
                        label="Nama Barang"
                        fullWidth
                        required
                        value={props.namabarang}
                        onChange={handleChange}
                    />
                )}
                {(props.type == "edit" && !props.kategori.kodekategori) ? (
                    <React.Fragment>
                        <FormLabel>Kategori</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                    <FormControl fullWidth={true}>

                        <InputLabel>Kategori</InputLabel>
                        <Select
                            name="kategori"
                            id="kategori"
                            required
                            value={props.kategori.kodekategori}
                            onChange={handleChange}
                        >
                            {props.listKategori.map(el => (
                                <MenuItem key={el.kodekategori} value={el.kodekategori}>{el.namakategori}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {(props.type == "edit" && !props.subkategori.kodesubkategori) ? (
                    <React.Fragment>
                        <FormLabel>Sub Kategori</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                    <FormControl fullWidth={true}>
                        <InputLabel>Sub Kategori</InputLabel>
                        <Select
                            name="subkategori"
                            id="subkategori"
                            required
                            value={props.subkategori.kodesubkategori}
                            onChange={handleChange}
                        >
                            {props.listSubKategori.map(el => (
                                <MenuItem key={el.kodesubkategori} value={el.kodesubkategori}>{el.namasubkategori}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {(props.type == "edit" && !props.satuan) ? (
                    <React.Fragment>
                        <FormLabel>Satuan</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (
                    <TextField
                        id="satuan"
                        name="satuan"
                        label="satuan"
                        fullWidth
                        value={props.satuan}
                        onChange={handleChange}
                    />
                )}
            </Col>
            {!props.useVarian && (
                <Col xs={12} lg={6}>
                    {(props.type == "edit" && !props) ? (
                        <React.Fragment>
                            <FormLabel>Harga Satuan</FormLabel>
                            <SkeletonTheme color="#fff" highlightColor="#ec7510">
                                <span className="skeleton-line">
                                    <Skeleton />
                                </span>
                            </SkeletonTheme>
                        </React.Fragment>
                    ) : (
                        <TextField
                            id="hargasatuan"
                            name="hargasatuan"
                            label="Harga Satuan"
                            fullWidth
                            type="number"
                            value={props.hargasatuan}
                            onChange={handleChange}
                        />
                    )}
                    {(props.type == "edit" && !props) ? (
                        <React.Fragment>
                            <FormLabel>Harga Gojek</FormLabel>
                            <SkeletonTheme color="#fff" highlightColor="#ec7510">
                                <span className="skeleton-line">
                                    <Skeleton />
                                </span>
                            </SkeletonTheme>
                        </React.Fragment>
                    ) : (
                        <TextField
                            id="hargagojek"
                            name="hargagojek"
                            label="Harga Gojek"
                            fullWidth
                            type="number"
                            value={props.hargagojek || 0}
                            onChange={handleChange}
                        />
                    )}
                    {(props.type == "edit" && !props) ? (
                        <React.Fragment>
                            <FormLabel>Harga Grab</FormLabel>
                            <SkeletonTheme color="#fff" highlightColor="#ec7510">
                                <span className="skeleton-line">
                                    <Skeleton />
                                </span>
                            </SkeletonTheme>
                        </React.Fragment>
                    ) : (
                        <TextField
                            id="hargagrab"
                            name="hargagrab"
                            label="Harga Grab"
                            fullWidth
                            type="number"
                            value={props.hargagrab || 0}
                            onChange={handleChange}
                        />
                    )}
                    {(props.type == "edit" && !props) ? (
                        <React.Fragment>
                            <FormLabel>Harga Lain</FormLabel>
                            <SkeletonTheme color="#fff" highlightColor="#ec7510">
                                <span className="skeleton-line">
                                    <Skeleton />
                                </span>
                            </SkeletonTheme>
                        </React.Fragment>
                    ) : (
                        <TextField
                            id="hargalain"
                            name="hargalain"
                            label="Harga Lain"
                            fullWidth
                            type="number"
                            value={props.hargalain || 0}
                            onChange={handleChange}
                        />
                    )}
                    {(props.type == "edit" && !props) ? (
                        <React.Fragment>
                            <FormLabel>Harga Lain 2</FormLabel>
                            <SkeletonTheme color="#fff" highlightColor="#ec7510">
                                <span className="skeleton-line">
                                    <Skeleton />
                                </span>
                            </SkeletonTheme>
                        </React.Fragment>
                    ) : (
                        <TextField
                            id="hargalain2"
                            name="hargalain2"
                            label="Harga Lain2"
                            fullWidth
                            type="number"
                            value={props.hargalain2 || 0}
                            onChange={handleChange}
                        />
                    )}
                    {kodebarang && (
                        <h6 style={{ color: '#eb6f65', marginTop: 5, cursor: 'pointer' }} onClick={() => props.history.push(`/manajemenoutlet/${kodeoutlet}/diskon/${kodebarang}`)}><LoyaltyIcon className="btnHref" />Setting Diskon</h6>
                    )}
                </Col>
            )}
            <Col xs={12} style={{ display: 'none' }}>
                <div className="garis-bawah">
                    <p style={{ margin: '20px 0 -5px 10px' }}>Gambar</p>
                    <img alt="logo" style={{ padding: '8px' }} width="150" src="http://www.h2oconcepts.ae/images/noimageavailable.png" />
                    <TextField
                        id="gambar"
                        label="Gambar"
                        type="file"
                    />
                </div>
            </Col>
            <Col xs={12}>
                <div className="garis-bawah">
                    <FormControlLabel control={<Checkbox checked={props.isStock || false} onChange={handleChange} />} id="isStock" name="isStock" label="Manajemen Stok" />
                    <FormControlLabel control={<Checkbox checked={props.favorite || false} onChange={handleChange} />} id="favorite" name="favorite" label="Favorite" />
                </div>
            </Col>
            <Col xs={12}>
                <div className="garis-bawah">
                    <FormLabel component="legend">Manajemen printer</FormLabel>
                    <RadioGroup
                        aria-label="printer"
                        name="printer"
                        id="printer"
                        onChange={handleChange}
                        value={props.printer}
                    >
                        <FormControlLabel value="kitchen" control={<Radio checked={props.printer === 'kitchen'} />} label="Kitchen" />
                        <FormControlLabel value="bartender" control={<Radio checked={props.printer === 'bartender'} />} label="Bartender" />
                        <FormControlLabel value="kitchen2" control={<Radio checked={props.printer === 'kitchen2'} />} label="Kitchen 2" />
                        <FormControlLabel value="bartender2" control={<Radio checked={props.printer === 'bartender2'} />} label="Bartender 2" />
                        <FormControlLabel value="none" control={<Radio checked={props.printer === 'none'} />} label="Tidak Dicetak" />
                    </RadioGroup>
                </div>
            </Col>
            <Col xs={12}>
                <div className="garis-bawah">
                    <Row>
                        <Col xs={6} md={9}>
                            <FormLabel component="legend">Background Color
                            <input name="bgColor" id="bgColor" type="color" value={bgColor || props.dBgColor || dBgColor} onChange={changeBgColor} />
                            </FormLabel>
                            <FormLabel component="legend">Color Text
                            <input name="colorText" id="colorText" type="color" value={colorText || props.dColor || dColor} onChange={changeColorText} />
                            </FormLabel>
                        </Col>
                        <Col xs={6} md={3}>
                            <h6 className="text-center">Preview</h6>
                            <div className="text-center" style={{ margin: '10px 1px', backgroundColor: `${bgColor || props.dBgColor || dBgColor}`, color: `${colorText || props.dColor || dColor}` }}>
                                {namaBarang || props.namabarang}<br />
                                {numberWithCommas(hargaBarang || props.hargasatuan)}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xs={12}>
                <div className="">
                    <FormControl fullWidth={true}>
                        <InputLabel>Topping</InputLabel>
                        <Select
                            name="topping"
                            id="topping"
                            required
                            value={props.topping}
                            onChange={handleChange}
                        >
                            <MenuItem value={null}>none</MenuItem>
                            {props.listTopping.map(el => (
                                <MenuItem key={el.kodegroup} value={el.kodegroup}>{el.namagroup}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel control={<Checkbox checked={props.useVarian || false} onChange={handleChange} />} id="useVarian" name="useVarian" label="Varian" />
                </div>
            </Col>
        </Row>

    )
}
export default BarangForm;