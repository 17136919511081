import GQL from '../../../GQL';
import moment from 'moment-timezone'

const grafik = {
  loadGrafik: (clientID, token, tglDari, tglSampai, kodeOutlet) => {
    return new Promise((resolve, reject) => {
      const query = `
      query{
        laporanTransaksiSuksesFilter(tglDari:"${moment(tglDari).format("YYYY-MM-DD")}",tglSampai:"${moment(tglSampai).format("YYYY-MM-DD")}"){
            outlet{
              kodeoutlet
              namaoutlet
            }
            hitung
            jumlah
            tanggal
        }
        V2AllGrafikPenjualan(tglDari:"${moment(tglDari).format("YYYY-MM-DD")}",tglSampai:"${moment(tglSampai).format("YYYY-MM-DD")}"){
          kodeoutlet
          namaoutlet
          nilaiPenjualan
          tanggal
        }
      }
      `
      GQL(query, clientID, token).then(resp => {
        const tanggal = [];
        const nilaiPenjualan = [];
        const listData = []
        var report
            if(kodeOutlet === 'all'){
              report = resp.data.V2AllGrafikPenjualan
            }else {
              report = resp.data.V2AllGrafikPenjualan.filter(el => {
               return kodeOutlet === el.kodeoutlet 
              })
            }
            report.reduce((a, b) => {
          if (!a[b.tanggal]) {
            a[b.tanggal] = { tanggal: b.tanggal, nominal: 0 };
            listData.push(a[b.tanggal])
          }
          a[b.tanggal].nominal += b.nilaiPenjualan;
          return a;
        }, {});
        var tanggalSelect = [];
        for (var d = new Date(tglDari); d <= new Date(tglSampai); d.setDate(d.getDate() + 1)) {
          tanggalSelect.push(moment(d).format("DD MMM YYYY"));
        }
        for (var i = 0, j = tanggalSelect.length; i < j; i++) {
          var total = 0;
          const cekLaporan = listData.find(data => data.tanggal === tanggalSelect[i]);
          if (cekLaporan) {
            var total = cekLaporan.nominal;
          }
          tanggal.push(tanggalSelect[i]);
          nilaiPenjualan.push(total)

        }

        return (resolve({
          nilaiPenjualan,
          tanggal
        }))
      }).catch(err => reject(err))
    })
  },
  loadOutlet: (userlogin, clientID, token) => {
    return new Promise((resolve, reject) => {
        const query = `
            query{
                customer(email:"${userlogin}"){
                    outlet{
                        id
                        kodeoutlet
                        namaoutlet
                    }
                }
            }
        `
        GQL(query, clientID, token).then(resp => {
            if (resp.errors) throw resp.errors;
            if (resp.data && resp.data.customer && resp.data.customer.outlet) return (resolve(resp.data.customer.outlet));
            return (resolve([]))
        }).catch(err => reject(err))
    })
},
}

export default grafik;