import GQL from '../../GQL';
const payment = {
    loadData: (idOutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listPaymentApp(idOutlet:${idOutlet}){
                        kodepayment
                        namapayment
                        isHide
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listPaymentApp) return(resolve(resp.data.listPaymentApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadExport: (idOutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listPaymentApp(idOutlet:${idOutlet}){
                        kodepayment
                        namapayment
                        isCard
                        isAutoFill
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listPaymentApp) return(resolve(resp.data.listPaymentApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    search: (idOutlet,search,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listPaymentApp(idOutlet:${idOutlet},search:"${search}"){
                        kodepayment
                        namapayment
                        isHide
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listPaymentApp) return(resolve(resp.data.listPaymentApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDetail: (idOutlet,kodepayment,clientID,token)=> {
        return new Promise((resolve,reject)=> {
            const query =`
                query{
                    paymentApp(idOutlet:${idOutlet},kodepayment:"${kodepayment}"){
                        kodepayment
                        namapayment
                        isCard
                        isAutoFill
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.paymentApp) return (resolve(resp.data.paymentApp))
            }).catch(err => reject(err))
        })
    },
    simpanPayment:(idOutlet, namapayment , isAutoFill, isCard , clientID,token)=>{
         return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanPaymentApp(idOutlet:${idOutlet},input:{namapayment:"${namapayment}",isAutoFill:${isAutoFill},isCard:${isCard}})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanPaymentApp){
                    return(resolve(resp.data.simpanPaymentApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    updatePayment:(idOutlet,kodepayment,namapayment,isAutoFill, isCard,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanPaymentApp(idOutlet:${idOutlet},input:{namapayment:"${namapayment}",kodepayment:"${kodepayment}",isAutoFill:${isAutoFill},isCard:${isCard}})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanPaymentApp != undefined){
                    return(resolve(resp.data.simpanPaymentApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    deletePayment:(idOutlet,kodepayment,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    hapusPaymentApp(idOutlet:${idOutlet},kodepayment:"${kodepayment}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.hapusPaymentApp != undefined ){
                    return (resolve(resp.data.hapusPaymentApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    importPayment:(idOutlet,listPaymnet,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(listPaymnet.length > 0){
                let input = '[';
                for(var i=0,j=listPaymnet.length;i<j;i++){
                    if(i>0) input += `,`;
                    var _kodepayment = listPaymnet[i].kodepayment ? `,kodepayment:"${listPaymnet[i].kodepayment}"` : '';
                    input += `{namapayment:"${listPaymnet[i].namapayment}",isAutoFill:${listPaymnet[i].isAutoFill},isCard:${listPaymnet[i].isCard}${_kodepayment}}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiPaymentApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.simpanMultiPaymentApp != undefined ){
                        return (resolve(resp.data.simpanMultiPaymentApp));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    hapusMultiPaymentApp:(idOutlet,kodepayment,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(kodepayment.length > 0){
                let input = '[';
                for(var i=0,j=kodepayment.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodepayment[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        hapusMultiPaymentApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.hapusMultiPaymentApp != undefined ){
                        return (resolve(resp.data.hapusMultiPaymentApp));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    getData: (idOutlet,clientID,token, kodepayment) =>{
        return new Promise((resolve,reject)=>{
            let input = '[';
                for(var i=0,j=kodepayment.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodepayment[i]}"`;
                };
                input += ']';
            const query = `
                query {
                    getMultiPayment(idOutlet:${idOutlet}, kodepayment: ${input}){
                        kodepayment
                        namapayment
                        isCard
                        isAutoFill
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.getMultiPayment) return(resolve(resp.data.getMultiPayment));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    handleActive:(idOutlet,kodepayment,clientID,token, hide)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    handleActivePayment(idOutlet:${idOutlet},kodepayment:"${kodepayment}",hide:${hide})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.handleActivePayment != undefined ){
                    return (resolve(resp.data.handleActivePayment));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default payment;