import React, { useContext, useState, useEffect } from "react";
import {
    Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, Table, TableRow, TableCell, TableHead, TableBody, TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col } from "react-bootstrap";
import AuthContext from '../../context/auth-context'
import mWaiter from './Waiter.controller'
import moment from 'moment'
import { isArray } from 'util'
import DeleteIcon from '@material-ui/icons/Delete';
// import { array } from "prop-types";
// import DialogInfo from '../../Dialog/DialogInfo'
import validate from 'validate.js';

var dateNow = moment();
// const tgl = dateNow.format("YYMMDDHHmmss")
const DialogEditWaiter = (props) => {
    const classes = useStyles();
    const kodewaiter = props.item
    const kodeoutlet = props.kodeoutlet
    const { clientID, token } = useContext(AuthContext)
    const [addRow, setAddRow] = useState(dateNow.format("YYMMDDHHmmss") * 1 + 0)
    const [listWaiter, setListWaiter] = useState([])

    const handleClose = () => {
        props.setOpen(false);
    }
    const SimpanKategori = () => {
        const validasiError = []
        listWaiter.map(el => {
            const { namawaiter } = el;
            const constraint = {
                namawaiter: {
                    length: {
                        minimum: 3,
                        message: "Nama Kategori minimal terdiri dari 3 huruf"
                    },
                }
            }
            const validator = validate({ namawaiter }, constraint, { fullMessages: false });
            if (validator) {
                const err = [validator][0]
                const keys = Object.keys(err);
                var errorMessage = "";
                for (const key of keys) {
                    errorMessage += err[key] + "\n";
                }
                validasiError.push(errorMessage)
                return
            }
        })
        if (validasiError.length > 0) return alert(validasiError[0])
        mWaiter.importWaiter(kodeoutlet, listWaiter, clientID, token).then(resp => {
            //  setIsLoading(false);
            if (resp) {
                alert("Data Berhasil Di Simpan")
                props.setOpen(false);
                props.setIsRefresh(true);
                props.setSelected([])
                props.notif()
            } else {
                alert("Data Gagal Di Simpan")
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                alert(`Gagal Simpan :  ${err[0].message}`)
            } else if (typeof err == 'error') {
                alert(err.message)
            } else {
                console.log(err);
            }
        })
    }
    const handleaddRow = () => {
        setAddRow(addRow + 1)
        setListWaiter([
            ...listWaiter,
            {
                kodewaiter: addRow,
                namawaiter: "",
                isInsert: true
            }
        ])
    }

    useEffect(() => {
        if (kodewaiter == null) {
            setAddRow(addRow + 1)
            setListWaiter([
                {
                    kodewaiter: addRow,
                    namawaiter: "",
                    isInsert: true
                }
            ])
        } else {
            var render = true
            mWaiter.getData(kodeoutlet, clientID, token, kodewaiter).then(resp => {
                if (render) setListWaiter(resp);
            }).catch(err => {
                console.error(err);
            })
            return () => {
                render = false
            };
        }

    }, [kodeoutlet, clientID, token]);
    const handleChange = (ev, kodewaiter) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        const dataUpdate = []
        let b = listWaiter.map(el => {
            if (el.kodewaiter == kodewaiter) {
                if (key == "namawaiter") {
                    return {
                        ...el,
                        kodewaiter: kodewaiter,
                        namawaiter: value
                    }
                } else if (key == "kodewaiter") {
                    return {
                        ...el,
                        kodewaiter: value,
                        namawaiter: el.namawaiter
                    }
                }
            } else return el
        })
        setListWaiter(b)
    }
    const deleteRow = (data) => {
        let a = listWaiter.filter(el => {
            return el.kodewaiter !== data.kodewaiter
        })
        setListWaiter(a)
    }
    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Edit Kategori</Typography>
                    <Button color="inherit" onClick={SimpanKategori}>
                        save</Button>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                <Row className={classes.root}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className="colorWhite" style={{ minWidth: ' 250px' }}>Kode Kategori</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 350px' }}>Nama kategori</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listWaiter.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row" className="colorWhite">
                                            {!data.isInsert && (data.kodewaiter)}
                                            {data.isInsert && (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col xs={2} md={1}>
                                                            <DeleteIcon className="deleteDialog" onClick={deleteRow.bind(this, data)} />
                                                        </Col>
                                                        <Col xs={10} md={11}>
                                                            <span className="tabelForm">
                                                                <TextField
                                                                    id="kodewaiter"
                                                                    name="kodewaiter"
                                                                    label=""
                                                                    fullWidth
                                                                    required
                                                                    multiline={false}
                                                                    value={data.kodewaiter}
                                                                    variant="outlined"
                                                                    onChange={(ev) => handleChange(ev, data.kodewaiter)}
                                                                />
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                id="namawaiter"
                                                name="namawaiter"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.namawaiter}
                                                variant="outlined"
                                                onChange={(ev) => handleChange(ev, data.kodewaiter)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Row>
                <Row>
                    <Button className="marginTop15" variant="contained" color="primary" component="span" onClick={handleaddRow}>Add Row</Button>
                    {/* <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} /> */}
                </Row>
            </Container>
        </Dialog>
    )
}
export default DialogEditWaiter;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));