import React from 'react'
import { TextField, FormLabel } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const SubKategoriForm = (props) => {
    const defaultPage = props.defaultPage * 1 || 1;
    const defaultrowsPerPage = props.defaultrowsPerPage * 1 || 5;
    const kodeoutlet = props.kodeoutlet
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/subkategori/${defaultPage}/${defaultrowsPerPage}`)
    }

    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        props.handleChange(key, value)
    }


    return (
        <Row>
            <Col xs={12}>
                <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} style={{
                    color: " rgba(255,117,0,1)", cursor: 'pointer'
                }} />Sub Kategori Form</h4>
            </Col>
            <Col xs={12} lg={12}>
                {(props.type == "edit" && !props.namasubkategori) ? (
                    <React.Fragment>
                        <FormLabel>Nama Sub Kategori</FormLabel>
                        <SkeletonTheme color="#fff" highlightColor="#ec7510">
                            <span className="skeleton-line">
                                <Skeleton />
                            </span>
                        </SkeletonTheme>
                    </React.Fragment>
                ) : (

                        <TextField
                            id="namasubkategori"
                            label="Nama Sub Kategori"
                            fullWidth
                            required
                            multiline={false}
                            value={props.namasubkategori}
                            onChange={handleChange}
                        />

                    )}
            </Col>
        </Row>

    )
}
export default SubKategoriForm;