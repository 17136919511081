/* eslint-disable no-redeclare */
import GQL from '../../GQL';
const Diskon = {
    loadData: (idOutlet, clientID, token , kodebarang) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listPromo(idOutlet:${idOutlet}){
                        kodePromo
                        namaPromo
                        tanggalMulai
                        tanggalSelesai
                        minimumPurchase
                        discountPercent
                        discountAmount
                        maxDiscountAmount
                        isHide
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listPromo) return (resolve(resp.data.listPromo));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    savePromo: (idOutlet, clientID, token, kodePromo, namaPromo, tanggalMulai, tanggalSelesai, minimumPurchase, discountPercent, discountAmount, maxDiscountAmount) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanPromo(idOutlet:${idOutlet}, input:{
                        kodePromo:"${kodePromo}",
                        namaPromo:"${namaPromo}",
                        tanggalMulai:"${tanggalMulai}",
                        tanggalSelesai:"${tanggalSelesai}",
                        minimumPurchase:${minimumPurchase || null},
                        discountPercent:${discountPercent || null},
                        discountAmount:${discountAmount || null},
                        maxDiscountAmount:${maxDiscountAmount || null}
                      })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanPromo !== undefined) {
                    return (resolve(resp.data.simpanPromo));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    hapusPromo: (idOutlet, clientID, token, kodePromo) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusPromo(idOutlet:${idOutlet},kodePromo:"${kodePromo}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusPromo !== undefined) {
                    return (resolve(resp.data.hapusPromo));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    handleActive:(idOutlet,kodePromo,clientID,token, hide)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    handleActivePromo(idOutlet:${idOutlet},kodePromo:"${kodePromo}",hide:${hide})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.handleActivePromo != undefined ){
                    return (resolve(resp.data.handleActivePromo));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default Diskon;