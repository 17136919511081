import GQL from '../../GQL';
const subkategori = {
    loadData: (idOutlet,clientID,token) =>{
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listSubKategoriApp(idOutlet:${idOutlet}){
                        kodesubkategori
                        namasubkategori
                        isHide
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listSubKategoriApp) return(resolve(resp.data.listSubKategoriApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    search:(idOutlet,search,clientID,token) => {
        return new Promise((resolve,reject)=>{
            const query = `
                query {
                    listSubKategoriApp(idOutlet:${idOutlet},search:"${search}"){
                        kodesubkategori
                        namasubkategori
                        isHide
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.listSubKategoriApp) return(resolve(resp.data.listSubKategoriApp));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    loadDetail: (idOutlet,kodesubkategori,clientID,token)=> {
        return new Promise((resolve,reject)=> {
            const query =`
                query{
                    subKategoriApp(idOutlet:${idOutlet},kodesubkategori:"${kodesubkategori}"){
                        kodesubkategori
                        namasubkategori
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.subKategoriApp) return (resolve(resp.data.subKategoriApp))
            }).catch(err => reject(err))
        })
    },
    simpanSubKategori:(idOutlet, namasubkategori, clientID,token)=>{
         return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanSubKategoriApp(idOutlet:${idOutlet},input:{namasubkategori:"${namasubkategori}"})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanSubKategoriApp){
                    return(resolve(resp.data.simpanSubKategoriApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    updateSubKategori:(idOutlet,kodesubkategori,namasubkategori,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            const query = `
                mutation{
                    simpanSubKategoriApp(idOutlet:${idOutlet},input:{namasubkategori:"${namasubkategori}",kodesubkategori:"${kodesubkategori}"})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.simpanSubKategoriApp != undefined){
                    return(resolve(resp.data.simpanSubKategoriApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    deleteSubKategori:(idOutlet,kodesubkategori,clientID,token)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    hapusSubKategoriApp(idOutlet:${idOutlet},kodesubkategori:"${kodesubkategori}")
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));
                if(resp.data && resp.data.hapusSubKategoriApp != undefined ){
                    return (resolve(resp.data.hapusSubKategoriApp));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    importSubKategori:(idOutlet,listSubKategori,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(listSubKategori.length > 0){
                let input = '[';
                for(var i=0,j=listSubKategori.length;i<j;i++){
                    if(i>0) input += `,`;
                    var _kodesubkategori = listSubKategori[i].kodesubkategori ? `,kodesubkategori:"${listSubKategori[i].kodesubkategori}"` : '';
                    input += `{namasubkategori:"${listSubKategori[i].namasubkategori}"${_kodesubkategori}}`;
                };
                input += ']';
                const query = `
                    mutation{
                        simpanMultiSubKategoriApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.simpanMultiSubKategoriApp != undefined ){
                        return (resolve(resp.data.simpanMultiSubKategoriApp));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    deleteMultiSubKategori:(idOutlet,kodesubkategori,clientID,token)=>{
        return new Promise((resolve,reject)=> {
            if(kodesubkategori.length > 0){
                let input = '[';
                for(var i=0,j=kodesubkategori.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodesubkategori[i]}"`;
                };
                input += ']';
                const query = `
                    mutation{
                        hapusMultiSubKategoriApp(idOutlet:${idOutlet},input:${input})
                    }
                `
                GQL(query,clientID,token).then(resp => {
                    if(resp.errors) return(reject(resp.errors));;
                    if(resp.data && resp.data.hapusMultiSubKategoriApp != undefined ){
                        return (resolve(resp.data.hapusMultiSubKategoriApp));
                    }else{
                        return(reject(new Error("Gagal menyimpan data")))
                    }
                })
            }else{
                return(reject(new Error("Tidak menemukan data valid di format Excel")))
            }
        })
    },
    getData: (idOutlet,clientID,token, kodesubkategori) =>{
        return new Promise((resolve,reject)=>{
            let input = '[';
                for(var i=0,j=kodesubkategori.length;i<j;i++){
                    if(i>0) input += `,`;
                    input += `"${kodesubkategori[i]}"`;
                };
                input += ']';
            const query = `
                query {
                    getMultiSubKategori(idOutlet:${idOutlet}, kodesubkategori: ${input}){
                        kodesubkategori
                        namasubkategori
                    }
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.getMultiSubKategori) return(resolve(resp.data.getMultiSubKategori));
                return(resolve([]))
            }).catch(err => {
                return(reject(err));
            })
        })
    },
    handleActive:(idOutlet,kodesubkategori,clientID,token, hide)=>{
        return new Promise((resolve,reject)=>{
            const query = `
                mutation{
                    handleActiveSubKategori(idOutlet:${idOutlet},kodesubkategori:"${kodesubkategori}",hide:${hide})
                }
            `
            GQL(query,clientID,token).then(resp => {
                if(resp.errors) return(reject(resp.errors));;
                if(resp.data && resp.data.handleActiveSubKategori != undefined ){
                    return (resolve(resp.data.handleActiveSubKategori));
                }else{
                    return(reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default subkategori;