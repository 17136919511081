/* eslint-disable valid-typeof */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, InputBase, Checkbox, Toolbar, Typography, Tooltip, IconButton, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dropdown from 'react-bootstrap/Dropdown'
import mKategori from './Kategori.controller'
import { FaChevronLeft } from 'react-icons/fa'
import useStyles from '../../css/useStylesTabel'
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Skeleton from 'react-loading-skeleton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { isArray } from "util";
import clsx from 'clsx';
import DialogEditKategori from './DialogEditKategori'
import useToolbarStyles from '../../css/useToolbarStyles'
import DialogConfirm from '../../Dialog/DialogConfirm'
import DialogInfo from '../../Dialog/DialogInfo'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ExportExel from './Kategori.Export'

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const Kategori = (props) => {
    var defaultPage = parseInt(props.match.params.page, 10) - 1;
    var defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const kodeoutlet = props.match.params.kodeoutlet;
    // const [state, dispatch] = useReducer(reducer, initialValue)
    const { clientID, token } = useContext(AuthContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(defaultPage);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [rows, setRows] = useState([])
    const [search, setSearch] = useState(null)
    const [isRefresh, setIsRefresh] = useState(true)
    const [noData, setNoData] = useState(false)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dataDialog, setdataDialog] = useState()
    const [dialogtext, setDialogtext] = useState()
    const [open, setOpen] = useState(false);
    const addKategori = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/kategori-add`);
    }
    const handleEdit = (kode) => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/kategori-detail/${kode}/${page + 1}/${rowsPerPage}`)
    }
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}`)
    }
    const goImport = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}/kategori/import`)
    }
    const konfirmasiDelete = (data) => {
        setOpenConfirmDelete(true);
        setdataDialog(data)
        setDialogtext(`<p>Yakin Anda Ingin Menghapus ${data.namakategori}?<br/>Data tidak dapat dikembalikan setelah berhasil dihapus<p/>`)
    }
    const handleDelete = (data) => {
        mKategori.deleteKategori(kodeoutlet, data.kodekategori, clientID, token).then(resp => {
            if (resp) {
                setOpenConfirmDelete(false);
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${data.namakategori} berhasil di hapus<p/>`)
                setIsRefresh(true);
            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Gagal hapus ${err[0].message}<p/>`)
            } else if (typeof err == 'error') {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${err.message}<p/>`)
            } else {
                console.log(err);
            }
        })

    }
    useEffect(() => {
        var render = true
        if (isRefresh) {
            mKategori.loadData(kodeoutlet, clientID, token).then(resp => {
                if (render) setRows(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh]);


    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.kodekategori);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }
    const handleClick = (event, kodekategori) => {
        const selectedIndex = selected.indexOf(kodekategori);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, kodekategori);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const isSelected = name => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const searchHandler = (val) => {
        setSearch(val.target.value)
    }
    const formSubmitHandler = (event) => {
        setNoData(false);
        if (search) {
            mKategori.search(kodeoutlet, search, clientID, token).then(resp => {
                if (resp.length <= 0) {
                    setNoData(true);
                    setRows(resp);
                } else {
                    setNoData(false);
                    setRows(resp);
                }
            })
        } else {
            setIsRefresh(true)
        }
    }
    const loadingRow = [0, 1, 2, 3, 4];

    const headRows = [
        {
            id: "kodekategori", numeric: false, disablePadding: true, label: "kode"
        },
        { id: "namakategori", numeric: true, disablePadding: false, label: "Kategori" },
        { id: "isHide", numeric: true, disablePadding: false, label: "Aktif" },

    ];

    const EnhancedTableHead = (props) => {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
            numSelected,
            rowCount,
            onSelectAllClick
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: ' 10px' }}>
                        <span className="checkBoxTabel">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all' }}
                            />
                        </span>
                    </TableCell>
                    <TableCell style={{ width: ' 25px' }}>Action</TableCell>
                    {headRows.map(row => (
                        <TableCell key={row.id}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired
    };
    const EnhancedTableToolbar = props => {
        const [open, setOpen] = useState(false);
        const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
        const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
        const [dataDialog, setdataDialog] = useState()
        const [dialogtext, setDialogtext] = useState()
        const openDialogEdit = () => {
            setOpen(true);
        }
        const classes = useToolbarStyles();
        const { numSelected, itemSelect } = props;
        const konfirmasiDeleteSelecter = (data) => {
            setOpenConfirmDelete(true);
            setdataDialog(data)
            setDialogtext(`<p>Yakin Anda Ingin Menghapus ${data.length} kategori ini?<br/>Data tidak dapat dikembalikan setelah berhasil dihapus<p/>`)
        }
        const deleteSelected = (data) => {
            mKategori.deleteMultiKategori(kodeoutlet, data, clientID, token).then(resp => {
                setOpenConfirmDelete(false);
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${data.length} kategori berhasil di hapus<p/>`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
                }

            }).catch(err => {
                setOpenConfirmDelete(false);
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal hapus ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            });
        }
        const notifSukses = () => {
            console.log("a")
            setOpenConfirmInfo(true)
            setDialogtext(`<p>Data berhasil di simpan<p/>`)
        }
        return numSelected > 0 && (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                    <Typography color="inherit" variant="subtitle1">{numSelected} selected</Typography>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Tooltip title="Edit" flex onClick={openDialogEdit}>
                                <IconButton aria-label="edit">
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                            <Tooltip title="Delete" flex onClick={konfirmasiDeleteSelecter.bind(this, itemSelect)}>
                                <IconButton aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
                <DialogEditKategori open={open} setOpen={setOpen} item={itemSelect} kodeoutlet={kodeoutlet} setIsRefresh={setIsRefresh} setSelected={setSelected} />
                <DialogConfirm open={openConfirmDelete} setOpen={setOpenConfirmDelete} text={dialogtext} data={dataDialog} oke={deleteSelected} />
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} refresh={refreshData} />
            </Toolbar>
        )
    };
    const addMultiple = () => {
        setOpen(true);
    }
    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };
    const refreshData = () => {
        setSelected([])
        setIsRefresh(true)
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            formSubmitHandler()
        }
    }

    const handleActive = (data) => {
        let aktif = false
        if (data.isHide) aktif = data.isHide
        mKategori.handleActive(kodeoutlet, data.kodekategori, clientID, token, !aktif).then(resp => {
            if (resp) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Kategori berhasil di ${aktif ? ("activated ") : ("deactivated")}<p/>`)
                setIsRefresh(true)
            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi<p/>`)
            }

        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Gagal hapus ${err[0].message}<p/>`)
            } else if (typeof err == 'error') {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${err.message}<p/>`)
            } else {
                console.log(err);
            }
        });
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Kategori</h3>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <AddCircleOutlineIcon id="dropdown-basic" /> Tambah
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item><Button title="Edit" variant="contained" className="btnAddManual" onClick={addKategori}><EditIcon /> Tambah Manual</Button></Dropdown.Item>
                            <Dropdown.Item><Button title="Edit" variant="contained" className="btnAddMultiple" onClick={addMultiple}><EditIcon /> Tambah Multiple</Button></Dropdown.Item>
                            <Dropdown.Item><Button title="Hapus" variant="contained" className="btnImportExcel" onClick={goImport}><ImportExportIcon /> Import Excel</Button></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            <div className="btnTable">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <ExportExel kodeoutlet={kodeoutlet} clientID={clientID} token={token} />
                                    </Col>
                                    <Col  xs={12} md={6}>
                                        <div className={classes.search}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase onChange={searchHandler} onKeyPress={handleKeyPress}
                                                placeholder="Search…"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                                inputProps={{ 'aria-label': 'search' }}
                                            />
                                            <Button style={{ float: 'right' }} onClick={formSubmitHandler} variant="contained" color="primary">Cari</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <EnhancedTableToolbar numSelected={selected.length} itemSelect={selected} />
                            <div className={classes.tableWrapper}>
                                <Table
                                    className="table"
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {noData && (
                                            <TableRow style={{ height: 15 * emptyRows }}>
                                                <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                    <b>Tidak ada data</b>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {rows.length == 0 && !noData && loadingRow.map(el => (
                                            <TableRow
                                                key={el.toString()}
                                                style={{ height: 50 }}
                                            >
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell component="th" scope="row" >
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                            </TableRow>
                                        ))

                                        }
                                        {stableSort(rows, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.kodekategori);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        // onClick={event => handleClick(event, row.kodekategori)}
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.kodekategori}
                                                    >
                                                        <TableCell>
                                                            <span className="checkBoxTabel">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    onClick={event => handleClick(event, row.kodekategori)}
                                                                />
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                    <TouchAppIcon id="dropdown-basic" /></Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item><Button title="Edit" variant="contained" style={{ backgroundColor: '#376275', marginRight: 10, color: "#fff", width: '135px' }} onClick={handleEdit.bind(this, row.kodekategori)}><EditIcon /> Edit/lihat</Button></Dropdown.Item>
                                                                    <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: 'red', marginRight: 10, color: "#fff", width: '135px' }} onClick={konfirmasiDelete.bind(this, row)}><DeleteIcon /> Hapus</Button></Dropdown.Item>
                                                                    <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: '#588c4b', marginRight: 10, color: "#fff", width: '135px' }} onClick={handleActive.bind(this, row)}> {row.isHide ? ("Activated") : ("Deactivated")}</Button></Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" >
                                                            {row.kodekategori}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.namakategori}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.isHide ? <CloseIcon /> : <CheckIcon />}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 49 * emptyRows }}>
                                                <TableCell colSpan={7} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "previous page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "next page"
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label="Dense padding"
                        />
                    </div>
                </Col>
            </Row>
            <DialogConfirm open={openConfirmDelete} setOpen={setOpenConfirmDelete} text={dialogtext} data={dataDialog} oke={handleDelete} />
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
            <DialogEditKategori open={open} setOpen={setOpen} item={null} kodeoutlet={kodeoutlet} setIsRefresh={setIsRefresh} setSelected={setSelected} />
        </Container>
    );
}
export default Kategori;
