import GQL from '../../GQL'

const pilihoutlet = {
    loadData: (userlogin, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    listCustomerWeb{
                        email
                        namacustomer
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listCustomerWeb && resp.data.listCustomerWeb) return (resolve(resp.data.listCustomerWeb));
                return (resolve([]))
            }).catch(err => reject(err))
        })
    },
    loginAsCustomer: (clientID, token, email) => {
        return new Promise((resolve, reject) => {
            const query = `
                query{
                    loginCustomerMaster(email:"${email}"){
                        clientID
                        token
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.loginCustomerMaster && resp.data.loginCustomerMaster) return (resolve(resp.data.loginCustomerMaster));
                return (resolve([]))
            }).catch(err => reject(err))
        })
    },
}

export default pilihoutlet;