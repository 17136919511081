import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import mLaporan from '../Laporan.controler'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment-timezone'
import PageviewIcon from '@material-ui/icons/Pageview';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { stableSort, getSorting } from '../../../../Helper/Sorting'
import PropTypes from "prop-types";
import { TableSortLabel } from '@material-ui/core';
import numberWithCommas from '../../../../Helper/numberWithCommas'

const Transaksi = (props) => {
    const classes = useStyles();
    const [isRefresh, setIsRefresh] = useState(true)
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const [kodeoulet, setKodeoulet] = useState(props.kodeOutlet)
    const [idOutlet, setIdOutlet] = useState(props.idOutlet)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [listLaporan, setListLaporan] = useState([])
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("noinvoice");
    var urut = 0
    var myWindow;
    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.laporanTransaksi(clientID, token, kodeoulet, tglDari, tglSampai).then(resp => {
                setListLaporan(resp)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])


    const lihatLaporan = () => {
        setListLaporan([])
        setIsRefresh(true)
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    var jumlah = 0, tax = 0, net = 0;
    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    const headRows = [
        {
            id: "noinvoice", label: "Transaksi"
        }

    ];
    const EnhancedTableHead = (props) => {
        const {
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (

            <thead>
                <tr className="colorWhite bgHeaderTabel tabelCenter">
                    <th className="text-center" style={{ width: '5px' }}>Urut</th>
                    <th>Detail</th>
                    {headRows.map(row => (
                        <th key={row.id} >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </th>
                    ))}
                    <th>Tanggal</th>
                    <th>Meja</th>
                    <th className="text-right">Jumlah</th>
                    <th className="text-right">Tax</th>
                    <th className="text-right">Net</th>
                </tr>
            </thead>
        );
    }
    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const openTab = (id) => {
        window.open(`/manajemenoutlet/${idOutlet}/transhistory-detail/${id}/1/5/rpt`, "_blank");
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} lg={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Dari"
                            format="DD-MM-YYYY HH:mm"
                            value={tglDari}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            onChange={handleChangeTglDari}
                            ampm={false}
                            disableFuture
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDateTimePicker
                            fullWidth={true}
                            margin="normal"
                            id="tanggal"
                            label="Tanggal Sampai"
                            format="DD-MM-YYYY HH:mm"
                            value={tglSampai}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ color: '#000 !important' }}
                            onChange={handleChangeTglSampai}
                            ampm={false}
                        />
                    </MuiPickersUtilsProvider>
                </Col>
                <Col xs={12} md={2} className="text-center"><br />
                    <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                </Col>
            </Row>
            <Row className=" bodySessionDetail">
                <Col xs={12} className="marginGrafik">
                    <ReactHTMLTableToExcel
                        className="buttonExportExcel"
                        table="laporanTransaksi"
                        filename={`Laporan transaksi ${moment(tglDari).format("DD-MM-YYYY HH mm")} sampai ${moment(tglSampai).format("DD-MM-YYYY HH mm")}`}
                        sheet="Sheet1"
                        buttonText={namaExport()} />
                    <Table id="laporanTransaksi" striped bordered hover responsive="lg">
                        <EnhancedTableHead
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                        />
                        <tbody className={`${classes.contentBody}`}>
                            {stableSort(listLaporan, getSorting(order, orderBy))
                                .map((data, index) => {
                                    const NoTrans = data._id.substring(27)
                                    const noTrans = data.noinvoice === null ? NoTrans : data.noinvoice
                                    jumlah += data.jumlah
                                    tax += data.taxAmount
                                    net += data.net
                                    urut ++
                                    return (
                                        <tr className="colorWhite" key={index}>
                                            <td className="text-center"> {urut}</td>
                                            <td className="text-center">
                                                <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={openTab.bind(this, data._id)}><PageviewIcon /></Button>
                                            </td>
                                            <td> {noTrans}</td>
                                            <td>
                                                {moment(data.tanggal).format("DD-MMM-YYYY HH:mm:ss")}
                                            </td>
                                            <td className="text-center">{data.nomeja}</td>
                                            <td className="text-right">
                                                {numberWithCommas(data.jumlah)}
                                            </td>
                                            <td className="text-right">
                                                {numberWithCommas(data.taxAmount)}
                                            </td>
                                            <td className="text-right">
                                                {numberWithCommas(data.net)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            <tr className="colorWhite">
                                <th className="text-center" colSpan="5">Total</th>
                                <th className="text-center">
                                    {numberWithCommas(jumlah)}
                                </th>
                                <th className="text-center">
                                    {numberWithCommas(tax)}
                                </th>
                                <th className="text-center">
                                    {numberWithCommas(net)}
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default Transaksi;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));