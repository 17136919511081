import React, { useState, useEffect } from 'react'
import AuthContext from '../../context/auth-context'
import mControl from './Diskon.Controler'
import { isArray } from 'util';
import { Row, Col } from 'react-bootstrap';
import { Toolbar, Typography, DialogContent, DialogContentText, Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft } from 'react-icons/fa';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const tglBesok = moment().add(1, 'days')

const Modal = (props) => {
    const kodeoutlet = props.kodeoutlet
    const kodebarang = props.kodebarang
    const clientID = props.clientID
    const token = props.token
    const namabarang = props.namabarang
    const detail = props.dataDialog || null
    const kodediskon = detail.kodediskon || ""
    const [form, setForm] = useState({
        tglmulai: tglBesok,
        tglselesai: tglBesok,
        harga1: 0,
        harga2: 0,
        harga3: 0,
        harga4: 0,
        harga5: 0,
    })
    useEffect(() => {
        if (detail.kodediskon) {
            setForm({
                tglmulai: detail.tglmulai,
                tglselesai: detail.tglselesai,
                harga1: detail.harga1,
                harga2: detail.harga2,
                harga3: detail.harga3,
                harga4: detail.harga4,
                harga5: detail.harga5,
            })
        }
        return () => {

        }
    }, [detail])
    const handleClose = () => {
        props.setOpen(false)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const handleSimpan = () => {
        if (moment(form.tglmulai) < moment(form.tglselesai)) {
            mControl.saveDiskon(kodeoutlet, clientID, token, kodediskon, kodebarang, namabarang, form.tglmulai, form.tglselesai, form.harga1, form.harga2, form.harga3, form.harga4, form.harga5).then(resp => {
                alert("Data Berhasil Disimpan")
                handleClose()
                props.setIsRefresh(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        } else {
            alert("Tanggal mulai harus dibawah tanggal selesai")
        }
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <FaChevronLeft color="inherit" onClick={handleClose} className="tangan" />
                    <Typography variant="h6">
                        {detail.kodediskon ? ("Update") : ("Add")} Diskon - {namabarang}</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="DialogFont bodyDialog">
                <DialogContentText className="colorWhite">
                    <Row>
                        <Col xs={12} lg={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDateTimePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Mulai"
                                    format="DD-MM-YYYY HH:mm"
                                    value={form.tglmulai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    onChange={(e) => setForm({ ...form, tglmulai: e })}
                                    ampm={false}
                                    minDate={tglBesok}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDateTimePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Sampai"
                                    format="DD-MM-YYYY HH:mm"
                                    value={form.tglselesai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{ color: '#000 !important' }}
                                    onChange={(e) => setForm({ ...form, tglselesai: e })}
                                    ampm={false}
                                    minDate={tglBesok}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga1"
                                name="harga1"
                                label="Harga Satuan"
                                fullWidth
                                type="number"
                                value={form.harga1}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga2"
                                name="harga2"
                                label="Harga Gojek"
                                fullWidth
                                type="number"
                                value={form.harga2}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga3"
                                name="harga3"
                                label="Harga Grab"
                                fullWidth
                                type="number"
                                value={form.harga3}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga4"
                                name="harga4"
                                label="Harga Lainya"
                                fullWidth
                                type="number"
                                value={form.harga4}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="harga5"
                                name="harga5"
                                label="Harga Lainya 2"
                                fullWidth
                                type="number"
                                value={form.harga5}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 20 }}>
                        <Col xs={12} className="text-center">
                            <Button variant="contained" color="primary" fullWidth onClick={handleSimpan}>Simpan</Button>
                        </Col>
                    </Row>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;