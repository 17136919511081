import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Desain/General.css'
import * as serviceWorker from './serviceWorker';
require(`./Desain/${process.env.REACT_APP_COMPANY}/index.css`);
// if (process.env.REACT_APP_COMPANY == "MonkeyPOS") {
// } else if (process.env.REACT_APP_COMPANY == "Ipos") {
//     require('./Desain/Ipos/index.css');
// }
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: registration => {
      console.log("New version available!");
      //  alert("New version available!  Ready to update?");
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
      window.location.reload();
    }
  });