import React from "react";
import {
    Button, Dialog, DialogTitle, DialogActions, DialogContent
} from '@material-ui/core';

const DialogInfo = (props) => {
    const handleClose = () => {
        props.setOpen(false);
        if(props.url){
            props.redirect(props.url)
        }
        if(props.refresh){
            props.refresh()
        }
    }
    return (
        <Dialog open={props.open}>
            <DialogTitle className="colorWhite">Portal {process.env.REACT_APP_COMPANY} says</DialogTitle>
            <DialogContent className="colorWhite">
                    <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>Oke</Button>
            </DialogActions>
        </Dialog>
    )
}
export default DialogInfo;