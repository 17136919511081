import React, { useState, useEffect } from 'react'
import mControl from './Promo.Controler'
import { Row, Col } from 'react-bootstrap';
import { Toolbar, Typography, DialogContent, DialogContentText, Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft } from 'react-icons/fa';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const tglBesok = moment().add(0, 'days')

const Modal = (props) => {
    const kodeoutlet = props.kodeoutlet
    const clientID = props.clientID
    const token = props.token
    const detail = props.dataDialog || null
    const kodePromo = detail.kodePromo || ""
    const [form, setForm] = useState({
        namaPromo: '',
        tanggalMulai: tglBesok,
        tanggalSelesai: tglBesok,
        minimumPurchase: "",
        discountPercent: "",
        discountAmount: "",
        maxDiscountAmount: "",
    })
    useEffect(() => {
        if (detail.kodePromo) {
            setForm({
                namaPromo: detail.namaPromo,
                tanggalMulai: detail.tanggalMulai,
                tanggalSelesai: detail.tanggalSelesai,
                minimumPurchase: detail.minimumPurchase,
                discountPercent: detail.discountPercent,
                discountAmount: detail.discountAmount,
                maxDiscountAmount: detail.maxDiscountAmount,
            })
        }
        return () => {

        }
    }, [detail])
    const handleClose = () => {
        props.setOpen(false)
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    const handleSimpan = () => {
        if (moment(form.tanggalMulai) < moment(form.tanggalSelesai)) {
            mControl.savePromo(kodeoutlet, clientID, token, kodePromo, form.namaPromo, form.tanggalMulai, form.tanggalSelesai, form.minimumPurchase, form.discountPercent, form.discountAmount, form.maxDiscountAmount).then(resp => {
                alert("Data Berhasil Disimpan")
                handleClose()
                props.setIsRefresh(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        } else {
            alert("Tanggal mulai harus dibawah tanggal selesai")
        }
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <FaChevronLeft color="inherit" onClick={handleClose} className="tangan" />
                    <Typography variant="h6">
                        {detail.kodepromo ? ("Update") : ("Add")} Promo</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="DialogFont bodyDialog">
                <DialogContentText className="colorWhite">
                    <Row>
                        <Col xs={12} md={12}>
                            <TextField
                                id="namaPromo"
                                name="namaPromo"
                                label="Nama Promo"
                                fullWidth
                                value={form.namaPromo}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDateTimePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Mulai"
                                    format="DD-MM-YYYY HH:mm"
                                    value={form.tanggalMulai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    onChange={(e) => setForm({ ...form, tanggalMulai: e })}
                                    ampm={false}
                                    minDate={tglBesok}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDateTimePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Selesai"
                                    format="DD-MM-YYYY HH:mm"
                                    value={form.tanggalSelesai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{ color: '#000 !important' }}
                                    onChange={(e) => setForm({ ...form, tanggalSelesai: e })}
                                    ampm={false}
                                    minDate={tglBesok}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                id="minimumPurchase"
                                name="minimumPurchase"
                                label="Minimum Pembelian"
                                fullWidth
                                type="number"
                                value={form.minimumPurchase}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {(form.discountAmount * 1 <= 0) && (
                                <TextField
                                    id="discountPercent"
                                    name="discountPercent"
                                    label="Diskon Persen"
                                    fullWidth
                                    type="number"
                                    value={form.discountPercent}
                                    onChange={handleChange}
                                />
                            )}
                        </Col>
                        <Col xs={12} md={6}>
                            {(form.discountPercent * 1 <= 0) && (
                                <TextField
                                    id="discountAmount"
                                    name="discountAmount"
                                    label="Jumlah Diskon"
                                    fullWidth
                                    type="number"
                                    value={form.discountAmount}
                                    onChange={handleChange}
                                />
                            )}
                        </Col>
                        <Col xs={12} md={6}>
                            {(form.discountAmount * 1 <= 0) && (
                                <TextField
                                    id="maxDiscountAmount"
                                    name="maxDiscountAmount"
                                    label="Maksimal Jumlah Diskon"
                                    fullWidth
                                    type="number"
                                    value={form.maxDiscountAmount}
                                    onChange={handleChange}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 20 }}>
                        <Col xs={12} className="text-center">
                            <Button variant="contained" color="primary" fullWidth onClick={handleSimpan}>Simpan</Button>
                        </Col>
                    </Row>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;