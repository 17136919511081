import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import UserOpnameForm from './UserOpname.Form'
import mOpname from './UserOpname.controller'
import AuthContext from '../../../context/auth-context'
import { isArray } from 'util'
import validate from 'validate.js'
import DialogInfo from '../../../Dialog/DialogInfo'
import { Spinner } from 'react-bootstrap'

const UserOpnameDetail = (props) => {
    const defaultPage = parseInt(props.match.params.page, 10);
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const kodeoutlet = props.match.params.kodeoutlet;
    const username = props.match.params.username;
    const { clientID, token } = useContext(AuthContext)
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const validateForm = () => {
        const { namawaiter } = state;
        const promKategori = new Promise((resolve, reject) => {
            const constraint = {
                noHp: {
                    length: {
                        minimum: 8,
                        message: "no Hp minimal terdiri dari 8 huruf"
                    },
                },
            }
            const validator = validate({ namawaiter }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promKategori]);
    }
    const handleSimpan = () => {
        setIsLoading(true)
        validateForm().then(() => {
            
            const { username, noHp, gender } = state;
            mOpname.updateOpname(kodeoutlet, clientID, token, username, noHp, gender ).then(resp => {
                setIsLoading(false)
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>User ${username} berhasil di update<p/>`)
                    setDialogUrl(`/manajemenoutlet/${kodeoutlet}/opname-user/${defaultPage}/${defaultrowsPerPage}`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Server tidak dapat menjalankan perintah Anda saat ini. Silakan coba sesaat lagi<p/>`)
                }
            }).catch(err => {
                setIsLoading(false)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setIsLoading(false)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }
    const [state, setState] = useState(
        {
            username: '',
            noHp: '',
            gender: ''
        })
    useEffect(() => {
        var render = true;
        if (kodeoutlet && username) {
            mOpname.detailOpname(kodeoutlet, username, clientID, token).then(resp => {
                if (render) setState(resp);
            })
        }
        return () => {
            render = false;
        };
    }, [kodeoutlet, username, clientID, token])


    const handleChange = (key, value) => {
        const newState = Object.assign({}, state);
        newState[key] = value;
        setState(newState);
    }
    if (isLoading) {
        return <Spinner className="spinner" animation="border" />;
    } else {
        return (
            <Container>
                <UserOpnameForm type="edit" history={props.history} kodeoutlet={kodeoutlet} username={state.username} noHp={state.noHp} gender={state.gender} handleChange={handleChange} defaultPage={defaultPage} defaultrowsPerPage={defaultrowsPerPage} />
                <Row style={{ marginTop: 20, marginBottom: 20 }}>
                    <Col xs={12} className="text-center">
                        <Button variant="contained" color="primary" onClick={handleSimpan}>Update</Button>
                    </Col>
                </Row>
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            </Container>
        )
    }
}

export default UserOpnameDetail;