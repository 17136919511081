import React, { useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import SubKategoriForm from './SubKategoriForm'
import AuthContext from '../../context/auth-context'
import mSubkategori from './SubKategori.controller'
import { isArray } from 'util';
import validate from 'validate.js'
import DialogInfo from '../../Dialog/DialogInfo'

const SubKategoriAdd = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const { clientID, token } = useContext(AuthContext)
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [state, setState] = useState({ kodesubkategori: '', namasubkategori: '' });
    const [isLoading, setIsLoading] = useState(false)
    const validateForm = () => {
        const { namasubkategori } = state;
        const promSubKategori = new Promise((resolve, reject) => {
            const constraint = {
                namasubkategori: {
                    length: {
                        minimum: 3,
                        message: "Nama Sub Kategori minimal terdiri dari 3 huruf"
                    },
                }
            }
            const validator = validate({ namasubkategori }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promSubKategori]);
    }
    const handleSimpan = () => {
        setIsLoading(true)
        validateForm().then(() => {
            mSubkategori.simpanSubKategori(kodeoutlet, state.namasubkategori, clientID, token).then(resp => {
                setIsLoading(false)
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${state.namasubkategori} berhasil di simpan<p/>`)
                    setDialogUrl(`/manajemenoutlet/${kodeoutlet}/subkategori/1/5`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Server tidak dapat menjalankan perintah Anda saat ini. Silakan coba sesaat lagi<p/>`)
                }
            }).catch(err => {
                setIsLoading(false)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setIsLoading(false)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }

    const handleChange = (key, value) => {
        const newState = Object.assign({}, state);
        newState[key] = value;
        setState(newState);
    }
    return (
        <Container>
            <SubKategoriForm history={props.history} kodeoutlet={kodeoutlet} handleChange={handleChange} namasubkategori={state.namasubkategori} />
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12} className="text-center">
                    <Button variant="contained" color="primary" onClick={handleSimpan} disabled={isLoading}>Simpan</Button>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    )
}

export default SubKategoriAdd;