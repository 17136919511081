/* eslint-disable no-redeclare */
import GQL from '../../GQL';
import moment from 'moment'


const Point = {
    getOutlet: (clientID, token, kodeoutlet) => {
        return new Promise((resolve, reject) => {
            const query = `
          query {
            getOutlet(idOutlet:${kodeoutlet}){
                kodeoutlet
             }
        }
    
          `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.getOutlet != null) {
                    return (resolve(resp.data.getOutlet));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err));
        })
    },
    loadData: (kodeoutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listMemberMessaging(kodeoutlet:"${kodeoutlet}"){
                        id
                        username
                        fullname
                        hp
                        alamat
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.listMemberMessaging) return (resolve(resp.data.listMemberMessaging));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    savePoktan: (clientID, token, kodeoutlet, fullname, hp, alamat, username, password) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanMemberMessaging(input:{
                        username:"${username}",
                        password:"${password}",
                        fullname:"${fullname}"
                        hp:"${hp}",
                        alamat:"${alamat}",
                        kodeoutlet:"${kodeoutlet}"
                    })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanMemberMessaging !== undefined) {
                    return (resolve(resp.data.simpanMemberMessaging));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    updatePoktan: (clientID, token, id, fullname, hp, alamat) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    updateMemberMessaging(input:{
                        id:${id},
                        fullname:"${fullname}"
                        hp:"${hp}",
                        alamat:"${alamat}",
                    })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.updateMemberMessaging !== undefined) {
                    return (resolve(resp.data.updateMemberMessaging));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    hapusPoktan: (clientID, token, id) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusMemberMessaging(id:${id})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusMemberMessaging !== undefined) {
                    return (resolve(resp.data.hapusMemberMessaging));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    cekUserPoktan: (clientID, token, username) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    cekMemberMessaging(username:"${username}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.cekMemberMessaging !== undefined) {
                    return (resolve(resp.data.cekMemberMessaging));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
    resetUserPoktan: (clientID, token, id, password) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    ResetPasswordMemberMessaging(id:${id}, password:"${password}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp.data && resp.data.ResetPasswordMemberMessaging !== undefined) {
                    return (resolve(resp.data.ResetPasswordMemberMessaging));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            })
        })
    },
}

export default Point;