import React, { useContext, useState, useEffect } from "react";
import {
    Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, Table, TableRow, TableCell, TableHead, TableBody, TextField, FormControlLabel, Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import { Row } from "react-bootstrap";
import AuthContext from '../../context/auth-context'
import mPayment from './Payment.controller'
import moment from 'moment'
import { isArray } from 'util'
import DeleteIcon from '@material-ui/icons/Delete';
import validate from 'validate.js';


var dateNow = moment();
const DialogEditPayment = (props) => {
    const classes = useStyles();
    const kodepayment = props.item
    const kodeoutlet = props.kodeoutlet
    const { clientID, token } = useContext(AuthContext)
    const [addRow, setAddRow] = useState(dateNow.format("YYMMDDHHmmss") * 1 + 0)
    const [listPayment, setListPayment] = useState([])

    const handleClose = () => {
        props.setOpen(false);
    }
    const SimpanPayment = () => {
        const validasiError = []
        listPayment.map(el => {
            const { namapayment } = el;
            const constraint = {
                namapayment: {
                    length: {
                        minimum: 3,
                        message: "Nama Payment minimal terdiri dari 3 huruf"
                    },
                }
            }
            const validator = validate({ namapayment }, constraint, { fullMessages: false });
            if (validator) {
                const err = [validator][0]
                const keys = Object.keys(err);
                var errorMessage = "";
                for (const key of keys) {
                    errorMessage += err[key] + "\n";
                }
                validasiError.push(errorMessage)
                return
            }
        })
        if (validasiError.length > 0) return alert(validasiError[0])
        mPayment.importPayment(kodeoutlet, listPayment, clientID, token).then(resp => {
            if (resp) {
                alert("Data Berhasil Di Simpan")
                props.setOpen(false);
                props.setIsRefresh(true);
                props.setSelected([])
            } else {
                alert("Data Gagal Di Simpan")
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                alert(`Gagal Simpan :  ${err[0].message}`)
            } else if (typeof err == 'error') {
                alert(err.message)
            } else {
                console.log(err);
            }
        })
    }
    const handleaddRow = () => {
        setAddRow(addRow + 1)
        setListPayment([
            ...listPayment,
            {
                kodepayment: addRow,
                namapayment: "",
                isInsert: true,
                isAutoFill: false,
                isCard: false

            }
        ])
    }

    useEffect(() => {
        if (kodepayment == null) {
            setAddRow(addRow + 1)
            setListPayment([
                {
                    kodepayment: addRow,
                    namapayment: "",
                    isInsert: true,
                    isAutoFill: false,
                    isCard: false
                }
            ])
        } else {
            var render = true
            mPayment.getData(kodeoutlet, clientID, token, kodepayment).then(resp => {
                if (render) setListPayment(resp);
            }).catch(err => {
                console.error(err);
            })
            return () => {
                render = false
            };
        }

    }, [kodeoutlet, clientID, token]);
    const handleChange = (ev, kodepayment) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        let b = listPayment.map(el => {
            if (el.kodepayment == kodepayment) {
                if (key == "namapayment") {
                    return {
                        ...el,
                        namapayment: value
                    }
                } else if (key == "kodepayment") {
                    return {
                        ...el,
                        kodepayment: value,
                    }
                }
            } else return el
        })
        setListPayment(b)
    }
    const handleChangeCheckBox = (name, kodepayment) => event => {
        var value = event.target.checked;
        const key = name;
        let b = listPayment.map(el => {
            if (el.kodepayment == kodepayment) {
                if (key == "isAutoFill") {
                    return {
                        ...el,
                        isAutoFill: value,
                    }
                } else if (key == "isCard") {
                    return {
                        ...el,
                        isCard: value
                    }
                }
            } else return el
        })
        setListPayment(b)

    };
    const deleteRow = (data) => {
        let a = listPayment.filter(el => {
            return el.kodepayment !== data.kodepayment
        })
        setListPayment(a)
    }
    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Edit Payment</Typography>
                    <Button color="inherit" onClick={SimpanPayment}>
                        save</Button>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                <Row className={classes.root}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className="colorWhite" style={{ width: ' 10px' }}></TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 250px' }}>Kode Payment</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 350px' }}>Nama Payment</TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 450px' }}></TableCell>
                                <TableCell className="colorWhite" style={{ minWidth: ' 450px' }}></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listPayment.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {data.isInsert && (
                                                <DeleteIcon className="deleteDialog" onClick={deleteRow.bind(this, data)} />
                                            )}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className="colorWhite">
                                            {!data.isInsert && (data.kodepayment)}
                                            {data.isInsert && (
                                                <span className="tabelForm">
                                                    <TextField
                                                        id="kodepayment"
                                                        name="kodepayment"
                                                        label=""
                                                        fullWidth
                                                        required
                                                        multiline={false}
                                                        value={data.kodepayment}
                                                        variant="outlined"
                                                        onChange={(ev) => handleChange(ev, data.kodepayment)}
                                                    />
                                                </span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                id="namapayment"
                                                name="namapayment"
                                                label=""
                                                fullWidth
                                                required
                                                multiline={false}
                                                value={data.namapayment}
                                                variant="outlined"
                                                onChange={(ev) => handleChange(ev, data.kodepayment)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControlLabel control={<Checkbox checked={data.isAutoFill}
                                                onChange={handleChangeCheckBox('isAutoFill', data.kodepayment)}
                                                value="isAutoFill" />} label="Jumlah pembayaran diisi sistem secara otomatis saat pembayaran" />
                                        </TableCell>
                                        <TableCell>
                                            <FormControlLabel control={<Checkbox checked={data.isCard}
                                                onChange={handleChangeCheckBox('isCard', data.kodepayment)}
                                                value="isCard" />} label="Pembayaran menggunkan mesin EDC" />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Row>
                <Row>
                    <Button className="marginTop15" variant="contained" color="primary" component="span" onClick={handleaddRow}>Add Row</Button>
                </Row>
            </Container>
        </Dialog>
    )
}
export default DialogEditPayment;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));