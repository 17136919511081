import React, { useState, useEffect, useContext } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa'
import DateFnsUtils from '@date-io/moment';
import { Line, } from 'react-chartjs-2';
import { makeStyles, Paper, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AuthContext from '../../../context/auth-context'
import mGrafik from './Grafik.controler'
import moment from 'moment'
import 'moment-timezone';
import { TextField, Select, FormControl, MenuItem, InputLabel, FormControlLabel, Checkbox, FormLabel, Radio, RadioGroup } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    }
}));
var dateNow = moment();
const GrafikPenjualan = (props) => {
    const [viewGrafik, setViewGrafik] = useState(false)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [kodeOutlet, setKodeOutlet] = useState("all")
    const [tglDari, setTglDari] = useState(moment(dateNow).tz("Asia/Jakarta").set({
        date: 1,
        hour: 0,
        minute: 0,
        second: 0
    }))
    const [tglSampai, setTglSampai] = useState(dateNow)
    const [isRefresh, setIsRefresh] = useState(true)
    const [listOutlet, setListOutlet] = useState([])

    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Grafik Harian',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgb(230, 123, 33)',
                borderColor: 'rgb(255, 117, 0)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(255, 117, 0)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255, 117, 0)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: []
            }
        ]
    })


    useEffect(() => {
        var render = true
        if (isRefresh) {
            mGrafik.loadOutlet(userLogin, clientID, token).then(resp => {
                if (render) setListOutlet(resp);
            }).catch(err => {
                console.error(err);
            })
            mGrafik.loadGrafik(clientID, token, tglDari, tglSampai, kodeOutlet).then(resp => {
                const newData = {
                    ...data,
                    labels: resp.tanggal,
                    datasets: [
                        {
                            ...data.datasets[0],
                            data: resp.nilaiPenjualan
                        }
                    ]
                }
                setData(newData);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [clientID, token, isRefresh])
    const goBack = () => {
        props.history.push(`/laporan/menu`)
    }
    const lihatGrafik = () => {
        setIsRefresh(true)
        setViewGrafik(true);
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
        kosongkanData()
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
        kosongkanData()
    }
    const handleChangeOutlet =(ev) => {
        setKodeOutlet(ev.target.value)
        kosongkanData()
    }
    const kosongkanData = () => {
        const newData = {
            ...data,
            labels: [],
            datasets: [
                {
                    ...data.datasets[0],
                    data: []
                }
            ]
        }
        setData(newData);
    }
    return (
        <React.Fragment>
            <main>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Grafik Penjualan</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDatePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Dari"
                                    format="DD MMM YYYY"
                                    value={tglDari}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    onChange={handleChangeTglDari}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDatePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Sampai"
                                    format="DD MMM YYYY"
                                    value={tglSampai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{ color: '#000 !important' }}
                                    onChange={handleChangeTglSampai}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12}>
                            <FormControl fullWidth={true}>
                                <InputLabel>Pilih Outlet</InputLabel>
                                <Select
                                    name="subkategori"
                                    id="subkategori"
                                    required
                                    value={kodeOutlet}
                                    onChange={handleChangeOutlet}
                                >
                                    <MenuItem value="all">Semua Outlet</MenuItem>
                                    {listOutlet.map(el => (
                                    <MenuItem key={el.kodeoutlet} value={el.kodeoutlet}>{el.namaoutlet}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={12} className="text-center"><br />
                            <Button variant="contained" color="primary" onClick={lihatGrafik}>Lihat Grafik</Button>
                        </Col>
                    </Row>
                    {viewGrafik && (
                        <Row style={{ marginTop: 15 }}>
                            <Col>
                                <div className={useStyles.root}>
                                    <Paper className={useStyles.paper}>
                                        <Line data={data} />
                                    </Paper>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <br />
                </Container>
            </main>
        </React.Fragment>
    )
}
export default GrafikPenjualan;