import GQL from '../../../GQL';
const Info = {
    loadOutletDet: (idOutlet, clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    getOutlet(idOutlet:${idOutlet}){
                        phoneNumber
                        address
                        kodeoutlet
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.getOutlet) return (resolve(resp.data.getOutlet));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    updateOutlet: (clientID, token, form) => {
        const _address = form.address.replace(/\n/g,"<BR>");
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                updateOutlet( input:{
                    kodeoutlet:"${form.kodeoutlet}",
                    phoneNumber:"${form.phoneNumber}",
                    address:"${_address}"
                })
              }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.updateOutlet) return (resolve(resp.data.updateOutlet));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },

}

export default Info;