/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { Container, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';
import useStyles from '../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'react-moment';
import moment from 'moment'
import mSettlementPassti from './SettlementPasstiControler'
import { isArray } from "util";

const dateSkrg = moment()
const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    {
        id: "noinvoice", numeric: false, disablePadding: true, label: "No Invoice"
    },
    { id: "amount", numeric: true, disablePadding: false, label: "Amount" },
    { id: "tangal", numeric: true, disablePadding: false, label: "Tanggal" },
    { id: "tipekartu", numeric: true, disablePadding: false, label: "Tipe Kartu" },


];

const EnhancedTableHead = (props) => {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
        rowCount,
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const SettlementPassti = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = parseInt(props.match.params.page, 10) - 1;
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const { clientID, token } = useContext(AuthContext)
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [dense, setDense] = React.useState(false);
    const [isRefresh, setIsRefresh] = useState(true)
    const [rows, setRows] = useState([])
    const [openRiwayat, setOpenRiwayat] = useState(true)
    const [openTgl, setOpenTgl] = useState(false)
    const [tglDari, setTglDari] = useState(dateSkrg)
    const [listAlat, setListAlat] = useState([])

    useEffect(() => {
        var render = true
        if (isRefresh) {
            mSettlementPassti.loadData(kodeoutlet, clientID, token, tglDari).then(resp => {
                if (render) {
                    setRows(resp.listTrans)
                    setListAlat(resp.listAlat)
                }
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [kodeoutlet, clientID, token, isRefresh]);
    const goBack = () => {
        props.history.push(`/manajemenoutlet/${kodeoutlet}`)
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const loadingRow = [0, 1, 2, 3, 4];
    const TglDariHandler = (val) => {
        setTglDari(moment(val).format("D MMM YYYY"))
    }
    const handleOpenRiwayat = () => {
        setOpenTgl(false)
        setOpenRiwayat(true);
        setIsRefresh(true);
    }
    const handleOpenTgl = () => {
        setOpenRiwayat(false);
        setOpenTgl(true)
        setPage(defaultPage)
        setRowsPerPage(defaultrowsPerPage)
    }
    const handleProses = (MIDReader, TIDReader) => {
        if (window.confirm("Yakin ingin diproses ?")) {
            mSettlementPassti.proses(kodeoutlet, MIDReader, TIDReader, clientID, token).then(resp => {
                if (resp) {
                    alert(`berhasil diproses`)
                    setIsRefresh(true);
                } else {
                    alert(`Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi`)
                }
            }).catch(err => {
                if (isArray(err) && err.length > 0) {
                    alert(`Gagal hapus ${err[0].message}`)
                } else if (typeof err == 'error') {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom">
                        <FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Settlement Passti
                    </h3>
                    {openTgl && (
                        <Row>
                            <Col xs={12} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                    <KeyboardDatePicker
                                        fullWidth={true}
                                        margin="normal"
                                        id="tanggal"
                                        label="Tanggal Dari"
                                        format="DD MMM YYYY"
                                        value={tglDari}
                                        onChange={TglDariHandler}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                            <Col xs={12} md={6}><br />
                                <Button variant="contained" color="primary" onClick={handleOpenRiwayat}>Lihat Settlement</Button>
                            </Col>
                        </Row>
                    )}
                    {openRiwayat && (
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <div className="btnTable">
                                    <span>
                                        <b>
                                            <Moment format="D MMM YYYY">{tglDari}</Moment>
                                        </b>
                                    </span>
                                    <span className="ubahTanggal" onClick={handleOpenTgl}> (Ubah Tanggal)</span>
                                    {listAlat.map(alat => (
                                        <React.Fragment key={alat.TIDReader}>
                                            <h6>kode alat : {alat.TIDReader} <Button variant="contained" color="primary" onClick={handleProses.bind(this, alat.MIDReader, alat.TIDReader)}>Proses</Button></h6>
                                            <Table
                                                className={classes.table}
                                                aria-labelledby="tableTitle"
                                                size={dense ? "small" : "medium"}
                                            >
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={rows.length}
                                                />
                                                <TableBody>
                                                    {rows.length == 0 && loadingRow.map(el => (
                                                        <TableRow
                                                            key={el.toString()}
                                                            style={{ height: 50 }}
                                                        >
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell >
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))

                                                    }
                                                    {stableSort(rows.filter(trans => {
                                                        return trans.TIDReader == alat.TIDReader
                                                    }), getSorting(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row, index) => {
                                                            const total = row.statusid === 20 ? row.payment.map(el => el.total).reduce((a, b) => a + b, 0) : 0;
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    className={(row.statusid === 8 || row.statusid === 9) && ("bgRowCancel")}
                                                                >
                                                                    <TableCell >
                                                                        {row.noinvoice}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.amount}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.tanggal}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.namapayment}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 49 * emptyRows }}>
                                                            <TableCell colSpan={7} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page"
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
export default SettlementPassti;