import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import KasKeluarForm from './KasKeluarForm'
import mControl from './KasKeluar.controller'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util'
import validate from 'validate.js'
import DialogInfo from '../../Dialog/DialogInfo'

const KategoriDetail = (props) => {
    const defaultPage = parseInt(props.match.params.page, 10);
    const defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const kodeoutlet = props.match.params.kodeoutlet;
    const kodebiaya = props.match.params.kodebiaya;
    const { clientID, token } = useContext(AuthContext)
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [kodeOutletString, setKodeOutletString] = useState()
    const validateForm = () => {
        const { namabiaya, kodebiaya } = state;
        const promValidasi = new Promise((resolve, reject) => {
            const constraint = {
                kodebiaya: {
                    length: {
                        minimum: 3,
                        message: "Kode Biaya minimal terdiri dari 3 digit"
                    },
                },
                namabiaya: {
                    length: {
                        minimum: 3,
                        message: "Nama Kategori minimal terdiri dari 3 huruf"
                    },
                }
            }
            const validator = validate({ namabiaya, kodebiaya }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promValidasi]);
    }
    const handleSimpan = () => {
        validateForm().then(() => {
            setIsLoading(true)
            mControl.update(kodeOutletString, kodebiaya, state.namabiaya, clientID, token).then(resp => {
                setIsLoading(false)
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Kas keeluar berhasil di update<p/>`)
                    setDialogUrl(`/manajemenoutlet/${kodeoutlet}/listkaskeluar/${defaultPage}/${defaultrowsPerPage}`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Server tidak dapat menjalankan perintah Anda saat ini. Silakan coba sesaat lagi<p/>`)
                }
            }).catch(err => {
                setIsLoading(false)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setIsLoading(false)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }
    const [state, setState] = useState({ kodebiaya: '', namabiaya: '' })
    useEffect(() => {
        var render = true;
        if (kodeoutlet && kodebiaya) {
            mControl.getOutlet(kodeoutlet, clientID, token).then(resp => {
                setKodeOutletString(resp.kodeoutlet)
                mControl.loadDetail(resp.kodeoutlet, kodebiaya, clientID, token).then(resp => {
                    if (render) setState(resp);
                })
            }).catch(err => {
                console.error(err);
            })
        }
        return () => {
            render = false;
        };
    }, [kodeoutlet, kodebiaya, clientID, token])


    const handleChange = (key, value) => {
        const newState = Object.assign({}, state);
        newState[key] = value;
        setState(newState);
    }

    return (
        <Container>
            <KasKeluarForm type="edit" history={props.history} kodeoutlet={kodeoutlet} kodebiaya={state.kodebiaya} namabiaya={state.namabiaya} handleChange={handleChange} defaultPage={defaultPage} defaultrowsPerPage={defaultrowsPerPage} isUpdate={true}/>
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12} className="text-center">
                    <Button variant="contained" color="primary" onClick={handleSimpan} disabled={isLoading}>Update</Button>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    )
}

export default KategoriDetail;