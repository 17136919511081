import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import AuthContext from '../../../../context/auth-context'
import mLaporan from '../Laporan.controler'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment-timezone'
import PageviewIcon from '@material-ui/icons/Pageview';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { stableSort, getSorting } from '../../../../Helper/Sorting'
import PropTypes from "prop-types";
import { TableSortLabel } from '@material-ui/core';
import numberWithCommas from '../../../../Helper/numberWithCommas'
import Dropdown from 'react-bootstrap/Dropdown'
import { FaChevronLeft } from 'react-icons/fa';
import SalesmanPerTrans from './SalesmanPerTrans'
import SalesmanPerSub from './SalesmanPerSub'

const Salesman = (props) => {
    const classes = useStyles();
    const [isRefresh, setIsRefresh] = useState(true)
    const [tglDari, setTglDari] = useState(props.tanggalDari)
    const [tglSampai, setTglSampai] = useState(props.tanggalSampai)
    const [kodeoulet, setKodeoulet] = useState(props.kodeOutlet)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [idOutlet, setIdOutlet] = useState(props.idOutlet)
    const [listLaporan, setListLaporan] = useState([])
    const [listLapBarang, setListLapBarang] = useState([])
    const [lisLaporanSub, setLisLaporanSub] = useState([])
    const [listLapTrans, setListLapTrans] = useState([])
    const [listLapAllTrans, setListLapAllTrans] = useState([])
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("namawaiter");
    const [isLapUtama, setIsLapUtama] = useState(true)
    const [isLapPerTrans, setIsLapPerTrans] = useState(false)
    const [isLapPerSub, setIsLapPerSub] = useState(false)
    const [sales, setSales] = useState({})
    var totJumlah = 0;
    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.laporanSalesman(clientID, token, userLogin, idOutlet, tglDari, tglSampai).then(resp => {
                setListLaporan(resp.salesmanGroup)
                setListLapBarang(resp.itemCOsubkat)
                setListLapAllTrans(resp.transaksiGroup)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])


    const lihatLaporan = () => {
        setListLaporan([])
        setIsRefresh(true)
    }
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    const namaExport = () => {
        return (
            <React.Fragment>
                <ImportExportIcon />  Export Excel
            </React.Fragment>
        )
    }
    const headRows = [
        { id: "namawaiter", label: "Nama Sales" },
        { id: "total", label: "Jumlah" },

    ];
    const EnhancedTableHead = (props) => {
        const {
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (

            <thead>
                <tr className="colorWhite bgHeaderTabel tabelCenter">
                    <th style={{ "width": "15px" }}>Detail</th>
                    {headRows.map(row => (
                        <th key={row.id} >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const openLapPerTrans = (data) => {
        setIsLapUtama(false)
        setIsLapPerTrans(true)
        setSales(data)
        const lapTrans = listLapAllTrans.filter(el => {
            return el.kodewaiter === data.kodewaiter
        })
        setListLapTrans(lapTrans)
    }
    const openLapPerSub = (data) => {
        setIsLapUtama(false)
        setIsLapPerSub(true)
        setSales(data)
        const lapSub = listLapBarang.filter(el => {
            return el.kodewaiter === data.kodewaiter
        })
        setLisLaporanSub(lapSub)
    }
    const closeLapTrans = () => {
        setIsLapUtama(true)
        setIsLapPerTrans(false)
    }
    const closeLapSub = (data) => {
        setIsLapUtama(true)
        setIsLapPerSub(false)
    }
    return (
        <React.Fragment>
            {isLapUtama && (
                <React.Fragment>
                    <Row>
                        <Col xs={12} lg={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDatePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Dari"
                                    format="DD-MM-YYYY"
                                    value={tglDari}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    onChange={handleChangeTglDari}
                                    ampm={false}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                                <KeyboardDatePicker
                                    fullWidth={true}
                                    margin="normal"
                                    id="tanggal"
                                    label="Tanggal Sampai"
                                    format="DD-MM-YYYY"
                                    value={tglSampai}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{ color: '#000 !important' }}
                                    onChange={handleChangeTglSampai}
                                    ampm={false}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={2} className="text-center"><br />
                            <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                        </Col>
                    </Row>
                    <Row className=" bodySessionDetail">
                        <Col xs={12} className="marginGrafik">
                            <ReactHTMLTableToExcel
                                className="buttonExportExcel"
                                table="laporanSalesman"
                                filename={`Laporan Salesman ${moment(tglDari).format("DD-MM-YYYY")} sampai ${moment(tglSampai).format("DD-MM-YYYY")}`}
                                sheet="Sheet1"
                                buttonText={namaExport()} />
                            <Table id="laporanSalesman" striped bordered hover responsive="lg">
                                <EnhancedTableHead
                                    onRequestSort={handleRequestSort}
                                    order={order}
                                    orderBy={orderBy}
                                />
                                <tbody className={`${classes.contentBody}`}>
                                    {stableSort(listLaporan, getSorting(order, orderBy))
                                        .map((data, index) => {
                                            totJumlah += (data.total + (data.total * (data.tax + data.service) / 100)) - data.discountAmount
                                            return (
                                                <tr className="colorWhite" key={index}>
                                                    <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="dropdown-basic" className="btnRiwayatTrans">
                                                                <PageviewIcon id="dropdown-basic" /></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item><Button title="Per Transaksi" className="btnRiwayatTrans" style={{ marginRight: 8, color: "#fff", width: '100%' }} onClick={openLapPerTrans.bind(this, data)}> Per Transaksi</Button></Dropdown.Item>
                                                                <Dropdown.Item><Button title="Per Sub Kategori" className="btnRiwayatTrans" style={{ marginRight: 8, color: "#fff", width: '100%' }} onClick={openLapPerSub.bind(this, data)}> Per Sub Kategori</Button></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        {/* <Button title="Detail" variant="contained" className="btnRiwayatTrans" ><PageviewIcon /></Button> */}
                                                    </td>
                                                    <td>{data.namawaiter}</td>
                                                    <td className="text-right">
                                                        {numberWithCommas((data.total + (data.total * (data.tax + data.service) / 100)) - data.discountAmount)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    <tr className="colorWhite">
                                        <th className="text-center" colSpan="2">Total</th>
                                        <th className="text-right">
                                            {numberWithCommas(totJumlah)}
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
            {isLapPerTrans && (
                <React.Fragment>
                    <Row>
                        <Col xs={12}>
                            <div className="fixHeaderModal">
                                <h4 style={{ paddingBottom: 10 }} className="border-bottom btnBack"><FaChevronLeft onClick={closeLapTrans} className="btnBack" />Laporan Per Transaksi</h4>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <p className="colorBlack">Nama Salesman : <b>{sales.namawaiter}</b> <br />
                                Tanggal Periode: {moment(tglDari).format("DD-MM-YYYY")} sampai {moment(tglSampai).format("DD-MM-YYYY")}</p>
                        </Col>
                    </Row>
                    <SalesmanPerTrans listLapTrans={listLapTrans} tglDari={tglDari} tglSampai={tglSampai} namawaiter={sales.namawaiter} />
                </React.Fragment>
            )}
            {isLapPerSub && (
                <React.Fragment>
                    <Row>
                        <Col xs={12}>
                            <div className="fixHeaderModal">
                                <h4 style={{ paddingBottom: 10 }} className="border-bottom btnBack"><FaChevronLeft onClick={closeLapSub} className="btnBack" />Laporan Per Sub Kategori</h4>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <p className="colorBlack">Nama Salesman : <b>{sales.namawaiter}</b> <br />
                                Tanggal Periode: {moment(tglDari).format("DD-MM-YYYY")} sampai {moment(tglSampai).format("DD-MM-YYYY")}</p>
                        </Col>
                    </Row>
                    <SalesmanPerSub  lisLaporanSub={lisLaporanSub} idOutlet={idOutlet} tglDari={tglDari} tglSampai={tglSampai} namawaiter={sales.namawaiter}/>
                </React.Fragment>
            )}
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default Salesman;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));