import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
const BoxInformasi = (props) => {
    return (

        <Col xs={12} md={6} lg>
            <div className="box">
                <div className={`judul-kotak  ${props.bg}`}>
                    <span className={`right label label-tot`}>Today</span>
                    <h5>{props.nama}</h5>
                </div>
                <div className={`box-content ${props.bg}`} >
                    <h2>
                        {props.jumlah}
                    </h2>
                    <Row>
                        <Col xs>
                            {props.value}
                        </Col>
                    </Row>
                    {props.value2 && (
                        <Row>
                            <Col xs>
                                {props.value2}
                            </Col>
                        </Row>

                    )}
                    {props.cabang && (
                        <React.Fragment>
                            <Row>
                                <Col xs>
                                    <small>{props.aktif} Cabang</small>
                                    <div className="right">
                                        <span>Aktif</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <small>{props.nonAktif} Cabang</small>
                                    <div className="right">
                                        <span>Tidak Aktif</span>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>

                    )}
                    {props.Pembayaran && (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <span className="isiBayar">Tunai</span>
                                    <div className="right">
                                        <span>{props.tunai}</span>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <span  className="isiBayar">Non Tunai</span>
                                    <div className="right">
                                        <span>{props.nonTunai}</span>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </Col>

    )
}

const Item1 = (props) => {
    return (
        <React.Fragment>
            <small>{props.judul}</small>
            <div className="right">
                <span>{props.total}</span>
                <ShoppingCartIcon />
            </div>

        </React.Fragment>
    )
}
const Item2 = (props) => {
    return (
        <React.Fragment>
            <small>{props.judul}</small>
            <div className="right">
                <span>{props.total}</span>
                <ShoppingCartIcon />
            </div>
        </React.Fragment>
    )
}

export { BoxInformasi, Item1, Item2 };