import React, { useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import UserOpnameForm from './UserOpname.Form'
import AuthContext from '../../../context/auth-context'
import mOpname from './UserOpname.controller'
import { isArray } from 'util'
import validate from 'validate.js'
import DialogInfo from '../../../Dialog/DialogInfo'
import { Spinner } from 'react-bootstrap'

const UserOpnameAdd = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const { clientID, token } = useContext(AuthContext)
    const [state, setState] = useState(
        {
            username: '',
            password: '',
            noHp: '',
            gender: ''
        })
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const validateForm = () => {
        // console.log(state)
        const { username, password, noHp, gender } = state;
        const promKategori = new Promise((resolve, reject) => {
            const constraint = {
                username: {
                    length: {
                        minimum: 3,
                        message: "username minimal terdiri dari 3 huruf"
                    },
                },
                noHp: {
                    length: {
                        minimum: 8,
                        message: "no Hp minimal terdiri dari 8 huruf"
                    },
                },
            }
            const validator = validate({ username, password, noHp, gender }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promKategori]);
    }
    const handleSimpan = () => {
        setIsLoading(true)
        validateForm().then(() => {
            const { username, password, noHp, gender } = state;
            mOpname.simpan(kodeoutlet, username, password, noHp, gender, clientID, token).then(resp => {
                setIsLoading(false)
                if (resp) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${username} berhasil di simpan<p/>`)
                    setDialogUrl(`/manajemenoutlet/${kodeoutlet}/opname-user/1/5`)
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Server tidak dapat menjalankan perintah Anda saat ini. Silakan coba sesaat lagi<p/>`)
                }
            }).catch(err => {
                setIsLoading(false)
                if (isArray(err) && err.length > 0) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>Gagal Simpan : ${err[0].message}<p/>`)
                } else if (typeof err == 'error') {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${err.message}<p/>`)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            setIsLoading(false)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }
    const handleChange = (key, value) => {
        const newState = Object.assign({}, state);
        newState[key] = value;
        setState(newState);
    }
    if (isLoading) {
        return <Spinner className="spinner" animation="border" />;
    } else {
        return (
            <Container>
                <UserOpnameForm type="add" history={props.history} kodeoutlet={kodeoutlet} handleChange={handleChange} kodewaiter={state.kodewaiter} namaopname={state.namaopname} isInsert={true}/>
                <Row style={{ marginTop: 20, marginBottom: 20 }}>
                    <Col xs={12} className="text-center">
                        <Button variant="contained" color="primary" onClick={handleSimpan} disabled={isLoading}>Simpan</Button>
                    </Col>
                </Row>
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            </Container>
        )
    }
}

export default UserOpnameAdd;