import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import mLaporan from '../Laporan.controler'
import moment from 'moment-timezone'
import AuthContext from '../../../../context/auth-context'
import { makeStyles } from '@material-ui/core/styles';
import { FaChevronLeft } from 'react-icons/fa';
import { Button, TableRow, TableCell, TableBody, TableHead, } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import TransaksiDetail from './TransaksiDetail'

const numberWithCommas = (x) => {
    if (!x) x = 0;
    const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PaymentDetail = (props) => {
    const classes = useStyles();
    const dataDetail = props.dataDetail
    const [isRefresh, setIsRefresh] = useState(true)
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [listPayment, setlistPayment] = useState([])
    const [paymentDet, setPaymentDet] = useState({})
    const [isDetail, setIsDetail] = useState(false)
    const [detOrder, setDetOrder] = useState([])
    const [detBayar, setDetBayar] = useState([])
    const [hitungItem, setHitungItem] = useState({})
    const [jumlahDataBayar, setJumlahDataBayar] = useState(0)
    let totalPayment = 0, subTotalItem = 0
    let detTotBayar = 0;

    useEffect(() => {
        var render = true
        if (isRefresh) {
            mLaporan.laporanPaymentDetail(clientID, token, props.kodeoulet, props.tglDari, props.tglSampai, dataDetail.kodepayment).then(resp => {
                setlistPayment(resp)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
            render = false
        };
    }, [isRefresh])

    const handleDetail = (data) => {
        setIsDetail(true)
        props.setIsDetHeader(false)
        setPaymentDet(data)
        mLaporan.laporanPaymentDetailTrans(clientID, token, props.kodeoulet, data.noinvoice).then(resp => {
            setDetOrder(resp.item)
            setHitungItem(resp.itemTotal)
            setDetBayar(resp.payment);
        }).catch(err => {
            console.error(err);
        })
    }
    const handleCloseDetail = () => {
        setIsDetail(false)
        props.setIsDetHeader(true)
    }
    return (
        <React.Fragment>
            {!isDetail && (
                <Row >
                    <Col xs={12} className={`${classes.contentBody} bodySessionDetail`}>
                        <Table id="laporanPayment" striped bordered hover responsive="lg">
                            <thead>
                                <tr className="colorWhite bgHeaderTabel tabelCenter">
                                    <th>Detail Invoice</th>
                                    <th>No Invoice</th>
                                    <th>Tanggal</th>
                                    <th>Tipe Transaksi</th>
                                    <th className="text-right">Jumlah</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listPayment.map((data, index) => {
                                    totalPayment += data.jumlah
                                    return (
                                        <tr className="colorWhite" key={index}>
                                            <td className="text-center">
                                                <Button title="Detail" variant="contained" className="btnRiwayatTrans" onClick={handleDetail.bind(this, data)}><PageviewIcon /></Button>
                                            </td>
                                            <td >{data.noinvoice}</td>
                                            <td>
                                                {moment(data.tanggal).format("DD-MMM-YYYY HH:mm")}
                                            </td>
                                            <td className="text-center">{data.tipeTransaksi}</td>
                                            <td className="text-right">
                                                {numberWithCommas(data.jumlah)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr className="colorWhite">
                                    <th className="text-center" colSpan="4">Total</th>
                                    <th className="text-center">
                                        {numberWithCommas(totalPayment)}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {isDetail && (
                <React.Fragment>
                    <Row>
                        <Col xs={12}>
                            <div className="fixHeaderModal">
                                <h3 style={{ paddingBottom: 10 }} className="border-bottom btnBack"><FaChevronLeft onClick={handleCloseDetail} className="btnBack" />Payment Transaksi Detail </h3>
                                <h5 className="colorBlack">No Invoice : {paymentDet.noinvoice}</h5>
                            </div>
                        </Col>
                        <TransaksiDetail noinvoice={paymentDet.noinvoice} kodeoulet={props.kodeoulet}/>
                    </Row>

                </React.Fragment>
            )}
            <Row className="marginGrafik"></Row>
        </React.Fragment>

    )
}


export default PaymentDetail;
const useStyles = makeStyles(theme => ({
    contentBody: {
        height: window.innerHeight - 200,
    },
}));