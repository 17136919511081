import GQL from '../../GQL';
import moment from 'moment-timezone'

const SettlementPassti = {
  loadData: (idOutlet, clientID, token, tanggal) => {
    return new Promise((resolve, reject) => {
      const query = `
      query {
        listTransaksiPasstiUnprocess(idOutlet:${idOutlet}, tanggal:"${tanggal}"){
            noinvoice
            tanggal
            transRef
            namapayment
            nomorKartu
            MIDReader
            TIDReader
            amount
         }
    }

      `
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) return (reject(resp.errors));;
        if (resp && resp.data && resp.data.listTransaksiPasstiUnprocess != null) {
          const listAlat = []
          resp.data.listTransaksiPasstiUnprocess.reduce((a, b) => {
            if (!a[b.TIDReader]) {
              a[b.TIDReader] = {
                TIDReader: b.TIDReader,
                MIDReader: b.MIDReader
              }
              listAlat.push(a[b.TIDReader])
            }
            return a;
          }, {})
          const listTrans = resp.data.listTransaksiPasstiUnprocess
          return (resolve({
            listAlat,
            listTrans
          }));
        } else {
          return (reject(new Error("Gagal menyimpan data")))
        }
      }).catch(err => reject(err));
    })
  },
  proses:(idOutlet,MIDReader, TIDReader,clientID,token)=>{
    return new Promise((resolve,reject)=>{
        const query = `
            mutation{
              prosesSettlement(tanggal:"${moment().format("YYYY-MM-DD")}",MIDReader:"${MIDReader}",TIDReader:"${TIDReader}",idOutlet:${idOutlet})
            }
        `
        GQL(query,clientID,token).then(resp => {
            if(resp.errors) return(reject(resp.errors));;
            if(resp.data && resp.data.hapusKategoriApp != undefined ){
                return (resolve(resp.data.hapusKategoriApp));
            }else{
                return(reject(new Error("Gagal menyimpan data")))
            }
        })
    })
},
}

export default SettlementPassti;