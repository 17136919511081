/* eslint-disable no-mixed-operators */
import React, { useState, useContext } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom'
import AuthContext from '../../context/auth-context'
const imageLogo = require(`../../Icon/logo_${process.env.REACT_APP_COMPANY}.png`);

const MyNavbar = (props) => {
  const [navExpanded, setNavExpanded] = useState(false)
  const { userLogin } = useContext(AuthContext);

  const Logo = () => {
    if (process.env.REACT_APP_COMPANY === "MonkeyPOS") {
      return (
        <img alt="logo" width="50"  src={imageLogo} />
      )
    } else if (process.env.REACT_APP_COMPANY === "Ipos") {
      return (
        <img alt="logo" width="73" src={imageLogo}  />
      )
    }
  }
  return (
    <Navbar expand="lg" bg="dark" className="fixed-top navbar" onToggle={setNavExpanded.bind(true)} expanded={navExpanded}>
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <Logo />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" onClick={setNavExpanded.bind(this, false)}>
            <NavLink className="nav-link" to="/">Home</NavLink>
            {props.isLogin &&
              <React.Fragment>
                {(userLogin.toUpperCase() === "JACKY" || userLogin.toUpperCase() === "IPOS") &&
                  <React.Fragment>
                    <NavLink className="nav-link" to="/user">User</NavLink>
                  </React.Fragment>
                  ||
                  <React.Fragment>
                    <NavLink className="nav-link" to="/pilih-outlet">Managemen Outlet</NavLink>
                    <NavLink className="nav-link" to="" onClick={() => window.open(process.env.REACT_APP_REPORT)}>Laporan</NavLink>
                  </React.Fragment>
                  //process.env.REACT_APP_REPORT
                }
              </React.Fragment>
              ||
              <React.Fragment>
                <NavLink className="nav-link" to="/login">Login</NavLink>
                <NavLink className="nav-link" to="/register">Register</NavLink>
              </React.Fragment>
            }

          </Nav>
          {props.isLogin &&
            <Nav>
              <NavLink className="nav-link" to="/login" onClick={props.onLogout.bind(this)}>Log Out</NavLink>
            </Nav>
          }

        </Navbar.Collapse>

      </Container>

    </Navbar>
  )
}

export default MyNavbar;