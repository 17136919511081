/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import PaketForm from './PaketForm'
import mControl from './Paket.controller'
import AuthContext from '../../context/auth-context'
import { isArray } from 'util';
import validate from 'validate.js';
import DialogInfo from '../../Dialog/DialogInfo'
import moment from 'moment-timezone'
import ModalAddPaketItem from './Modal.AddPaketItem'


const BarangAdd = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const { clientID, token } = useContext(AuthContext)
    const [listKategori, setListKategori] = useState([])
    const [listSubKategori, setListSubKategori] = useState([])
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isModalPaketAdd, setIsModalPaketAdd] = useState(false)
    const [kodebarang, setKodebarang] = useState("")
    const [state, setState] = useState({
        kodebarang: '',
        namabarang: '',
        hargasatuan: 0,
        hargagojek: 0,
        hargagrab: 0,
        hargalain: 0,
        satuan: '',
        isStock: false,
    })
    const handleChange = (key, value) => {
        var newState = { ...state }
        if (key == 'kategori') {
            newState.kategori.kodekategori = value;
        } else if (key == 'subkategori') {
            newState.subkategori.kodesubkategori = value;
        } else {

            newState[key] = value;
        }
        setState(newState);
    }

    const validateForm = () => {
        const { namabarang, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock, printer } = state;
        const promBarang = new Promise((resolve, reject) => {
            const constraint = {
                namabarang: {
                    length: {
                        minimum: 3,
                        message: "Nama Barang minimal terdiri dari 3 huruf !"
                    },
                },
                satuan: {
                    length: {
                        minimum: 1,
                        message: "Satuan Wajib Isi !"
                    }
                },
                hargasatuan: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga satuan yang sesuai !"
                    }
                },
                hargagojek: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga gojek yang sesuai !"
                    }
                },
                hargagrab: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga grab yang sesuai !"
                    }
                },
                hargalain: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Harap mengisi harga lain yang sesuai !"
                    }
                }
            }
            const validator = validate({ namabarang, hargasatuan, hargagojek, hargagrab, hargalain, satuan, isStock }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promBarang]);
    }


    const handleSimpan = () => {
        setIsLoading(true)
        validateForm().then(() => {
            const _kodebarang = `kopkt-${state.namabarang.substring(0, 3)}-${moment().format("YYMMDDHHmmss")}`
            setKodebarang(_kodebarang)
            setIsModalPaketAdd(true)
        }).catch(err => {
            setIsLoading(false)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "<br/>";
            }
            setOpenConfirmInfo(true)
            setDialogtext(`<p>${errorMessage}<p/>`)
        })
    }
    return (
        <Container>
            <PaketForm
                history={props.history}
                kodeoutlet={kodeoutlet}
                kodebarang={state.kodebarang}
                listKategori={listKategori}
                listSubKategori={listSubKategori}
                namabarang={state.namabarang}
                kategori={state.kategori}
                subkategori={state.subkategori}
                satuan={state.satuan}
                hargasatuan={state.hargasatuan}
                hargagojek={state.hargagojek}
                hargagrab={state.hargagrab}
                hargalain={state.hargalain}
                isStock={state.isStock}
                printer={state.printer}
                dBgColor={state.bgColor}
                dColor={state.colorText}
                handleChange={handleChange}
            />
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={12} className="text-center">
                    <Button variant="contained" color="primary" onClick={handleSimpan} disabled={isLoading}>Simpan</Button>
                </Col>
            </Row>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            {isModalPaketAdd && (
                <ModalAddPaketItem open={isModalPaketAdd} setOpen={setIsModalPaketAdd} setIsLoading={setIsLoading} kodeoutlet={kodeoutlet} kodebarang={kodebarang} namabarang={state.namabarang} hargasatuan={state.hargasatuan} hargagojek={state.hargagojek} hargagrab={state.hargagrab} hargalain={state.hargalain} satuan={state.satuan} isStock={state.isStock} isNew={true} history={props.history} />
            )}
        </Container>
    )
}

export default BarangAdd;