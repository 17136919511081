/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import { Button, TextField } from '@material-ui/core';
import mControl from './TaxService.Controler'



const TaxService = (props) => {
    const kodeoutlet = props.kodeoutlet;
    const clientID = props.clientID
    const token = props.token
    const [isRefresh, setIsRefresh] = useState(true)
    const [form, setForm] = useState({
        tax: 0,
        service: 0,
        taxNormal: 0,
        serviceNormal: 0,
    
        taxGojek: 0,
        serviceGojek: 0,
    
        taxGrab: 0,
        serviceGrab: 0,
    
        taxLain: 0,
        serviceLain: 0,
    
        taxLain2: 0,
        serviceLain2: 0,
    })
    useEffect(() => {
        if (isRefresh) {
            mControl.loadTaxService(kodeoutlet, clientID, token).then(resp => {
                setForm(resp);
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
        };
    }, [kodeoutlet, clientID, token, isRefresh]);
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }

    const handleSaveUser = () => {
        mControl.updateTaxService(kodeoutlet, clientID, token, form.tax, form.service,form).then(resp => {
            if (resp) {
                alert("Data berhasil disimpan")
                setIsRefresh(true)
            } else {
                alert("Gagal simpan data")
            }
        }).catch(err => {
            if (err.length > 0) {
                alert(err[0].message)
            } else if (typeof err) {
                alert(err.message)
            } else {
                console.log(err);
            }
        })

    }
    return (
        <React.Fragment>
            <Row>
                                  <Col xs={12} lg={12} className="mgTop10">
                                    <h6 className="text-center"><b>Tax dan Service untuk semua profil harga</b></h6>
                                  </Col>
                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="service"
                                      label="Service (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="service"
                                      value={form.service}
                                      onChange={handleChange}
                                    />
                                  </Col>

                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="tax"
                                      label="Tax (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="tax"
                                      value={form.tax}
                                      onChange={handleChange}
                                    />
                                  </Col>

                                  <Col xs={12} lg={12} style={{ marginTop: 50 }}>
                                    <h6 className="text-center"><b>Tax dan Service untuk masing-masing profil harga</b></h6>
                                  </Col>
                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="serviceNormal"
                                      label="Service Normal (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="serviceNormal"
                                      value={form.serviceNormal}
                                      onChange={handleChange}
                                    />
                                  </Col>
                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="taxNormal"
                                      label="Tax Normal (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="taxNormal"
                                      value={form.taxNormal}
                                      onChange={handleChange}
                                    />
                                  </Col>


                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="serviceGojek"
                                      label="Service Gojek (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="serviceGojek"
                                      value={form.serviceGojek}
                                      onChange={handleChange}
                                    />
                                  </Col>
                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="taxGojek"
                                      label="Tax Gojek (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="taxGojek"
                                      value={form.taxGojek}
                                      onChange={handleChange}
                                    />
                                  </Col>


                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="serviceGrab"
                                      label="Service Grab (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="serviceGrab"
                                      value={form.serviceGrab}
                                      onChange={handleChange}
                                    />
                                  </Col>
                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="taxGrab"
                                      label="Tax Grab (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="taxGrab"
                                      value={form.taxGrab}
                                      onChange={handleChange}
                                    />
                                  </Col>


                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="serviceLain"
                                      label="Service Lain (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="serviceLain"
                                      value={form.serviceLain}
                                      onChange={handleChange}
                                    />
                                  </Col>
                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="taxLain"
                                      label="Tax Lain (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="taxLain"
                                      value={form.taxLain}
                                      onChange={handleChange}
                                    />
                                  </Col>


                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="serviceLain2"
                                      label="Service Lain 2 (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="serviceLain2"
                                      value={form.serviceLain2}
                                      onChange={handleChange}
                                    />
                                  </Col>
                                  <Col xs={12} lg={6} className="mgTop10">
                                    <TextField
                                      id="taxLain2"
                                      label="Tax Lain 2 (%)"
                                      type="number"
                                      // variant="outlined"
                                      fullWidth
                                      name="taxLain2"
                                      value={form.taxLain2}
                                      onChange={handleChange}
                                    />
                                  </Col>

                           
            <Col xs={12} className="text-center" style={{ marginTop: 20 }}>
                <Button variant="contained" color="primary" onClick={handleSaveUser}>Simpan</Button>
            </Col>
            </Row>

        </React.Fragment>
    );
}
export default TaxService;